import { IAlloy, IShopConnection } from '@/types/shop';
import { useAppSelector } from '../hooks';
import {
  selectAlloy,
  selectShopConnectionRemovingStatus,
  selectShopConnections,
  selectShopConnectionsStatus,
} from './slice';
import { useMemo } from 'react';
import { FETCHING_STATUS } from '@/constants';
import { SHOP_TYPES } from '@/constants/shop';
import _ from 'lodash';

export function useAlloy(): IAlloy | undefined {
  const alloy = useAppSelector(selectAlloy);
  return useMemo(() => alloy, [alloy]);
}
export function useShopConnections(type?: SHOP_TYPES): IShopConnection[] {
  const connections = useAppSelector(selectShopConnections(type));
  return useMemo(() => _.orderBy(connections, ['lastConnectedAt'], ['desc']), [connections]);
}
export function useShopConnectionsStatus(): FETCHING_STATUS {
  const status = useAppSelector(selectShopConnectionsStatus);
  return useMemo(() => status, [status]);
}
export function useShopConnectionRemovingStatus(id: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectShopConnectionRemovingStatus(id));
  return useMemo(() => status, [status]);
}
