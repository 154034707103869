/**
 * @description Component-AICommentReply
 */
import React, { FC, useCallback, useEffect } from 'react';
import './index.less';
import { IMetaComment } from '@/types/inbox';
import { useAigcCommentReply, usePostCommentsSelected, useReplyingComment } from '@/features/inbox/hooks';
import { Button, Checkbox, Input } from 'antd';
import { ReplyMetaPostComment, setAigcCommentReply, setPostCommentSelected } from '@/features/inbox/actions';
import { ReactComponent as SendOutlined } from '@/assets/svg/icon_btnsend.svg';

import { useAppDispatch } from '@/features/hooks';
import { useOrg } from '@/features/settings/hooks';
import { isNA } from '@/utils/aigc';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  item: IMetaComment;
}
const AICommentReply: FC<IPropTypes> = function ({ item }) {
  const aigcReply = useAigcCommentReply(item.commentId);
  const dispatch = useAppDispatch();
  const replying = useReplyingComment(item.id);
  const org = useOrg();
  const selectedComments = usePostCommentsSelected(item.metaPagePostId);
  const dispatchSendMessage = useCallback(() => {
    aigcReply &&
      !isNA(aigcReply) &&
      dispatch(
        ReplyMetaPostComment({
          orgId: org?.id,
          id: item?.id,
          postId: item.metaPagePostId,
          commentId: item?.commentId,
          message: aigcReply,
        }),
      );
  }, [aigcReply, dispatch, org?.id, item?.id, item.metaPagePostId, item?.commentId]);
  const toggleComment = useCallback(
    (checked) => {
      dispatch(
        setPostCommentSelected({
          postId: item.metaPagePostId,
          commentId: item.commentId,
          selected: checked,
        }),
      );
    },
    [dispatch, item.commentId, item.metaPagePostId],
  );
  const updateReply = useCallback(
    (reply: string) => {
      dispatch(
        setAigcCommentReply({
          commentId: item.commentId,
          reply,
        }),
      );
    },
    [dispatch, item.commentId],
  );
  useEffect(() => {
    dispatch(setPostCommentSelected({ commentId: item.commentId, postId: item.metaPagePostId, selected: false }));
  }, [dispatch, item.commentId, item.metaPagePostId]);
  return (
    <div className="cor-a-iComment-reply">
      <div className="cor-a-iComment-reply-title"></div>
      <div className="cor-a-iComment-reply-content">
        <Checkbox
          checked={selectedComments?.includes(item.commentId)}
          onChange={(e) => toggleComment(e.target.checked)}
        />
        <Input.TextArea
          autoSize={{ minRows: 2, maxRows: 5 }}
          onChange={(e) => updateReply(e.target.value)}
          autoFocus={true}
          value={aigcReply}
        />
        <Button
          style={{ height: 24 }}
          disabled={!aigcReply}
          loading={replying}
          type="link"
          onClick={dispatchSendMessage}>
          <SendOutlined />
        </Button>
      </div>
    </div>
  );
};

export default AICommentReply;
