/**
 * @description Component-Features
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import { ReactComponent as Icon_feature_01 } from './assets/icon_feature_01.svg';
import { ReactComponent as Icon_feature_02 } from './assets/icon_feature_02.svg';
import unnamed_1 from './assets/unnamed_1.png';
import unnamed_2 from './assets/unnamed_2.png';
import unnamed_3 from './assets/unnamed_3.png';
interface IPropTypes {
  className?: string;
}
const Features: FC<IPropTypes> = function () {
  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };
  return (
    <div className={`cor-features`}>
      <div className="cor-features-top">
        <div className="cor-features-container cor-features-type1 wow">
          <div className="cor-features-container-left">
            <div className="cor-features-container-left-1">Integrate data seamlessly to maximize ROI</div>
            <div className="cor-features-container-left-2">
              Integrate all your brand data—emails, social comments, and reviews—in one place effortlessly. Automate
              routine tasks with our smart AI to save time and cut costs to give a significant boosting to your ROI.
            </div>
            <div className="cor-features-container-left-3" onClick={handleScrollToBottom}>
              Book a Demo
            </div>
          </div>
          <img className="cor-features-container-right" src={unnamed_1} />
          <div className="cor-features-container-head cor-features-container-head1">
            <div className="cor-features-container-head-tips1">30%</div> <Icon_feature_01 />{' '}
            <div className="cor-features-container-head-tips2">
              in <b>ROI</b>
            </div>
            <div className="cor-features-container-head-left"></div>
            <div className="cor-features-container-head-right">
              <div></div>
            </div>
          </div>
        </div>
        <div className="cor-features-container cor-features-type2 wow">
          <div className="cor-features-container-left">
            <div className="cor-features-container-left-1">
              In-depth brand analysis power personalized interactions{' '}
            </div>
            <div className="cor-features-container-left-2">
              Turn casual visitors into loyal customers with our in-depth brand analysis that provides valuable insights
              into customer behavior, enabling you to tailor interactions and craft experiences that resonate.
            </div>
            <div className="cor-features-container-left-3" onClick={handleScrollToBottom}>
              Book a Demo
            </div>
          </div>
          <img className="cor-features-container-right" src={unnamed_2} />
          <div className="cor-features-container-head cor-features-container-head2">
            <div className="cor-features-container-head-tips1">30%</div> <Icon_feature_01 />{' '}
            <div className="cor-features-container-head-tips2">
              in <b>Conversion</b>
            </div>
            <div className="cor-features-container-head-left"></div>
            <div className="cor-features-container-head-right">
              <div></div>
            </div>
          </div>
        </div>
        <div className="cor-features-container cor-features-type3 wow">
          <div className="cor-features-container-left">
            <div className="cor-features-container-left-1">Deliver adaptive, real-time customer satisfaction</div>
            <div className="cor-features-container-left-2">
              Deliver exceptional customer service with our AI-powered platform that puts customers first. Automate
              accurate, timely responses and continuously improve with adaptive learning, ensuring your customers feel
              valued and taken care of.
            </div>
            <div className="cor-features-container-left-3" onClick={handleScrollToBottom}>
              Book a Demo
            </div>
          </div>
          <img className="cor-features-container-right" src={unnamed_3} />
          <div className="cor-features-container-head cor-features-container-head3">
            <div className="cor-features-container-head-tips1">98%</div>{' '}
            <div className="cor-features-container-head-tips2">
              in <b>Satisfaction</b>
            </div>
            <div className="cor-features-container-head-left"></div>
            <div className="cor-features-container-head-right">
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div className="cor-features-bottom ">
        <div className="cor-features-bottom-1">
          Our Solution Have Saved Our Clients <b>Millions Of Dollars</b>
        </div>
        <div className="cor-features-bottom-2">
          <b>60%</b>
          <Icon_feature_02 />
          <div>Operational Costs</div>
        </div>
        <div className="cor-features-bottom-3">
          Streamline your marketing operations and free up precious resources while boosting customer engagement.
        </div>
        <div className="cor-features-bottom-dian1"></div>
        <div className="cor-features-bottom-dian2"></div>
        <div className="cor-features-bottom-dian3"></div>
        <div className="cor-features-bottom-dian4"></div>
      </div>
    </div>
  );
};

export default Features;
