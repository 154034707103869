/**
 * @description Component-PostItemModal
 */
import React, { FC } from 'react';
import './index.less';
import { Modal } from 'antd';

import { IMetaPost } from '@/types/inbox';
import classNames from 'classnames';
import PostCommentList from '../PostCommentList';
import { useMediaQueryDevice } from '@/components/MediaQuery';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  fetchingReplies?: boolean;
  open: boolean;
  post: IMetaPost;
  onCancel: () => void;
}
const PostItemModal: FC<IPropTypes> = function ({ open, post, onCancel }) {
  const { deviceType } = useMediaQueryDevice();

  return (
    <Modal
      width={1000}
      open={open}
      footer={false}
      centered={true}
      className={classNames('cor-post-list-item-modal', deviceType)}
      onCancel={onCancel}>
      <PostCommentList postId={post.id} />
    </Modal>
  );
};

export default PostItemModal;
