import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { AppState } from '@auth0/auth0-react';
import { ListArrayStatus, setFulfilledEndListArrayStatus } from '@/types/redux';

import { IMember } from '@/types/team';
import { deleteTeamMember, getTeamMembers, inviteTeamMember, setTeamMembers } from './actions';
import { FETCHING_STATUS } from '@/constants';
export interface ITeamState {
  orgMembers: {
    [org: string]: ListArrayStatus<IMember>;
  };
  memberSavingStatus: {
    [orgId: string]: FETCHING_STATUS;
  };
  memberDeletingStatus: {
    [email: string]: FETCHING_STATUS;
  };
}

const initialState: ITeamState = {
  orgMembers: {},
  memberSavingStatus: {},
  memberDeletingStatus: {},
};

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setTeamMembers, (state, { payload }) => {
      _.set(state.orgMembers, [payload.orgId || ''], setFulfilledEndListArrayStatus(payload.members));
    });
    builder.addCase(getTeamMembers.pending, (state, { meta }) => {
      _.set(state.orgMembers, [meta.arg.orgId, 'status'], FETCHING_STATUS.FETCHING);
    });
    builder.addCase(getTeamMembers.fulfilled, (state, { meta, payload }) => {
      _.set(
        state.orgMembers,
        [meta.arg.orgId],
        setFulfilledEndListArrayStatus(
          payload.data.map((item) => {
            return { ...item, selected: false };
          }),
        ),
      );
    });
    builder.addCase(inviteTeamMember.pending, (state, { meta }) => {
      _.set(state.memberSavingStatus, [meta.arg.orgId], FETCHING_STATUS.FETCHING);
    });
    builder.addCase(inviteTeamMember.fulfilled, (state, { meta }) => {
      _.set(state.memberSavingStatus, [meta.arg.orgId], FETCHING_STATUS.DONE);
    });
    builder.addCase(deleteTeamMember.pending, (state, { meta }) => {
      _.set(state.memberDeletingStatus, [meta.arg.email], FETCHING_STATUS.FETCHING);
    });
    builder.addCase(deleteTeamMember.fulfilled, (state, { meta }) => {
      _.set(state.memberDeletingStatus, [meta.arg.email], FETCHING_STATUS.DONE);
    });
  },
});

export const selectMembers =
  (orgId: string | undefined) =>
  (state: AppState): IMember[] | undefined =>
    _.get(state.team.orgMembers, [orgId || '', 'list']);
export const selectMembersStatus =
  (orgId: string | undefined) =>
  (state: AppState): FETCHING_STATUS | undefined =>
    _.get(state.team.orgMembers, [orgId || '', 'status']);
export const selectMemberSavingStatus =
  (orgId: string | undefined) =>
  (state: AppState): FETCHING_STATUS | undefined =>
    _.get(state.team.memberSavingStatus, [orgId || ''], FETCHING_STATUS.DONE);
export const selectMemberDeletingStatus =
  (email: string | undefined) =>
  (state: AppState): FETCHING_STATUS | undefined =>
    _.get(state.team.memberDeletingStatus, [email || ''], FETCHING_STATUS.DONE);

export default teamSlice.reducer;
