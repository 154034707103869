/**
 * @description Component-WeeklyReport
 */
import React, { FC, useEffect, useMemo, useState } from 'react';
import './index.less';
import { FETCHING_STATUS } from '@/constants';
import { REPORT_TABS } from '@/constants/analysis';
import MDEditor from '@uiw/react-md-editor';
import { DatePicker, Tabs, Skeleton, Card, Empty } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import { getAnalysis } from '@/features/analysis/actions';
import { useAnalysis, useAnalysisStatus } from '@/features/analysis/hooks';
import { useAppDispatch } from '@/features/hooks';
import { useOrg } from '@/features/settings/hooks';
import moment from 'moment';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const WeeklyReport: FC<IPropTypes> = function (props) {
  const org = useOrg();
  const analysis = useAnalysis(org?.id);
  const [tab, setTab] = useState(REPORT_TABS.INSIGHTS);
  const analysisStatus = useAnalysisStatus(org?.id);
  const dispatch = useAppDispatch();
  const [week, setWeek] = useState(moment());
  console.log('🚀 ~ week:', week.valueOf());
  const items = useMemo(
    () => [
      {
        label: 'Insights',
        key: REPORT_TABS.INSIGHTS.toString(),
      },
      {
        label: 'Marketing',
        key: REPORT_TABS.MARKETING.toString(),
      },
      {
        label: 'FAQs',
        key: REPORT_TABS.FAQ.toString(),
      },
    ],
    [],
  );

  useEffect(() => {
    org &&
      dispatch(
        getAnalysis({
          orgId: org?.id,
          weekTs: week.valueOf(),
        }),
      );
  }, [dispatch, org, week]);
  return (
    <div className="cor-weekly-report">
      <div className="cor-weekly-report-title">Weekly Insights on your Business</div>
      <div className="cor-weekly-report-week">
        <DatePicker value={week} onChange={(week) => week && setWeek(week)} picker="week" />
      </div>
      <Tabs items={items} activeKey={tab.toString()} onChange={(key) => setTab(key as REPORT_TABS)} />
      <div
        className={classNames('cor-weekly-report-container', {
          empty: _.isEmpty(analysis) && analysisStatus === FETCHING_STATUS.DONE,
        })}>
        {!analysis && analysisStatus !== FETCHING_STATUS.DONE ? (
          <Skeleton active paragraph={{ rows: 15 }} />
        ) : analysis ? (
          <>
            <div className="cor-weekly-report-container-left">
              {analysis?.insights && tab === REPORT_TABS.INSIGHTS && (
                <Card className="cor-weekly-report-section">
                  <MDEditor.Markdown source={analysis?.insights} />
                </Card>
              )}
              {analysis?.marketing && tab === REPORT_TABS.MARKETING && (
                <Card className="cor-weekly-report-section">
                  <MDEditor.Markdown source={analysis?.marketing} />
                </Card>
              )}
              {analysis?.faqs && tab === REPORT_TABS.FAQ && (
                <Card className="cor-weekly-report-section">
                  <MDEditor.Markdown source={analysis?.faqs} />
                </Card>
              )}
            </div>
            <div className="cor-weekly-report-container-right"></div>
          </>
        ) : (
          <Empty description="Analysis not available yet!" />
        )}
      </div>
    </div>
  );
};

export default WeeklyReport;
