/**
 * @description Component-SettingDefault
 */
import React, { FC, useCallback } from 'react';
import { Button } from 'antd';

import './index.less';
import { ITemplate } from '@/types/settings';
import { useOrg, useTemplateSettingStatus } from '@/features/settings/hooks';
import { useAppDispatch } from '@/features/hooks';
import { FETCHING_STATUS } from '@/constants';
import { setDefaultTemplate } from '@/features/settings/actions';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  template: ITemplate;
}
const SettingDefault: FC<IPropTypes> = function ({ template }) {
  const org = useOrg();
  const dispatch = useAppDispatch();
  const setting = useTemplateSettingStatus(org?.id, template.id);
  const onSetTemplate = useCallback(() => {
    org && template.id && dispatch(setDefaultTemplate({ org: org.id, id: template.id }));
  }, [dispatch, org, template]);

  return (
    <div className="cor-setting-default">
      <Button
        size="small"
        disabled={setting === FETCHING_STATUS.FETCHING}
        loading={setting === FETCHING_STATUS.FETCHING}
        onClick={onSetTemplate}>
        Set as default
      </Button>
    </div>
  );
};

export default SettingDefault;
