import { IResponse } from '@/types/settings';
import { IPolicies, IUserInfo } from '@/types/users';
import { axiosGet, axiosPost } from '@/utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getUserInfo = createAsyncThunk(
  'user/getUserInfo',
  async ({}: { email: string }): Promise<IResponse<IUserInfo> | undefined> => {
    try {
      const { data } = await axiosGet(`${process.env.REACT_APP_API_ROOT}pay/stripe/subscription_status`);
      return data;
    } catch (e) {
      console.log('🚀 ~ file: action.ts:13 ~ e:', e);
      return undefined;
    }
  },
);
export const getUserPolicies = createAsyncThunk(
  'user/getUserPolicies',
  async (): Promise<IResponse<IPolicies> | undefined> => {
    try {
      const { data } = await axiosGet(`${process.env.REACT_APP_API_ROOT}permission/policies`);
      return data;
    } catch (e) {
      console.log('🚀 ~ file: action.ts:13 ~ e:', e);
      return undefined;
    }
  },
);
export const assignUserPolicies = createAsyncThunk(
  'user/assignUserPolicies',
  async ({ role, email }: { role: string; email: string }): Promise<IResponse<void> | undefined> => {
    try {
      const { data } = await axiosPost(`${process.env.REACT_APP_API_ROOT}permission/assign`, {
        role,
        email,
      });
      return data;
    } catch (e) {
      console.log('🚀 ~ file: action.ts:13 ~ e:', e);
      return undefined;
    }
  },
);
export const subscribe = createAsyncThunk(
  'user/getUserInfo',
  async ({
    priceId,
    success_url,
    cancel_url,
  }: {
    priceId: string;
    success_url: string;
    cancel_url: string;
  }): Promise<IResponse<IUserInfo> | undefined> => {
    try {
      const {
        data: { data },
      } = await axiosPost(`${process.env.REACT_APP_API_ROOT}pay/stripe/create_subscription`, {
        priceId,
        success_url,
        cancel_url,
      });
      if (data.url) {
        window.location.href = data.url;
      }
    } catch (e) {
      console.log('🚀 ~ file: action.ts:13 ~ e:', e);
      return undefined;
    }
  },
);
