import { memo, useEffect } from 'react';
import { useAppDispatch } from '../hooks';
import { getComments } from './actions';

function useGetComments(ticketId: string) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (ticketId) {
      dispatch(
        getComments({
          ticketId,
        }),
      );
    }
  }, [dispatch, ticketId]);
}

interface CommentGlobalEffectProps {
  ticketId: string;
}

function CommentGlobalEffect({ ticketId }: CommentGlobalEffectProps): null {
  useGetComments(ticketId);
  return null;
}

export default memo(CommentGlobalEffect);
