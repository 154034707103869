/**
 * @description Component-MerchanReplyBox
 */
import React, { FC, useCallback, useState } from 'react';
import './index.less';
import { useAppDispatch } from '@/features/hooks';
import { Button, Input } from 'antd';
import { ReactComponent as SendOutlined } from '@/assets/svg/icon_btnsend.svg';
import _ from 'lodash';
import { useCurrentMerchant, useLanguages, useMerchantSocket, useSessionReplying } from '@/features/demo/hooks';
import { setSessionMessage } from '@/features/demo/actions';
import { IMessageRole } from '@/types/aigc';
import { v4 as uuidv4 } from 'uuid';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  sessionId: string;
}
const MerchantReplyBox: FC<IPropTypes> = function ({ sessionId }) {
  const dispatch = useAppDispatch();
  const [currentText, setCurrentText] = useState('');
  const languages = useLanguages();
  const replying = useSessionReplying(sessionId);
  const merchant = useCurrentMerchant();
  const socket = useMerchantSocket(merchant?.id);
  const inputMessage = (messageText: string) => {
    setCurrentText(messageText);
  };
  const replyToCustomer = useCallback(() => {
    if (merchant) {
      socket?.emit('message', {
        question: currentText,
        sessionId,
        language: _.get(languages, [0, 'name']),
      });
      dispatch(
        setSessionMessage({
          sessionId,
          message: {
            id: uuidv4(),
            content: currentText,
            role: IMessageRole.USER,
            sessionId,
            createdAt: Date.now(),
          },
        }),
      );
      setCurrentText('');
    }
  }, [currentText, dispatch, languages, merchant, sessionId, socket]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (currentText && !replying) {
        replyToCustomer();
      }
    }
  };

  return (
    <div className="cor-merchant-reply-box">
      <div className="cor-merchant-reply-box-input-container">
        <div className="textarea">
          <Input.TextArea
            disabled={!socket}
            onChange={(e) => inputMessage(e.target.value)}
            value={currentText}
            onKeyDown={(e) => handleKeyDown(e)}
            rows={1}
            placeholder="Ask here..."
            bordered
          />
          <Button onClick={replyToCustomer} disabled={!currentText || replying || !socket}>
            <SendOutlined />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MerchantReplyBox;
