import React, { FC, useState, useEffect, useMemo } from 'react';
import { Card, Divider, Button, Tag, Input, Select, message } from 'antd';
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import './index.less';
import { useNavigate, useParams } from 'react-router-dom';
import { useTicket } from '@/features/plugin/hooks';
import { useOrg } from '@/features/settings/hooks';
import moment from 'moment';
import TicketGlobalEffect from '../globalEffect';
import { DATE_FORMAT_DETAIL } from '@/constants';
import DeleteTicket from './../DeleteTicket';
import { ReactComponent as EditIcon } from '@/assets/svg/icon_edit_linear.svg';
import { useTeamMembers } from '@/features/team/hooks';
import { useAppDispatch } from '@/features/hooks';
import { updateTicket, delTicketAttachment } from '@/features/plugin/actions';
import { TICKET_PRIORITY, TICKET_STATUS } from '@/constants/Tickets';
import CommentList from './CommentList';
import CommentInput from './CommentInput';
import CorUploader from './CorUploader';
import { useComments } from '@/features/comment/hooks';
import CommentGlobalEffect from '@/features/comment/globalEffect';
import { IComment } from '@/types/comment';
const { Option } = Select;

interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}

const TicketDetail: FC<IPropTypes> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const org = useOrg();
  const members = useTeamMembers(org?.id);
  const membersFiltered = useMemo(
    () => members,
    // ?.filter((m) => m.status !== MEMBER_STATUS.PENDING)
    [members],
  );

  const { id } = useParams();
  const ticket = useTicket(org?.id, id);
  const comments = useComments(id);
  const [editField, setEditField] = useState<string | null>(null);
  const [editingComment, setEditingComment] = useState<IComment | null>(null); // 管理当前编辑的评论
  const [fieldValues, setFieldValues] = useState({
    title: ticket?.title || '',
    customerEmail: ticket?.customerEmail || '',
    content: ticket?.content || '',
    assignees: ticket?.assignees || [],
    priority: ticket?.priority || '',
    status: ticket?.status || '',
  });
  useEffect(() => {
    if (ticket?.id) {
      setFieldValues({
        title: ticket.title,
        customerEmail: ticket.customerEmail,
        content: ticket.content,
        assignees: ticket.assignees,
        priority: ticket.priority,
        status: ticket.status,
      });
    }
  }, [ticket]);

  const handleEditClick = (field: string) => {
    setEditField(field);
  };

  const handleInputChange = (field: string, value: any) => {
    setFieldValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    // 这里可以添加保存逻辑，更新后端数据
    if (!ticket || !org) return;
    await dispatch(
      updateTicket({
        ...ticket,
        ...fieldValues,
        orgId: org?.id,
        assignees: fieldValues.assignees.join(','),
      }),
    ).unwrap();
    setEditField(null);
  };
  const handleCancel = async () => {
    ticket &&
      setFieldValues({
        title: ticket.title,
        customerEmail: ticket.customerEmail,
        content: ticket.content,
        assignees: ticket.assignees,
        priority: ticket.priority,
        status: ticket.status,
      });
    setEditField(null);
  };
  const onDelete = async (name: string) => {
    await dispatch(
      delTicketAttachment({
        ticketId: id || '',
        attachName: name,
      }),
    ).unwrap();
  };
  const handleOpen = async (responseURL: string) => {
    window.open(`${process.env.REACT_APP_API_ROOT}s3/file?url=${responseURL} `);
  };
  const handleEditComment = (comment: IComment) => {
    setEditingComment(comment); // 设置当前编辑的评论
  };

  const handleCommentInputClose = () => {
    setEditingComment(null); // 清除编辑状态
  };
  return (
    <div className="cor-ticket-detail" style={{ padding: '20px 5px' }}>
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                type="text"
                shape="circle"
                icon={<ArrowLeftOutlined />}
                onClick={() => navigate(-1)}
                className="back-button"
              />
              <span style={{ marginLeft: '10px' }}>Ticket Details</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginLeft: '10px' }}> {id && <DeleteTicket id={id} />}</div>
            </div>
          </div>
        }
        bordered={false}
        className="ticket-card">
        <Card type="inner" title="Basic Information" className="inner-card">
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <strong style={{ minWidth: '100px' }}>Title:</strong>
            {editField === 'title' ? (
              <>
                <Input
                  value={fieldValues.title}
                  onChange={(e) => handleInputChange('title', e.target.value)}
                  style={{ flex: 1 }}
                />
                {/* <SaveOutlined
                      onClick={() => handleSave('title')}
                      style={{ marginLeft: '5px', cursor: 'pointer', fontSize: '20px' }}
                    /> */}
                <Button type="link" onClick={() => handleSave('title')}>
                  Save
                </Button>
                <Button type="link" onClick={() => handleCancel()} style={{ marginLeft: '5px', color: '#000' }}>
                  Cancel
                </Button>
              </>
            ) : (
              <span
                onClick={() => handleEditClick('title')}
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                }}>
                {fieldValues.title || <span style={{ opacity: 0.5 }}>-</span>}
                <EditIcon className="edit-icon" style={{ marginLeft: '5px', cursor: 'pointer' }} />
              </span>
            )}
          </p>
          <p style={{ display: 'flex', flex: 1 }} className="description">
            <strong style={{ minWidth: '100px' }}>Description:</strong>
            {editField === 'content' ? (
              <>
                <Input.TextArea
                  value={fieldValues.content}
                  onChange={(e) => handleInputChange('content', e.target.value)}
                  style={{ flex: 1 }}
                  rows={10}
                />
                <Button type="link" onClick={() => handleSave('content')}>
                  Save
                </Button>
                <Button type="link" onClick={() => handleCancel()} style={{ marginLeft: '5px', color: '#000' }}>
                  Cancel
                </Button>
              </>
            ) : (
              <div
                style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}
                onClick={() => handleEditClick('content')}>
                <span className="description-span">
                  {fieldValues.content || <span style={{ opacity: 0.5 }}>-</span>}
                </span>
                <EditIcon className="edit-icon" style={{ marginLeft: '5px', cursor: 'pointer' }} />
              </div>
            )}
          </p>
          <Divider dashed />
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <strong style={{ minWidth: '100px' }}>Customer Email:</strong>
            {editField === 'customerEmail' ? (
              <>
                <Input
                  value={fieldValues.customerEmail}
                  onChange={(e) => handleInputChange('customerEmail', e.target.value)}
                  style={{ flex: 1 }}
                />
                <Button type="link" onClick={() => handleSave('customerEmail')}>
                  Save
                </Button>
                <Button type="link" onClick={() => handleCancel()} style={{ marginLeft: '5px', color: '#000' }}>
                  Cancel
                </Button>
              </>
            ) : (
              <span
                onClick={() => handleEditClick('customerEmail')}
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                }}>
                {fieldValues.customerEmail || <span style={{ opacity: 0.5 }}>-</span>}
                <EditIcon className="edit-icon" style={{ marginLeft: '5px', cursor: 'pointer' }} />
              </span>
            )}
          </p>
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <strong style={{ minWidth: '100px' }}>Merchant Email:</strong> {ticket?.merchantEmail}
          </p>
        </Card>

        <Card type="inner" title="Assignees" className="inner-card">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <strong style={{ minWidth: '100px' }}>Assignees:</strong>
            {editField === 'assignees' ? (
              <>
                <Select
                  mode="multiple"
                  value={fieldValues.assignees}
                  onChange={(value) => handleInputChange('assignees', value)}
                  style={{ flex: 1 }}>
                  {membersFiltered?.map((member) => (
                    <Option key={member.email} value={member.email}>
                      {member.email}
                    </Option>
                  ))}
                </Select>
                <Button type="link" onClick={() => handleSave('assignees')}>
                  Save
                </Button>
                <Button type="link" onClick={() => handleCancel()} style={{ marginLeft: '5px', color: '#000' }}>
                  Cancel
                </Button>
              </>
            ) : (
              <div
                onClick={() => handleEditClick('assignees')}
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                }}>
                {fieldValues.assignees.length ? (
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 8,
                    }}>
                    {fieldValues.assignees.map((assignee) => (
                      <Tag key={assignee} color="#2db7f5">
                        {assignee}
                      </Tag>
                    ))}
                  </div>
                ) : (
                  <span style={{ opacity: 0.5 }}>No assignees</span>
                )}
                <EditIcon className="edit-icon" style={{ marginLeft: '5px', cursor: 'pointer' }} />
              </div>
            )}
          </div>
        </Card>

        <Card type="inner" title="Detailed Information" className="inner-card">
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <strong style={{ minWidth: '100px' }}>Priority:</strong>
            {editField === 'priority' ? (
              <>
                <Select
                  value={fieldValues.priority}
                  onChange={(value) => handleInputChange('priority', value)}
                  style={{ flex: 1 }}>
                  <Select.Option value={TICKET_PRIORITY.HIGH}>High</Select.Option>
                  <Select.Option value={TICKET_PRIORITY.MEDIUM}>Medium</Select.Option>
                  <Select.Option value={TICKET_PRIORITY.LOW}>Low</Select.Option>
                </Select>
                <Button type="link" onClick={() => handleSave('priority')}>
                  Save
                </Button>
                <Button type="link" onClick={() => handleCancel()} style={{ marginLeft: '5px', color: '#000' }}>
                  Cancel
                </Button>
              </>
            ) : (
              <span
                onClick={() => handleEditClick('priority')}
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  textTransform: 'capitalize',
                }}>
                {fieldValues.priority || <span style={{ opacity: 0.5 }}>-</span>}
                <EditIcon className="edit-icon" style={{ marginLeft: '5px', cursor: 'pointer' }} />
              </span>
            )}
          </p>
          <p>
            <strong>Sequence:</strong> {ticket?.sequence}
          </p>
          <p>
            <strong>Source:</strong> {ticket?.source}
          </p>
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <strong style={{ minWidth: '100px' }}>Status:</strong>
            {editField === 'status' ? (
              <>
                <Select
                  value={fieldValues.status}
                  onChange={(value) => handleInputChange('status', value)}
                  style={{ flex: 1 }}>
                  <Select.Option value={TICKET_STATUS.PENDING}>Pending</Select.Option>
                  <Select.Option value={TICKET_STATUS.PROCESSING}>Processing</Select.Option>
                  <Select.Option value={TICKET_STATUS.Completed}>Completed</Select.Option>
                </Select>
                <Button type="link" onClick={() => handleSave('status')}>
                  Save
                </Button>
                <Button type="link" onClick={() => handleCancel()} style={{ marginLeft: '5px', color: '#000' }}>
                  Cancel
                </Button>
              </>
            ) : (
              <span
                onClick={() => handleEditClick('status')}
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                  textTransform: 'capitalize',
                }}>
                {fieldValues.status || <span style={{ opacity: 0.5 }}>-</span>}
                <EditIcon className="edit-icon" style={{ marginLeft: '5px', cursor: 'pointer' }} />
              </span>
            )}
          </p>
          <Divider dashed />
          <p>
            <strong>Team ID:</strong> {ticket?.teamId}
          </p>
          <p>
            <strong>Track ID:</strong> {ticket?.trackId}
          </p>
          <p>
            <strong>Type:</strong> {ticket?.type}
          </p>
          <Divider dashed />
          <p>
            <strong>Created At:</strong> {moment(ticket?.createdAt).format(DATE_FORMAT_DETAIL)}
          </p>
          <p>
            <strong>Updated At:</strong> {moment(ticket?.updatedAt).format(DATE_FORMAT_DETAIL)}
          </p>
        </Card>
        <Card title="Upload attachment" type="inner" className="inner-card">
          {ticket?.attachments && ticket.attachments.length > 0 && (
            <div className="attachment-list">
              {ticket.attachments.map((item, index) => (
                <div key={index} className="attachment-item">
                  <div onClick={() => handleOpen(item.attach)}>{item.name}</div>
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => onDelete(item.name)}
                    danger
                    className="attachment-delete">
                    Delete
                  </Button>
                </div>
              ))}
            </div>
          )}
          <CorUploader id={id}></CorUploader>
        </Card>
      </Card>
      <Card type="inner" title="Comments" className="inner-card-right">
        <div className="comments-input">
          <CommentInput id={id || ''} initialComment={editingComment} onClose={handleCommentInputClose} />
        </div>
        <div style={{ height: '60px' }}></div>
        <div className="comments">
          <CommentList comments={comments || []} onEditComment={handleEditComment} />
        </div>
      </Card>
      <TicketGlobalEffect />
      <CommentGlobalEffect ticketId={id || ''} />
    </div>
  );
};

export default TicketDetail;
