/**
 * @description Component-mobileTestimony
 */
import React, { FC, useState } from 'react';
import sw1_1 from './assets/sw1_1.png';
import sw1_2 from './assets/sw1_2.png';
import sw2_1 from './assets/sw2_1.png';
import sw2_2 from './assets/sw2_2.png';
import sw3_1 from './assets/sw3_1.png';
import sw3_2 from './assets/sw3_2.png';
import sw4_1 from './assets/sw4_1.png';
import sw4_2 from './assets/sw4_2.png';
import './index.less';

interface IPropTypes {
  className?: string;
}
const Testimony: FC<IPropTypes> = function () {
  const [current, setCurrent] = useState(1);
  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };
  return (
    <div className={`cor-mobileTestimony`}>
      <div className={`cor-mobileTestimony-top`}>
        <div className="cor-mobileTestimony-title">
          Customer-First Experiences,
          <br /> <b>Every Step of the Way</b>
        </div>
        <div className="cor-mobileTestimony-subtitle">
          We help brands guide their customers from first click to a lasting relationship.
        </div>
      </div>
      <div className="cor-mobileTestimony-container">
        <div className="cor-mobileTestimony-container-left">
          <div
            className={`cor-mobileTestimony-container-left-1 ${current == 1 ? 'active1' : ''}`}
            onClick={() => setCurrent(1)}>
            <div className="cor-mobileTestimony-container-left-1-yuan"></div>
            <div className="cor-mobileTestimony-container-left-1-btn acbtn">
              Awareness
              <div className="acbtn-right">
                <div></div>
              </div>
            </div>
            {current == 1 && (
              <div className="cor-mobileTestimony-container-left-1-text">View social posts, Comment & Reply</div>
            )}
          </div>
          <div
            className={`cor-mobileTestimony-container-left-2 ${current == 2 ? 'active2' : ''}`}
            onClick={() => setCurrent(2)}>
            <div className="cor-mobileTestimony-container-left-2-yuan"></div>
            <div className="cor-mobileTestimony-container-left-2-btn acbtn">
              Consideration
              <div className="acbtn-right">
                <div></div>
              </div>
            </div>
            {current == 2 && (
              <div className="cor-mobileTestimony-container-left-2-text">View websites, Browse products</div>
            )}
          </div>
          <div
            className={`cor-mobileTestimony-container-left-3 ${current == 3 ? 'active3' : ''}`}
            onClick={() => setCurrent(3)}>
            <div className="cor-mobileTestimony-container-left-3-yuan"></div>
            <div className="cor-mobileTestimony-container-left-3-btn acbtn">
              Conversion
              <div className="acbtn-right">
                <div></div>
              </div>
            </div>
            {current == 3 && (
              <div className="cor-mobileTestimony-container-left-3-text">Check out, Apply discount code</div>
            )}
          </div>
          <div
            className={`cor-mobileTestimony-container-left-4 ${current == 4 ? 'active4' : ''}`}
            onClick={() => setCurrent(4)}>
            <div className="cor-mobileTestimony-container-left-4-yuan"></div>
            <div className="cor-mobileTestimony-container-left-4-btn acbtn">
              Loyalty
              <div className="acbtn-right">
                <div></div>
              </div>
            </div>
            {current == 4 && (
              <div className="cor-mobileTestimony-container-left-4-text">Open email, Return to the website</div>
            )}
          </div>
        </div>
        <div style={{ position: 'relative', flex: 1 }}>
          <div className="cor-mobileTestimony-container-center">
            {current == 1 && <img className="cor-mobileTestimony-container-center-img1 wow" src={sw1_1} />}
            {current == 2 && <img className="cor-mobileTestimony-container-center-img2 wow" src={sw2_1} />}
            {current == 3 && <img className="cor-mobileTestimony-container-center-img3 wow" src={sw3_1} />}
            {current == 4 && <img className="cor-mobileTestimony-container-center-img4 wow" src={sw4_1} />}
          </div>
          {current == 1 && (
            <div
              className="cor-mobileTestimony-container-right cor-mobileTestimony-container-right1 wow"
              style={{ backgroundColor: '#EFFEF9' }}>
              <div className="cor-mobileTestimony-container-right-title">
                <b>Engage Customers Effectively</b> with Social Insights
              </div>
              <img className="cor-mobileTestimony-container-right-img" src={sw1_2} />
            </div>
          )}
          {current == 2 && (
            <div
              className="cor-mobileTestimony-container-right cor-mobileTestimony-container-right2 wow"
              style={{ backgroundColor: '#FFFEEC' }}>
              <div className="cor-mobileTestimony-container-right-title">
                <b>Leverage Customer’s Chat Data</b> to Recommend Perfect Product
              </div>
              <img className="cor-mobileTestimony-container-right-img" src={sw2_2} />
            </div>
          )}
          {current == 3 && (
            <div
              className="cor-mobileTestimony-container-right cor-mobileTestimony-container-right2 wow"
              style={{ backgroundColor: '#FFF3FA' }}>
              <div className="cor-mobileTestimony-container-right-title">
                <b>Convert with Targeted Offers</b> at the Perfect Time
              </div>
              <img className="cor-mobileTestimony-container-right-img" src={sw3_2} />
            </div>
          )}
          {current == 4 && (
            <div
              className="cor-mobileTestimony-container-right cor-mobileTestimony-container-right2 wow"
              style={{ backgroundColor: '#F1F3F9' }}>
              <div className="cor-mobileTestimony-container-right-title">
                <b>Re-Engage Customers</b> with Hyper-Personalized Emails
              </div>
              <img className="cor-mobileTestimony-container-right-img" src={sw4_2} />
            </div>
          )}
        </div>
      </div>
      <div className="cor-mobileTestimony-btn" onClick={handleScrollToBottom}>
        Request a Demo
      </div>
    </div>
  );
};

export default Testimony;
