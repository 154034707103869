/**
 * @description Component-ConversationItem
 */
import React, { FC, useEffect, useMemo } from 'react';
import './index.less';
import classNames from 'classnames';
import stoneIcon from '@/assets/svg/stone.svg';
import MDEditor from '@uiw/react-md-editor';
import Avatar from 'react-avatar';
import dayjs from 'dayjs';
import { DATE_FORMAT_DETAIL } from '@/constants';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import { IDemoMessage } from '@/types/demo';
import { IMessageRole } from '@/types/aigc';
import _ from 'lodash';
import LinkPreviewer from '@/pages/Plugin/PluginSessions/SessionList/LinkPreviewer';
import ExampleItem from './ExampleItem';

interface IPropTypes {
  conversation?: IDemoMessage;
  className?: string;
  onInputMessageChange?: (message: string) => void;
}
const MerchantItem: FC<IPropTypes> = function ({ conversation, onInputMessageChange }) {
  const { deviceType } = useMediaQueryDevice();
  const recommendations = useMemo(() => {
    if (conversation?.role !== IMessageRole.ASSISTANT) {
      return [];
    }
    const rec = _.filter(conversation?.functions, (f) => f.name === 'redirect');
    return rec;
  }, [conversation?.functions, conversation?.role]);
  useEffect(() => {
    const links = document.querySelectorAll('.cor-merchant-item a');
    links.forEach((link) => {
      link.addEventListener('click', (event) => {
        // Prevent the default action (navigation)
        event.preventDefault();

        // Get the href attribute value
        const href = link.getAttribute('href');

        // Do something with the href value
        href && window.open(href, '_blank', 'noopener,noreferrer');
      });
    });
  }, [conversation?.content]);
  return (
    <>
      <div className={classNames('cor-merchant-item', conversation?.role, deviceType)}>
        <div className="cor-merchant-item-top">
          <div className="ant-avatar">
            {conversation?.role === IMessageRole.ASSISTANT ? (
              <img src={stoneIcon} width={24} height={24} />
            ) : (
              <Avatar className="ant-avatar" round size="24" name={'Me'} />
            )}
          </div>
          <div className="cor-merchant-item-content">
            <div className="cor-merchant-item-content-top">
              <div className="cor-merchant-item-timestamp">
                <div className="cor-merchant-item-timestamp-name">
                  {conversation?.role === IMessageRole.USER ? 'Me' : 'AI'}
                </div>
                <div className="date-and-option">
                  <span className="date">{dayjs(conversation?.createdAt).format(DATE_FORMAT_DETAIL)}</span>
                </div>
              </div>

              <MDEditor.Markdown className="cor-merchant-item-text" source={conversation?.content || ''} />
            </div>
          </div>
        </div>
        {conversation?.examples && (
          <div className="cor-merchant-item-examples">
            {conversation?.examples?.map((e, index) => (
              <ExampleItem
                onInputMessageChange={onInputMessageChange}
                className="cor-merchant-item-examples-item"
                key={index}
                example={e}
              />
            ))}
          </div>
        )}
        {recommendations.map((recommendation, index) => (
          <LinkPreviewer key={index} url={recommendation?.params?.productUrl || recommendation.params.youtubeUrl} />
        ))}
      </div>
    </>
  );
};

export default MerchantItem;
