import { useMemo } from 'react';
import { useAppSelector } from '../hooks';
import {
  selectMailSettings,
  selectKnowledgeBases,
  selectNewKnowledgeBases,
  selectOrg,
  selectOrgKey,
  selectSavingMailStatus,
  selectSavingKnowledgeStatus,
  selectDeletingMailStatus,
  selectDeletingKnowledgeStatus,
  selectDeletingNewKnowledgeStatus,
  selectMailSettingsStatus,
  selectKnowledgeBasesStatus,
  selectTemplate,
  selectTemplateStatus,
  selectTemplateFetchingStatus,
  selectDataSource,
  selectSignature,
  selectSignatureFetchingStatus,
  selectSignatureStatus,
  setTemplateStatus,
  selectRequiredKnowledgeBase,
  selectRequiredKnowledgeBaseStatus,
  selectKnowledgeWebpagesStatus,
  selectKnowledgeWebpages,
  selectDeletingKnowledgeWebpageStatus,
  selectSavingKnowledgeWebpageStatus,
  selectSocialSettings,
  selectSocialSettingsStatus,
  selectDeletingSocialStatus,
  selectSavingSocialStatus,
  selectOrgAutoReplySettings,
  selectOrgAutoReplySettingsStatus,
  selectSavingNewKnowledgeStatus,
  selectNewKnowledgeChunks,
  selectNewKnowledgeChunksStatus,
} from './slice';
import {
  IAutoReplySettings,
  // IDataSource,
  IKnowledgeBase,
  IMailSetting,
  IMailSignature,
  INewKnowledgeBase,
  IOrganization,
  IRequiredKnowledgeBase,
  ISocialSetting,
  ITemplate,
} from '@/types/settings';
import _ from 'lodash';
import { FETCHING_STATUS } from '@/constants';
import { ObjectStatus } from '@/types/redux';

export function useOrgKey(org: string): string | undefined {
  const key = useAppSelector(selectOrgKey(org));
  return useMemo(() => key, [key]);
}
export function useMailSettings(org: string | undefined): IMailSetting[] {
  const settings = useAppSelector(selectMailSettings(org));
  return useMemo(() => _.values(settings), [settings]);
}
export function useMailSettingsStatus(org: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectMailSettingsStatus(org));
  return useMemo(() => status, [status]);
}
export function useMailSavingStatus(org: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectSavingMailStatus(org));
  return useMemo(() => status, [status]);
}
export function useMailDeletingStatus(org: string | undefined, id: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectDeletingMailStatus(org, id));
  return useMemo(() => status, [status]);
}
export function useKnowledgeSavingStatus(org: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectSavingKnowledgeStatus(org));
  return useMemo(() => status, [status]);
}

export function useNewKnowledgeSavingStatus(org: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectSavingNewKnowledgeStatus(org));
  return useMemo(() => status, [status]);
}

export function useKnowledgeDeletingStatus(org: string | undefined, id: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectDeletingKnowledgeStatus(org, id));
  return useMemo(() => status, [status]);
}
export function useNewKnowledgeDeletingStatus(org: string | undefined, id: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectDeletingNewKnowledgeStatus(org, id));
  return useMemo(() => status, [status]);
}
export function useKnowledgeBases(org: string | undefined): IKnowledgeBase[] {
  const bases = useAppSelector(selectKnowledgeBases(org));
  return useMemo(() => _.orderBy(_.values(bases), ['updatedAt'], ['desc']), [bases]);
}

export function useNewKnowledgeBases(org: string | undefined): INewKnowledgeBase | undefined {
  const bases = useAppSelector(selectNewKnowledgeBases(org));
  return bases;
}

export function useNewKnowledgeChunks(org: string | undefined): INewKnowledgeBase[] {
  const chunks = useAppSelector(selectNewKnowledgeChunks(org));
  return useMemo(() => _.orderBy(_.values(chunks), ['updatedAt'], ['desc']), [chunks]);
}
export function useNewKnowledgeChunksStatus(org: string | undefined): FETCHING_STATUS | undefined {
  const status = useAppSelector(selectNewKnowledgeChunksStatus(org));
  return useMemo(() => status, [status]);
}

export function useRequiredKnowledgeBase(org: string | undefined): IRequiredKnowledgeBase | undefined {
  const base = useAppSelector(selectRequiredKnowledgeBase(org));
  return useMemo(() => base, [base]);
}
export function useRequiredKnowledgeBaseStatus(org: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectRequiredKnowledgeBaseStatus(org));
  return useMemo(() => status, [status]);
}
export function useDataSource(org: string | undefined, id: string): IKnowledgeBase | undefined {
  const dataSource = useAppSelector(selectDataSource(org, id));
  return useMemo(() => dataSource, [dataSource]);
}
export function useKnowledgeBasesStatus(org: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectKnowledgeBasesStatus(org));
  return useMemo(() => status, [status]);
}
export function useNewKnowledgeBasesStatus(org: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectKnowledgeBasesStatus(org));
  return useMemo(() => status, [status]);
}
export function useSignature(org: string | undefined): IMailSignature[] {
  const signatures = useAppSelector(selectSignature(org));
  return useMemo(() => _.values(signatures), [signatures]);
}
export function useSignatureStatus(org: string | undefined, id?: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectSignatureStatus(org, id));
  return useMemo(() => status, [status]);
}
export function useSignatureFetchingStatus(org: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectSignatureFetchingStatus(org));
  return useMemo(() => status, [status]);
}
export function useTemplates(org: string | undefined): ITemplate[] {
  const templates = useAppSelector(selectTemplate(org));
  return useMemo(() => _.orderBy(_.values(templates), ['updatedAt'], ['desc']), [templates]);
}
export function useTemplateStatus(org: string | undefined, id?: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectTemplateStatus(org, id));
  return useMemo(() => status, [status]);
}
export function useTemplateSettingStatus(org: string | undefined, id?: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(setTemplateStatus(org, id));
  return useMemo(() => status, [status]);
}
export function useTemplateFetchingStatus(org: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectTemplateFetchingStatus(org));
  return useMemo(() => status, [status]);
}
export function useOrg(): IOrganization | undefined {
  const org = useAppSelector(selectOrg);
  return useMemo(() => org, [org]);
}
export function useKnowledgeWebpages(org: string | undefined): IKnowledgeBase[] {
  const bases = useAppSelector(selectKnowledgeWebpages(org));
  return useMemo(() => _.orderBy(_.values(bases), ['updatedAt'], ['desc']), [bases]);
}
export function useKnowledgeWebpage(org: string | undefined,id?:string): IKnowledgeBase|undefined{
  const bases = useKnowledgeWebpages(org)
  return useMemo(() => bases?.find((t) => t.id === id), [id, bases]);
}
export function useKnowledgeWebpagesStatus(org: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectKnowledgeWebpagesStatus(org));
  return useMemo(() => status, [status]);
}
export function useKnowledgeWebpageDeletingStatus(org: string | undefined, id: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectDeletingKnowledgeWebpageStatus(org, id));
  return useMemo(() => status, [status]);
}
export function useKnowledgeWebpageSavingStatus(org: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectSavingKnowledgeWebpageStatus(org));
  return useMemo(() => status, [status]);
}
export function useAllSettingsDone(org: string | undefined): boolean {
  const [webBase] = useKnowledgeWebpages(org);
  const [base] = useKnowledgeBases(org);

  return useMemo(() => !!base || !!webBase || false, [base, webBase]);
}

export function useSocialSettings(): ObjectStatus<ISocialSetting> {
  const social = useAppSelector(selectSocialSettings);
  return useMemo(() => social, [social]);
}

export function useSocialSettingsStatus(): FETCHING_STATUS {
  const status = useAppSelector(selectSocialSettingsStatus());
  return useMemo(() => status, [status]);
}

export function useSocialDeletingStatus(): FETCHING_STATUS {
  const status = useAppSelector(selectDeletingSocialStatus());
  return useMemo(() => status, [status]);
}
export function useSocialSavingStatus(): FETCHING_STATUS {
  const status = useAppSelector(selectSavingSocialStatus());
  return useMemo(() => status, [status]);
}
export function useAutoReplySettings(orgId: string | undefined): IAutoReplySettings | undefined {
  const settings = useAppSelector(selectOrgAutoReplySettings(orgId));
  return useMemo(() => settings, [settings]);
}
export function useAutoReplySettingsStatus(orgId: string | undefined): FETCHING_STATUS | undefined {
  const status = useAppSelector(selectOrgAutoReplySettingsStatus(orgId));
  return useMemo(() => status, [status]);
}
