import { createSlice } from '@reduxjs/toolkit';
// import _ from 'lodash';

// import { IMail, MAIL_FLAGS } from '@/types/email';
// import { FETCHING_STATUS } from '@/constants';
import { setAuthState, setCurrentToken } from './actions';
import { AppState } from '@/features/store';
import { Auth0ContextInterface, User } from '@auth0/auth0-react';
export interface IAuthState {
  currentToken: string;
  currentOrg: string;
  authState: Auth0ContextInterface<User> | undefined;
}

const initialState: IAuthState = {
  currentToken: '',
  currentOrg: '',
  authState: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setCurrentToken, (state, { payload }) => {
      state.currentToken = payload;
    });

    builder.addCase(setAuthState, (state, { payload }) => {
      state.authState = payload;
    });
  },
});
export const selectCurrentToken = (state: AppState): string => state.auth.currentToken;

export default authSlice.reducer;
