/**
 * @description Component-ExampleItem
 */
import './index.less';

import classNames from 'classnames';
import React, { FC, useState } from 'react';
interface IPropTypes {
  className?: string;
  example: string;
  onInputMessageChange?: (message: string) => void;
}
const ExampleItem: FC<IPropTypes> = function ({ example, onInputMessageChange }) {
  const [disabled, setDisabled] = useState(false);
  return (
    <div
      onClick={() => {
        if (disabled) {
          return;
        }
        onInputMessageChange && onInputMessageChange(example);
        setDisabled(true);
        setTimeout(() => {
          setDisabled(false);
        }, 1000 * 60);
      }}
      key={example}
      className={classNames('cor-merchant-item-examples-item', { disabled })}>
      {example}
    </div>
  );
};

export default ExampleItem;
