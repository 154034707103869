import React, { FC, useMemo, useState } from 'react';
import './index.less';
import { useOrg } from '@/features/settings/hooks';
import {
  useSelectTicketGetParam,
  useSelectTicketListTotal,
  useTickets,
  useTicketsStatus,
} from '@/features/plugin/hooks';
import { DATE_FORMAT, FETCHING_STATUS } from '@/constants';
import { Card, Table } from 'antd';
import { ITicket } from '@/types/plugin';
import { ColumnType, TableProps } from 'antd/lib/table';
import { ReactComponent as AddIcon } from '@/assets/svg/icon_add_16.svg';
import TicketGlobalEffect from './globalEffect';
import moment from 'moment';
import TeamGlobalEffect from '@/features/team/globalEffect';
import EditTicket from './EditTicket';
import Assignee from './Assignee';
import { setTicketGetParam } from '@/features/plugin/actions';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/features/hooks';
import { TicketParamSort, TicketParamSortBy } from '@/features/plugin/slice';
import DeleteTicket from './DeleteTicket';
import QueryHeader from './QueryHeader';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const stopPropagation = (e: { stopPropagation: () => void }) => {
  e.stopPropagation();
};
const Tickets: FC<IPropTypes> = () => {
  const dispatch = useAppDispatch();
  const org = useOrg();
  const tickets = useTickets(org?.id);
  const ticketsStatus = useTicketsStatus(org?.id);
  const param = useSelectTicketGetParam();
  const total = useSelectTicketListTotal();
  const navigate = useNavigate();
  const columDefs: ColumnType<ITicket>[] = useMemo(
    () => [
      {
        title: 'Subject',
        key: 'subject',
        align: 'left',
        ellipsis: true,
        render: (_value, record) => record.title,
      },
      {
        title: 'Customer Email',
        key: 'customereEmail',
        ellipsis: true,
        render: (_value, record) => record.customerEmail,
      },
      {
        title: 'Status',
        key: 'status',
        ellipsis: true,
        sorter: true,
        align: 'right',
        render: (_value, record) => <span className="cor-tickets-status"> {record.status}</span>,
      },
      {
        title: 'Assignee',
        key: 'assignee',
        ellipsis: true,
        sorter: true,
        align: 'right',
        width: 240,
        render: (_value, record) => <Assignee ticket={record} />,
        onCell: () => ({
          onClick: stopPropagation, // 阻止事件冒泡
        }),
      },
      {
        title: 'Priority',
        key: 'priority',
        ellipsis: true,
        sorter: true,
        align: 'right',
        render: (_value, record) => <span className="cor-tickets-status"> {record.priority}</span>,
      },
      {
        title: 'Created Time',
        key: 'createdAt',
        ellipsis: true,
        sorter: true,
        defaultSortOrder: param.sort == TicketParamSort.ASC ? 'ascend' : 'descend',
        align: 'right',
        render: (_value, record) => moment(record.updatedAt).format(DATE_FORMAT),
      },
      {
        title: 'Action',
        key: 'action',
        align: 'right',
        width: 100,
        render: (_value, record) => (
          <div className="cor-tickets-actions">
            {/* <EditTicket text="Edit" title="Edit" type="primary" ticketToEdit={record} icon={<EditIcon />} /> */}
            <DeleteTicket id={record.id} />
          </div>
        ),
        onCell: () => ({
          onClick: stopPropagation, // 阻止事件冒泡
        }),
      },
    ],
    [param.sort],
  );

  const pagination = useMemo(
    () => ({
      pageSize: 10,
      total,
      showTotal: (total: number) => `Total ${total} items`,
    }),
    [total],
  );

  const onChange: TableProps<ITicket>['onChange'] = (pagination, _filters, sorter, _extra) => {
    dispatch(
      setTicketGetParam({
        page: pagination.current,
        sort: sorter?.order === 'ascend' ? TicketParamSort.ASC : TicketParamSort.DESC,
        sortBy: sorter?.columnKey === 'assignee' ? TicketParamSortBy.ASSIGNEES : sorter?.columnKey,
      }),
    );
  };

  const rowClassName = () => 'custom-table-row';

  return (
    <div className="cor-tickets">
      <div className="cor-tickets-title">Tickets</div>
      <Card
        bordered={false}
        title={<QueryHeader />}
        extra={<EditTicket text="Add a New Ticket" title="New Ticket" type="primary" icon={<AddIcon />} />}
        className="cor-tickets-card">
        <Table
          loading={ticketsStatus === FETCHING_STATUS.FETCHING && !tickets?.length}
          rowKey={(record) => record?.id + record.updatedAt}
          columns={columDefs}
          dataSource={tickets}
          pagination={pagination}
          rowClassName={rowClassName}
          onChange={onChange}
          onRow={(record) => ({
            onClick: () => {
              navigate(`/dashboard/tickets/${record.id}`); // 根据记录的 ID 跳转到 /dashboard/:id
            },
          })}
        />
      </Card>
      <TicketGlobalEffect />
      <TeamGlobalEffect />
    </div>
  );
};

export default Tickets;
