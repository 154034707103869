/**
 * @description Component-Landing
 */
import React, { FC } from 'react';

import './index.less';
import Solution from './Solution';
import Header from './Header';
import Banner from './Banner';
import HomeClients from './HomeClients';
import Features from './Features';
import Testimony from './Testimony';
import PricingPackages from './PricingPackages';
import Footer from './Footer';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import Booknow from './Booknow';
interface IPropTypes {
  className?: string;
}
const Landing: FC<IPropTypes> = function () {
  const { deviceType } = useMediaQueryDevice();
  return (
    <div className={`cor-mobilelanding ${deviceType}`}>
      <div className="cor-mobilelanding-center">
        <div className="cor-mobilelanding-center-top">
          <Header />
          <Banner />
          <Features />
          <Testimony />
          <HomeClients />
          <Solution />
          {/* <PricingPackages /> */}
          <Booknow />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Landing;
