import { IMessage, IMessageRole } from '@/types/aigc';
import moment from 'moment';

export function inputToMessage(input: { user: string; question: string; conversationId?: string }): IMessage {
  return {
    id: input.question,
    role: IMessageRole.USER,
    content: input.question,
    createdAt: moment().toISOString(),
  };
}
export function isNA(value: string): boolean {
  return value === 'N/A';
}
