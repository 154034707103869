/**
 * @description Component-HeaderDropDown
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import { useAuth0 } from '@auth0/auth0-react';
import { Dropdown, DropdownProps } from 'antd';

import Avatar from 'react-avatar';
interface IPropTypes extends DropdownProps {
  className?: string;
}
const HeaderDropDown: FC<IPropTypes> = function ({ placement }) {
  const { user, logout } = useAuth0();

  const render = useCallback(
    () => (
      <div className="cor-header-drop-down-render">
        <div className="cor-header-drop-down-render-item email">{user?.email}</div>
        <div className="cor-header-drop-down-render-item" onClick={async () => await logout({})}>
          logout
        </div>
      </div>
    ),
    [logout, user?.email],
  );
  return (
    <Dropdown placement={placement} dropdownRender={render} trigger={['click']}>
      <div className="cor-header-drop-down">
        <Avatar round size="32" src={user?.picture} name={user?.name} />
      </div>
    </Dropdown>
  );
};

export default HeaderDropDown;
