import { useAppSelector } from '../hooks';
import { selectComments, selectCommentsStatus, selectCommentSavingStatus } from './slice';
import { IComment } from '@/types/comment';
import { FETCHING_STATUS } from '@/constants';

export function useComments(ticketId: string | undefined): IComment[] | undefined {
  return useAppSelector(selectComments(ticketId));
}

export function useCommentsStatus(ticketId: string | undefined): FETCHING_STATUS | undefined {
  return useAppSelector(selectCommentsStatus(ticketId));
}

export function useCommentSavingStatus(): FETCHING_STATUS {
  return useAppSelector(selectCommentSavingStatus);
}
