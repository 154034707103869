import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import { LANGUAGE_KEY } from './constants';

function getTranConfigs(lang: string) {
  return {
    translation: {
      landing: require(`@/locales/${lang}/landing.json`),
      inbox: require(`@/locales/${lang}/inbox.json`),
      plugin: require(`@/locales/${lang}/plugin.json`),
    },
  };
}

const resources = {
  en: getTranConfigs('en'),
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem(LANGUAGE_KEY) || 'en',
    // backend: {
    //   loadPath: `@/locales/{{lng}}/*.json`,
    // },
    react: {
      useSuspense: true,
    },
    fallbackLng: 'en',
    preload: [localStorage.getItem(LANGUAGE_KEY) || 'en'],
    interpolation: { escapeValue: false },
  });

export default i18n;
