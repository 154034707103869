import { memo, useEffect } from 'react';
import { useCurrentMerchant } from './hooks';
import { useAppDispatch } from '../hooks';
import { initMerchantSocket } from './actions';
import { useAuth0 } from '@auth0/auth0-react';
function useInitDemoSockets(): void {
  const currentMerchant = useCurrentMerchant();
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  useEffect(() => {
    user?.sub && currentMerchant && dispatch(initMerchantSocket({ merchantId: currentMerchant?.id, userId: user.sub }));
  }, [currentMerchant, user]);
}
function DemoGlobalEffect(): null {
  useInitDemoSockets();
  return null;
}

export default memo(DemoGlobalEffect);
