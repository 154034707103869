/**
 * @description Component-HowItWorks
 */
import React, { FC, useState } from 'react';
import './index.less';
import sp_tab1 from './assets/sp_tab1.png';
import sp_tab2 from './assets/sp_tab2.png';
import sp_tab3 from './assets/sp_tab3.png';
import sp_tab4 from './assets/sp_tab4.png';
import sp_tab5 from './assets/sp_tab5.png';
import asp_tab1 from './assets/asp_tab1.png';
import asp_tab2 from './assets/asp_tab2.png';
import asp_tab3 from './assets/asp_tab3.png';
import asp_tab4 from './assets/asp_tab4.png';
import asp_tab5 from './assets/asp_tab5.png';
// import tab_bg1 from './assets/tab_bg1.png';
// import tab_bg2 from './assets/tab_bg2.png';
// import tab_bg3 from './assets/tab_bg3.png';
// import tab_bg4 from './assets/tab_bg4.png';
// import tab_bg5 from './assets/tab_bg5.png';
interface IPropTypes {
  className?: string;
}

const HomeClients: FC<IPropTypes> = function () {
  const [current, setCurrent] = useState(1);
  return (
    <div className={`cor-homeClients`}>
      <div className="cor-homeClients-title">
        What our <span className="highlight">clients are saying....</span>
      </div>
      <div className="cor-homeClients-tabs">
        {current == 1 ? (
          <img src={asp_tab1} className="cor-homeClients-tabs-img" />
        ) : (
          <img className="cor-homeClients-tabs-img" src={sp_tab1} onClick={() => setCurrent(1)} />
        )}
        {current == 2 ? (
          <img src={asp_tab2} className="cor-homeClients-tabs-img" />
        ) : (
          <img className="cor-homeClients-tabs-img" src={sp_tab2} onClick={() => setCurrent(2)} />
        )}
        {current == 3 ? (
          <img src={asp_tab3} className="cor-homeClients-tabs-img" />
        ) : (
          <img className="cor-homeClients-tabs-img" src={sp_tab3} onClick={() => setCurrent(3)} />
        )}
        {current == 4 ? (
          <img src={asp_tab4} className="cor-homeClients-tabs-img" />
        ) : (
          <img className="cor-homeClients-tabs-img" src={sp_tab4} onClick={() => setCurrent(4)} />
        )}
        {current == 5 ? (
          <img src={asp_tab5} className="cor-homeClients-tabs-img" />
        ) : (
          <img className="cor-homeClients-tabs-img" src={sp_tab5} onClick={() => setCurrent(5)} />
        )}
      </div>
      {current == 1 && (
        <div className="cor-homeClients-container wow">
          <div className="cor-homeClients-container-left">
            <div className="cor-homeClients-container-left-title">
              I’ve always struggled with understanding my customer’s real perception of our brand.
            </div>
            <div className="cor-homeClients-container-left-title big">
              This platform not only bridged that gap but also automated a huge chunk of our communications,{' '}
              <span className="highlight">making our operations smooth and efficient</span>.
            </div>
            <div className="cor-homeClients-container-left-title3">
              <b>Anna</b>, Emojo Bike
            </div>
          </div>
          {/* <img src={tab_bg1} className="cor-homeClients-container-left" /> */}
        </div>
      )}
      {current == 2 && (
        <div className="cor-homeClients-container wow">
          <div className="cor-homeClients-container-left">
            <div className="cor-homeClients-container-left-title big">
              Cornerstone has <span className="highlight">transformed how we engage</span> with our customers.
            </div>
            <div className="cor-homeClients-container-left-title">
              The platform’s deep integration with our existing systems and its powerful AI capabilities have made our
              customer service more responsive and efficient. We’ve seen a noticeable improvement in customer
              satisfaction and loyalty since we started using their solution.
            </div>
            <div className="cor-homeClients-container-left-title3">
              <b>Richa</b>, Head of Commerce @ Intrepid Asia
            </div>
          </div>
          {/* <img src={tab_bg2} className="cor-homeClients-container-left" /> */}
        </div>
      )}
      {current == 3 && (
        <div className="cor-homeClients-container wow">
          <div className="cor-homeClients-container-left">
            <div className="cor-homeClients-container-left-title big">
              Cornerstone’s remarketing with time-limited discounts, combined with their seamless data integration, has
              <span className="highlight"> significantly boosted our revenue</span>.
            </div>
            <div className="cor-homeClients-container-left-title">
              Understanding the right time to push offers has always been challenging. The automation has allowed us to
              focus on strategy while the platform handles the heavy lifting. Highly recommend!
            </div>
            <div className="cor-homeClients-container-left-title3">
              {' '}
              <b>Chris</b>, CEO @ Sevensmarketing
            </div>
          </div>
          {/* <img src={tab_bg5} className="cor-homeClients-container-left" /> */}
        </div>
      )}
      {current == 4 && (
        <div className="cor-homeClients-container wow">
          <div className="cor-homeClients-container-left">
            <div className="cor-homeClients-container-left-title">
              As a CEO, understanding customer needs and responding efficiently has always been a priority.
            </div>
            <div className="cor-homeClients-container-left-title big">
              Cornerstone’s auto email service has <span className="highlight">transformed our customer support</span>,
              allowing us to provide timely, personalized responses effortlessly.
            </div>
            <div className="cor-homeClients-container-left-title3">
              <b>Yu</b>, CEO @ Yi See NYC
            </div>
          </div>
          {/* <img src={tab_bg4} className="cor-homeClients-container-left" /> */}
        </div>
      )}
      {current == 5 && (
        <div className="cor-homeClients-container wow">
          <div className="cor-homeClients-container-left">
            <div className="cor-homeClients-container-left-title big">
              Cornerstone’s social media management solution has been <span className="highlight">a game-changer</span>.
            </div>
            <div className="cor-homeClients-container-left-title">
              It has not only enhanced our engagement metrics but also provided us with deeper insights into customer
              behavior, allowing us to fine-tune our strategies with precision.
            </div>
            <div className="cor-homeClients-container-left-title3">
              <b>Joe</b>, CEO of Conversion Engine
            </div>
          </div>
          {/* <img src={tab_bg3} className="cor-homeClients-container-left" /> */}
        </div>
      )}
    </div>
  );
};

export default HomeClients;
