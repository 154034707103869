import { memo, useEffect } from 'react';
import { useOrg } from '../settings/hooks';
import { useAppDispatch } from '../hooks';
import { getTeamMembers } from './actions';
function useGetMembers() {
  const org = useOrg();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (org) {
      dispatch(
        getTeamMembers({
          orgId: org.id,
        }),
      );
    }
  }, [dispatch, org]);
}

function TeamGlobalEffect(): null {
  useGetMembers();
  return null;
}

export default memo(TeamGlobalEffect);
