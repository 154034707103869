import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { AppState } from '@auth0/auth0-react';
import { ListArrayStatus, setFulfilledEndListArrayStatus } from '@/types/redux';
import { IComment } from '@/types/comment';
import { getComments } from './actions';
import { FETCHING_STATUS } from '@/constants';

export interface ICommentState {
  comments: {
    [ticketId: string]: ListArrayStatus<IComment>;
  };
  commentSavingStatus: FETCHING_STATUS;
}

const initialState: ICommentState = {
  comments: {},
  commentSavingStatus: FETCHING_STATUS.DONE,
};

export const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getComments.pending, (state, { meta }) => {
      _.set(state.comments, [meta.arg.ticketId, 'status'], FETCHING_STATUS.FETCHING);
    });
    builder.addCase(getComments.fulfilled, (state, { payload, meta }) => {

      _.set(state.comments, [meta.arg.ticketId], setFulfilledEndListArrayStatus(payload.data));
    });
  },
});

export const selectComments =
  (ticketId: string | undefined) =>
  (state: AppState): IComment[] | undefined =>
    _.get(state.comment.comments, [ticketId || '', 'list'], []);

export const selectCommentsStatus =
  (ticketId: string | undefined) =>
  (state: AppState): FETCHING_STATUS | undefined =>
    _.get(state.comment.comments, [ticketId || '', 'status'], FETCHING_STATUS.DONE);

export const selectCommentSavingStatus = (state: AppState): FETCHING_STATUS => state.comment.commentSavingStatus;

export default commentSlice.reducer;
