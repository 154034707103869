import React, { FC, useState } from 'react';
import './index.less';
import { Form, Input, Select, message, Button } from 'antd';
import { axiosPost } from '@/utils/axios';

interface IPropTypes {
  className?: string;
}

const Booknow: FC<IPropTypes> = function ({ className }) {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSendContact = async (values: any) => {
    const { email, fullName, storeUrl, mobile, supportAgents, ecommercePlatform } = values;

    try {
      await axiosPost(`${process.env.REACT_APP_API_ROOT}contact`, {
        email,
        fullName,
        storeUrl,
        mobile: parseInt(mobile, 10),
        supportAgents: parseInt(supportAgents, 10),
        ecommercePlatform,
      });

      message.success('Request sent successfully');
      setIsSubmitted(true);
    } catch (error) {
      message.error('Failed to send request');
    }
  };

  return (
    <div className={`cor-booknow ${className}`}>
      <div className="cor-booknow-left">
        <div className="cor-booknow-left-1"></div>
        <div className="cor-booknow-left-2">
          30 Minutes Today <span className="highlight">Could Mean</span>{' '}
          <span className="highlight2">30% Growth Tomorrow</span>
        </div>
        <div className="cor-booknow-left-3">
          Schedule a demo to see how Cornerstone AI drives measurable growth and improves your bottom line by unlocking
          your customers&apos; full potential.
        </div>
      </div>
      <div className="cor-booknow-right">
        <div className="cor-booknow-right-title">Book your personalized demo now</div>
        <Form
          name="booknow"
          onFinish={handleSendContact}
          requiredMark={false}
          disabled={isSubmitted}
          layout="vertical"
          initialValues={{
            ecommercePlatform: 'Shopify',
          }}>
          <div className="cor-booknow-right-input">
            <Form.Item
              label="Work email*"
              name="email"
              rules={[
                { required: true, message: 'Please enter your email' },
                { type: 'email', message: 'Please enter a valid email' },
              ]}>
              <Input placeholder="Enter your work email" />
            </Form.Item>
            <Form.Item
              label="Full name*"
              name="fullName"
              rules={[{ required: true, message: 'Please enter your full name' }]}>
              <Input placeholder="Enter your full name" />
            </Form.Item>
          </div>
          <div className="cor-booknow-right-input">
            <Form.Item
              label="Store URL*"
              name="storeUrl"
              rules={[
                { required: true, message: 'Please enter your store URL' },
                { type: 'url', message: 'Please enter a valid URL' },
              ]}>
              <Input placeholder="Enter your store URL" />
            </Form.Item>
            <Form.Item
              label="Mobile phone number*"
              name="mobile"
              rules={[
                { required: true, message: 'Please enter your mobile phone number' },
                {
                  pattern: /^[0-9]{10,}$/,
                  message: 'Please enter a valid phone number with at least 10 digits',
                },
              ]}>
              <Input placeholder="Enter your mobile phone number" />
            </Form.Item>
          </div>
          <div className="cor-booknow-right-input">
            <Form.Item
              label="Number of support agents*"
              name="supportAgents"
              rules={[
                { required: true, message: 'Please enter the number of support agents' },
                {
                  pattern: /^[0-9]+$/,
                  message: 'Please enter a valid number',
                },
              ]}>
              <Input placeholder="Enter number of support agents" />
            </Form.Item>
            <Form.Item
              label="Ecommerce platform"
              name="ecommercePlatform"
              rules={[{ required: true, message: 'Please enter your ecommerce platform' }]}>
              <Select
                size="large"
                className="cor-booknow-right-input-item-sel"
                options={[
                  { value: 'Shopify', label: 'Shopify' },
                  { value: 'Magento2', label: 'Magento 2' },
                  { value: 'Salesforce', label: 'Salesforce commerce cloud' },
                  { value: 'Bigcommerce', label: 'Bigcommerce' },
                  { value: 'WooCommerce', label: 'WooCommerce' },
                  { value: 'Other', label: 'Other' },
                ]}
              />
            </Form.Item>
          </div>
          <Form.Item>
            <Button className="cor-booknow-right-btn" type="primary" htmlType="submit">
              Request a Demo
            </Button>
          </Form.Item>
        </Form>

        <div className="tips1"></div>
        <div className="tips2">
          <div></div>
        </div>
        <div className="tips3">
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Booknow;
