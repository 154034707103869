import { memo, useEffect } from 'react';
import { useAppDispatch } from '../hooks';
// import { getUserList } from './actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrg } from '../settings/hooks';

function useFetchUsers() {
  const dispatch = useAppDispatch();
  const org = useOrg();
  const { isAuthenticated } = useAuth0();
  useEffect(() => {
    // org && isAuthenticated && dispatch(getUserList({ orgId: org.id }));
  }, [dispatch, isAuthenticated, org]);
}

function InboxGlobalEffect(): null {
  useFetchUsers();
  return null;
}

export default memo(InboxGlobalEffect);
