/**
 * @description Component-UserList
 */
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Empty, Spin, Tooltip } from 'antd';
import _ from 'lodash';
import Avatar from 'react-avatar';
import InfiniteScroller from 'react-infinite-scroller';

import { useMailSettings, useMailSettingsStatus, useOrg } from '@/features/settings/hooks';
import { useCurrentUserId, useInboxSearchStr, useUserList, useUserListStatus } from '@/features/inbox/hooks';
import { useAppDispatch } from '@/features/hooks';
import {
  getArchiveById,
  getUserList,
  GetUserListRequest,
  getUserMailList,
  setInboxCurrentId,
  putReadById,
} from '@/features/inbox/actions';
import { spliceUserListByFrom } from '@/features/inbox/slice';
import { FETCHING_STATUS, SETTINGS_TABS } from '@/constants';
import { IInboxUser } from '@/types/inbox';
import { ReactComponent as RepliedIcon } from '@/assets/svg/icon_replied.svg';
import { ReactComponent as ArchiveIcon } from '@/assets/svg/icon_archive.svg';
import './index.less';
import FormattedDate from '@/components/FormattedDate';
import ListSkeleton from '@/pages/components/ListSkeleton';
import CustomerDrawer from '@/pages/Customers/CustomerDrawer';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}

// const COUNTDOWN_SECONDS = 60;

const UserList: FC<IPropTypes> = function () {
  const intervalRef = useRef<number | null>(null);
  // const [timeCount, setTimeCount] = useState<boolean>(false);
  // const [second, setSecond] = useState<number>(COUNTDOWN_SECONDS);

  // const startCount = (seconds?: number) => {
  //   setTimeCount(true);
  //   if (seconds !== undefined) {
  //     setSecond(seconds);
  //   }
  // };

  const org = useOrg();
  const users = useUserList(org?.id);
  const userStatus = useUserListStatus(org?.id);
  const dispatch = useAppDispatch();
  const query = useInboxSearchStr(org?.id);
  const currentId = useCurrentUserId(org?.id);
  const listRef = useRef<HTMLDivElement>(null);
  const firstUserRef = useRef<HTMLDivElement>(null);
  const prevScrollHeight = useRef<number>(0);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const mailSettingsStatus = useMailSettingsStatus(org?.id);
  const mailSettings = useMailSettings(org?.id);
  const navigate = useNavigate();
  const gotoSettings = (route: string, tab?: string) => {
    if (tab) {
      navigate(route, { state: { tab } });
      return;
    }

    navigate(route);
  };

  useEffect(() => {
    if (
      (userStatus === FETCHING_STATUS.DONE || userStatus === FETCHING_STATUS.COMPLETED) &&
      scrollContainerRef.current &&
      prevScrollHeight.current !== 0
    ) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight - prevScrollHeight.current;
    }
  }, [userStatus, prevScrollHeight, scrollContainerRef]);

  const selectUser = useCallback(
    (user: IInboxUser) => {
      org && dispatch(setInboxCurrentId({ orgId: org?.id, id: user.from }));
      org && dispatch(getUserMailList({ orgId: org?.id, from: user.from }));
      org && user?.unread && dispatch(putReadById({ orgId: org.id, id: user.id, from: user.from }));
    },
    [dispatch, org],
  );

  useEffect(() => {
    const id = currentId;
    if (id === _.head(users)?.id) {
      listRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (id === _.last(users)?.id) {
      listRef.current?.scrollTo({ top: listRef.current.scrollHeight, behavior: 'smooth' });
    }
  }, [currentId, users]);

  const onLoad = useCallback(
    async (mode?: string | number) => {
      if (
        org &&
        (userStatus === undefined ||
          userStatus === FETCHING_STATUS.DONE ||
          mode === 'unshift' ||
          (query !== undefined && userStatus < FETCHING_STATUS.FETCHING))
      ) {
        const params: GetUserListRequest = {
          orgId: org.id,
          mode: 'refresh',
        };
        if (mode === 'unshift') {
          params.mode = mode;
          // params.nextId = _.head(users)?.id;
        }
        if (_.last(users)?.id && (!mode || userStatus === FETCHING_STATUS.DONE)) {
          params.mode = 'append';
          params.preId = _.last(users)?.id;
        }
        if (mode === 'refresh' || !!query) {
          params.mode = 'refresh';
          params.query = query;
          params.preId = '';
        }
        dispatch(getUserList(params));
      }
    },
    [org, users, userStatus, dispatch, query],
  );

  useEffect(() => {
    const polling = setInterval(() => {
      if (userStatus !== FETCHING_STATUS.FETCHING) {
        console.log('🚀 ~ file: index.tsx:119 ~ polling ~ userStatus:', userStatus);
        onLoad('unshift');
      }
    }, 60 * 1000);

    return () => {
      if (polling) {
        clearInterval(polling);
        intervalRef.current = null;
      }
    };
  }, [onLoad, userStatus]);

  // useEffect(() => {
  //   startCount();
  // }, []);

  useEffect(() => {
    if (query !== undefined) {
      onLoad('refresh');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const onSplice = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, user: IInboxUser) => {
    e.stopPropagation();
    if (org) {
      dispatch(getArchiveById({ orgId: org.id, id: user.id }));
      dispatch(spliceUserListByFrom({ orgId: org.id, from: user.from }));
      dispatch(setInboxCurrentId({ orgId: org?.id, id: '' }));
    }
  };

  useEffect(() => {
    if (firstUserRef.current && users?.length && (!currentId || !users.some((u) => u.from === currentId))) {
      firstUserRef.current.click();
    }
  }, [users, currentId]);

  return (
    <div className="cor-user-list-wrap" ref={scrollContainerRef}>
      <InfiniteScroller
        className="cor-user-list"
        initialLoad={true}
        useWindow={false}
        hasMore={userStatus !== FETCHING_STATUS.COMPLETED}
        getScrollParent={() => scrollContainerRef.current}
        loadMore={onLoad}>
        {userStatus === FETCHING_STATUS.FETCHING && !users?.length && <ListSkeleton />}
        {mailSettingsStatus === FETCHING_STATUS.DONE &&
          !!mailSettings.length &&
          (userStatus === FETCHING_STATUS.DONE || userStatus === FETCHING_STATUS.COMPLETED) &&
          !users?.length && (
            <Empty
              description={
                <>
                  <div>Please wait</div>
                  <div>Updating mails may take up to 3 mins...</div>
                </>
              }
            />
          )}
        {!mailSettings.length && mailSettingsStatus === FETCHING_STATUS.DONE && (
          <Empty
            description={
              <>
                <div>No mail connected!</div>
                <div>
                  Please set up mail connection first!{' '}
                  <a onClick={() => navigate(`/dashboard/settings/${SETTINGS_TABS.CONNECTIONS}`)}>Settings</a>
                </div>
              </>
            }
          />
        )}
        {users?.map((user, index) => (
          <div
            ref={index === 0 ? firstUserRef : listRef}
            onClick={() => selectUser(user)}
            className={`cor-user-list-item ${user.from === currentId ? 'selected' : ''} ${user.unread ? 'unread' : ''}`}
            key={user.id}>
            <div className="cor-user-list-item-left">
              <Avatar name={user.name || user.from} size="48" src={user.avatar} round />
            </div>
            <div className="cor-user-list-item-right">
              <div className="cor-user-list-item-name">
                <div className={`cor-user-list-item-name-left ${user.from === currentId ? 'active' : ''}`}>
                  {user.from || user.name}
                </div>
                <div className="cor-user-list-item-name-right">
                  <FormattedDate date={user?.date} />
                </div>
              </div>
              <div className="cor-user-list-item-subject">{user.latestSubject}</div>
            </div>
            <div className="cor-user-list-item-tags">
              <CustomerDrawer email={user.from} />
              <div className="cor-user-list-item-archive" onClick={(e) => onSplice(e, user)}>
                <Tooltip title="Archive">
                  <ArchiveIcon color="green" width={16} height={16} />
                </Tooltip>
              </div>
              {user.replied && (
                <Tooltip title="Replied">
                  <RepliedIcon color="green" width={16} height={16} />
                </Tooltip>
              )}
            </div>
          </div>
        ))}
      </InfiniteScroller>
    </div>
  );
};

export default UserList;
