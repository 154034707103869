import { useAppSelector } from '../hooks';
import {
  ITicketGetParam,
  selectDeletingTicketStatus,
  selectOrderCancelRequests,
  selectOrderCancelRequestsStatus,
  selectPluginId,
  selectPluginSessions,
  selectPluginSessionsStatus,
  selectSavingTicketStatus,
  selectSelectedSession,
  selectSessionsConversations,
  selectSessionsConversationsStatus,
  selectTicketGetParam,
  selectTicketListTotal,
  selectTickets,
  selectTicketsStatus,
} from './slice';
import { IOrderCancelRequest, IPluginSession, ITicket } from '@/types/plugin';
import { FETCHING_STATUS } from '@/constants';
import { useMemo } from 'react';

export function usePluginId(orgId: string | undefined): string | undefined {
  return useAppSelector(selectPluginId(orgId));
}
export function usePluginSessions(orgId: string | undefined): IPluginSession[] | undefined {
  return useAppSelector(selectPluginSessions(orgId));
}
export function useTickets(orgId: string | undefined): ITicket[] | undefined {
  return useAppSelector(selectTickets(orgId));
}
export function useTicket(orgId: string | undefined, id?: string): ITicket | undefined {
  const tickets = useTickets(orgId);
  return useMemo(() => tickets?.find((t) => t.id === id), [id, tickets]);
}
export function useTicketSavingStatus(orgId: string | undefined): FETCHING_STATUS | undefined {
  return useAppSelector(selectSavingTicketStatus(orgId));
}
export function useTicketDeletingStatus(id: string | undefined): FETCHING_STATUS | undefined {
  return useAppSelector(selectDeletingTicketStatus(id));
}
export function useTicketsStatus(orgId: string | undefined): FETCHING_STATUS | undefined {
  return useAppSelector(selectTicketsStatus(orgId));
}
export function useSessionConversations(conversationId: string | undefined): IPluginSession[] | undefined {
  return useAppSelector(selectSessionsConversations(conversationId));
}
export function useSessionConversationsStatus(conversationId: string | undefined): FETCHING_STATUS | undefined {
  return useAppSelector(selectSessionsConversationsStatus(conversationId));
}
export function useOrderCancelRequests(orgId: string | undefined): IOrderCancelRequest[] | undefined {
  return useAppSelector(selectOrderCancelRequests(orgId));
}
export function useOrderCancelRequestsStatus(orgId: string | undefined): FETCHING_STATUS | undefined {
  return useAppSelector(selectOrderCancelRequestsStatus(orgId));
}
export function useSelectedSession(orgId: string | undefined): IPluginSession | undefined {
  return useAppSelector(selectSelectedSession(orgId));
}
export function usePluginSessionsStatus(orgId: string | undefined): FETCHING_STATUS | undefined {
  return useAppSelector(selectPluginSessionsStatus(orgId));
}

export function useSelectTicketGetParam(): ITicketGetParam {
  return useAppSelector(selectTicketGetParam());
}
export function useSelectTicketListTotal(): number {
  return useAppSelector(selectTicketListTotal());
}
