import { IBrandVoiceConfigResponse } from '@/types/settings';
import { createSlice } from '@reduxjs/toolkit';
import {
  generateBrandVoice,
  getBrandVoiceConfig,
  getBrandVoiceByReceipt,
  saveBrandVoice,
  saveBrandVoiceConfig,
  setGeneratingVoiceStatus,
} from './actions';
import { AppState } from '../store';
import { BRAND_STATUS_CODES, ERROR_CODE, FETCHING_STATUS } from '@/constants';
import _ from 'lodash';

export interface IBrandVoiceState {
  config: IBrandVoiceConfigResponse | undefined;
  brandVoice: string;
  gettingConfigStatus: FETCHING_STATUS;
  savingConfig: FETCHING_STATUS;
  generatingBrandVoice: FETCHING_STATUS;
  savingBrandVoice: FETCHING_STATUS;
  receiptToBeFetched: string;
}

const initialState: IBrandVoiceState = {
  config: undefined,
  brandVoice: '',
  gettingConfigStatus: FETCHING_STATUS.INIT,
  savingConfig: FETCHING_STATUS.INIT,
  generatingBrandVoice: FETCHING_STATUS.INIT,
  savingBrandVoice: FETCHING_STATUS.INIT,
  receiptToBeFetched: '',
};

export const inboxSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setGeneratingVoiceStatus, (state, { payload }) => {
        state.generatingBrandVoice = payload;
      })
      .addCase(getBrandVoiceConfig.pending, (state) => {
        state.gettingConfigStatus = FETCHING_STATUS.FETCHING;
      })
      .addCase(getBrandVoiceConfig.fulfilled, (state, { payload }) => {
        state.brandVoice = payload.data?.brandVoice;
        state.config = payload.data;
        state.gettingConfigStatus = FETCHING_STATUS.DONE;
      })
      .addCase(getBrandVoiceConfig.rejected, (state) => {
        state.gettingConfigStatus = FETCHING_STATUS.DONE;
      })
      .addCase(saveBrandVoiceConfig.pending, (state) => {
        state.savingConfig = FETCHING_STATUS.FETCHING;
      })
      .addCase(saveBrandVoiceConfig.fulfilled, (state, { payload }) => {
        state.savingConfig = FETCHING_STATUS.DONE;
        state.config = payload.data;
      })
      .addCase(saveBrandVoiceConfig.rejected, (state) => {
        state.savingConfig = FETCHING_STATUS.DONE;
      })

      .addCase(getBrandVoiceByReceipt.fulfilled, (state, { meta, payload }) => {
        if (payload.data?.status === BRAND_STATUS_CODES.SUCCESS) {
          state.brandVoice = payload.data.urls;
          if (meta.arg.receipt === state.receiptToBeFetched) {
            state.receiptToBeFetched = '';
          }
          state.generatingBrandVoice = FETCHING_STATUS.DONE;
        }
        if (payload.data?.status === BRAND_STATUS_CODES.FAILED) {
          state.generatingBrandVoice = FETCHING_STATUS.FAILED;
        }
      })
      .addCase(getBrandVoiceByReceipt.rejected, (state) => {
        state.generatingBrandVoice = FETCHING_STATUS.DONE;
      })
      .addCase(saveBrandVoice.pending, (state) => {
        state.savingBrandVoice = FETCHING_STATUS.FETCHING;
      })
      .addCase(saveBrandVoice.fulfilled, (state, { payload }) => {
        if (payload.errno === ERROR_CODE.NORMAL) {
          state.brandVoice = payload.data.brandVoice;
        }
        state.savingBrandVoice = FETCHING_STATUS.DONE;
      })
      .addCase(generateBrandVoice.pending, (state) => {
        state.generatingBrandVoice = FETCHING_STATUS.FETCHING;
      })
      .addCase(generateBrandVoice.fulfilled, (state, { payload }) => {
        const currentReceipts = state.config?.metaData?.receipts || [];
        if (state.config) {
          _.set(state.config, ['metaData', 'receipts'], [payload.data, ...currentReceipts]);
        }
        state.receiptToBeFetched = payload?.data?.receipt_number;
      })
      .addCase(generateBrandVoice.rejected, (state) => {
        state.generatingBrandVoice = FETCHING_STATUS.DONE;
      });
  },
});
export const selectBrandVoice = (state: AppState): string => state.brand.brandVoice;
export const selectReceiptToBeFetched = (state: AppState): string => state.brand.receiptToBeFetched;
export const selectSavingConfig = (state: AppState): FETCHING_STATUS => state.brand.savingConfig;
export const selectGeneratingVoiceStatus = (state: AppState): FETCHING_STATUS => state.brand.generatingBrandVoice;
export const selectSavingVoiceStatus = (state: AppState): FETCHING_STATUS => state.brand.savingBrandVoice;
export const selectGettingConfigStatus = (state: AppState): FETCHING_STATUS => state.brand.gettingConfigStatus;
export const selectBrandConfigs = (state: AppState): IBrandVoiceConfigResponse | undefined => state.brand.config;

export default inboxSlice.reducer;
