import { REQUEST_PAGE_SIZE } from '@/constants/plugin';
import { useAppDispatch } from '@/features/hooks';
import { getTickets } from '@/features/plugin/actions';
import { useSelectTicketGetParam } from '@/features/plugin/hooks';
import { useOrg } from '@/features/settings/hooks';
import { memo, useEffect } from 'react';

function useGetTickets() {
  const org = useOrg();
  const param = useSelectTicketGetParam();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (org) {
      dispatch(
        getTickets({
          orgId: org.id,
          param,
        }),
      );
    }
  }, [dispatch, org, param]);
}

function TicketsGlobalEffect(): null {
  useGetTickets();
  return null;
}

export default memo(TicketsGlobalEffect);
