import { ICustomer } from '@/types/customers';
import { IResponse } from '@/types/settings';
import { axiosGet } from '@/utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getCustomerByEmail = createAsyncThunk(
  'user/getCustomerByEmail',
  async ({ email }: { email: string; orgId: string }): Promise<IResponse<ICustomer> | undefined> => {
    try {
      const { data } = await axiosGet(`${process.env.REACT_APP_API_ROOT}union/customer/get`, {
        params: { email },
      });
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: action.ts:13 ~ e:', e);
      return undefined;
    }
  },
);
