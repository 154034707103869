/**
 * @description Component-Users
 */
import React, { FC } from 'react';
import './index.less';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const Users: FC<IPropTypes> = function () {
  return <div className="cor-users"></div>;
};

export default Users;
