/**
 * @description Component-Chat
 */
import React, { FC } from 'react';

import './index.less';
import ChatContent from './ChatContent';
import ChatReplyBox from './ChatReplyBox';
interface IPropTypes {
  className?: string;
}
const Chat: FC<IPropTypes> = function () {
  return (
    <div className="cor-chat">
      <ChatContent />
      <ChatReplyBox />
    </div>
  );
};

export default Chat;
