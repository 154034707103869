/**
 * @description Component-DeleteBtn
 */
import React, { FC, useCallback } from 'react';
import { INewKnowledgeBase } from '@/types/settings';
import { useNewKnowledgeDeletingStatus } from '@/features/settings/hooks';
import { Button, Popconfirm } from 'antd';
import { FETCHING_STATUS } from '@/constants';
import { ReactComponent as DeleteIcon } from '@/assets/svg/icon_delete.svg';
import { useAppDispatch } from '@/features/hooks';
import { deleteNewKnowledgeBase } from '@/features/settings/actions';
interface IPropTypes {
  knowledge: INewKnowledgeBase;
  org: string | undefined;
  className?: string;
}
const DeleteBtn: FC<IPropTypes> = function ({ knowledge, org }) {
  const deleting = useNewKnowledgeDeletingStatus(org, knowledge.id);
  const dispatch = useAppDispatch();
  const deleteSettings = useCallback(() => {
    dispatch(deleteNewKnowledgeBase({ id: knowledge.id, org }));
  }, [dispatch, org, knowledge]);
  return (
    <Popconfirm onConfirm={deleteSettings} okText="Yes" cancelText="No" title="Are you sure to delete this knowledge?">
      <Button
        disabled={deleting === FETCHING_STATUS.FETCHING}
        loading={deleting === FETCHING_STATUS.FETCHING}
        type="text"
        size="small"
        icon={<DeleteIcon />}>
        Delete
      </Button>
    </Popconfirm>
  );
};

export default DeleteBtn;
