/**
 * @description Component-SessionDetailSkeleton
 */
import React, { FC } from 'react';
import './index.less';
import { Skeleton } from 'antd';
import classNames from 'classnames';
interface IPropTypes {
  className?: string;
}
const SessionDetailSkeleton: FC<IPropTypes> = function () {
  const array = new Array(10).fill(0);
  return (
    <div className="cor-session-detail-skeleton">
      {array.map((_, index) => (
        <div key={index} className={classNames('cor-session-detail-skeleton-item', { even: index % 2 !== 0 })}>
          <Skeleton.Avatar size={24} active={true} />
          <Skeleton paragraph={false} active={true} />
        </div>
      ))}
    </div>
  );
};

export default SessionDetailSkeleton;
