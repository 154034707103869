import { notification } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import { enableMapSet } from 'immer';

enableMapSet();
notification.config({
  duration: 9,
  placement: 'topRight',
  top: 70,
});

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(localeData);
