export const PRODUCTS_INFO = {
  price_1OC3SwLiW1ucxYofczmyZAG9: {
    name: 'Starter',
    price: 49.0,
  },
  price_1OC3TlLiW1ucxYofafunhyka: {
    name: 'Pro',
    price: 149.0,
  },
};
