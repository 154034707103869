/**
 * @description Component-GmailCallBack
 */
import { useAppDispatch } from '@/features/hooks';
import { postGmailAuthorizeCallback } from '@/features/settings/actions';
import { FC, useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
interface IPropTypes {
  className?: string;
}
const GmailCallBack: FC<IPropTypes> = function ({}) {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const postCallBack = useCallback(async () => {
    const code = searchParams.get('code');
    const scope = searchParams.get('scope');
    const errmsg = searchParams.get('errmsg');
    if (errmsg) {
      toast.error(errmsg);
      navigate('/dashboard/settings', { replace: true });
    } else if (code && scope) {
      try {
        const posted = await dispatch(
          postGmailAuthorizeCallback({
            code,
            scope,
          }),
        ).unwrap();
        if (posted) {
          navigate('/dashboard/settings', { replace: true });
        }
      } catch (e) {
        console.log('🚀 ~ file: index.tsx:32 ~ postCallBack ~ e:', e);
      }
    }
  }, [dispatch, searchParams, navigate]);
  useEffect(() => {
    postCallBack();
  }, [postCallBack]);

  return null;
};

export default GmailCallBack;
