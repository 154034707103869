export const USER_INITIATED = 'USER_INITIATED';
export enum KNOWLEDGEBASE_CATEGORY {
  OTHERS = 'others',
  PRODUCT = 'product',
  FAQ = 'faq',
  RETURN = 'return',
  WARRANTY = 'warranty',
  WEBPAGE = 'webpage',
}

export enum WEBPAGE_KNOWLEDGE_STATUS {
  SUBMITTED = 'submitted',
  SUCCEED = 'succeeded',
}

export enum QUESTIONNAIRE_STEPS {
  NAME = 'name',
  WEBSITE = 'website',
  EMAIL = 'email',
}

export enum MAIL_SETTING_NAMES {
  GMAIL = 'gmail oauth2',
  OUTLOOK = 'outlook',
}

export enum CRAWLING_STATUS {
  CRAWLING = 'crawling',
  SUCCEED = 'succeeded',
  FAILED = 'failed',
  SUBMITTED = 'submitted',
  PROCESSING = 'processing',
}

export const URL_REGEX = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');

export const ASIN_REGEX = /https:\/\/www\.amazon\.[a-zA-Z]{2,}\/(?:[^\/\s]+\/)?dp\/[^\/\s]+/g;
export const INS_REGEX = new RegExp('https://(www.)?instagram.com/.+');
export const FB_SCOPES =
  'email,ads_read,public_profile,pages_show_list,pages_read_user_content,pages_manage_posts,business_management,pages_manage_engagement';
export const FB_ID = '';
