/**
 * @description Component-ProductModal
 */
import React, { FC } from 'react';
import './index.less';
import { Modal } from 'antd';
import Products from '../Products';
import { ReactComponent as CloseIcon } from '@/assets/svg/icon_close_24.svg';
interface IPropTypes {
  className?: string;
  open: boolean;
  onCancel: () => void;
}
const ProductModal: FC<IPropTypes> = function ({ open, onCancel }) {
  return (
    <Modal
      closeIcon={<CloseIcon />}
      open={open}
      width={'auto'}
      title={false}
      footer={false}
      onCancel={onCancel}
      onOk={onCancel}
      className="cor-product-modal">
      <Products />
    </Modal>
  );
};

export default ProductModal;
