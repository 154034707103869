/**
 * @description Component-LoadingIco
 */
import React, { FC } from 'react';
import { ReactComponent as LoadingIcon } from '@/assets/svg/load_circle.svg';
import loadingImg from '@/assets/images/loader circle.png';
import './index.less';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const LoadingIco: FC<IPropTypes> = function (props) {
  return <img src={loadingImg} className="cor-loading-ico" />;
};

export default LoadingIco;
