import { memo, useEffect } from 'react';
import { useAppDispatch } from '../hooks';
import { getBrandVoiceConfig, getBrandVoiceByReceipt } from './actions';
import { useReceiptToBeFetched } from './hooks';
import _ from 'lodash';
import { pollingFunc } from '@/utils/axios';
import { BRAND_STATUS_CODES } from '@/constants';
import { toast } from 'react-toastify';
import { useOrg } from '../settings/hooks';
let polling: NodeJS.Timeout;
function useFetchVoice() {
  const dispatch = useAppDispatch();
  const org = useOrg();
  const receiptToBeFetched = useReceiptToBeFetched();
  useEffect(() => {
    org && dispatch(getBrandVoiceConfig());
  }, [org]);

  useEffect(() => {
    polling && clearInterval(polling);
    if (receiptToBeFetched) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      polling = pollingFunc(async () => {
        const result = await dispatch(getBrandVoiceByReceipt({ receipt: receiptToBeFetched })).unwrap();
        if (result.data?.status === BRAND_STATUS_CODES.FAILED) {
          toast.error('Generating Brand Voice has encounter some issue and failed, please regenerate!');
        }
      }, 15000);
    }
    return () => polling && clearInterval(polling);
    // only this two
  }, [receiptToBeFetched, dispatch]);
  return null;
}

function BrandVoiceGlobalEffect(): null {
  useFetchVoice();
  return null;
}

export default memo(BrandVoiceGlobalEffect);
