/**
 * @description Component-SessionItem
 */
import React, { FC, useMemo, useState } from 'react';
import './index.less';
import { IFunctionDef, IPluginSession } from '@/types/plugin';
import FormattedDate from '@/components/FormattedDate';
import { useAppDispatch } from '@/features/hooks';
import { setSelectedSession } from '@/features/plugin/actions';
import { useOrg } from '@/features/settings/hooks';
import { useSelectedSession } from '@/features/plugin/hooks';
import classNames from 'classnames';
import { START_OF_FUNCTIONS } from '@/constants/plugin';
import { Modal, Tag, Typography } from 'antd';
import SessionDetail from '../../SessionDetail';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import SendMailModal from '@/components/SendMailModal';
import CustomerDrawer from '@/pages/Customers/CustomerDrawer';
interface IPropTypes {
  session: IPluginSession;
  className?: string;
}
const SessionItem: FC<IPropTypes> = function ({ session }) {
  const org = useOrg();
  const { isMobile } = useMediaQueryDevice();
  const dispatch = useAppDispatch();
  const currentSession = useSelectedSession(org?.id);
  const [modalOpen, setModalOpen] = useState(false);
  const content = useMemo(
    () => session.email || session.content.split(START_OF_FUNCTIONS)[0],
    [session.content, session.email],
  );
  return (
    <>
      <div
        className={classNames('cor-session-item', {
          selected: currentSession?.conversationId === session.conversationId,
        })}
        onClick={() => {
          org && dispatch(setSelectedSession({ orgId: org.id, session }));
          if (isMobile) {
            setModalOpen(true);
          }
        }}>
        <div className="cor-session-item-top">
          <Typography.Text ellipsis={{ tooltip: content }} className="cor-session-item-name">
            {content} {session.isOnline && <span className="cor-session-item-top-online" />}
          </Typography.Text>
          <div className="cor-session-item-top-right">
            <FormattedDate date={session?.updatedAt} />
          </div>
        </div>
        <div className="cor-session-item-bottom">
          {session.ipCity}
          {session.email && <CustomerDrawer email={session.email} />}
          <SendMailModal toRequired={true} sessionId={session.id} to={session.email} />
          {session.isReply && <Tag color="green">Replied</Tag>}
        </div>
      </div>
      {isMobile && (
        <Modal
          centered={true}
          footer={false}
          className="cor-session-item-modal"
          onCancel={() => setModalOpen(false)}
          open={modalOpen}>
          <SessionDetail />
        </Modal>
      )}
    </>
  );
};

export default SessionItem;
