/**
 * @description Component-ViewKnowledge
 */
import React, { FC, useState } from 'react';
import './index.less';
import { Button, Col, Modal, Row, Tooltip, Typography } from 'antd';
import { ReactComponent as ViewIcon } from '@/assets/svg/icon_view.svg';
import { ReactComponent as CloseIcon } from '@/pages/Dashboard/assets/close.svg';
import { WEBPAGE_KNOWLEDGE_STATUS } from '@/constants/settings';
import { isArray } from 'lodash';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  name: string;
  status: string;
  knowledge?: { source: string; title: string }[];
}
const ViewWebpageKnowledge: FC<IPropTypes> = function ({ name, status, knowledge }) {
  const [open, setOpen] = useState(false);

  const TitleName = () => {
    return (
      <>
        {name}
        <Typography.Title level={5}>
          <b>{knowledge?.length || 0}</b> Pages crawled
        </Typography.Title>
      </>
    );
  };
  return (
    <div className="cor-view-webpage-knowledge">
      <Tooltip
        title={
          status !== WEBPAGE_KNOWLEDGE_STATUS.SUCCEED ? 'Please come back later, crawling may take some time!' : ''
        }>
        <Button
          type="text"
          size="small"
          loading={status !== WEBPAGE_KNOWLEDGE_STATUS.SUCCEED}
          icon={status === WEBPAGE_KNOWLEDGE_STATUS.SUCCEED && <ViewIcon width={16} height={16} />}
          onClick={() => setOpen(true)}
          disabled={status !== WEBPAGE_KNOWLEDGE_STATUS.SUCCEED}>
          {status === WEBPAGE_KNOWLEDGE_STATUS.SUCCEED ? (knowledge?.length || 0) + ' pages crawled' : 'Crawling'}
        </Button>
      </Tooltip>
      <Modal
        footer={false}
        title={<TitleName />}
        open={open}
        width={800}
        centered
        className="cor-settings-modal cor-view-webpage-knowledge-url-modal"
        closeIcon={<CloseIcon />}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}>
        <div className="cor-view-webpage-knowledge-url-modal-container">
          {knowledge?.length &&
            isArray(knowledge) &&
            knowledge.map(({ title, source }) => (
              <Row key={source} gutter={16}>
                <Col span={12} key={source}>
                  {title}
                </Col>
                <Col span={12} key={source}>
                  <a href={source} target="_blank" rel="noreferrer">
                    {source}
                  </a>
                </Col>
              </Row>
            ))}
        </div>
      </Modal>
    </div>
  );
};

export default ViewWebpageKnowledge;
