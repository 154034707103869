/**
 * @description Component-DeletteTeamButton
 */
import React, { FC, memo, useCallback } from 'react';
import './index.less';
import { IMember } from '@/types/team';
import { Button } from 'antd';
import { useOrg } from '@/features/settings/hooks';
import { useAppDispatch } from '@/features/hooks';
import { deleteTeamMember } from '@/features/team/actions';
import { FETCHING_STATUS } from '@/constants';
import { ReactComponent as DeleteIcon } from '@/assets/svg/icon_delete.svg';
import { useMemberDeletingStatus } from '@/features/team/hooks';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  member: IMember;
}
const DeleteTeamButton: FC<IPropTypes> = function ({ member }) {
  const org = useOrg();
  const deleting = useMemberDeletingStatus(member?.email);
  const dispatch = useAppDispatch();
  const deleteMember = useCallback(() => {
    org && dispatch(deleteTeamMember({ orgId: org?.id, email: member.email }));
  }, [dispatch, org, member]);
  return (
    <Button
      loading={deleting === FETCHING_STATUS.FETCHING}
      size="small"
      type="ghost"
      onClick={deleteMember}
      icon={<DeleteIcon />}
      className="cor-delete-team-button">
      Delete
    </Button>
  );
};

export default memo(DeleteTeamButton);
