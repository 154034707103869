/**
 * @description Component-CorAceEditor
 */
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import './index.less';
interface IPropTypes {
  className?: string;
  value?: string;
  mode?: string;
  minLines?: number;
  onChange?: (text: string) => void;
}
const CorAceEditor: FC<IPropTypes> = function ({ value, onChange, mode = 'text', minLines = 20 }) {
  const [text, setText] = useState(value || '');
  const editorChange = useCallback(
    (s) => {
      setText(s);
      onChange && onChange(s);
    },
    [onChange],
  );
  useEffect(() => {
    value && setText(value);
  }, [value]);
  return (
    <div className="cor-cor-ace-editor">
      <AceEditor
        mode={mode}
        onChange={editorChange}
        placeholder="Knowledge..."
        value={text}
        width="100%"
        readOnly
        minLines={minLines}
        maxLines={20}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          showPrintMargin: false,
          tabSize: 2,
        }}
      />
    </div>
  );
};

export default memo(CorAceEditor);
