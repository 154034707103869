import { createSlice } from '@reduxjs/toolkit';
import _, { add } from 'lodash';
import { toast } from 'react-toastify';
import type { PayloadAction } from '@reduxjs/toolkit';

import {
  ListArrayStatus,
  ListStatus,
  // getDefaultListStatus,
  setFulfilledBeginListArrayStatus,
  setFulfilledEndListArrayStatus,
  setFulfilledNoNewListArrayStatus,
  setFulfilledListArrayStatus,
  setFulfilledEndListStatus,
  setFulfilledListStatus,
} from '@/types/redux';
import { AppState } from '@/features/store';
import { IInboxUser, IMetaComment, IMetaPost } from '@/types/inbox';
import { INBOX_SOURCES_KEY, USER_PAGE_SIZE } from '@/constants/inbox';
import { IMail } from '@/types/email';
import { ERROR_CODE, FETCHING_STATUS } from '@/constants';

import {
  getUserList,
  getUserMailList,
  setInboxCurrentId,
  setInboxCurrentText,
  setInboxSearchStr,
  setInboxSourceType,
  sendMail,
  getAigcResponse,
  setEmailRender,
  getArchiveById,
  putReadById,
  setInboxDrawerOpen,
  setInboxReplying,
  setAigcResponse,
  getMetaPosts,
  getMetaPostComments,
  setInboxPostSearchStr,
  setInboxCurrentPostId,
  setInboxCommentSearchStr,
  getMetaPostCommentReplies,
  setInboxCurrentPostComment,
  ReplyMetaPostComment,
  setAigcReplyResponse,
  setInboxMetaCurrentText,
  setAigcCommentReplies,
  setPostCommentSelected,
  setAigcCommentReply,
  resetMeta,
  sendDirectMail,
} from './actions';

export interface IInboxState {
  orgUserList: { [orgId: string]: ListStatus<IInboxUser> };
  orgCurrentUserId: { [orgId: string]: string };
  orgUserMails: { [orgId: string]: { [user: string]: ListArrayStatus<IMail> } };
  orgInboxSourceType: { [orgId: string]: INBOX_SOURCES_KEY };
  orgInboxUserSearchStr: { [orgId: string]: string };
  orgUserCurrentText: { [orgId: string]: { [user: string]: string } };
  orgMetaPosts: { [orgId: string]: ListStatus<IMetaPost> };
  orgMetaPostComments: { [orgId: string]: { [postId: string]: ListStatus<IMetaComment> } };
  sendingMail: { [address: string]: boolean };
  gettingAigcResponse: boolean;
  drawerOpen: boolean;
  replying: boolean;
  aigcResponse: { [mailId: string]: string };
  emailRenderStatus: { [mailId: string]: string };
  orgUserSentEmail: { [orgId: string]: { [user: string]: number } };
  orgInboxPostSearchStr: { [orgId: string]: string };
  orgCurrentPostId: { [orgId: string]: string };
  orgInboxCommentSearchStr: { [orgId: string]: string };
  orgMetaPostCommentReplies: {
    [orgId: string]: { [commentId: string]: ListStatus<IMetaComment> };
  };
  orgCurrentPostComment: {
    [orgId: string]: IMetaComment;
  };
  replyingComment: { [id: string]: boolean };
  aigcReplyResponse: { [commentId: string]: string };
  orgPostCurrentText: { [orgId: string]: { [post: string]: string } };
  gettingAigcReplyResponse: boolean;
  commentAigcReplies: {
    [commentId: string]: string;
  };
  postSelectedComments: {
    [postId: string]: string[];
  };
}

const initialState: IInboxState = {
  orgUserList: {},
  orgUserMails: {},
  orgInboxSourceType: {},
  orgInboxUserSearchStr: {},
  orgCurrentUserId: {},
  orgUserCurrentText: {},
  sendingMail: {},
  drawerOpen: false,
  replying: false,
  gettingAigcResponse: false,
  aigcResponse: {},
  emailRenderStatus: {},
  orgUserSentEmail: {},
  orgMetaPosts: {},
  orgMetaPostComments: {},
  orgInboxPostSearchStr: {},
  orgCurrentPostId: {},
  orgInboxCommentSearchStr: {},
  orgMetaPostCommentReplies: {},
  orgCurrentPostComment: {},
  replyingComment: {},
  aigcReplyResponse: {},
  orgPostCurrentText: {},
  gettingAigcReplyResponse: false,
  commentAigcReplies: {},
  postSelectedComments: {},
};

export const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    spliceUserListByFrom: (state, action: PayloadAction<{ from: string; orgId: string }>) => {
      const { orgId, from } = action.payload;
      const current = _.get(state.orgUserList, [orgId, 'list']) || {};
      _.unset(current, from);
      const filledListStatus = setFulfilledEndListStatus(current);
      _.set(state.orgUserList, [orgId], filledListStatus);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserList.pending, (state, { meta }) => {
        const { orgId } = meta.arg;
        _.set(state.orgUserList, [orgId, 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getUserList.fulfilled, (state, { meta, payload }) => {
        const { orgId, mode, pageSize = USER_PAGE_SIZE } = meta.arg;
        let filledListStatus;
        const current = _.get(state.orgUserList, [orgId, 'list']) || {};
        switch (mode) {
          case 'append':
            if (_.isEmpty(payload) || _.values(payload).length < pageSize) {
              filledListStatus = setFulfilledEndListStatus({ ...current, ...payload });
            } else {
              filledListStatus = setFulfilledListStatus({ ...current, ...payload });
            }
            break;
          case 'unshift':
            if (_.isEmpty(payload)) {
              filledListStatus = setFulfilledEndListStatus(current);
            } else {
              filledListStatus = setFulfilledEndListStatus({ ...current, ...payload });
            }
            break;
          default:
            if (_.values(payload).length < pageSize) {
              // const updatedData = _.mapValues(payload, (value) => ({ ...value, unread: true }));
              filledListStatus = setFulfilledEndListStatus(payload);
            } else {
              filledListStatus = setFulfilledListStatus(payload);
            }
            break;
        }
        _.set(state.orgUserList, [orgId], filledListStatus);
      })
      .addCase(getArchiveById.pending, (state, { meta }) => {
        const { orgId } = meta.arg;
        _.set(state.orgUserList, [orgId, 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getArchiveById.fulfilled, (state, { meta }) => {
        const { orgId } = meta.arg;
        _.set(state.orgUserList, [orgId, 'status'], FETCHING_STATUS.COMPLETED);
      })
      .addCase(putReadById.fulfilled, (state, { meta }) => {
        const { orgId, from } = meta.arg;
        const current = _.get(state.orgUserList, [orgId, 'list']) || {};
        const filledListStatus = setFulfilledEndListStatus(_.set(current, [from, 'unread'], false));
        _.set(state.orgUserList, [orgId], filledListStatus);
      })
      .addCase(setEmailRender, (state, { payload }) => {
        const { mailId, render } = payload;
        _.set(state.emailRenderStatus, [mailId], render);
      })
      .addCase(setAigcResponse, (state, { payload }) => {
        if (payload.status === FETCHING_STATUS.INIT) {
          _.set(state.aigcResponse, [payload.mailId], payload.response);
        } else {
          _.set(state.aigcResponse, [payload.mailId], _.get(state.aigcResponse, [payload.mailId]) + payload.response);
        }
        state.gettingAigcResponse = payload.status !== FETCHING_STATUS.DONE;
      })
      .addCase(getUserMailList.pending, (state, { meta }) => {
        const { orgId, from } = meta.arg;
        _.set(state.orgUserMails, [orgId, from || '', 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getUserMailList.rejected, (state, { meta }) => {
        const { orgId, from } = meta.arg;
        _.set(state.orgUserMails, [orgId, from || '', 'status'], FETCHING_STATUS.DONE);
      })
      .addCase(getUserMailList.fulfilled, (state, { meta, payload }) => {
        const { orgId, from, mode, pageSize = 10 } = meta.arg;
        const hasMore = _.get(state.orgUserMails, [orgId, from || '', 'hasMore']) || undefined;
        let fulfilledStatus: ListArrayStatus<IMail>;
        const current = _.get(state.orgUserMails, [orgId, from || '', 'list']) || [];
        switch (mode) {
          case 'unshift':
            if (_.isEmpty(payload) || payload.length < pageSize) {
              fulfilledStatus = setFulfilledEndListArrayStatus([...payload, ...current]) as ListArrayStatus<IMail>;
            } else {
              fulfilledStatus = setFulfilledListArrayStatus([...payload, ...current]) as ListArrayStatus<IMail>;
            }
            break;
          case 'append':
            if (_.isEmpty(payload)) {
              if (current.length < pageSize) {
                fulfilledStatus = setFulfilledEndListArrayStatus(current);
                fulfilledStatus.hasMore = hasMore;
              } else {
                fulfilledStatus = setFulfilledNoNewListArrayStatus(current) as ListArrayStatus<IMail>;
                fulfilledStatus.hasMore = hasMore;
              }
            } else {
              fulfilledStatus = setFulfilledListArrayStatus([...current, ...payload]) as ListArrayStatus<IMail>;
              fulfilledStatus.hasMore = hasMore;
            }
            break;
          default:
            if (payload?.length < pageSize) {
              fulfilledStatus = setFulfilledEndListArrayStatus(payload);
              fulfilledStatus.status = FETCHING_STATUS.BOTTOM;
            } else {
              fulfilledStatus = setFulfilledBeginListArrayStatus(payload);
            }
            break;
        }
        _.set(state.orgUserMails, [orgId, from || ''], fulfilledStatus);
      })
      .addCase(getAigcResponse.pending, (state, {}) => {
        state.gettingAigcResponse = true;
      })
      .addCase(getAigcResponse.fulfilled, (state, { payload, meta }) => {
        state.gettingAigcResponse = false;
        if (payload.errno !== 0) {
          toast.warning(payload.errmsg);
        } else {
          _.set(state.aigcResponse, [meta.arg.mailId], payload.data?.text || '');
        }
      })
      .addCase(getAigcResponse.rejected, (state, {}) => {
        state.gettingAigcResponse = false;
      })
      .addCase(setAigcCommentReplies, (state, { payload }) => {
        state.commentAigcReplies = _.merge({}, state.commentAigcReplies, payload);
      })
      .addCase(setAigcCommentReply, (state, { payload }) => {
        state.commentAigcReplies = _.set(state.commentAigcReplies, [payload.commentId], payload.reply);
      })
      .addCase(sendMail.pending, (state, { meta }) => {
        _.set(state.sendingMail, [meta.arg.to], true);
      })
      .addCase(sendMail.fulfilled, (state, { meta, payload }) => {
        const { orgId, to } = meta.arg;
        if (payload.errno === ERROR_CODE.ERROR) {
          toast.error(payload.errmsg);
        } else if (payload) {
          toast.success(`Mail sent to ${to}`);
          if (orgId) {
            _.set(state.orgUserList, [orgId, 'list', to, 'latestReplyDate'], new Date().toISOString());
            _.set(state.orgUserSentEmail, [orgId, to], Date.now());
          }
        }
        _.set(state.sendingMail, [meta.arg.to], false);
      })
      .addCase(sendMail.rejected, (state, { meta }) => {
        _.set(state.sendingMail, [meta.arg.to], true);
      })
      .addCase(sendDirectMail.pending, (state, { meta }) => {
        _.set(state.sendingMail, [meta.arg.to], true);
      })
      .addCase(sendDirectMail.fulfilled, (state, { meta, payload }) => {
        const { orgId, to } = meta.arg;
        if (payload.errno === ERROR_CODE.ERROR) {
          toast.error(payload.errmsg);
        } else if (payload) {
          toast.success(`Mail sent to ${to}`);
          if (orgId) {
            _.set(state.orgUserList, [orgId, 'list', to, 'latestReplyDate'], new Date().toISOString());
            _.set(state.orgUserSentEmail, [orgId, to], Date.now());
          }
        }
        _.set(state.sendingMail, [meta.arg.to], false);
      })
      .addCase(sendDirectMail.rejected, (state, { meta }) => {
        _.set(state.sendingMail, [meta.arg.to], true);
      })
      .addCase(setInboxSourceType, (state, { payload }) => {
        const { orgId, key } = payload;
        _.set(state.orgInboxSourceType, [orgId], key);
      })
      .addCase(setInboxSearchStr, (state, { payload }) => {
        const { orgId, searchStr } = payload;
        _.set(state.orgInboxUserSearchStr, [orgId], searchStr);
      })
      .addCase(setInboxDrawerOpen, (state, { payload }) => {
        const { drawerOpen } = payload;
        state.drawerOpen = drawerOpen;
      })
      .addCase(setInboxReplying, (state, { payload }) => {
        const { replying } = payload;
        state.replying = replying;
      })
      .addCase(setInboxCurrentId, (state, { payload }) => {
        const { orgId, id } = payload;
        _.set(state.orgCurrentUserId, [orgId], id);
      })
      .addCase(setInboxCurrentText, (state, { payload }) => {
        const { orgId, id, text } = payload;
        _.set(state.orgUserCurrentText, [orgId], { [id]: text });
      })
      .addCase(resetMeta, (state) => {
        state.orgMetaPosts = {};
        state.orgMetaPostComments = {};
        state.orgMetaPostCommentReplies = {};
      })
      .addCase(getMetaPosts.pending, (state, { meta }) => {
        const { orgId } = meta.arg;
        _.set(state.orgMetaPosts, [orgId, 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getMetaPosts.fulfilled, (state, { meta, payload }) => {
        const { orgId, mode, pageSize = 10 } = meta.arg;
        let filledListStatus;
        const current = _.get(state.orgMetaPosts, [orgId, 'list']) || {};
        switch (mode) {
          case 'append':
            if (_.isEmpty(payload) || _.values(payload).length < pageSize) {
              filledListStatus = setFulfilledEndListStatus({ ...current, ...payload });
            } else {
              filledListStatus = setFulfilledListStatus({ ...current, ...payload });
            }
            break;
          case 'unshift':
            if (_.isEmpty(payload)) {
              filledListStatus = setFulfilledEndListStatus(current);
            } else {
              filledListStatus = setFulfilledEndListStatus({ ...current, ...payload });
            }
            break;
          default:
            if (_.values(payload).length < pageSize) {
              filledListStatus = setFulfilledEndListStatus(payload);
            } else {
              filledListStatus = setFulfilledListStatus(payload);
            }
            break;
        }
        _.set(state.orgMetaPosts, [orgId], filledListStatus);
      })
      .addCase(getMetaPostComments.pending, (state, { meta }) => {
        const { orgId, metaPagePostId } = meta.arg;
        _.set(state.orgMetaPostComments, [orgId || '', metaPagePostId || '', 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getMetaPostComments.fulfilled, (state, { meta, payload }) => {
        const { orgId, metaPagePostId } = meta.arg;
        if (payload) {
          _.set(state.orgMetaPostComments, [orgId || '', metaPagePostId || ''], setFulfilledListStatus(payload));
        }
      })
      .addCase(setInboxPostSearchStr, (state, { payload }) => {
        const { orgId, searchStr } = payload;
        _.set(state.orgInboxPostSearchStr, [orgId], searchStr);
      })
      .addCase(setInboxCurrentPostId, (state, { payload }) => {
        const { orgId, id } = payload;
        _.set(state.orgCurrentPostId, [orgId], id);
      })
      .addCase(setInboxCommentSearchStr, (state, { payload }) => {
        const { orgId, searchStr } = payload;
        _.set(state.orgInboxCommentSearchStr, [orgId], searchStr);
      })
      .addCase(getMetaPostCommentReplies.pending, (state, { meta }) => {
        const { orgId, metaPostCommentId: commentId } = meta.arg;
        _.set(state.orgMetaPostCommentReplies, [orgId || '', commentId || '', 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getMetaPostCommentReplies.fulfilled, (state, { meta, payload }) => {
        const { orgId, metaPostCommentId: commentId } = meta.arg;
        if (payload) {
          _.set(state.orgMetaPostCommentReplies, [orgId || '', commentId || ''], setFulfilledListStatus(payload));
        }
      })
      .addCase(setInboxCurrentPostComment, (state, { payload }) => {
        const { orgId, comment } = payload;
        _.set(state.orgCurrentPostComment, [orgId], comment);
      })
      .addCase(ReplyMetaPostComment.pending, (state, { meta }) => {
        _.set(state.replyingComment, [meta.arg.id || ''], true);
      })
      .addCase(ReplyMetaPostComment.fulfilled, (state, { meta, payload }) => {
        const { orgId, id, commentId } = meta.arg;
        const current = _.get(state.orgMetaPostCommentReplies, [orgId || '', id || '', 'list']) || {};
        if (payload.errno === ERROR_CODE.ERROR) {
          toast.error(payload.errmsg);
        } else if (payload) {
          _.set(
            state.orgMetaPostCommentReplies,
            [orgId || '', id || ''],
            setFulfilledListStatus({ ...current, ..._.keyBy([payload.data], 'id') }),
          );
          _.set(state.commentAigcReplies, [commentId || ''], '');
        }
        _.set(state.replyingComment, [meta.arg.id || ''], false);
      })
      .addCase(ReplyMetaPostComment.rejected, (state, { meta }) => {
        _.set(state.replyingComment, [meta.arg.id || ''], false);
      })
      .addCase(setAigcReplyResponse, (state, { payload }) => {
        if (payload.status === FETCHING_STATUS.INIT) {
          _.set(state.aigcReplyResponse, [payload.commentId], payload.response);
        } else {
          _.set(
            state.aigcReplyResponse,
            [payload.commentId],
            _.get(state.aigcReplyResponse, [payload.commentId]) + payload.response,
          );
        }
        state.gettingAigcReplyResponse = payload.status !== FETCHING_STATUS.DONE;
      })
      .addCase(setInboxMetaCurrentText, (state, { payload }) => {
        const { orgId, commentId, text } = payload;
        _.set(state.orgPostCurrentText, [orgId], { [commentId]: text });
      })
      .addCase(setPostCommentSelected, (state, { payload }) => {
        const currentComments = _.get(state.postSelectedComments, [payload.postId], []);
        _.set(
          state.postSelectedComments,
          [payload.postId],
          payload.selected
            ? [...currentComments, payload.commentId]
            : currentComments.filter((c) => c !== payload.commentId),
        );
      });
  },
});
export const { spliceUserListByFrom } = inboxSlice.actions;

export const selectUserList =
  (orgId: string | undefined) =>
  (state: AppState): Record<string, IInboxUser> => {
    return _.get(state.inbox.orgUserList, [orgId || '', 'list']);
  };

export const selectUserMailList =
  (orgId: string | undefined, user: string | undefined) =>
  (state: AppState): IMail[] =>
    _.get(state.inbox.orgUserMails, [orgId || '', user || '', 'list']);

export const selectCurrentUserId =
  (orgId: string | undefined) =>
  (state: AppState): string =>
    _.get(state.inbox.orgCurrentUserId, [orgId || '']);

export const selectUserById =
  (orgId: string | undefined, id: string) =>
  (state: AppState): IInboxUser =>
    _.get(state.inbox.orgUserList, [orgId || '', 'list', id]);

export const selectUserListStatus =
  (orgId: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.inbox.orgUserList, [orgId || '', 'status']);

export const selectOrgInboxSourceType =
  (orgId: string | undefined) =>
  (state: AppState): INBOX_SOURCES_KEY =>
    _.get(state.inbox.orgInboxSourceType, [orgId || ''], INBOX_SOURCES_KEY.GMAIL);

export const selectOrgInboxSearchStr =
  (orgId: string | undefined) =>
  (state: AppState): string =>
    _.get(state.inbox.orgInboxUserSearchStr, [orgId || '']);

export const selectOrgInboxUserText =
  (orgId: string | undefined, id: string | undefined) =>
  (state: AppState): string =>
    _.get(state.inbox.orgUserCurrentText, [orgId || '', id || '']);

export const selectEmailListStatus =
  (orgId: string | undefined, id: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.inbox.orgUserMails, [orgId || '', id || '', 'status']);

export const selectEmailListHasMore =
  (orgId: string | undefined, id: string | undefined) =>
  (state: AppState): boolean | undefined =>
    _.get(state.inbox.orgUserMails, [orgId || '', id || '', 'hasMore']);

export const selectSendingMail =
  (address: string | undefined) =>
  (state: AppState): boolean =>
    _.get(state.inbox.sendingMail, [address || '']);
export const selectGettingAigcResponse = (state: AppState): boolean => state.inbox.gettingAigcResponse;
export const selectAigcResponse =
  (mailId: string | undefined) =>
  (state: AppState): string =>
    _.get(state.inbox.aigcResponse, [mailId || ''], '');
export const selectEmailRenderStatus = (state: AppState): { [mailId: string]: string } => state.inbox.emailRenderStatus;
export const selectUserSentEmail =
  (orgId: string | undefined, userId: string) =>
  (state: AppState): number =>
    _.get(state.inbox.orgUserSentEmail, [orgId || '', userId]);
export const selectInboxDrawerOpen = (state: AppState): boolean => state.inbox.drawerOpen;
export const selectInboxReplying = (state: AppState): boolean => state.inbox.replying;
export const selectMetaPosts =
  (orgId: string | undefined) =>
  (state: AppState): Record<string, IMetaPost> =>
    _.get(state.inbox.orgMetaPosts, [orgId || '', 'list']);

export const selectMetaPostsStatus =
  (orgId: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.inbox.orgMetaPosts, [orgId || '', 'status']);

export const selectMetaPostsComments =
  (orgId: string | undefined, postId: string | undefined) =>
  (state: AppState): Record<string, IMetaComment> =>
    _.get(state.inbox.orgMetaPostComments, [orgId || '', postId || '', 'list']);

export const selectMetaPostsCommentsStatus =
  (orgId: string | undefined, postId: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.inbox.orgMetaPostComments, [orgId || '', postId || '', 'status']);

export const selectOrgInboxPostSearchStr =
  (orgId: string | undefined) =>
  (state: AppState): string =>
    _.get(state.inbox.orgInboxPostSearchStr, [orgId || '']);

export const selectCurrentPostId =
  (orgId: string | undefined) =>
  (state: AppState): string =>
    _.get(state.inbox.orgCurrentPostId, [orgId || '']);

export const selectOrgInboxCommentSearchStr =
  (orgId: string | undefined) =>
  (state: AppState): string =>
    _.get(state.inbox.orgInboxCommentSearchStr, [orgId || '']);

export const selectMetaPostCommentReplies =
  (orgId: string | undefined, commentId: string | undefined) =>
  (state: AppState): Record<string, IMetaComment> =>
    _.get(state.inbox.orgMetaPostCommentReplies, [orgId || '', commentId || '', 'list']);

export const selectMetaPostCommentRepliesStatus =
  (orgId: string | undefined, commentId: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.inbox.orgMetaPostCommentReplies, [orgId || '', commentId || '', 'status']);

export const selectCurrentPostComment =
  (orgId: string | undefined) =>
  (state: AppState): IMetaComment =>
    _.get(state.inbox.orgCurrentPostComment, [orgId || '']);

export const selectMetaPostById =
  (orgId: string | undefined, id: string) =>
  (state: AppState): IMetaPost | undefined =>
    _.get(state.inbox.orgMetaPosts, [orgId || '', 'list', id]);

export const selectReplyingComment =
  (id: string | undefined) =>
  (state: AppState): boolean =>
    _.get(state.inbox.replyingComment, [id || ''], false);
export const selectReplyingComments = (state: AppState): boolean =>
  _.some(_.values(state.inbox.replyingComment), (replying) => !!replying);

export const selectAigcReplyResponse =
  (commentId?: string) =>
  (state: AppState): string =>
    _.get(state.inbox.aigcReplyResponse, [commentId || ''], '');

export const selectAigcCommentReply =
  (commentId?: string) =>
  (state: AppState): string =>
    _.get(state.inbox.commentAigcReplies, [commentId || ''], '');
export const selectPostCommentsSelected =
  (postId?: string) =>
  (state: AppState): string[] =>
    _.get(state.inbox.postSelectedComments, [postId || '']);

export const selectOrgInboxMetaCurrentText =
  (orgId: string | undefined, commentId: string | undefined) =>
  (state: AppState): string =>
    _.get(state.inbox.orgPostCurrentText, [orgId || '', commentId || '']);

export const selectGettingAigcReplyResponse = (state: AppState): boolean => state.inbox.gettingAigcReplyResponse;

export default inboxSlice.reducer;
