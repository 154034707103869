/**
 * @description Component-QueryHeader
 */
import React, { FC, useMemo, useState } from 'react';
import './index.less';
import { Avatar, Button, Drawer, Input, Radio } from 'antd';
import classNames from 'classnames';
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseOutlined,
  DoubleRightOutlined,
  SearchOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useOrg } from '@/features/settings/hooks';
import { useTeamMembers } from '@/features/team/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelectTicketGetParam } from '@/features/plugin/hooks';
import { setTicketGetParam } from '@/features/plugin/actions';
import { useAppDispatch } from '@/features/hooks';
import { useDebounceFn } from 'ahooks';
import type { RadioChangeEvent } from 'antd';
import { matchSorter } from 'match-sorter';
import { setTeamMembers } from '@/features/team/actions';
import { IMember } from '@/types/team';
import { MEMBER_STATUS } from '@/constants/team';
import { TICKET_STATUS } from '@/constants/Tickets';

interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const QueryHeader: FC<IPropTypes> = function ({}) {
  const dispatch = useAppDispatch();
  const org = useOrg();
  const { user } = useAuth0();
  const teams = useTeamMembers(org?.id);
  const param = useSelectTicketGetParam();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [all, setAll] = useState(false);

  const changeDrawer = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };
  const handleMeClick = () => {
    setSearch('');
    setOpen(false);
    setAll(false);
    handleSetTeamMembers(false);
    dispatch(
      setTicketGetParam({ page: 1, assignees: param.assignees?.join(',') === user?.email ? [] : [user?.email || ''] }),
    );
  };

  const handleItemClick = (member: IMember) => {
    const list =
      teams?.map((item) => {
        return member.id === item.id
          ? {
              ...item,
              selected: !item.selected,
            }
          : item;
      }) || [];

    dispatch(
      setTeamMembers({
        orgId: org?.id,
        members: list,
      }),
    );
    dispatch(
      setTicketGetParam({
        page: 1,
        assignees: list?.reduce((acc: string[], item) => {
          if (item.selected) {
            acc.push(item.email);
          }
          return acc;
        }, []),
      }),
    );
  };

  const handleSetTeamMembers = (selected: boolean) => {
    const list = teams?.map((item) => {
      return { ...item, selected };
    });
    dispatch(
      setTeamMembers({
        orgId: org?.id,
        members: list || [],
      }),
    );
    dispatch(setTicketGetParam({ page: 1, assignees: list?.map((item) => item.email) }));
  };

  const handleAllClick = () => {
    setAll(!all);
    handleSetTeamMembers(!all);
  };

  const filterTeams = useMemo(() => {
    const filterTeams = teams;
    // .filter((m) => m.status !== MEMBER_STATUS.PENDING)
    if (search) {
      return matchSorter(filterTeams || [], search, {
        keys: ['email'],
        threshold: matchSorter.rankings.ACRONYM, // https://www.npmjs.com/package/match-sorter?activeTab=readme
      });
    }
    return filterTeams;
  }, [teams, search]);

  const checkAllTeams = useMemo(() => {
    const list = teams?.filter((member) => {
      return member.selected;
    });
    if (list && list?.length > 0) {
      return (
        <Avatar.Group size="small" maxCount={2} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
          {list.map((item) => (
            <Avatar key={item.id}>{item.email.toUpperCase().charAt(0)}</Avatar>
          ))}
        </Avatar.Group>
      );
    }
    return 'Assignees';
  }, [teams]);

  const onRadioChange = ({ target: { value } }: RadioChangeEvent) => {
    dispatch(setTicketGetParam({ page: 1, status: value }));
  };

  const { run: onInputChange } = useDebounceFn(
    (str: string) => {
      dispatch(setTicketGetParam({ page: 1, query: str }));
    },
    { wait: 200 },
  );

  const radioOptions = useMemo(() => {
    const opt = [];
    for (const key in TICKET_STATUS) {
      if (TICKET_STATUS.hasOwnProperty(key)) {
        opt.push({ label: key.toLowerCase(), value: TICKET_STATUS[key as keyof typeof TICKET_STATUS] });
      }
    }
    return opt;
  }, []);

  return (
    <div className="cor-query-header">
      <div className="cor-query-header-search">
        <Input
          value={param.query}
          size="small"
          prefix={<SearchOutlined />}
          placeholder="Search..."
          allowClear={{
            clearIcon: <CloseOutlined />,
          }}
          onChange={(e) => onInputChange(e.target.value.trim())}></Input>
      </div>

      <div className="cor-query-header-assignees">
        <Button
          type={param.assignees?.join(',') === user?.email ? 'primary' : 'default'}
          size="small"
          icon={<UserOutlined />}
          shape="round"
          onClick={handleMeClick}>
          Me mode
        </Button>
      </div>

      <div className="cor-query-header-assignees">
        <Button size="small" icon={<TeamOutlined width="30" />} shape="round" onClick={changeDrawer}>
          {checkAllTeams}
        </Button>
      </div>

      <div className="cor-query-header-status">
        <Radio.Group
          options={radioOptions}
          onChange={onRadioChange}
          value={param.status}
          optionType="button"
          buttonStyle="solid"
          size="middle"
        />
      </div>
      <Drawer
        className="cor-query-header-drawer"
        title="Assignees"
        placement="right"
        onClose={onClose}
        maskStyle={{ background: 'none' }}
        open={open}
        width={250}
        closeIcon={<DoubleRightOutlined />}>
        <div className="cor-query-header-drawer-search">
          <Input
            value={search}
            size="small"
            prefix={<SearchOutlined />}
            placeholder="Search..."
            allowClear={{
              clearIcon: <CloseOutlined />,
            }}
            onChange={(e) => setSearch(e.target.value.trim())}></Input>
        </div>
        <div className="cor-query-header-drawer-team">
          {filterTeams?.map((member) => (
            <div
              onClick={() => {
                handleItemClick(member);
              }}
              className={classNames('cor-query-header-drawer-item', member.selected ? 'selected' : '')}
              key={member.id}>
              <Avatar className="cor-query-header-drawer-item-avatar">{member.email.toUpperCase().charAt(0)}</Avatar>
              <div className="cor-query-header-drawer-item-text">{member.email}-</div>
              <div className="cor-query-header-drawer-item-check">
                {member.selected ? <CheckCircleFilled /> : <CheckCircleOutlined />}
              </div>
            </div>
          ))}
        </div>
        <div className="cor-query-header-drawer-all">
          <Button type="text" onClick={handleAllClick}>
            {all ? 'clear all' : 'select all'}
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default QueryHeader;
