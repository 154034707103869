import { IOrderCancelRequest, IPluginSession, IProduct, ITicket } from '@/types/plugin';
import { IResponse } from '@/types/settings';
import { axiosGet, axiosPost } from '@/utils/axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ITicketGetParam } from './slice';
import _ from 'lodash';
export const setSelectedSession = createAction<{ orgId: string; session: IPluginSession }>('plugin/setSelectedSession');

export const getPluginId = createAsyncThunk(
  'plugin/getPluginId',
  async ({}: { orgId: string }): Promise<IResponse<string>> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT}plugin-id`);
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const getSessions = createAsyncThunk(
  'plugin/getSessions',
  async ({
    pageSize,
    nextId,
    preId,
    search,
  }: {
    orgId: string;
    pageSize?: number;
    nextId?: string;
    preId?: string;
    search?: string;
  }): Promise<IResponse<IPluginSession[]>> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT}livechat/sessions`, {
        params: {
          pageSize,
          nextId,
          preId,
          search,
        },
      });
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const getSessionConversation = createAsyncThunk(
  'plugin/getSessionConversation',
  async ({ conversationId }: { orgId: string; conversationId: string }): Promise<IResponse<IPluginSession[]>> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT}livechat/conversations`, {
        params: {
          conversationId,
        },
      });
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const getProductInfo = createAsyncThunk(
  'plugin/getProductInfo',
  async ({ url, trackId }: { url: string; trackId: string }): Promise<IProduct | undefined> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT}alloy/products/get`, {
        params: { url, trackId: trackId },
      });
      return result.data.data;
    } catch (e) {
      console.log('🚀 ~ e:', e);
      return undefined;
    }
  },
);

export const getOrderCancelRequest = createAsyncThunk(
  'plugin/getOrderCancelRequest',
  async ({
    pageSize,
    nextId,
    remoteId,
  }: {
    pageSize: number;
    nextId?: string;
    remoteId?: string;
    orgId: string;
  }): Promise<IOrderCancelRequest[] | undefined> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT}merchant/order/refund/list`, {
        params: { pageSize, nextId, remoteId },
      });
      return result.data.data;
    } catch (e) {
      console.log('🚀 ~ e:', e);
      return undefined;
    }
  },
);
export const createTicket = createAsyncThunk(
  'plugin/createTicket',
  async (
    {
      conversationId,
      trackId,
      source,
      type,
      customerEmail,
      merchantEmail,
      title,
      content,
      priority,
      assignees,
    }: {
      conversationId?: string;
      trackId: string;
      source: string;
      type: string;
      customerEmail: string;
      merchantEmail: string;
      title: string;
      content: string;
      priority: string;
      orgId: string;
      assignees: string;
    },
    { dispatch },
  ): Promise<ITicket | undefined> => {
    try {
      const result = await axiosPost(`${process.env.REACT_APP_API_ROOT}tickets`, {
        conversationId,
        trackId,
        source,
        type,
        customerEmail,
        merchantEmail,
        title,
        content,
        priority,
        assignees,
      });
      dispatch(setTicketGetParam({ page: 1 }));
      return result.data.data;
    } catch (e) {
      console.log('🚀 ~ e:', e);
      return undefined;
    }
  },
);
export const getTickets = createAsyncThunk(
  'plugin/getTickets',
  async ({
    param,
  }: {
    param: ITicketGetParam;
    orgId: string;
  }): Promise<{
    data: ITicket[] | undefined;
    meta: { page: number; pageSize: number; total: number };
  } | null> => {
    const assignees = param.assignees?.join(',');
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT}tickets`, {
        params: _.omitBy({ ...param, assignees }, (value) => _.isString(value) && _.isEmpty(value)),
      });
      return result.data;
    } catch (e) {
      console.log('🚀 ~ e:', e);
      return null;
    }
  },
);

export const deleteTicket = createAsyncThunk(
  'plugin/deleteTicket',
  async ({ id }: { id: string; orgId: string }, { dispatch }): Promise<boolean | undefined> => {
    try {
      await axiosPost(`${process.env.REACT_APP_API_ROOT}tickets/delete`, {
        id,
      });
      dispatch(setTicketGetParam({ page: 1 }));
      return true;
    } catch (e) {
      console.log('🚀 ~ e:', e);
      return undefined;
    }
  },
);
export const updateTicket = createAsyncThunk(
  'plugin/updateTicket',
  async (
    {
      id,
      status,
      content,
      priority,
      title,
      customerEmail,
      assignees,
    }: {
      id: string;
      status: string;
      orgId: string;
      content?: string;
      priority?: string;
      title?: string;
      customerEmail?: string;
      assignees?: string;
    },
    { dispatch },
  ): Promise<boolean | undefined> => {
    try {
      const result = await axiosPost(`${process.env.REACT_APP_API_ROOT}tickets/update`, {
        id,
        status,
        content,
        priority,
        title,
        customerEmail,
        assignees,
      });
      dispatch(setTicketGetParam({ page: 1 }));
      return result.data.data;
    } catch (e) {
      console.log('🚀 ~ e:', e);
      return undefined;
    }
  },
);
// 添加附件的 action
export const addTicketAttachment = createAsyncThunk(
  'ticket/addComment',
  async (
    { ticketId, attachment, attachName }: { ticketId: string; attachment: string; attachName: string },
    { dispatch },
  ): Promise<boolean | undefined> => {
    try {
      const result = await axiosPost(`${process.env.REACT_APP_API_ROOT}tickets/attachments `, {
        ticketId,
        attachment,
        attachName,
      });
      dispatch(setTicketGetParam({ page: 1 }));
      return result.data;
    } catch (e) {
      console.log('🚀 ~ e:', e);
      return undefined;
    }
  },
);
// 删除附件的 action
export const delTicketAttachment = createAsyncThunk(
  'ticket/addComment',
  async (
    { ticketId, attachName }: { ticketId: string; attachName: string },
    { dispatch },
  ): Promise<boolean | undefined> => {
    try {
      await axiosPost(`${process.env.REACT_APP_API_ROOT}tickets/attachments/delete  `, {
        ticketId,
        attachName,
      });
      dispatch(setTicketGetParam({ page: 1 }));
      return true;
    } catch (e) {
      console.log('🚀 ~ e:', e);
      return undefined;
    }
  },
);

export const setTicketGetParam = createAction<ITicketGetParam>('plugin/setTicketGetParam');
