/**
 * @description Component-Banner
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import Frame77 from './assets/Frame77.png';
interface IPropTypes {
  className?: string;
}
const Banner: FC<IPropTypes> = function () {
  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };
  return (
    <div className={`cor-banner`}>
      <div className="cor-banner-title">
        <div>
          The <span className="highlight">Leading</span> Customer-Centric
        </div>
        <div>AI CX Platform</div>
      </div>
      <div className="cor-banner-subtitle">Interpret Multi-Channel Insights for Accelerated Growth</div>
      <div className="cor-banner-btn" onClick={handleScrollToBottom}>
        See it live
      </div>
      <img className="cor-banner-img" src={Frame77} />
      <div className="cor-banner-bottom">
        <div className="cor-banner-bottom-container">
          The first <span className="tips">customer-centric</span> intelligent CX platform delivers personalized,
          real-time interactions to <span className="tips">boosts conversions, ROI, and customer satisfaction. </span>  
        </div>
      </div>
    </div>
  );
};

export default Banner;
