export enum IMessageRole {
  CUSTOMER = 'customer',
  ASSISTANT = 'assistant',
  STARTER = 'starter',
}
export enum SESSION_MODE {
  MANUAL = 'manual',
  AUTO = 'auto',
}

export const START_OF_FUNCTIONS = '<<START_OF_FUNCTIONS>>';
export const END_OF_FUNCTIONS = '<<END_OF_FUNCTIONS>>';
export const END_OF_PLAINTEXT = '<<END_OF_PLANTEXT>>';
export const START_OF_PAYLOAD = '<<START_OF_PAYLOAD>>';
export const REQUEST_PAGE_SIZE = 100;
export const PLUGIN_SESSION_POLLING_INTERVAL = 10000;
