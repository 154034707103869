/**
 * @description Component-Profile
 */
import React, { FC } from 'react';
import './index.less';
import SignatureCard from '../Connections/SignatureCard';
interface IPropTypes {
  className?: string;
}
const Profile: FC<IPropTypes> = function () {
  return (
    <div className="cor-profile">
      <SignatureCard />
    </div>
  );
};

export default Profile;
