/**
 * @description Component-ChatReplyBox
 */
import React, { FC, useCallback, useState } from 'react';
import './index.less';
import { Button, Input } from 'antd';
import { ReactComponent as SendIcon } from '@/assets/svg/icon_send.svg';
import { useOrg } from '@/features/settings/hooks';
import { useLastMessage } from '@/features/aigc/hooks';
import { SOCKET_STATUS } from '@/constants/sockets';
import { AIGC_TYPE } from '@/constants/aigc';
interface IPropTypes {
  className?: string;
  onInputMessageChange: (message: string) => void;
  customEmail: string | undefined;
  scene: AIGC_TYPE;
}
const ChatReplyBox: FC<IPropTypes> = function ({ onInputMessageChange, customEmail, scene }) {
  const [currentMessage, setCurrentMessage] = useState('');
  const org = useOrg();
  const lastMessage = useLastMessage(org?.id, customEmail, scene);
  const sendMessage = useCallback(() => {
    if (currentMessage.trim()) {
      onInputMessageChange(currentMessage);
      setCurrentMessage('');
    }
  }, [currentMessage, onInputMessageChange]);
  const onInputKeyUp = useCallback(
    (e: { keyCode: number; key: string }) => {
      if (e.keyCode === 13 || e.key === 'Enter') {
        sendMessage();
      }
    },
    [sendMessage],
  );

  return (
    <div className="cor-chat-reply-box">
      <Input.TextArea
        onChange={(e) => setCurrentMessage(e.target.value)}
        className="cor-chat-reply-box-textarea"
        onKeyUp={onInputKeyUp}
        value={currentMessage}
        autoSize={{ minRows: 1, maxRows: 6 }}
        placeholder="Ask stone..."
        bordered={false}
      />
      <Button
        type="text"
        loading={lastMessage?.status === SOCKET_STATUS.FETCHING}
        onClick={sendMessage}
        icon={<SendIcon />}
      />
    </div>
  );
};

export default ChatReplyBox;
