/**
 * @description Component-DeleteBtn
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import { IKnowledgeBase } from '@/types/settings';
import { useKnowledgeWebpageDeletingStatus } from '@/features/settings/hooks';
import { Button } from 'antd';
import { FETCHING_STATUS } from '@/constants';
import { ReactComponent as DeleteIcon } from '@/assets/svg/icon_delete.svg';
import { useAppDispatch } from '@/features/hooks';
import { deleteKnowledgeWebpage } from '@/features/settings/actions';
interface IPropTypes {
  knowledge: IKnowledgeBase;
  org: string | undefined;
  className?: string;
}
const DeleteBtn: FC<IPropTypes> = function ({ knowledge, org }) {
  const deleting = useKnowledgeWebpageDeletingStatus(org, knowledge.id);
  const dispatch = useAppDispatch();
  const deleteSettings = useCallback(() => {
    dispatch(deleteKnowledgeWebpage({ id: knowledge.id, org }));
  }, [dispatch, org, knowledge]);
  return (
    <Button
      size="small"
      onClick={deleteSettings}
      disabled={deleting === FETCHING_STATUS.FETCHING}
      loading={deleting === FETCHING_STATUS.FETCHING}
      type="text"
      icon={<DeleteIcon />}>
      Delete
    </Button>
  );
};

export default DeleteBtn;
