/**
 * @description Component-About
 */
import React, { FC } from 'react';
import './index.less';
import Footer from '../Landing/Footer';
import Header from '../Landing/Header';
import HeaderMobile from '../LandingMobile/Header';
import FooterMobile from '../LandingMobile/Footer';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import classNames from 'classnames';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const About: FC<IPropTypes> = function (props) {
  const { deviceType, isMobile } = useMediaQueryDevice();
  return (
    <div className={classNames('cor-about', deviceType)}>
      {isMobile ? <HeaderMobile /> : <Header />}
      <div className="cor-about-content">
        <p>
          At Cornerstone AI, we believe in the power of technology to transform businesses, creating opportunities for
          growth, efficiency, and enhanced customer experiences. As a pioneering AI-driven SaaS company based in
          Seattle, we are committed to providing cutting-edge solutions that help businesses thrive in the digital age.
        </p>
        <h4>Our Mission</h4>
        <p>
          Our mission is simple: to empower businesses by harnessing the full potential of AI. We develop innovative
          tools that enable companies to understand their customers better, streamline operations, and drive
          conversions. With Cornerstone AI, you&apos;re not just adopting a technology solution—you&apos;re partnering
          with a team dedicated to your success
        </p>
        <h4>Who We Are</h4>
        <p>
          Founded by a group of passionate tech enthusiasts and industry experts, Cornerstone AI was built on the
          principles of trust, innovation, and excellence. Our Seattle-based team has decades of experience working at
          leading technology companies, including Amazon, Microsoft, Nvidia, and Meta. This diverse background has
          equipped us with the knowledge and expertise to build AI solutions that truly make a difference.
        </p>
        <h4>What We Do</h4>
        <p>
          We specialize in delivering dynamic personalization, robust data consolidation, and intelligent customer
          engagement solutions. Our platform seamlessly integrates with your existing systems, providing real-time
          insights and automation that optimize your business processes. From personalized marketing to customer service
          automation, our solutions are designed to enhance your operational efficiency and customer satisfaction.
        </p>
        <h4>Our Promise</h4>
        <p>
          At Cornerstone AI, we understand that trust is earned, not given. That&apos;s why we prioritize transparency,
          security, and integrity in everything we do. We are committed to safeguarding your data with industry-leading
          encryption and compliance standards. Our customer-centric approach ensures that your needs are always at the
          forefront, and we work closely with you to tailor our solutions to your unique challenges.
        </p>
      </div>
      {isMobile ? <FooterMobile /> : <Footer />}
    </div>
  );
};

export default About;
