/**
 * @description Component-Header
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import { Button, Menu, MenuProps } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderDropDown from '@/pages/components/HeaderDropDown';
import Logo from '@/pages/components/Logo';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import { useTranslation } from 'react-i18next';
import { useOrg } from '@/features/settings/hooks';
interface IPropTypes {
  className?: string;
  isDisclosure?: boolean;
}
const Header: FC<IPropTypes> = function ({ isDisclosure }) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { deviceType, isMobile } = useMediaQueryDevice();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const org = useOrg();
  const login = useCallback(async () => {
    try {
      await loginWithRedirect();
    } catch (e) {}
  }, [loginWithRedirect]);
  const scrollToProducts = useCallback(() => {
    const productEl = document.getElementById('products');
    if (productEl) {
      productEl.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  const menuItems = [
    {
      key: 'company',
      label: 'Company',
      children: [
        { key: 'about', label: 'About' },
        { key: 'career', label: 'Careers' },
      ],
    },
  ];
  const onClick: MenuProps['onClick'] = useCallback(
    (e: { key: string }) => {
      navigate(`/${e.key}`);
    },
    [navigate],
  );
  return (
    <div className={`cor-mobileheader`}>
      <Logo />
      <div className="cor-mobileheader-btns">
        <Menu
          className="cor-mobileheader-menu"
          style={{ width: 111.7 }}
          mode="horizontal"
          items={menuItems}
          onClick={onClick}
        />
        {isAuthenticated ? (
          <>
            {!location.pathname.includes('dashboard') && (
              <Button
                onClick={() => {
                  navigate('/dashboard');
                }}
                type="text">
                {t('landing.header.dashboard')}
              </Button>
            )}
            <HeaderDropDown />
          </>
        ) : (
          <Button type="primary" onClick={login}>
            {t('landing.header.signUpIn')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Header;
