import React, { FC, useState } from 'react';
import { Collapse, Input, Button, Empty } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useKnowledgeWebpage, useOrg } from '@/features/settings/hooks';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './index.less';
import { updateWebpage } from '@/features/settings/actions';
import { useAppDispatch } from '@/features/hooks';

const { Panel } = Collapse;
const { TextArea } = Input;

interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}

interface Urls {
  content?: any;
  title: string;
  source: string;
}

const WebpageDetail: FC<IPropTypes> = () => {
  const navigate = useNavigate();
  const org = useOrg();
  const { id } = useParams();
  const pageDetail = useKnowledgeWebpage(org?.id, id);
  const dispatch = useAppDispatch();

  const [editIndex, setEditIndex] = useState<number>(-1);
  const [editContent, setEditContent] = useState<string>('');

  const handleEdit = (index: number, content: string) => {
    setEditIndex(index);
    setEditContent(content);
  };

  const handleSave = async () => {
    if (org && pageDetail) {
      const updatedUrls = pageDetail.result.urls.map((url: any, index: number) => {
        if (index === editIndex) {
          return { ...url, content: editContent };
        }
        return url;
      });

      await dispatch(
        updateWebpage({
          org: org.id,
          knowledge: {
            id: pageDetail.id,
            result: {
              urls: updatedUrls,
            },
          },
        }),
      ).unwrap();
      setEditIndex(-1);
    }
  };

  if (!pageDetail || !pageDetail?.result?.urls || pageDetail?.result?.urls.length === 0) {
    return (
      <div
        style={{ padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
        <Empty description="No data available" />
      </div>
    );
  }

  return (
    <div className="cor-pageDetail">
      <div style={{ display: 'flex', alignItems: 'center', padding: '20px' }}>
        <Button
          type="text"
          shape="circle"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
          className="back-button"
        />
        <span style={{ marginLeft: '10px' }}>{pageDetail.name}</span>
      </div>
      <div style={{ overflowY: 'auto', maxHeight: '100vh' }}>
        <Collapse>
          {pageDetail.result.urls.map((url: Urls, index: number) => (
            <Panel header={`${url.title} - ${url.source}`} key={index}>
              {editIndex === index ? (
                <>
                  <TextArea value={editContent} onChange={(e) => setEditContent(e.target.value)} rows={10} />
                  <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={handleSave} type="primary">
                      Save
                    </Button>
                  </div>
                </>
              ) : (
                <div onClick={() => handleEdit(index, url.content)} style={{ maxHeight: '170px', overflowY: 'auto' }}>
                  {url.content}
                </div>
              )}
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default WebpageDetail;
