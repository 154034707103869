import { createSlice } from '@reduxjs/toolkit';
import { getShopConnections, deleteShopConnection, setAlloy } from './actions';
import { IAlloy, IShopConnection } from '@/types/shop';
import { AppState } from '../store';
import { ListArrayStatus, getDefaultListArrayStatus, setFulfilledListArrayStatus } from '@/types/redux';
import _ from 'lodash';
import { ERROR_CODE, FETCHING_STATUS } from '@/constants';
import { SHOP_TYPES } from '@/constants/shop';
export interface IShopConnectState {
  alloy: IAlloy | undefined;
  shopConnections: ListArrayStatus<IShopConnection>;
  removeShopConnectionStatus: {
    [id: string]: FETCHING_STATUS;
  };
}

const initialState: IShopConnectState = {
  alloy: undefined,
  shopConnections: getDefaultListArrayStatus(),
  removeShopConnectionStatus: {},
};

export const shopSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setAlloy, (state, { payload }) => {
        state.alloy = payload;
      })
      .addCase(getShopConnections.pending, (state) => {
        _.set(state.shopConnections, ['status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getShopConnections.fulfilled, (state, { payload }) => {
        state.shopConnections = setFulfilledListArrayStatus(payload.data);
      })
      .addCase(getShopConnections.rejected, (state) => {
        _.set(state.shopConnections, ['status'], FETCHING_STATUS.DONE);
      })
      .addCase(deleteShopConnection.pending, (state, { meta }) => {
        _.set(state.removeShopConnectionStatus, [meta.arg.connectionId], FETCHING_STATUS.FETCHING);
      })
      .addCase(deleteShopConnection.fulfilled, (state, { meta, payload }) => {
        if (payload.errno === ERROR_CODE.NORMAL) {
          state.shopConnections.list = _.filter(
            state.shopConnections.list,
            (item) => item.connectionId !== meta.arg.connectionId,
          );
        }
        _.set(state.removeShopConnectionStatus, [meta.arg.connectionId], FETCHING_STATUS.DONE);
      })
      .addCase(deleteShopConnection.rejected, (state, { meta }) => {
        _.set(state.removeShopConnectionStatus, [meta.arg.connectionId], FETCHING_STATUS.DONE);
      });
  },
});
export const selectAlloy = (state: AppState): IAlloy | undefined => state.shop.alloy;
export const selectShopConnections =
  (type: SHOP_TYPES | undefined) =>
  (state: AppState): IShopConnection[] =>
    type
      ? _.filter(state.shop.shopConnections.list, (item) => item.app === type)
      : state.shop.shopConnections.list || [];
export const selectShopConnectionRemovingStatus =
  (id: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.shop.removeShopConnectionStatus, [id || ''], FETCHING_STATUS.INIT);
export const selectShopConnectionsStatus = (state: AppState): FETCHING_STATUS => state.shop.shopConnections.status;
export default shopSlice.reducer;
