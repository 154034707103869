import { useEffect } from 'react';

const useResponsiveFontSize = (): void => {
  useEffect(() => {
    let previousWidth = window.innerWidth;

    const setRemUnit = () => {
      const baseSize = 10; // 基础尺寸
      let designWidth = 1440; // 设计稿宽度

      const clientWidth = document.documentElement.clientWidth;
      if (clientWidth < 700) {
        designWidth = 375;
      }
      const rem = (clientWidth / designWidth) * baseSize;
      document.documentElement.style.fontSize = `${rem > 10 ? 10 : rem}px`; // 最大值10px，保持设计图原有尺寸

      // 检查视口宽度变化是否需要刷新页面
      if ((previousWidth < 700 && clientWidth >= 700) || (previousWidth >= 700 && clientWidth < 700)) {
        window.location.reload();
      }
      previousWidth = clientWidth;
    };

    setRemUnit(); // 初始化时设置一次

    window.addEventListener('resize', setRemUnit); // 添加 resize 事件监听器
    return () => {
      window.removeEventListener('resize', setRemUnit); // 组件卸载时移除事件监听器
    };
  }, []);
};

export default useResponsiveFontSize;
