import { FETCHING_STATUS } from '@/constants';
import { useAppSelector } from '../hooks';
import { selectMemberDeletingStatus, selectMemberSavingStatus, selectMembers, selectMembersStatus } from './slice';

import { IMember } from '@/types/team';

export function useTeamMembers(orgId: string | undefined): IMember[] | undefined {
  return useAppSelector(selectMembers(orgId));
}
export function useTeamMembersStatus(orgId: string | undefined): FETCHING_STATUS | undefined {
  return useAppSelector(selectMembersStatus(orgId));
}
export function useMemberSavingStatus(orgId: string | undefined): FETCHING_STATUS | undefined {
  return useAppSelector(selectMemberSavingStatus(orgId));
}
export function useMemberDeletingStatus(email: string | undefined): FETCHING_STATUS | undefined {
  return useAppSelector(selectMemberDeletingStatus(email));
}
