/**
 * @description Component-SignatureCard
 */
import React, { FC, useState } from 'react';
import './index.less';
import { Button, Empty } from 'antd';
import { useOrg, useSignature, useSignatureFetchingStatus } from '@/features/settings/hooks';
import EditSignature from '../Signature/EditSignature';
import { FETCHING_STATUS } from '@/constants';
import { ReactComponent as AddIcon } from '@/assets/svg/icon_add_16.svg';
import { ReactComponent as EditIcon } from '@/assets/svg/icon_edit_linear.svg';
import { ReactComponent as SignatureIcon } from './assets/icon_signature.svg';
import TextDisplay from '@/pages/components/TextDisplay';
interface IPropTypes {
  className?: string;
}

const SignatureCard: FC<IPropTypes> = function () {
  const [isEdit, setIsEdit] = useState(false);
  const org = useOrg();
  const [signature] = useSignature(org?.id);
  const signuatureStatus = useSignatureFetchingStatus(org?.id);
  return (
    <div className="cor-connections-card cor-connections-signature">
      <div className="cor-connections-card-head">
        <div className="cor-connections-card-title">
          <SignatureIcon />
          Signature for the email
        </div>
        <div className="cor-connections-card-des">It will be appended at the end of all outgoing messages.</div>
      </div>
      <div className="cor-connections-card-signature">
        {isEdit ? (
          <EditSignature
            signature={signature}
            onCancel={() => {
              setIsEdit(false);
            }}
          />
        ) : signature && signuatureStatus === FETCHING_STATUS.DONE ? (
          <>
            <TextDisplay content={signature?.signature} />
            <Button
              onClick={() => {
                setIsEdit(true);
              }}
              icon={<EditIcon />}>
              Edit
            </Button>
          </>
        ) : (
          <>
            <Empty description="No signatures found" />
            <Button
              onClick={() => {
                setIsEdit(true);
              }}
              type="primary"
              icon={<AddIcon />}>
              Create New
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default SignatureCard;
