/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * @description Component-EditNewBtn
 */
import React, { FC, useCallback, useState } from 'react';
import './index.less';
import { FETCHING_STATUS } from '@/constants';
import { Modal, Form, Input, Button } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useAppDispatch } from '@/features/hooks';
import { useOrg, useNewKnowledgeSavingStatus, useNewKnowledgeBases } from '@/features/settings/hooks';
import { INewKnowledgeBase } from '@/types/settings';
import { ReactComponent as CloseIcon } from '@/pages/Dashboard/assets/close.svg';
import { ReactComponent as EditIcon } from '@/assets/svg/icon_edit_linear.svg';
import { saveNewKnowledgeChunks, updateNewKnowledgeChunks } from '@/features/settings/actions';

interface IPropTypes {
  title: string;
  type?: 'primary' | 'text' | 'ghost';
  icon: React.ReactNode;
  knowledge?: INewKnowledgeBase;
  initialValues?: Partial<INewKnowledgeBase>;
}
const EditNewBtn: FC<IPropTypes> = function ({ title, icon, type = 'primary', knowledge, initialValues }) {
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const org = useOrg();
  const bases = useNewKnowledgeBases(org?.id);
  const savingStatus = useNewKnowledgeSavingStatus(org?.id);
  const [form] = Form.useForm();

  const onSaveKnowledgeBase = useCallback(
    async (_knowledgeForm: INewKnowledgeBase) => {
      if (org) {
        if (knowledge?.id) {
          await dispatch(
            updateNewKnowledgeChunks({
              org: org.id,
              knowledge: { ...knowledge, ..._knowledgeForm },
            }),
          ).unwrap();
        } else {
          await dispatch(
            saveNewKnowledgeChunks({ org: org.id, knowledgeId: bases?.id, content: _knowledgeForm.content }),
          ).unwrap();
        }
        setModalOpen(false);
      }
    },
    [org, bases, knowledge, dispatch],
  );
  const onClickAdd = useCallback(() => {
    setModalOpen(true);
    form.resetFields();
    form.setFieldsValue({
      ...knowledge,
      ...initialValues,
    });
  }, [form, knowledge, initialValues]);

  return (
    <div className="cor-edit-new-knowledge">
      <Button
        className="cor-edit-new-knowledge-btn"
        type={type}
        title={title}
        size="small"
        icon={icon}
        onClick={onClickAdd}>
        {title}
      </Button>
      <Modal
        footer={false}
        title={title}
        open={modalOpen}
        width={480}
        centered
        className="cor-settings-modal cor-edit-knowledge-new-doc-modal"
        closeIcon={<CloseIcon />}
        onCancel={() => setModalOpen(false)}
        onOk={() => setModalOpen(false)}>
        <Form form={form} onFinish={onSaveKnowledgeBase}>
          <FormItem rules={[{ required: true, message: 'Please enter' }]} label="Content" name="content">
            <Input.TextArea rows={15} />
          </FormItem>

          <Form.Item>
            <Button
              loading={savingStatus !== FETCHING_STATUS.DONE}
              disabled={savingStatus !== FETCHING_STATUS.DONE}
              className="cor-save"
              type="primary"
              htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditNewBtn;
