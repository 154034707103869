/**
 * @description Component-AigcBot
 */
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import './index.less';
import { useOrg } from '@/features/settings/hooks';
import { useBotHistory } from '@/features/aigc/hooks';
import ChatContent from '@/components/AigcBot/Chat/BotChatContent';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as ChevronDown } from '@/assets/svg/chevron_down.svg';
import ChatReplyBox from '@/components/AigcBot/Chat/ChatReplyBox';
import classNames from 'classnames';
import { useAppDispatch } from '@/features/hooks';
import { getBotHistory, setBotMessage, setBotStatus } from '@/features/aigc/actions';
import { Tooltip } from 'antd';
import { ReactComponent as GenerateIcon } from '@/assets/svg/icon_btn_generate.svg';
import { useMediaQueryDevice } from '../MediaQuery';
import { useSocket } from '@/features/socket/hooks';
import { SOCKET_CHANNELS, SOCKET_STATUS } from '@/constants/sockets';
import { IMessage } from '@/types/aigc';
import { AIGC_TYPE } from '@/constants/aigc';
import { inputToMessage } from '@/utils/aigc';
import { FETCHING_STATUS } from '@/constants';
import { toast } from 'react-toastify';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  customerEmail: string | undefined;
  conversationId: string | undefined;
  customerName: string | undefined;
  scene: AIGC_TYPE;
}
const AigcBot: FC<IPropTypes> = function ({ customerEmail, conversationId, customerName, scene }) {
  const [expand, setExpand] = useState(false);
  const org = useOrg();
  const { deviceType, isMobile } = useMediaQueryDevice();
  const userMail = useMemo(
    () => (scene === AIGC_TYPE.LIVECHAT_ASSISTANT ? conversationId || '' : customerEmail),
    [conversationId, customerEmail, scene],
  );
  const dispatch = useAppDispatch();
  const messageList = useBotHistory(org?.id, userMail, scene);
  const socket = useSocket(SOCKET_CHANNELS.CHATBOT);
  const botStyle = useSpring(
    expand
      ? {
          height: isMobile ? 500 : window.innerHeight * 0.8,
          width: isMobile ? 350 : 400,
          padding: 20,
        }
      : {
          height: 0,
          width: 0,
        },
  );

  const onInputMessageChange = useCallback(
    (question: string) => {
      if (customerEmail && org?.id) {
        if (socket) {
          if (!socket.connected) {
            socket.connect();
          }
          socket.emit('message', {
            user: userMail,
            question,
            scene,
          });
        }
        userMail &&
          dispatch(
            setBotMessage({
              orgId: org.id,
              message: inputToMessage({
                user: customerEmail,
                question,
                conversationId,
              }),
              scene,
              userEmail: userMail,
            }),
          );
        userMail && dispatch(setBotStatus({ orgId: org.id, status: FETCHING_STATUS.FETCHING, userEmail: userMail }));
      }
    },
    [conversationId, customerEmail, dispatch, org?.id, scene, socket, userMail],
  );
  const onResponse = useCallback(
    (response: IMessage) => {
      if (response.error) {
        toast.error(response.error);
        org &&
          userMail &&
          dispatch(
            setBotStatus({
              status: FETCHING_STATUS.DONE,
              orgId: org?.id,
              userEmail: userMail,
            }),
          );
        return;
      }
      org &&
        response &&
        userMail &&
        dispatch(
          setBotMessage({
            message: {
              ...response,
              status: response?.status ?? SOCKET_STATUS.FETCHING,
            },
            scene,
            orgId: org.id,
            userEmail: userMail,
          }),
        );
    },
    [dispatch, org, scene, userMail],
  );
  useEffect(() => {
    if (socket) {
      socket.on('response', onResponse);
    }
    return () => {
      socket && socket.off('response', onResponse);
    };
  }, [dispatch, onResponse, socket]);
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (expand) {
        const aigcbot = document.getElementById('agicbot');
        if (aigcbot) {
          aigcbot.style.setProperty('height', `${window.innerHeight * 0.8}px`);
        }
      }
    });
  }, [expand]);
  useEffect(() => {
    if (org && userMail && scene) {
      dispatch(
        getBotHistory({
          userEmail: userMail,
          org: org.id,
          scene,
        }),
      );
    }
  }, [dispatch, org, scene, userMail]);
  return (
    <>
      {customerEmail && (
        <div className="cor-aigc-bot-ai">
          <div className="cor-aigc-bot-ai-text">I am your assistant!</div>
          <GenerateIcon className={classNames('ailog', deviceType)} onClick={() => setExpand(true)} type="text" />
        </div>
      )}
      <animated.div
        style={botStyle}
        id="agicbot"
        className={classNames('cor-aigc-bot', expand ? 'expand' : '', deviceType)}>
        {expand && (
          <>
            <div onClick={() => setExpand(false)} className="cor-aigc-bot-header">
              <div className="cor-aigc-bot-header-left">
                Chat on:{' '}
                <Tooltip title={customerName || customerEmail}>
                  <span className="highlight">{customerName || customerEmail}</span>
                </Tooltip>
              </div>
              <ChevronDown className="cor-aigc-bot-header-right" />
            </div>
            <div className="cor-aigc-bot-list">
              <ChatContent data={messageList} customEmail={userMail} />
            </div>
            <div className="cor-aigc-bot-input">
              <ChatReplyBox customEmail={customerEmail} scene={scene} onInputMessageChange={onInputMessageChange} />
            </div>
          </>
        )}
      </animated.div>
    </>
  );
};

export default memo(AigcBot);
