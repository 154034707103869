import { memo, useEffect } from 'react';
import { useOrg } from '../settings/hooks';
import { useAppDispatch } from '../hooks';
import { getPluginId } from './actions';
function useGetPluginId() {
  const org = useOrg();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (org) {
      dispatch(
        getPluginId({
          orgId: org.id,
        }),
      );
    }
  }, [dispatch, org]);
}

function PluginGlobalEffect(): null {
  useGetPluginId();
  return null;
}

export default memo(PluginGlobalEffect);
