import { IAnalysis } from '@/types/analysis';
import { useAppSelector } from '../hooks';
import { selectAnalysis, selectAnalysisStatus } from './slice';
import { FETCHING_STATUS } from '@/constants';

export function useAnalysis(orgId: string | undefined): IAnalysis | undefined {
  return useAppSelector(selectAnalysis(orgId));
}
export function useAnalysisStatus(orgId: string | undefined): FETCHING_STATUS | undefined {
  return useAppSelector(selectAnalysisStatus(orgId));
}
