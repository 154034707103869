/**
 * @description Component-ChatLoading
 */
import React, { FC } from 'react';
import './index.less';
import Avatar from 'react-avatar';
import stoneIcon from '@/assets/svg/stone.svg';
interface IPropTypes {
  className?: string;
}
const ChatLoading: FC<IPropTypes> = function () {
  return (
    <div className="cor-chat-loading">
      <Avatar size="40" round src={stoneIcon} name={'Stone'} />
      <div className="loading-dots-animation">
        <div className="loading-dot"></div>
        <div className="loading-dot"></div>
        <div className="loading-dot"></div>
      </div>
    </div>
  );
};

export default ChatLoading;
