/**
 * @description Component-SessionListSkeleton
 */
import React, { FC } from 'react';
import './index.less';
import { Skeleton } from 'antd';
interface IPropTypes {
  className?: string;
}
const ListSkeleton: FC<IPropTypes> = function () {
  const array = new Array(10).fill(0);
  return (
    <div className="cor-list-skeleton">
      {array.map((_, index) => (
        <Skeleton key={index} active={true} paragraph={false} />
      ))}
    </div>
  );
};

export default ListSkeleton;
