/**
 * @description Component-MailSettingCard
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import './index.less';
import { Button, Popconfirm, Skeleton, Switch } from 'antd';
import { useAppDispatch } from '@/features/hooks';
import {
  useOrg,
  useSocialSettings,
  useSocialSettingsStatus,
  useSocialDeletingStatus,
  useSocialSavingStatus,
  useAutoReplySettings,
} from '@/features/settings/hooks';
import { FETCHING_STATUS } from '@/constants';
import { deleteOrgSocialSettings, saveOrgSocialSettings, updateAutoReplySetting } from '@/features/settings/actions';
import { ReactComponent as OpenIcon } from '@/assets/svg/icon_open_new.svg';
import IconFacebook from './assets/icon_facebook.svg';
import { ReactComponent as SocialIcon } from './assets/icon_connect_social.svg';
import { FB_SCOPES } from '@/constants/settings';
interface IPropTypes {
  className?: string;
}
const SocialSettingCard: FC<IPropTypes> = function () {
  const dispatch = useAppDispatch();
  const org = useOrg();
  const autoReplySettings = useAutoReplySettings(org?.id);
  const social = useSocialSettings();
  const socialStatus = useSocialSettingsStatus();
  const deleting = useSocialDeletingStatus();
  const saving = useSocialSavingStatus();
  const [switching, setSwitching] = useState(false);

  const SocialCallBack = useCallback(
    async (accessToken: string | undefined, userID: string) => {
      try {
        accessToken &&
          (await dispatch(
            saveOrgSocialSettings({
              accessToken,
              userID,
              org: org?.id,
            }),
          ).unwrap());
      } catch (e) {
        console.log('🚀 ~ file: index.tsx:39 ~ SocialCallBack ~ e:', e);
      }
    },
    [dispatch, org],
  );

  const authorizeSocial = (business?: boolean) => {
    if (!FB) return;
    console.log('🚀 ~ authorizeSocial ~ FB:', process.env.REACT_APP_FB_CONFIG_ID, business);
    FB.login(
      (response) => {
        console.log(response);
        try {
          if (response) {
            if (response.authResponse) {
              const {
                authResponse: { accessToken, code, userID },
              } = response;
              if (accessToken || code) {
                SocialCallBack(accessToken || code, userID);
              }
            }
          } else {
            // The person is not logged into your webpage or we are unable to tell.
            console.log(response);
          }
        } catch (error) {
          console.log(error);
        }
      },
      {
        scope: FB_SCOPES,
        // config_id: business ? process.env.REACT_APP_FB_CONFIG_ID : '',
        // // response_type: business ? 'code' : '',
        // response_type: 'token',
        // override_default_response_type: business,
      },
    );
  };

  const deleteSettings = useCallback(() => {
    try {
      dispatch(deleteOrgSocialSettings({ orgId: org?.id }));
    } catch (error) {
      console.log('🚀 ~ file: index.tsx:71 ~ deleteSettings ~ e:', error);
    }
  }, [dispatch, org]);

  const switchAutoReply = useCallback(
    async (enable: boolean) => {
      try {
        if (org) {
          setSwitching(true);
          await dispatch(
            updateAutoReplySetting({
              orgId: org?.id,
              settings: {
                ...autoReplySettings,
                facebook: {
                  enable,
                },
              },
            }),
          );
          setSwitching(false);
        }
      } catch (e) {
        setSwitching(false);
      }
    },
    [autoReplySettings, dispatch, org],
  );
  // TODO
  useEffect(() => {
    window.fbAsyncInit = () => {
      FB.init({
        appId: process.env.REACT_APP_FB_ID,
        cookie: true,
        xfbml: true,
        version: 'v18.0',
      });
      FB.AppEvents.logPageView();
    };
    // Add the Facebook SDK for Javascript -
    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }

      const js = d.createElement(s);
      js.id = id;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, [useCallback]);

  return (
    <div className="cor-connections-card">
      <div className="cor-connections-card-head">
        <div className="cor-connections-card-title">
          <SocialIcon />
          Social
        </div>
      </div>
      <ul className="cor-connections-card-items">
        {socialStatus === FETCHING_STATUS.DONE ? (
          social?.userId ? (
            <li className="cor-connections-card-item">
              <div className="cor-connections-card-item-left">
                <div className="cor-connections-card-item-left-desc">
                  <img width={32} height={32} src={IconFacebook} />
                  {social?.metadata?.name}
                </div>
              </div>
              <div className="cor-connections-card-item-right">
                <div>
                  <Switch
                    loading={switching}
                    onChange={switchAutoReply}
                    checked={autoReplySettings?.facebook?.enable}
                  />{' '}
                  Auto Reply Comments
                </div>
                <div className="cor-connections-card-item-right-divider" />
                <Popconfirm
                  onConfirm={deleteSettings}
                  okText="Yes"
                  cancelText="No"
                  title="Are you sure to delete this facebook account?">
                  <Button
                    size="small"
                    className="cor-connections-card-delete-btn"
                    disabled={deleting === FETCHING_STATUS.FETCHING}
                    loading={deleting === FETCHING_STATUS.FETCHING}
                    type="text">
                    Disconnect
                  </Button>
                </Popconfirm>
              </div>
            </li>
          ) : (
            <li className="cor-connections-card-item">
              <div className="cor-connections-card-item-left">
                <img src={IconFacebook} height={32} width={32} loading="lazy" />
                Facebook
              </div>
              <div className="cor-connections-card-item-right">
                <div>
                  <Switch
                    loading={switching}
                    onChange={switchAutoReply}
                    checked={autoReplySettings?.facebook?.enable}
                  />{' '}
                  Auto Reply
                </div>
                {/* <Popconfirm
                  onConfirm={() => authorizeSocial(true)}
                  okText="Accept"
                  cancelText="Cancel"
                  title="Your anonymized data may be shared with our third-part Large Language Learning Model partners."> */}
                <Button
                  size="small"
                  onClick={() => authorizeSocial(true)}
                  disabled={saving === FETCHING_STATUS.FETCHING}
                  loading={saving === FETCHING_STATUS.FETCHING}>
                  Connect Business Account <OpenIcon />
                </Button>
                {/* </Popconfirm> */}
                {/* <Popconfirm
                  onConfirm={() => authorizeSocial()}
                  okText="Accept"
                  cancelText="Cancel"
                  title="Your anonymized data may be shared with our third-part Large Language Learning Model partners.">
                  <Button
                    size="small"
                    disabled={saving === FETCHING_STATUS.FETCHING}
                    loading={saving === FETCHING_STATUS.FETCHING}>
                    Connect Personal Account <OpenIcon />
                  </Button>
                </Popconfirm> */}
              </div>
            </li>
          )
        ) : (
          <Skeleton active title={false} />
        )}
      </ul>
    </div>
  );
};

export default SocialSettingCard;
