import React, { useEffect, useState } from 'react';
import './index.less';
import { Outlet } from 'react-router-dom';
import SiderLeft from './SiderLeft';
import AigcBotGlobalEffect from '@/features/aigc/globalEffects';
import ProductModal from '../components/ProductModal';
import { useUserInfo, useUserInfoStatus } from '@/features/user/hooks';
// import { FETCHING_STATUS } from '@/constants';
// import { USER_SUBSCRIBE_STATUS } from '@/constants/user';
import { useWatchRouting } from '@/features/settings/GlobalEffect';
import { Mobile, useMediaQueryDevice } from '@/components/MediaQuery';
// import Questionnaire from '../components/Questionnaire';
import { useOrg } from '@/features/settings/hooks';
import TabBar from '../components/tabbarMoblie';
import Header from '../Landing/Header';
export default function Dashboard(): JSX.Element {
  const [showProductModal, setShowProductModal] = useState(false);
  const userInfo = useUserInfo();
  const org = useOrg();
  const userInfoStatus = useUserInfoStatus();
  const { deviceType } = useMediaQueryDevice();
  useWatchRouting();
  useEffect(() => {
    // userInfoStatus === FETCHING_STATUS.DONE && setShowProductModal(userInfo?.status !== USER_SUBSCRIBE_STATUS.PAID);
  }, [userInfo, userInfoStatus, org]);
  return (
    <div className="dashboard">
      {deviceType !== 'mobile' ? <SiderLeft /> : <></>}
      <div className={`dashboard-content ${deviceType}`}>
        <Mobile>
          <Header />
        </Mobile>
        <Outlet />
        <Mobile>
          <TabBar />
        </Mobile>
      </div>
      {deviceType !== 'mobile' ? (
        <>
          <AigcBotGlobalEffect />
        </>
      ) : (
        <></>
      )}
      <ProductModal open={showProductModal} onCancel={() => setShowProductModal(false)} />
      {/* <Questionnaire /> */}
    </div>
  );
}
