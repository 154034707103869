import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getBotHistory, setBotMessage, setBotStatus } from './actions';
import { AppState } from '@/features/store';
import { ListArrayStatus, setFulfilledListArrayStatus } from '@/types/redux';
import { IMessage, IMessageRole } from '@/types/aigc';
import { FETCHING_STATUS } from '@/constants';
import { AIGC_TYPE } from '@/constants/aigc';
export interface IAigcState {
  orgMessageList: {
    [org: string]: { [userEmail: string]: { [scene: string]: ListArrayStatus<IMessage> } };
  };
  orgChatBotStatus: {
    [org: string]: { [userEmail: string]: FETCHING_STATUS };
  };
  orgCurrentConversationId: {
    [org: string]: string;
  };
}

const initialState: IAigcState = {
  orgMessageList: {},
  orgChatBotStatus: {},
  orgCurrentConversationId: {},
};

export const aigcSlice = createSlice({
  name: 'agic',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBotHistory.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          _.set(
            state.orgMessageList,
            [meta.arg.org, meta.arg.userEmail, meta.arg.scene],
            setFulfilledListArrayStatus(payload.data),
          );
        }
      })
      .addCase(setBotStatus, (state, { payload }) => {
        _.set(state.orgChatBotStatus, [payload.orgId, payload.userEmail], payload.status);
      })
      .addCase(setBotMessage, (state, { payload }) => {
        const currentList = _.get(
          state.orgMessageList,
          [payload.orgId || '', payload.userEmail, payload.scene, 'list'],
          [],
        );
        const currentMessage = _.last(currentList);
        _.set(state.orgChatBotStatus, [payload.orgId, payload.userEmail], FETCHING_STATUS.DONE);
        if (payload.message.role === IMessageRole.USER) {
          _.set(
            state.orgMessageList,
            [payload.orgId, payload.userEmail, payload.scene, 'list'],
            [...currentList, payload.message],
          );
        } else {
          if (currentMessage?.id === payload.message.id) {
            _.set(
              state.orgMessageList,
              [payload.orgId, payload.userEmail, payload.scene, 'list'],
              [
                ..._.initial(currentList),
                { ...payload.message, content: currentMessage?.content + payload.message?.content },
              ],
            );
          } else {
            _.set(
              state.orgMessageList,
              [payload.orgId, payload.userEmail, payload.scene, 'list'],
              [...currentList, payload.message],
            );
          }
        }
      });
  },
});
export const selectLastMessage =
  (org: string | undefined, userMail: string | undefined, scene: AIGC_TYPE) =>
  (state: AppState): IMessage | undefined =>
    _.last(_.get(state.aigc.orgMessageList, [org || '', userMail || '', scene, 'list'], []));
export const selectBotHistory =
  (org: string | undefined, userMail: string | undefined, scene: AIGC_TYPE) =>
  (state: AppState): IMessage[] =>
    _.get(state.aigc.orgMessageList, [org || '', userMail || '', scene, 'list'], []);
export const selectBotStatus =
  (org: string | undefined, userMail: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.aigc.orgChatBotStatus, [org || '', userMail || ''], FETCHING_STATUS.INIT);
export const selectCurrentConversationId =
  (org: string | undefined) =>
  (state: AppState): string =>
    _.get(state.aigc.orgCurrentConversationId, [org || '']);

export default aigcSlice.reducer;
