import { AUTH_ACCESS_TOKEN, ERROR_CODE } from '@/constants';
import { AppState } from '@auth0/auth0-react';
import { Middleware, ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { setCurrentToken } from './auth/actions';

export const JWTExpiredMiddleware: Middleware<
  Record<string, unknown>,
  unknown,
  ThunkDispatch<unknown, unknown, AnyAction>
> = (store) => (next) => async (action) => {
  let result = next(action);

  if (result?.payload?.errno === ERROR_CODE.UNAUTHED) {
    const {
      auth: { authState },
    } = store.getState() as AppState;
    try {
      if (authState) {
        await authState.getAccessTokenSilently();
        const token = await authState.getIdTokenClaims();
        if (token) {
          localStorage.setItem(AUTH_ACCESS_TOKEN, token.__raw);
          store.dispatch(setCurrentToken(token.__raw));
        }
        const typeStrs = action.type.split('/');
        store.dispatch({ meta: action.meta, type: typeStrs[0] + '/' + typeStrs[1] });
        result = { ...result, payload: undefined };
      }
    } catch (e) {
      console.log('🚀 ~ file: middlewares.ts:27 ~ >= ~ e:', e);
    }
  }
  return result;
};
