import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { DASHBOARD_MENU_ITEMS } from '@/constants';

export function useGetDashboardMenuItem(): DASHBOARD_MENU_ITEMS {
  const location = useLocation();
  return useMemo(() => _.get(location.pathname.split('/'), [2]) as DASHBOARD_MENU_ITEMS, [location]);
}
export function useQueryParam(key: string): string | null {
  const location = useLocation();
  return useMemo(() => {
    const params = new URLSearchParams(location.search);
    if (params) {
      return params.get(key);
    }
    return null;
  }, [key, location.search]);
}
