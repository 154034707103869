import { useAppSelector } from '../hooks';
import {
  selectCurrentUserId,
  selectEmailListHasMore,
  selectEmailListStatus,
  selectOrgInboxSearchStr,
  selectOrgInboxSourceType,
  selectOrgInboxUserText,
  selectSendingMail,
  selectUserById,
  selectUserList,
  selectUserListStatus,
  selectUserMailList,
  selectGettingAigcResponse,
  selectAigcResponse,
  selectEmailRenderStatus,
  selectUserSentEmail,
  selectInboxDrawerOpen,
  selectInboxReplying,
  selectMetaPosts,
  selectMetaPostsComments,
  selectMetaPostsStatus,
  selectMetaPostsCommentsStatus,
  selectOrgInboxPostSearchStr,
  selectCurrentPostId,
  selectOrgInboxCommentSearchStr,
  selectMetaPostById,
  selectCurrentPostComment,
  selectMetaPostCommentReplies,
  selectMetaPostCommentRepliesStatus,
  selectReplyingComment,
  selectAigcReplyResponse,
  selectOrgInboxMetaCurrentText,
  selectGettingAigcReplyResponse,
  selectAigcCommentReply,
  selectReplyingComments,
  selectPostCommentsSelected,
} from './slice';
import { useMemo } from 'react';
import _ from 'lodash';
import { IInboxUser, IMetaComment, IMetaPost } from '@/types/inbox';
import { INBOX_SOURCES_KEY } from '@/constants/inbox';
import { FETCHING_STATUS } from '@/constants';
import { IMail } from '@/types/email';
import { isNA } from '@/utils/aigc';

export function useUserList(orgId: string | undefined): IInboxUser[] | undefined {
  const list = useAppSelector(selectUserList(orgId));
  return useMemo(() => {
    const users = _.values(list);
    const sortedUsersDesc = _.sortBy(users, (user) => -new Date(user?.date || ''));
    return sortedUsersDesc;
  }, [list]);
}
export function useUserMailList(orgId: string | undefined, user: string): IMail[] | undefined {
  const list = useAppSelector(selectUserMailList(orgId, user));
  return useMemo(() => list, [list]);
}
export function useCurrentUserId(orgId: string | undefined): string {
  const id = useAppSelector(selectCurrentUserId(orgId));
  return useMemo(() => id, [id]);
}
export function useCurrentUserText(orgId: string | undefined, user: string | undefined): string {
  const id = useAppSelector(selectOrgInboxUserText(orgId, user));
  return useMemo(() => id, [id]);
}
export function useUserById(orgId: string | undefined, id: string): IInboxUser {
  const user = useAppSelector(selectUserById(orgId, id));
  return useMemo(() => user, [user]);
}
export function useCurrentCustomer(orgId: string | undefined): IInboxUser {
  const currentUserId = useCurrentUserId(orgId);
  const user = useUserById(orgId, currentUserId);
  return useMemo(() => user, [user]);
}

export function useUserListStatus(orgId: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectUserListStatus(orgId));
  return useMemo(() => status, [status]);
}
export function useInboxSourceType(orgId: string | undefined): INBOX_SOURCES_KEY {
  const source = useAppSelector(selectOrgInboxSourceType(orgId));
  return useMemo(() => source, [source]);
}
export function useInboxSearchStr(orgId: string | undefined): string {
  const searchStr = useAppSelector(selectOrgInboxSearchStr(orgId));
  return useMemo(() => searchStr, [searchStr]);
}
export function useMailListStatus(orgId: string | undefined, id: string): FETCHING_STATUS | undefined {
  const status = useAppSelector(selectEmailListStatus(orgId, id));
  return useMemo(() => status, [status]);
}
export function useMailListHasMore(orgId: string | undefined, id: string): boolean | undefined {
  const hasMore = useAppSelector(selectEmailListHasMore(orgId, id));
  return useMemo(() => hasMore, [hasMore]);
}
export function useSendingMail(address: string | undefined): boolean {
  const sending = useAppSelector(selectSendingMail(address));
  return useMemo(() => sending, [sending]);
}
export function useGettingAigcResponse(): boolean {
  const getting = useAppSelector(selectGettingAigcResponse);
  return useMemo(() => getting, [getting]);
}
export function useAigcResponse(mailId: string | undefined): string {
  const text = useAppSelector(selectAigcResponse(mailId));
  return useMemo(() => text, [text]);
}

export function useEmailRenderStatus(): { [messageId: string]: string } {
  const status = useAppSelector(selectEmailRenderStatus);
  return useMemo(() => status, [status]);
}

export function useUserSentMail(orgId: string | undefined, id: string): number {
  const sentMail = useAppSelector(selectUserSentEmail(orgId, id));
  return useMemo(() => sentMail, [sentMail]);
}
export function useInboxDrawerOpen(): boolean {
  const drawerOpen = useAppSelector(selectInboxDrawerOpen);
  return useMemo(() => drawerOpen, [drawerOpen]);
}
export function useInboxReplying(): boolean {
  const replying = useAppSelector(selectInboxReplying);
  return useMemo(() => replying, [replying]);
}

export function useMetaPosts(orgId: string | undefined): IMetaPost[] | undefined {
  const list = useAppSelector(selectMetaPosts(orgId));
  return useMemo(() => {
    const posts = _.values(list);
    // const sortedUsersDesc = _.orderBy(posts, ['updatedAt'], ['desc']);
    return posts;
  }, [list]);
}
export function useMetaPostById(orgId: string | undefined, id: string): IMetaPost | undefined {
  const post = useAppSelector(selectMetaPostById(orgId, id));
  return useMemo(() => post, [post]);
}

export function useMetaPostsStatus(orgId: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectMetaPostsStatus(orgId));
  return useMemo(() => status, [status]);
}

export function useMetaPostComments(orgId: string | undefined, postId: string): IMetaComment[] | undefined {
  const list = useAppSelector(selectMetaPostsComments(orgId, postId));
  return useMemo(() => {
    const comments = _.values(list);
    const sortedUsersDesc = _.orderBy(comments, ['createdTime'], ['desc']);
    return sortedUsersDesc;
  }, [list]);
}
export function useMetaPostsCommentsStatus(orgId: string | undefined, postId: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectMetaPostsCommentsStatus(orgId, postId));
  return useMemo(() => status, [status]);
}

export function useInboxPostSearchStr(orgId: string | undefined): string {
  const searchStr = useAppSelector(selectOrgInboxPostSearchStr(orgId));
  return useMemo(() => searchStr, [searchStr]);
}

export function useCurrentPostId(orgId: string | undefined): string {
  const id = useAppSelector(selectCurrentPostId(orgId));
  return useMemo(() => id, [id]);
}

export function useInboxCommentSearchStr(orgId: string | undefined): string {
  const searchStr = useAppSelector(selectOrgInboxCommentSearchStr(orgId));
  return useMemo(() => searchStr, [searchStr]);
}

export function usePostById(orgId: string | undefined, id: string): IMetaPost | undefined {
  const post = useAppSelector(selectMetaPostById(orgId, id));
  return useMemo(() => post, [post]);
}

export function useCurrentPostComment(orgId: string | undefined): IMetaComment {
  const comment = useAppSelector(selectCurrentPostComment(orgId));
  return useMemo(() => comment, [comment]);
}

export function useMetaPostCommentReplies(orgId?: string, commentId?: string): IMetaComment[] | undefined {
  const list = useAppSelector(selectMetaPostCommentReplies(orgId, commentId));
  return useMemo(() => {
    const comments = _.values(list);
    const sortedUsersDesc = _.orderBy(comments, ['createdTime'], ['asc']);
    return sortedUsersDesc;
  }, [list]);
}
export function useMetaPostsUserCommentsStatus(orgId?: string, commentId?: string): FETCHING_STATUS {
  const status = useAppSelector(selectMetaPostCommentRepliesStatus(orgId, commentId));
  return useMemo(() => status, [status]);
}

export function useReplyingComment(id: string | undefined): boolean {
  const status = useAppSelector(selectReplyingComment(id));
  return useMemo(() => status, [status]);
}
export function useReplyingComments(): boolean {
  const status = useAppSelector(selectReplyingComments);
  return useMemo(() => status, [status]);
}

export function useAigcReplyResponse(commentId?: string): string {
  const text = useAppSelector(selectAigcReplyResponse(commentId));
  return useMemo(() => text, [text]);
}
export function useAigcCommentReply(commentId?: string): string {
  const text = useAppSelector(selectAigcCommentReply(commentId));
  return useMemo(() => (isNA(text) ? '' : text), [text]);
}
export function usePostCommentsSelected(postId?: string): string[] {
  const ids = useAppSelector(selectPostCommentsSelected(postId));
  return useMemo(() => ids, [ids]);
}

export function useCurrentMetaText(orgId: string | undefined, commentId: string | undefined): string {
  const text = useAppSelector(selectOrgInboxMetaCurrentText(orgId, commentId));
  return useMemo(() => text, [text]);
}

export function useGettingAigcReplyResponse(): boolean {
  const getting = useAppSelector(selectGettingAigcReplyResponse);
  return useMemo(() => getting, [getting]);
}
