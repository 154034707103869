/**
 * @description Component-Testimony
 */
import React, { FC, useState } from 'react';
import sw1_1 from './assets/sw1_1.png';
import sw1_2 from './assets/sw1_2.png';
import sw2_1 from './assets/sw2_1.png';
import sw2_2 from './assets/sw2_2.png';
import sw3_1 from './assets/sw3_1.png';
import sw3_2 from './assets/sw3_2.png';
import sw4_1 from './assets/sw4_1.png';
import sw4_2 from './assets/sw4_2.png';
import './index.less';

interface IPropTypes {
  className?: string;
}
const Testimony: FC<IPropTypes> = function () {
  const [current, setCurrent] = useState(1);
  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };
  return (
    <div className={`cor-testimony`}>
      <div className="cor-testimony-title">
        Customer-First Experiences <b> Every Step of the Way</b>
      </div>
      <div className="cor-testimony-subtitle">
        We help brands guide their customers from first click to a lasting relationship.
      </div>
      <div className="cor-testimony-container">
        <div className="cor-testimony-container-left">
          <div
            className={`cor-testimony-container-left-1 ${current == 1 ? 'active1' : ''}`}
            onClick={() => setCurrent(1)}>
            <div className="cor-testimony-container-left-1-yuan"></div>
            <div className="cor-testimony-container-left-1-btn acbtn">
              Awareness
              <div className="acbtn-right">
                <div></div>
              </div>
            </div>
            {current == 1 && (
              <div className="cor-testimony-container-left-1-text">
                A shopper engages with your social posts, then comments with a question.
              </div>
            )}
          </div>
          <div
            className={`cor-testimony-container-left-2 ${current == 2 ? 'active2' : ''}`}
            onClick={() => setCurrent(2)}>
            <div className="cor-testimony-container-left-2-yuan"></div>
            <div className="cor-testimony-container-left-2-btn acbtn">
              Consideration
              <div className="acbtn-right">
                <div></div>
              </div>
            </div>
            {current == 2 && (
              <div className="cor-testimony-container-left-2-text">
                A shopper visits your website and browses product reviews and specifications.
              </div>
            )}
          </div>
          <div
            className={`cor-testimony-container-left-3 ${current == 3 ? 'active3' : ''}`}
            onClick={() => setCurrent(3)}>
            <div className="cor-testimony-container-left-3-yuan"></div>
            <div className="cor-testimony-container-left-3-btn acbtn">
              Conversion
              <div className="acbtn-right">
                <div></div>
              </div>
            </div>
            {current == 3 && (
              <div className="cor-testimony-container-left-3-text">
                A customer adds items to their cart but hesitates to check out.
              </div>
            )}
          </div>
          <div
            className={`cor-testimony-container-left-4 ${current == 4 ? 'active4' : ''}`}
            onClick={() => setCurrent(4)}>
            <div className="cor-testimony-container-left-4-yuan"></div>
            <div className="cor-testimony-container-left-4-btn acbtn">
              Loyalty
              <div className="acbtn-right">
                <div></div>
              </div>
            </div>
            {current == 4 && (
              <div className="cor-testimony-container-left-4-text">Lapsed customer opens your marketing email</div>
            )}
          </div>
        </div>
        <div className="cor-testimony-container-center">
          {current == 1 && <img className="cor-testimony-container-center-img1 wow " src={sw1_1} />}
          {current == 2 && <img className="cor-testimony-container-center-img2 wow " src={sw2_1} />}
          {current == 3 && <img className="cor-testimony-container-center-img3 wow " src={sw3_1} />}
          {current == 4 && <img className="cor-testimony-container-center-img4 wow " src={sw4_1} />}
        </div>
        {current == 1 && (
          <div className="cor-testimony-container-right wow " style={{ backgroundColor: '#EFFEF9' }}>
            <div className="cor-testimony-container-right-title">
              Engage Customers Effectively with <b>Social Insights</b>
            </div>
            <img className="cor-testimony-container-right-img" src={sw1_2} />
          </div>
        )}
        {current == 2 && (
          <div className="cor-testimony-container-right wow " style={{ backgroundColor: '#FFFEEC' }}>
            <div className="cor-testimony-container-right-title">
              <b>Leverage Customer’s Chat</b> Data to Recommend Perfect Product{' '}
            </div>
            <img className="cor-testimony-container-right-img" src={sw2_2} />
          </div>
        )}
        {current == 3 && (
          <div className="cor-testimony-container-right wow " style={{ backgroundColor: '#FFF3FA' }}>
            <div className="cor-testimony-container-right-title">
              Convert with <b>Targeted Offers</b> at the Perfect Time
            </div>
            <img className="cor-testimony-container-right-img" src={sw3_2} />
          </div>
        )}
        {current == 4 && (
          <div className="cor-testimony-container-right wow " style={{ backgroundColor: '#F1F3F9' }}>
            <div className="cor-testimony-container-right-title">
              Re-Engage Customers with
              <br /> <b>Hyper-Personalized Emails</b>
            </div>
            <img className="cor-testimony-container-right-img" src={sw4_2} />
          </div>
        )}
      </div>
      <div className="cor-testimony-btn" onClick={handleScrollToBottom}>
        Request a Demo
      </div>
    </div>
  );
};

export default Testimony;
