/**
 * @description Component-Products
 */
import React, { FC } from 'react';
import SlickSlider from 'react-slick';

import { ReactComponent as TitleAdornIcon } from '@/assets/svg/icon_title_adorn.svg';
import { ReactComponent as TitleAdornFlipIcon } from '@/assets/svg/icon_title_adorn_flip.svg';

import { useMediaQueryDevice, Default, Mobile } from '@/components/MediaQuery';

import './../../../../node_modules/slick-carousel/slick/slick.css';
import './../../../../node_modules/slick-carousel/slick/slick-theme.css';

import './index.less';

import ProductSubscribe from './ProductSubscribe';
import { ReactComponent as IconChecked } from './assets/icon_checked.svg';
import { ReactComponent as StarterIcon } from './assets/icon_subscribe_1.svg';
import { ReactComponent as ProIcon } from './assets/icon_subscribe_2.svg';
import { ReactComponent as EnterpriseIcon } from './assets/icon_subscribe_3.svg';
import { Trans } from 'react-i18next';
interface IPropTypes {
  className?: string;
}
const Products: FC<IPropTypes> = function () {
  const { deviceType } = useMediaQueryDevice();
  const settings = {
    dots: true,
    arrows: false,
    centerMode: true,
    infinite: true,
    centerPadding: '0px',
    slidesToShow: 1,
    speed: 500,
    // autoplay: true,
  };

  return (
    <div className={`cor-products ${deviceType}`} id="products">
      <Default>
        <div className="cor-products-inner">
          <div className="cor-products-title">
            <TitleAdornIcon />
            <Trans i18nKey="landing.products.title" components={{ span: <span /> }} />
            <TitleAdornFlipIcon />
          </div>
          <div className="cor-products-list">
            <div className="cor-products-item">
              <div className="cor-products-item-title">
                <StarterIcon />
                <Trans i18nKey="landing.products.product1.name" />
              </div>
              <div className="cor-products-item-price">
                <span className="highlight">$49</span> / <Trans i18nKey="landing.products.month" />
              </div>
              <ProductSubscribe priceId="price_1OC3SwLiW1ucxYofczmyZAG9" />
              <div className="cor-products-item-desc">
                <dl>
                  <IconChecked />
                  <Trans i18nKey="landing.products.product1.desc1" />
                </dl>
                <dl>
                  <IconChecked />
                  <Trans i18nKey="landing.products.product1.desc2" />
                </dl>
                <dl>
                  <IconChecked />
                  <Trans i18nKey="landing.products.product1.desc3" />
                </dl>
                <dl>
                  <IconChecked />
                  <Trans i18nKey="landing.products.product1.desc4" />
                </dl>
              </div>
            </div>
            <div className="cor-products-item">
              <div className="cor-products-item-title">
                <ProIcon />
                <Trans i18nKey="landing.products.product2.name" />
              </div>
              <div className="cor-products-item-price">
                <span className="highlight">$149</span>/<Trans i18nKey="landing.products.month" />
              </div>
              <ProductSubscribe priceId="price_1OC3TlLiW1ucxYofafunhyka" />
              <div className="cor-products-item-desc">
                <dl>
                  <IconChecked />
                  <Trans i18nKey="landing.products.product2.desc1" />
                </dl>
                <dl>
                  <IconChecked />
                  <Trans i18nKey="landing.products.product2.desc2" />
                </dl>
                <dl>
                  <IconChecked />
                  <Trans i18nKey="landing.products.product2.desc3" />
                </dl>
                <dl>
                  <IconChecked />
                  <Trans i18nKey="landing.products.product2.desc4" />
                </dl>
                <dl>
                  <IconChecked />
                  <Trans i18nKey="landing.products.product2.desc5" />
                </dl>
              </div>
            </div>
            <div className="cor-products-item">
              <div className="cor-products-item-title">
                <EnterpriseIcon />
                <Trans i18nKey="landing.products.product3.name" />
              </div>
              <div className="cor-products-item-price custom">
                <Trans i18nKey="landing.products.product3.customP" />
              </div>
              <a className="contact-us" href="mailto:fancornerstone@gmail.com">
                <Trans i18nKey="landing.products.product3.contactUs" />
              </a>
              <div className="cor-products-item-desc">
                <dl>
                  <IconChecked />
                  <Trans i18nKey="landing.products.product3.desc1" />
                </dl>
                <dl>
                  <IconChecked />
                  <Trans i18nKey="landing.products.product3.desc2" />
                </dl>
                <dl>
                  <IconChecked />
                  <Trans i18nKey="landing.products.product3.desc3" />
                </dl>
                <dl>
                  <IconChecked />
                  <Trans i18nKey="landing.products.product3.desc4" />
                </dl>
              </div>
            </div>
          </div>
        </div>
      </Default>
      <Mobile>
        <>
          <div className="cor-products-title">
            <TitleAdornIcon />
            <span className="highlight">PRICING</span>PACKAGES
            <TitleAdornFlipIcon />
          </div>
          <SlickSlider {...settings} className="cor-products-list">
            <div className="cor-products-item cor-products-item_0">
              <div className="cor-products-item-title">
                <StarterIcon />
                Starter
              </div>
              <div className="cor-products-item-price">
                <span className="highlight">$49</span> /month
              </div>
              <ProductSubscribe priceId="price_1OC3SwLiW1ucxYofczmyZAG9" />
              <div className="cor-products-item-desc">
                <dl>
                  <IconChecked />
                  Ideal for start up
                </dl>
                <dl>
                  <IconChecked />
                  Basic data integration
                </dl>
                <dl>
                  <IconChecked />
                  AI driven data integration
                </dl>
                <dl>
                  <IconChecked />
                  500 credits
                </dl>
              </div>
            </div>
            <div className="cor-products-item cor-products-item_1">
              <div className="cor-products-item-title">
                <ProIcon />
                Pro
              </div>
              <div className="cor-products-item-price">
                <span className="highlight">$149</span>/month
              </div>
              <ProductSubscribe priceId="price_1OC3TlLiW1ucxYofafunhyka" />
              <div className="cor-products-item-desc">
                <dl>
                  <IconChecked />
                  Advanced data integration
                </dl>
                <dl>
                  <IconChecked />
                  Full access to AI
                </dl>
                <dl>
                  <IconChecked />
                  Communication
                </dl>
                <dl>
                  <IconChecked />
                  Basic brand analysis
                </dl>
                <dl>
                  <IconChecked />
                  2000 credits
                </dl>
              </div>
            </div>
            <div className="cor-products-item cor-products-item_2">
              <div className="cor-products-item-title">
                <EnterpriseIcon />
                Enterprise
              </div>
              <div className="cor-products-item-price custom">Custom Pricing</div>
              <a className="contact-us" href="mailto:fancornerstone@gmail.com">
                Contact us
              </a>
              <div className="cor-products-item-desc">
                <dl>
                  <IconChecked />
                  All pro features
                </dl>
                <dl>
                  <IconChecked />
                  Advanced brand analysis
                </dl>
                <dl>
                  <IconChecked />
                  Priority support
                </dl>
                <dl>
                  <IconChecked />
                  Custom integration
                </dl>
              </div>
            </div>
          </SlickSlider>
          {/* <div >



          </div> */}
        </>
      </Mobile>
    </div>
  );
};

export default Products;
