/**
 * @description Component-Limited use disclosure modal
 */
import React, { FC, useEffect } from 'react';

import Header from '../Landing/Header';

import './index.less';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import Footer from '../Landing/Footer';

interface IPropTypes {
  className?: string;
}

const LimitedUseDisclosure: FC<IPropTypes> = function () {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  const { deviceType } = useMediaQueryDevice();
  return (
    <div className={`cor-limited ${deviceType}`}>
      <div className="cor-limited-top">
        <div className="cor-limited-top-inner">
          <Header isDisclosure={true} />
        </div>
      </div>
      <div className="cor-limited-bottom">
        <p>
          Cornerstone uses Google’s Application Programming Interface (API) Services to enable the user authorization
          feature for accessing the Cornerstone platform. (i.e. use of your Gmail account to login to Cornerstone
          platform)
        </p>
        <p>
          Cornerstone’s use and transfer to any other app of information received from Google APIs will adhere to the
          &nbsp;
          <a
            rel="noreferrer"
            target="_blank"
            href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
            Google API Service User Data Policy
          </a>
          , including the Limited Use Requirements.
        </p>
        <p>
          Your Google information is used by our system developers to provide or improve user-facing features that are
          prominent to your user experience. Cornerstone policies and procedures define requirements that prohibit the
          unauthorized use of data within the platform.
        </p>
        <p>
          We place significant importance on maintaining the security and privacy of your data. Reference our Privacy
          Policy for information on our privacy policies regarding the use of the Cornerstone platform and its services.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default LimitedUseDisclosure;
