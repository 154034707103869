import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getDefaultListStatus, ListStatus, setFulfilledListStatus } from '@/types/redux';

import { IMail, MAIL_FLAGS } from '@/types/email';
import { ERROR_CODE, FETCHING_STATUS } from '@/constants';
import {
  archiveMail,
  getAigcResponse,
  getMail,
  getMails,
  sendMail,
  setChatText,
  setCurrentFlag,
  setCurrentMailId,
  setMails,
} from './actions';
import { AppState } from '@/features/store';
import { toast } from 'react-toastify';
export interface IMailState {
  emailList: ListStatus<IMail>;
  currentMailId: string | undefined;
  currentChatText: { [mailId: string]: string | undefined };
  currentChatTextStatus: { [mailId: string]: FETCHING_STATUS };
  currentMailStatus: { [mailId: string]: FETCHING_STATUS };
  sendingMail: boolean;
  currentFlag: MAIL_FLAGS | string;
}

const initialState: IMailState = {
  emailList: getDefaultListStatus(),
  currentMailId: undefined,
  currentMailStatus: {},
  currentChatText: {},
  currentChatTextStatus: {},
  sendingMail: false,
  currentFlag: '',
};

export const emailSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMail.pending, (state, { meta }) => {
        const { mailId } = meta.arg;
        _.set(state.currentMailStatus, [mailId], FETCHING_STATUS.FETCHING);
      })
      .addCase(getMail.fulfilled, (state, { payload, meta }) => {
        const { mailId } = meta.arg;
        if (payload && payload.data) {
          _.set(state.emailList.list, [payload.data.id || ''], payload.data);
        }
        _.set(state.currentMailStatus, [mailId], FETCHING_STATUS.DONE);
      })
      .addCase(getMail.rejected, (state, { meta }) => {
        const { mailId } = meta.arg;
        _.set(state.currentMailStatus, [mailId], FETCHING_STATUS.DONE);
      })
      .addCase(getMails.pending, (state, {}) => {
        state.emailList.status = FETCHING_STATUS.FETCHING;
      })
      .addCase(getMails.fulfilled, (state, { payload }) => {
        if (payload) {
          const newList = _.merge(state.emailList.list, payload);
          state.emailList = setFulfilledListStatus(newList);
        }
      })
      .addCase(getMails.rejected, (state, {}) => {
        state.emailList.status = FETCHING_STATUS.DONE;
      })
      .addCase(getAigcResponse.pending, (state, { meta }) => {
        const { mailId } = meta.arg;
        _.set(state.currentChatTextStatus, [mailId], FETCHING_STATUS.FETCHING);
      })
      .addCase(getAigcResponse.fulfilled, (state, { meta, payload }) => {
        const { mailId } = meta.arg;
        _.set(state.currentChatText, [mailId], payload);
        _.set(state.currentChatTextStatus, [mailId], FETCHING_STATUS.DONE);
      })
      .addCase(getAigcResponse.rejected, (state, { meta }) => {
        const { mailId } = meta.arg;
        _.set(state.currentChatTextStatus, [mailId], FETCHING_STATUS.DONE);
      })

      .addCase(archiveMail.fulfilled, (state, { meta }) => {
        if (meta.arg.mailId) {
          const currentList = _.get(state.emailList, ['list']);
          _.set(state.emailList, ['list'], _.omit(currentList, [meta.arg.mailId]));
        }
      })
      .addCase(sendMail.pending, (state, {}) => {
        state.sendingMail = true;
      })
      .addCase(sendMail.fulfilled, (state, { meta, payload }) => {
        const { mailId, to } = meta.arg;
        if (payload.errno === ERROR_CODE.ERROR) {
          toast.error(payload.errmsg);
        } else if (payload) {
          const mail = _.get(state.emailList.list, [mailId]);
          if (mail) {
            _.set(state.emailList.list, [mailId], { ...mail, flags: [...mail.flags, MAIL_FLAGS.ANSWERED] });
          }
          toast.success(`Mail sent to ${to}`);
        }
        state.sendingMail = false;
      })
      .addCase(sendMail.rejected, (state, {}) => {
        state.sendingMail = false;
      })
      .addCase(setMails, (state, { payload }) => {
        if (payload) {
          state.emailList = setFulfilledListStatus(payload);
        }
      })
      .addCase(setChatText, (state, { payload }) => {
        if (payload) {
          _.set(state.currentChatText, [payload.mailId], payload.text);
        }
      })
      .addCase(setCurrentFlag, (state, { payload }) => {
        state.currentFlag = payload;
      })
      .addCase(setCurrentMailId, (state, { payload }) => {
        if (payload) {
          state.currentMailId = payload.emailId;
        }
      });
  },
});
export const selectEmailList = (state: AppState): Record<string, IMail> => state.email.emailList.list;
export const selectEmailListStatus = (state: AppState): FETCHING_STATUS => state.email.emailList.status;
export const selectSendingMail = (state: AppState): boolean => state.email.sendingMail;
export const selectCurrentMailId = (state: AppState): string | undefined => state.email.currentMailId;
export const selectCurrentFlag = (state: AppState): MAIL_FLAGS | string => state.email.currentFlag;
export const selectCurrentChatText =
  (mailId: string | undefined) =>
  (state: AppState): string | undefined =>
    _.get(state.email.currentChatText, [mailId || '']);
export const selectCurrentChatTextStatus =
  (mailId: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.email.currentChatTextStatus, [mailId || '']);
export const selectCurrentMailStatus =
  (mailId: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.email.currentMailStatus, [mailId || '']);
export const selectCurrentMail =
  (mailId: string | undefined) =>
  (state: AppState): IMail | undefined =>
    _.get(state.email.emailList.list, [mailId || '']);

export default emailSlice.reducer;
