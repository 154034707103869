import { createSlice, current } from '@reduxjs/toolkit';
import {
  getMerchants,
  initMerchantSocket,
  setSessionMessage,
  setSelectedMerchant,
  setMerchantSession,
  getMerchantSessions,
  startSession,
  getDemoConversations,
} from './actions';
import _ from 'lodash';
import { AppState } from '@auth0/auth0-react';
import {
  ListArrayStatus,
  getDefaultListArrayStatus,
  setFulfilledEndListArrayStatus,
  setFulfilledListArrayStatus,
} from '@/types/redux';
import { ERROR_CODE, FETCHING_STATUS } from '@/constants';
import { IDemoMessage, IDemoSession, ILanguage, IMerchant } from '@/types/demo';
import { Socket } from 'socket.io-client';
import { MESSAGE_STATUS } from '@/constants/demo';
export interface IDemoState {
  merchants: ListArrayStatus<IMerchant>;
  sessionMessages: { [sessionId: string]: ListArrayStatus<IDemoMessage> };
  selectedMerchant: IMerchant | undefined;
  languages: ILanguage[];
  merchantSessions: { [merchantId: string]: ListArrayStatus<IDemoSession> };
  sessionReplying: { [sessionId: string]: boolean };
  merchantSocket: { [merchantId: string]: Socket | undefined };
  merchantSessionId: { [merchantId: string]: string };
}

const initialState: IDemoState = {
  merchants: getDefaultListArrayStatus(),
  selectedMerchant: undefined,
  sessionMessages: {},
  merchantSocket: {},
  languages: [],
  sessionReplying: {},
  merchantSessionId: {},
  merchantSessions: {},
};

export const demoSlice = createSlice({
  name: 'demo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMerchants.pending, (state, { meta }) => {
      const { search } = meta.arg;
      const sessions = _.get(state.merchants, ['list'], []);
      if (!sessions.length || !!search) {
        _.set(state.merchants, ['status'], FETCHING_STATUS.FETCHING);
      }
    });
    builder.addCase(getMerchants.fulfilled, (state, { payload }) => {
      if (payload.errno === ERROR_CODE.NORMAL) {
        state.merchants = setFulfilledEndListArrayStatus(payload.data);
      }
    });
    builder.addCase(getMerchants.rejected, (state) => {
      _.set(state.merchants, ['status'], FETCHING_STATUS.DONE);
    });
    builder.addCase(setSelectedMerchant, (state, { payload }) => {
      state.selectedMerchant = payload;
    });
    builder.addCase(initMerchantSocket.fulfilled, (state, { meta, payload }) => {
      if (payload) {
        _.set(state.merchantSocket, [meta.arg.merchantId], payload);
      }
    });
    builder.addCase(setSessionMessage, (state, { payload }) => {
      if (payload.message.status === MESSAGE_STATUS.FINISHED) {
        _.set(state.sessionReplying, [payload.sessionId], false);
      } else {
        _.set(state.sessionReplying, [payload.sessionId], true);
        const messages = _.get(state.sessionMessages, [payload.sessionId, 'list'], []);
        const lastMessage = _.last(messages);
        if (lastMessage?.id === payload.message.id) {
          _.set(
            state.sessionMessages,
            [payload.sessionId, 'list'],
            [
              ..._.initial(messages),
              {
                ...payload.message,
                createdAt: lastMessage.createdAt,
                content: (lastMessage?.content || '') + (payload.message?.content || ''),
              },
            ],
          );
        } else {
          _.set(state.sessionMessages, [payload.sessionId, 'list'], [...messages, payload.message]);
        }
      }
    });
    builder.addCase(setMerchantSession, (state, { payload }) => {
      _.set(state.merchantSessionId, [payload.merchantId], payload.sessionId);
    });
    builder.addCase(getMerchantSessions.pending, (state, { meta }) => {
      _.set(state.merchantSessions, [meta.arg.trackId || '', 'status'], FETCHING_STATUS.FETCHING);
    });
    builder.addCase(getMerchantSessions.fulfilled, (state, { payload, meta }) => {
      _.set(state.merchantSessions, [meta.arg.trackId || ''], setFulfilledListArrayStatus(payload || []));
    });
    builder.addCase(startSession.fulfilled, (state, { payload, meta }) => {
      if (payload) {
        _.set(state.merchantSessionId, [meta.arg.merchantId || ''], payload);
      }
    });
    builder
      .addCase(getDemoConversations.pending, (state, { meta }) => {
        _.set(state.sessionMessages, [meta.arg.sessionId || '', 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getDemoConversations.fulfilled, (state, { payload, meta }) => {
        if (payload) {
          _.set(state.sessionMessages, [meta.arg.sessionId || ''], setFulfilledListArrayStatus(payload));
        }
      });
  },
});

export const selectMerchants = (state: AppState): IMerchant[] | undefined => _.get(state.demo.merchants, ['list']);
export const selectMerchantSessionId =
  (merchantId: string | undefined) =>
  (state: AppState): string | undefined =>
    _.get(state.demo.merchantSessionId, [merchantId || '']);
export const selectSessionReplying =
  (sessionId: string | undefined) =>
  (state: AppState): boolean =>
    _.get(state.demo.sessionReplying, [sessionId || '']);
export const selectReplying = (state: AppState): boolean[] => _.values(state.demo.sessionReplying);
export const selectSessionMessages =
  (sessionId: string | undefined) =>
  (state: AppState): IDemoMessage[] | undefined =>
    _.get(state.demo.sessionMessages, [sessionId || '', 'list']);
export const selectSessionMessagesStatus =
  (sessionId: string | undefined) =>
  (state: AppState): FETCHING_STATUS | undefined =>
    _.get(state.demo.sessionMessages, [sessionId || '', 'status']);
export const selectMerchantSocket =
  (merchantId: string | undefined) =>
  (state: AppState): Socket | undefined =>
    _.get(state.demo.merchantSocket, [merchantId || '']);
export const selectLanguages = (state: AppState): ILanguage[] | undefined => state.demo.languages;
export const selectSelectedMerchant = (state: AppState): IMerchant | undefined => state.demo.selectedMerchant;
export const selectMerchantsStatus = (state: AppState): FETCHING_STATUS | undefined =>
  _.get(state.demo.merchants, ['status']);
export const selectMerchantSessions =
  (merchantId: string | undefined) =>
  (state: AppState): IDemoSession[] | undefined =>
    _.get(state.demo.merchantSessions, [merchantId || '', 'list'], []);
export const selectMerchantSessionsStatus =
  (merchantId: string | undefined) =>
  (state: AppState): FETCHING_STATUS | undefined =>
    _.get(state.demo.merchantSessions, [merchantId || '', 'status']);

export default demoSlice.reducer;
