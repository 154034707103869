/**
 * @description Component-PostItem
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import './index.less';
import FormattedDate from '@/components/FormattedDate';
import {
  // IMetaComment,
  IMetaPost,
} from '@/types/inbox';
import { Button, Tag } from 'antd';
// import CommentList from '../CommentList';
import Avatar from 'react-avatar';
import PostItemModal from './PostItemModal';
import { SOCKET_CHANNELS } from '@/constants/sockets';
import { useSocket } from '@/features/socket/hooks';
import { setAigcCommentReplies, setInboxCurrentPostId } from '@/features/inbox/actions';
import { ISocketReplyResponse } from '@/types/socket';
import _ from 'lodash';
import { useAppDispatch } from '@/features/hooks';
import { Mobile, useMediaQueryDevice } from '@/components/MediaQuery';
import classNames from 'classnames';
import { useCurrentPostId } from '@/features/inbox/hooks';
import { useOrg } from '@/features/settings/hooks';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  post: IMetaPost;
}
const PostItem: FC<IPropTypes> = function ({ post }) {
  const org = useOrg();
  const [commentsOpen, setCommentsOpen] = useState(false);
  const { deviceType, isMobile } = useMediaQueryDevice();
  const [modalOpen, setModalOpen] = useState(false);
  const socket = useSocket(SOCKET_CHANNELS.META_IN_ONE);
  const [fetchingReplies, setFetchingReplies] = useState(false);
  const dispatch = useAppDispatch();
  const currentPostId = useCurrentPostId(org?.id);
  const onResponse = useCallback(
    (replies: ISocketReplyResponse<{ id: string; content: string }[]>) => {
      if (replies.data) {
        const repliesTransformed = replies.data.reduce((acc, cur) => {
          _.set(acc, [cur.id], cur.content);
          return acc;
        }, {});
        dispatch(setAigcCommentReplies(repliesTransformed));
      }
      setFetchingReplies(false);
    },
    [dispatch],
  );
  useEffect(() => {
    if (socket && (modalOpen || commentsOpen)) {
      socket?.emit('message', {
        postId: post?.id,
        // commentId: comment?.commentId,
      });
      setFetchingReplies(true);
    }
  }, [post?.id, socket, modalOpen, commentsOpen]);
  useEffect(() => {
    if (socket) {
      socket.on('response', onResponse);
    }
    return () => {
      socket && socket.off('response', onResponse);
    };
  }, [onResponse, socket]);
  return (
    <div
      onClick={() => org && dispatch(setInboxCurrentPostId({ id: post.id, orgId: org?.id }))}
      key={post.id}
      className={classNames(`cor-post-list-item`, deviceType, {
        current: post.id === currentPostId,
        unreplied: post.unrepliedComments > 0,
      })}>
      <div className="cor-post-list-item-name">
        <Avatar name={post.pageName} size="36" src={post.pageName} round />
        <div className="cor-post-list-item-name-right">
          {post.pageName} <FormattedDate date={post?.createdTime} />
        </div>
      </div>
      <div className={`cor-post-list-item-content`}>{post.content}</div>
      <div className="cor-post-list-item-tags">
        {post.unrepliedComments > 1 && (
          <Tag color="#f66" style={{ fontWeight: 700 }}>
            {' '}
            {post.unrepliedComments} Unreplied
          </Tag>
        )}
        <div className="cor-post-list-item-tags-right">
          <Button size="small" type="text" onClick={() => !isMobile && setCommentsOpen(!commentsOpen)}>
            {post.totalComments} Comments
          </Button>{' '}
          <Mobile>
            <Button size="small" type="primary" onClick={() => setModalOpen(!modalOpen)}>
              Manage replies with AI
            </Button>
          </Mobile>
        </div>
      </div>
      {/* {commentsOpen && (
        <div className="cor-post-list-item-comments">
          <CommentList fetchingReplies={fetchingReplies} selectComment={setSelectedComment} postId={post.id} />
        </div>
      )} */}
      <PostItemModal
        fetchingReplies={fetchingReplies}
        open={modalOpen}
        post={post}
        onCancel={() => setModalOpen(false)}
      />
    </div>
  );
};

export default PostItem;
