/**
 * @description Component-Banner
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import st_1 from './assets/st-1.png';
import st_2 from './assets/st-2.png';
import st_3 from './assets/st-3.png';
interface IPropTypes {
  className?: string;
}
const Solution: FC<IPropTypes> = function () {
  return (
    <div className={`cor-solution`}>
      <div className="cor-solution-title">
        Intelligent solutions for <span className="highlight">unstoppable growth</span>
      </div>
      <div className="cor-solution-container">
        <div className="cor-solution-container-left cor-solution-container-tong wow animate__animated animate__slideInUp">
          <img className="cor-solution-container-tong-img" src={st_1} />
          <div className="cor-solution-container-tong-title">Social Media Management</div>
          <div className="cor-solution-container-tong-subtitle">
            Elevate your brand presence with AI-driven social media management. Automate likes, replies, and
            interactions to engage customers and drive sales.
          </div>
          <div className="tips1"></div>
          <div className="tips2">
            <div></div>
          </div>
          <div className="tips3">
            <div></div>
          </div>
          <div className="tips4"></div>
        </div>
        <div className="cor-solution-container-center cor-solution-container-tong wow animate__animated animate__slideInUp">
          <img className="cor-solution-container-tong-img" src={st_2} />
          <div className="cor-solution-container-tong-title">Website Live Chat</div>
          <div className="cor-solution-container-tong-subtitle">
            Increase conversions with our smart chatbot that answers questions, provides personalized recommendations
            and timely discounts to turn visitors into buyers.
          </div>
          <div className="tips1"></div>
          <div className="tips2">
            <div></div>
          </div>
          <div className="tips3">
            <div></div>
          </div>
          <div className="tips4"></div>
        </div>
        <div className="cor-solution-container-right cor-solution-container-tong wow animate__animated animate__slideInUp">
          <img className="cor-solution-container-tong-img" src={st_3} />
          <div className="cor-solution-container-tong-title">Automated Email Responses</div>
          <div className="cor-solution-container-tong-subtitle">
            Ensure quick, helpful replies with our smart email system that replaces outdated customer service responses
            with targeted, effective communication.
          </div>
          <div className="tips1"></div>
          <div className="tips2">
            <div></div>
          </div>
          <div className="tips3">
            <div></div>
          </div>
          <div className="tips4"></div>
        </div>
      </div>
    </div>
  );
};

export default Solution;
