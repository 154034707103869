import { IBrandVoiceConfigResponse } from '@/types/settings';
import { useAppSelector } from '../hooks';
import {
  selectBrandConfigs,
  selectBrandVoice,
  selectGeneratingVoiceStatus,
  selectGettingConfigStatus,
  selectReceiptToBeFetched,
  selectSavingConfig,
  selectSavingVoiceStatus,
} from './slice';
import { useMemo } from 'react';
import { FETCHING_STATUS } from '@/constants';

export function useBrandConfig(): IBrandVoiceConfigResponse | undefined {
  const configs = useAppSelector(selectBrandConfigs);
  return useMemo(() => configs, [configs]);
}
export function useSavingConfig(): FETCHING_STATUS {
  const status = useAppSelector(selectSavingConfig);
  return useMemo(() => status, [status]);
}
export function useGeneratingVoiceStatus(): FETCHING_STATUS {
  const status = useAppSelector(selectGeneratingVoiceStatus);
  return useMemo(() => status, [status]);
}
export function useSavingVoiceStatus(): FETCHING_STATUS {
  const status = useAppSelector(selectSavingVoiceStatus);
  return useMemo(() => status, [status]);
}
export function useGettingVoiceConfigStatus(): FETCHING_STATUS {
  const status = useAppSelector(selectGettingConfigStatus);
  return useMemo(() => status, [status]);
}
export function useBrandVoice(): string {
  const voice = useAppSelector(selectBrandVoice);
  return useMemo(() => voice, [voice]);
}
export function useReceiptToBeFetched(): string {
  const receipt = useAppSelector(selectReceiptToBeFetched);
  return useMemo(() => receipt, [receipt]);
}
