import { memo, useCallback, useEffect } from 'react';
import { useOrg } from '../settings/hooks';
import { useAppDispatch } from '../hooks';
import { getAlloyToken, getShopConnections, setAlloy } from './actions';
import { ERROR_CODE } from '@/constants';
import * as Alloy from 'alloy-frontend';

function useFetchToken(): void {
  const org = useOrg();
  const dispatch = useAppDispatch();
  const getToken = useCallback(async () => {
    const result = await dispatch(getAlloyToken()).unwrap();
    if (result.errno === ERROR_CODE.NORMAL) {
      const alloy = new Alloy();
      alloy.setToken(result.data.token);
      dispatch(setAlloy(alloy));
      localStorage.setItem('alloyToken', result.data.token);
    }
  }, [dispatch]);
  useEffect(() => {
    org?.id && getToken();
  }, [org?.id]);
}
function useIntegrations(): void {
  const org = useOrg();
  const dispatch = useAppDispatch();
  useEffect(() => {
    org && dispatch(getShopConnections());
  }, [org]);
}

function ShopGlobalEffect(): null {
  useFetchToken();
  useIntegrations();
  return null;
}

export default memo(ShopGlobalEffect);
