import { ICustomer } from '@/types/customers';
import { useAppSelector } from '../hooks';
import { selectCustomerByEmail, selectCustomerStatusByEmail } from './slice';
import { useMemo } from 'react';
import { FETCHING_STATUS } from '@/constants';

export function useCustomer(orgId: string | undefined, email: string | undefined): ICustomer | undefined {
  const customer = useAppSelector(selectCustomerByEmail(orgId, email));
  return useMemo(() => customer, [customer]);
}
export function useCustomerStatus(orgId: string | undefined, email: string | undefined): FETCHING_STATUS | undefined {
  const status = useAppSelector(selectCustomerStatusByEmail(orgId, email));
  return useMemo(() => status, [status]);
}
