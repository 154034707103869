/**
 * @description Component-KnowlegeBase
 */
import React, { FC } from 'react';
import './index.less';

import KnowledgeTable from './KnowledgeTable';
import KnowledgeWebpageTable from './KnowledgeWebpageTable';
import NewKnowledgeTable from './NewKnowledgeTable';
interface IPropTypes {
  className?: string;
}
const KnowledgeBase: FC<IPropTypes> = function () {
  return (
    <div className="cor-knowledge-base">
      {/* <KnowledgeTable /> */}
      <NewKnowledgeTable />
      <div className="cor-knowledge-base-divider" />
      <KnowledgeWebpageTable />
    </div>
  );
};

export default KnowledgeBase;
