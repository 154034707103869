import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import _ from 'lodash';
import { useAppSelector } from '../hooks';
import { selectCurrentToken } from './slice';

export function useAuthOrg(): string | undefined {
  const user = useAuth0();
  return useMemo(() => _.get(user, ['organization']), [user]);
}
export function useAuthToken(): string | undefined {
  const token = useAppSelector(selectCurrentToken);
  return useMemo(() => token, [token]);
}
