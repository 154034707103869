import React from 'react';
import ReactDOM from 'react-dom';
// import ReactGA from 'react-ga';
import './themes/index.less';
import App from './App';
import { store } from './features/store';
import { Provider } from 'react-redux';
import './initSettings';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider, AppState as AuthState } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import './i18n';
import { createBrowserHistory } from 'history';
export const onRedirectCallback = (appState: AuthState | undefined) => {
  const history = createBrowserHistory();
  history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};
ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <Auth0Provider
          onRedirectCallback={onRedirectCallback}
          domain={process.env.REACT_APP_AUTH_DOMAIN || ''}
          clientId={process.env.REACT_APP_AUTH_ID || ''}
          useRefreshTokens={true}
          cacheLocation="localstorage"
          authorizationParams={{
            redirect_uri: window.location.origin + '/success',
            audience: 'https://cornerstone-chat.us.auth0.com/api/v2/',
          }}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Auth0Provider>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
