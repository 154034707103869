/* eslint-disable @typescript-eslint/no-explicit-any */
import './index.less';

import MDEditor from '@uiw/react-md-editor';
import {
  Carousel,
  // Image,
  Modal,
} from 'antd';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useOrg } from '@/features/settings/hooks';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import { getProductInfo } from '@/features/plugin/actions';
import { useAppDispatch } from '@/features/hooks';
import { IProduct } from '@/types/plugin';

// import { transformUrl } from '@/utils';
// import VariantItem from '../VariantItem';
interface IPropTypes {
  url: string | undefined;
  showName?: boolean;
  remoteIds?: string[];
}
const LinkPreviewer: FC<IPropTypes> = function ({
  url,
}: // remoteIds
IPropTypes) {
  const { deviceType, isMobile } = useMediaQueryDevice();
  const productUrl = useMemo(() => url, [url]);
  const dispatch = useAppDispatch();
  const org = useOrg();
  const [productInfo, setProductInfo] = useState<IProduct | undefined>();
  const [showDetail, setShowDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const getProduct = useCallback(async () => {
    if (productUrl && org) {
      try {
        setLoading(true);
        const product = await dispatch(getProductInfo({ url: productUrl, trackId: org?.teams[0].trackId })).unwrap();
        setProductInfo(product);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  }, [dispatch, org, productUrl]);
  useEffect(() => {
    getProduct();
  }, [getProduct]);
  if (!loading && !productInfo) {
    return (
      <a className="cor-link-preview-link" href={url} target="_blank" rel="noreferrer">
        {url}
      </a>
    );
  }
  return (
    <>
      <div className={classNames('cor-link-preview-top', deviceType)}>
        <div className="cor-link-preview-top-left">
          <a className="cor-link-preview-title" target="_blank" rel="noreferrer" href={url}>
            {' '}
            {productInfo?.productName}
          </a>
        </div>
      </div>

      <Modal
        open={showDetail}
        footer={false}
        width={'80%'}
        zIndex={100000000}
        className="cor-link-preview-modal"
        centered={true}
        onCancel={() => setShowDetail(false)}
        title={
          <a href={url} className="cor-link-preview-title">
            {productInfo?.productName}
          </a>
        }>
        <div className={classNames('cor-link-preview', deviceType)}>
          <MDEditor.Markdown className="cor-link-preview-desc" source={productInfo?.description || ''} />
          <Carousel autoplay>
            {productInfo?.images.map((img) => (
              <img
                width={'100%'}
                height={isMobile ? 120 : 'auto'}
                key={img.src}
                className="cor-link-preview-image"
                src={img.src}
              />
            ))}
          </Carousel>
        </div>
      </Modal>
    </>
  );
};

export default LinkPreviewer;
