/**
 * @description Component-SessionList
 */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import './index.less';
import { RedoOutlined } from '@ant-design/icons';
import { usePluginSessions, usePluginSessionsStatus, useSelectedSession } from '@/features/plugin/hooks';
import { useOrg } from '@/features/settings/hooks';
import { FETCHING_STATUS, SETTINGS_TABS } from '@/constants';
import InfiniteScroller from 'react-infinite-scroller';
import { useAppDispatch } from '@/features/hooks';
import { getSessions, setSelectedSession } from '@/features/plugin/actions';
import _ from 'lodash';
import SessionItem from './SessionItem';
import { ReactComponent as SearchIcon } from '@/assets/svg/icon_search_linear.svg';
import { ReactComponent as CloseIcon } from '@/assets/svg/icon_close_24.svg';
import { Button, Empty, Tooltip } from 'antd';
import { USER_PAGE_SIZE } from '@/constants/inbox';
import { Search } from '@/components/Search';
import { useDebounceFn } from 'ahooks';
import ListSkeleton from '@/pages/components/ListSkeleton';
import { useNavigate } from 'react-router-dom';
interface IPropTypes {
  className?: string;
}
const SessionList: FC<IPropTypes> = function () {
  const org = useOrg();
  const wrapRef = useRef<HTMLDivElement | null>(null);
  const sessions = usePluginSessions(org?.id);
  const sessionsStatus = usePluginSessionsStatus(org?.id);
  const [refreshing, setRefreshing] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedSession = useSelectedSession(org?.id);
  const gotoSettings = (route: string, tab?: string) => {
    if (tab) {
      navigate(route, { state: { tab } });
      return;
    }

    navigate(route);
  };
  const onLoad = useCallback(() => {
    if (!sessionsStatus || sessionsStatus === FETCHING_STATUS.DONE) {
      org &&
        dispatch(
          getSessions({
            orgId: org?.id,
            pageSize: USER_PAGE_SIZE,
            preId: _.last(sessions)?.id,
          }),
        );
    }
  }, [dispatch, org, sessions, sessionsStatus]);
  const { run: searchFunc } = useDebounceFn((search: string) => {
    if (sessionsStatus !== FETCHING_STATUS.FETCHING) {
      org &&
        dispatch(
          getSessions({
            orgId: org?.id,
            search,
          }),
        );
    }
  });
  const refresh = useCallback(async () => {
    if (org) {
      try {
        setRefreshing(true);
        await dispatch(
          getSessions({
            orgId: org?.id,
            nextId: _.first(sessions)?.id,
          }),
        ).unwrap();
        setRefreshing(false);
      } catch (e) {
        setRefreshing(false);
      }
    }
  }, [dispatch, org, sessions]);
  useEffect(() => {
    wrapRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [org]);

  useEffect(() => {
    //  set first session to selected
    if (!!sessions?.length && !sessions?.some((s) => s.id === selectedSession?.id) && org) {
      const firstSession = _.first(sessions);
      firstSession &&
        dispatch(
          setSelectedSession({
            orgId: org.id,
            session: firstSession,
          }),
        );
    }
  }, [sessions, org, selectedSession, dispatch]);
  return (
    <>
      <div ref={wrapRef} className="cor-session-list-search">
        <Search
          placeholder="Search..."
          allowClear={{
            clearIcon: <CloseIcon />,
          }}
          suffix={null}
          prefix={<SearchIcon />}
          onChange={(e) => {
            const search = e.target.value;
            search ? searchFunc(e.target.value) : onLoad();
          }}
        />
        <Tooltip title="Refresh">
          <Button
            type="text"
            disabled={sessionsStatus === FETCHING_STATUS.FETCHING}
            onClick={refresh}
            icon={<RedoOutlined spin={refreshing} />}
          />
        </Tooltip>
      </div>
      <div ref={wrapRef} className="cor-session-list-wrap">
        <InfiniteScroller
          className="cor-session-list"
          initialLoad={true}
          useWindow={false}
          hasMore={sessionsStatus !== FETCHING_STATUS.COMPLETED}
          getScrollParent={() => wrapRef.current}
          loadMore={(e) => {
            console.log('🚀 ~ e:', e);
            onLoad();
          }}>
          {sessionsStatus === FETCHING_STATUS.FETCHING && !sessions?.length && <ListSkeleton />}
          {(sessionsStatus === FETCHING_STATUS.DONE || sessionsStatus === FETCHING_STATUS.COMPLETED) &&
            !sessions?.length && (
              <Empty
                description={
                  <>
                    <div>No Chats Found!</div>
                    <div>
                      If you have not set up your Live Chat yet, Please set up the Live Chat first!{' '}
                      <a onClick={() => navigate(`/dashboard/settings/${SETTINGS_TABS.PLUGIN}`)}>Settings</a>
                    </div>
                  </>
                }
              />
            )}
          {sessions?.map((s) => (
            <SessionItem session={s} key={s.conversationId} />
          ))}
        </InfiniteScroller>
      </div>
    </>
  );
};

export default SessionList;
