/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * @description Component-EditKnowledgeWebpage
 */
import React, { FC, useCallback, useState } from 'react';
import './index.less';
import { FETCHING_STATUS } from '@/constants';
import { Modal, Form, Input, Button } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useAppDispatch } from '@/features/hooks';
import { useOrg, useKnowledgeWebpageSavingStatus } from '@/features/settings/hooks';
import { IKnowledgeBase } from '@/types/settings';
import { ReactComponent as CloseIcon } from '@/pages/Dashboard/assets/close.svg';
import { saveKnowledgeWebpage, updateKnowledgeWebpage } from '@/features/settings/actions';

import { KNOWLEDGEBASE_CATEGORY, URL_REGEX } from '@/constants/settings';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  base?: IKnowledgeBase;
  text: string;
  title: string;
  icon: React.ReactNode;
  type?: 'primary' | 'text' | 'ghost';
}
const EditKnowledge: FC<IPropTypes> = function ({ text, title, icon, type = 'primary' }) {
  const dispatch = useAppDispatch();
  const [currentWebpage, setCurrentWebpage] = useState({} as IKnowledgeBase);
  const [modalOpen, setModalOpen] = useState(false);
  const org = useOrg();
  const savingStatus = useKnowledgeWebpageSavingStatus(org?.id);

  const [form] = Form.useForm();
  const onSaveKnowledgeWebpage = useCallback(
    async (_knowledgeForm: IKnowledgeBase) => {
      _knowledgeForm.category = KNOWLEDGEBASE_CATEGORY.WEBPAGE;
      if (org) {
        let result;
        if (currentWebpage.id) {
          result = await dispatch(
            updateKnowledgeWebpage({
              org: org.id,
              knowledge: { ...currentWebpage, ..._knowledgeForm },
            }),
          ).unwrap();
        } else {
          result = await dispatch(saveKnowledgeWebpage({ org: org.id, knowledge: { ..._knowledgeForm } })).unwrap();
        }
        if (result) {
          setModalOpen(false);
          setCurrentWebpage({} as IKnowledgeBase);
        }
      }
    },
    [org, currentWebpage, dispatch],
  );
  const onClickAdd = useCallback(() => {
    setModalOpen(true);
    form.resetFields();
    setCurrentWebpage({} as IKnowledgeBase);
  }, [form]);

  return (
    <div className="cor-edit-knowledge">
      <Button size="small" type={type} icon={icon} onClick={onClickAdd}>
        {text}
      </Button>
      <Modal
        footer={false}
        title={title}
        open={modalOpen}
        width={480}
        centered
        className="cor-settings-modal cor-edit-knowledge-url-modal"
        closeIcon={<CloseIcon />}
        onCancel={() => setModalOpen(false)}
        onOk={() => setModalOpen(false)}>
        <Form form={form} initialValues={currentWebpage} onFinish={onSaveKnowledgeWebpage}>
          <FormItem rules={[{ required: true, message: 'Please name this webpage' }]} label="Name" name="name">
            <Input />
          </FormItem>
          <FormItem
            rules={[
              { required: true, message: 'Please input webpage url' },
              { pattern: URL_REGEX, message: 'please enter a valid url' },
            ]}
            label="Url"
            name="knowledge">
            <Input placeholder="Enter URL" />
          </FormItem>

          <Form.Item>
            <Button
              loading={savingStatus === FETCHING_STATUS.FETCHING}
              className="cor-save"
              type="primary"
              htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditKnowledge;
