/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * @description Component-ActionNewKnowledge
 */
import React, { FC } from 'react';
import './index.less';
import { Search } from '@/components/Search';
import { Input } from 'antd';
import { ReactComponent as CloseIcon } from '@/assets/svg/icon_close_24.svg';
import { SearchOutlined } from '@ant-design/icons';

import CorNewUploader from '@/pages/components/CorNewUploader';
import EditNewBtn from '../EditNewBtn';
import { ReactComponent as AddIcon } from '@/assets/svg/icon_add_16.svg';

interface IPropTypes {
  className?: string;
  onInputChange: (str: string) => void;
}

const ActionNewKnowledge: FC<IPropTypes> = function ({ onInputChange }) {
  return (
    <div className="cor-header-action-knowledge">
      <div className="cor-header-action-knowledge-search">
        <Input
          size="small"
          prefix={<SearchOutlined />}
          placeholder="Search..."
          allowClear={{
            clearIcon: <CloseIcon />,
          }}
          onChange={(e) => onInputChange(e.target.value.trim())}></Input>
      </div>
      <EditNewBtn title="Add Knowledge" type="primary" icon={<AddIcon />} />
      <CorNewUploader />
    </div>
  );
};

export default ActionNewKnowledge;
