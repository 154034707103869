import { IMail, MAIL_FLAGS } from '@/types/email';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { axiosGet, axiosPost } from '@/utils/axios';
import { IResponse } from '@/types/settings';
import { toast } from 'react-toastify';
export const setCurrentMailId = createAction<{ emailId: string }>('email/setCurrentMailId');
export const setMails = createAction<Record<string, IMail>>('email/setMails');
export const setCurrentFlag = createAction<MAIL_FLAGS | string>('email/setCurrentFlat');
export const setChatText = createAction<{ text: string; mailId: string }>('email/setChatText');

export const getUsersFromMails = createAsyncThunk(
  'email/getUsersFromMails',
  async ({
    match,
    lastUserId,
  }: {
    lastUserId?: string | undefined;
    match?: string;
  }): Promise<Record<string, IMail> | undefined> => {
    try {
      const { data } = await axiosGet(
        `${process.env.REACT_APP_API_ROOT}mail/users/?query=${match || ''}&pageSize=100&nextId=${lastUserId || ''}`,
      );
      console.log('🚀 ~ file: actions.ts:16 ~ data:', data);
      return _.keyBy(
        _.filter(data.data, (mail) => !process.env.REACT_APP_FILTER_MAILS?.includes(mail.from.trim())),
        'id',
      );
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const getMails = createAsyncThunk(
  'email/getMails',
  async ({
    match,
    lastMailId,
  }: {
    lastMailId?: string | undefined;
    match?: string;
  }): Promise<Record<string, IMail> | undefined> => {
    try {
      const { data } = await axiosGet(
        `${process.env.REACT_APP_API_ROOT}mail/?query=${match || ''}&pageSize=100&nextId=${lastMailId || ''}`,
      );
      return _.keyBy(
        _.filter(data.data, (mail) => !process.env.REACT_APP_FILTER_MAILS?.includes(mail.from.trim())),
        'id',
      );
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const getMail = createAsyncThunk(
  'email/getMail',
  async ({ mailId }: { mailId: string }): Promise<IResponse<IMail> | undefined> => {
    try {
      const { data } = await axiosGet(process.env.REACT_APP_API_ROOT + `mail/get?id=${mailId}`);
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);

/**
 * @Deprecated
 * @See move to : inbox/actions
 **/
export const getAigcResponse = createAsyncThunk(
  'email/getAigcResponse',
  async ({
    question,
    mailId,
    templateId,
  }: {
    question: string | undefined;
    mailId: string;
    templateId: string;
  }): Promise<string | undefined> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + 'llm/completions', {
        question,
        mailId,
        templateId,
      });
      console.log('🚀 ~ file: actions.ts:54 ~ data:', data);
      if (data.errno !== 0) {
        toast.warning(data.errmsg);
        return undefined;
      }
      return data.data.text;
    } catch (e) {
      toast.warning(_.get(e, ['message']));
      throw e;
    }
  },
);

/**
 * @Deprecated
 * @See move to : inbox/actions
 **/
export const sendMail = createAsyncThunk(
  'email/sendMail',
  async (
    { subject, to, body, mailId }: { subject: string; to: string; body: string; mailId: string },
    {},
  ): Promise<IResponse<boolean | undefined>> => {
    try {
      const result = await axiosPost(process.env.REACT_APP_API_ROOT + 'mail/send_mail', {
        subject,
        to,
        body,
        mailId,
      });
      return result.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);

export const archiveMail = createAsyncThunk(
  'email/archiveMail',
  async ({ mailId }: { mailId: string }, { dispatch }): Promise<boolean> => {
    try {
      const result = await axiosPost(process.env.REACT_APP_API_ROOT + 'mail/archive', {
        id: mailId,
      });
      return result.data;
    } catch (e) {
      return false;
    }
  },
);
