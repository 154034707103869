/**
 * @description Component-Chating
 */
import React, { FC } from 'react';
import './index.less';
import { useOrg } from '@/features/settings/hooks';
import { useUserById, useCurrentUserId, useCurrentCustomer } from '@/features/inbox/hooks';
import AigcBot from '@/components/AigcBot';
import Avatar from 'react-avatar';
import { useCurrentConversationId } from '@/features/aigc/hooks';
import { AIGC_TYPE } from '@/constants/aigc';
interface IPropTypes {
  className?: string;
}
const ChatHeader: FC<IPropTypes> = function () {
  const org = useOrg();
  const currentUserId = useCurrentUserId(org?.id);
  const currentUser = useUserById(org?.id, currentUserId);
  const customer = useCurrentCustomer(org?.id);
  const conversationId = useCurrentConversationId(org?.id);
  return (
    <div className="cor-chat-header">
      <div className="cor-chat-header-left">
        <Avatar round size="48" src={currentUser?.avatar} name={currentUser?.name || currentUser?.from} />
        <div className="cor-chat-header-right">
          <span className="name">{currentUser?.name}</span>
          <span className="email">{currentUser?.from ? `<${currentUser?.from}>` : ''}</span>
        </div>
      </div>
      <div className="cor-chat-header-ai">
        <AigcBot
          scene={AIGC_TYPE.MAIL_ASSISTANT}
          customerName={customer?.name}
          customerEmail={customer?.from}
          conversationId={conversationId}
        />
      </div>
      {/* <Button type="link">View Profile</Button> */}
    </div>
  );
};

export default ChatHeader;
