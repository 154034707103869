export interface IRetryOptions {
  n: number;
  minWait: number;
  maxWait: number;
}
export type RetryError = {
  code: number;
  message: string;
};
export type ShouldTryFn = (error: RetryError) => boolean;
export class CancelledError extends Error {
  public isCancelledError: true = true as const;
  constructor() {
    super('Cancelled');
  }
}

export class RetryableError extends Error {
  code!: number;
  public isRetryableError: true = true as const;
}
function wait(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
function waitRandom(min: number, max: number): Promise<void> {
  return wait(min + Math.round(Math.random() * Math.max(0, max - min)));
}

export function retry<T>(
  fn: Promise<T>,
  { n, minWait, maxWait }: IRetryOptions,
  shouldRetryFn?: ShouldTryFn,
): { promise: Promise<T>; cancel: () => void } {
  let completed = false;
  let rejectCancelled: (error: Error) => void;
  const promise = new Promise<T>(async (resolve, reject) => {
    rejectCancelled = reject;
    while (true) {
      let result: T;
      try {
        result = await fn;
        if (!completed) {
          resolve(result);
          completed = true;
        }
        break;
      } catch (error) {
        const err = error as RetryableError;

        if (completed) {
          break;
        }
        n--;
        // Stop trying if one of the conditions meets
        // 1: Tried 3 times already
        // 2: When there is shouldTryFun and the shouldTryFun returns false
        if (n <= 0 || !err.isRetryableError || (shouldRetryFn && !shouldRetryFn(err))) {
          reject(error);
          completed = true;
          break;
        }
      }
      await waitRandom(minWait, maxWait);
    }
  });
  return {
    promise,
    cancel: () => {
      if (completed) return;
      completed = true;
      rejectCancelled(new CancelledError());
    },
  };
}
