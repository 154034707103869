/**
 * @description Component-MerchantDetail
 */
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import './index.less';
import { v4 as uuidv4 } from 'uuid';
import { useOrg } from '@/features/settings/hooks';
import {
  useCurrentMerchant,
  useMerchantSessionId,
  useMerchantSessions,
  useMerchantSessionsStatus,
  useMerchantSocket,
  useSessionMessages,
  useSessionMessagesStatus,
  useSessionReplying,
} from '@/features/demo/hooks';
import { useAppDispatch } from '@/features/hooks';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import { getSessionConversation } from '@/features/plugin/actions';
import classNames from 'classnames';

import MerchantReplyBox from '../MerchantReplyBox';
import _ from 'lodash';
import {
  getDemoConversations,
  getMerchantSessions,
  setMerchantSession,
  setSessionMessage,
  startSession,
} from '@/features/demo/actions';
import MerchantItem from '../MerchantItem';
import { IDemoMessage } from '@/types/demo';
import ChatLoading from '@/components/AigcBot/Chat/BotChatContent/ChatLoading';
import { IMessageRole } from '@/types/aigc';
import { useAuth0 } from '@auth0/auth0-react';
import { useDebounceFn } from 'ahooks';
import { FETCHING_STATUS } from '@/constants';
import { REQUEST_PAGE_SIZE } from '@/constants/plugin';
import SessionItem from './SessionItem';
import SessionDetailSkeleton from '@/pages/Plugin/PluginSessions/SessionDetail/SessionDetailSkeleton';
import { Button } from 'antd';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const MerchantDetail: FC<IPropTypes> = function () {
  const org = useOrg();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { deviceType } = useMediaQueryDevice();
  const merchant = useCurrentMerchant();
  const authState = useAuth0();
  const { getAccessTokenSilently } = authState;
  const merchantSessionId = useMerchantSessionId(merchant?.id);
  const dispatch = useAppDispatch();
  const replying = useSessionReplying(merchantSessionId);
  const messages = useSessionMessages(merchantSessionId);
  const messagesStatus = useSessionMessagesStatus(merchantSessionId);
  const messagesFiltered = useMemo(() => messages?.filter((c) => !!c.content), [messages]);
  const socket = useMerchantSocket(merchant?.id);
  const merchantSessions = useMerchantSessions(merchant?.id);
  const merchantSessionsStatus = useMerchantSessionsStatus(merchant?.id);
  const { run: scrollBottom } = useDebounceFn(
    () => {
      containerRef.current?.scrollTo({ top: containerRef.current.scrollHeight, behavior: 'smooth' });
    },
    { wait: 10 },
  );
  const onResponse = useCallback(
    async (message: IDemoMessage) => {
      if (message.errorCode === 403) {
        await getAccessTokenSilently();
        return;
      }
      message.sessionId &&
        dispatch(
          setSessionMessage({
            sessionId: message.sessionId,
            message,
          }),
        );
      scrollBottom();
    },
    [dispatch, getAccessTokenSilently, scrollBottom],
  );
  useEffect(() => {
    if (socket) {
      socket.on('response', onResponse);
    }
    return () => {
      socket && socket.off('response', onResponse);
    };
  }, [dispatch, merchant, onResponse, socket]);
  useEffect(() => {
    org && merchant && dispatch(getSessionConversation({ orgId: org?.id, conversationId: merchant.id }));
  }, [dispatch, org, merchant?.id, merchant]);
  useEffect(() => {
    if (socket && !messagesFiltered?.length && merchantSessionId && messagesStatus === FETCHING_STATUS.DONE) {
      socket?.emit('message', {
        question: `hi, I speak ${merchant?.language}`,
        sessionId: merchantSessionId,
        language: merchant?.language,
      });
      merchantSessionId &&
        dispatch(
          setSessionMessage({
            sessionId: merchantSessionId,
            message: {
              id: uuidv4(),
              content: `hi, I speak ${merchant?.language}`,
              role: IMessageRole.STARTER,
              sessionId: merchantSessionId,
              createdAt: Date.now(),
            },
          }),
        );
    }
  }, [socket, merchantSessionId, messagesStatus, messagesFiltered?.length, merchant?.language, dispatch]);
  useEffect(() => {
    scrollBottom();
  }, [messagesFiltered?.length]);

  useEffect(() => {
    merchant?.id && dispatch(getMerchantSessions({ trackId: merchant.id, pageSize: REQUEST_PAGE_SIZE }));
  }, [merchant?.id]);

  useEffect(() => {
    if (merchantSessionsStatus === FETCHING_STATUS.DONE) {
      if (!merchantSessions?.length) {
        merchant && dispatch(startSession({ merchantId: merchant?.id }));
      } else if (!merchantSessionId) {
        const firstSession = _.first(merchantSessions);
        merchant &&
          firstSession &&
          dispatch(
            setMerchantSession({
              merchantId: merchant.id,
              sessionId: firstSession.sessionId,
            }),
          );
      }
    }
  }, [merchantSessionsStatus, merchant?.id]);
  useEffect(() => {
    merchantSessionId &&
      dispatch(
        getDemoConversations({
          sessionId: merchantSessionId,
        }),
      );
  }, [merchantSessionId, dispatch]);

  // useEffect(() => {}, []);

  return (
    <div className="cor-merchant-detail-wrap">
      <div className="cor-merchant-detail-wrap-left">
        <div ref={containerRef} className={classNames('cor-merchant-detail', deviceType)}>
          {messagesStatus === FETCHING_STATUS.FETCHING && !messages?.length && <SessionDetailSkeleton />}
          {messagesFiltered?.map((c) => (
            <MerchantItem
              onInputMessageChange={(question) => {
                socket?.emit('message', {
                  question,
                  sessionId: merchantSessionId,
                  language: merchant?.language,
                });
                merchantSessionId &&
                  dispatch(
                    setSessionMessage({
                      sessionId: merchantSessionId,
                      message: {
                        id: uuidv4(),
                        content: question,
                        role: IMessageRole.USER,
                        sessionId: merchantSessionId,
                        createdAt: Date.now(),
                      },
                    }),
                  );
              }}
              key={c.id}
              conversation={c}
            />
          ))}
          {replying && _.last(messagesFiltered)?.role !== IMessageRole.ASSISTANT && <ChatLoading />}
        </div>
        {merchantSessionId && <MerchantReplyBox sessionId={merchantSessionId} />}
      </div>
      <div className="cor-merchant-detail-wrap-right">
        <div className="cor-merchant-detail-wrap-right-bottom">
          {merchantSessions?.map((session) => (
            <SessionItem disabled={replying} key={session.id} session={session} merchantId={merchant?.id} />
          ))}
        </div>
        <div className="cor-merchant-detail-wrap-right-top">
          <Button
            disabled={replying}
            onClick={() => {
              if (replying) {
                return;
              }
              merchant && dispatch(startSession({ merchantId: merchant?.id, force: true }));
            }}
            type="primary">
            New Session
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MerchantDetail;
