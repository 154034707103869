export enum MAIL_FLAGS {
  SEEN = '\\See',
  ANSWERED = '\\Answered',
  FLAGGED = '\\Flagged',
  DRAFT = '\\Draft',
  DELETED = '\\Deleted',
}
export interface IMail {
  date: number;
  from: string;
  realFrom: string;
  name: string;
  to: string[];
  cc: string[];
  subject: string;
  html?: string;
  text?: string;
  uid: number;
  id: string;
  aiCategory: string;
  replyTo: { address: string; name: string }[];
  flags: MAIL_FLAGS[];
  replyOnlyText: string;
  replied?: boolean;
  teamId: string;
  imapId: string;
  messageId: string;
  fromName: string;
  avatar?: string;
  type?: string;
  render?: string;
  bcc: string[];
  sender: JSON;
  attachments: JSON;
}
export interface ISocketMails {
  mails: IMail[];
}
