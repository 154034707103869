export enum REPORT_TABS {
  MARKETING = 'marketing',
  INSIGHTS = 'insights',
  REPORTS = 'reports',
  FAQ = 'faq',
}

export enum ANALYSIS_TABS {
  STATS = 'STATS',
  REPORTS = 'REPORTS',
}
