import { memo, useEffect } from 'react';
import { useAppDispatch } from '../hooks';
import { getMails } from './actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useMailSettings, useOrg } from '../settings/hooks';
// import { useMailSettings, useMailSettingsStatus, useOrg } from '../settings/hooks';
// import { useNavigate } from 'react-router-dom';
// import { FETCHING_STATUS } from '@/constants';

function useFetchEmails() {
  const dispatch = useAppDispatch();
  const org = useOrg();
  const mailSettings = useMailSettings(org?.id);
  const { isAuthenticated } = useAuth0();
  useEffect(() => {
    isAuthenticated && !!mailSettings?.length && dispatch(getMails({}));
  }, [dispatch, isAuthenticated, mailSettings]);
}
// function useRedirectToSettings() {
//   const org = useOrg();
//   const mailSettings = useMailSettings(org?.id);
//   const navigate = useNavigate();
//   const mailSettingStatus = useMailSettingsStatus(org?.id);
//   useEffect(() => {
//     if (org && !mailSettings?.length && mailSettingStatus === FETCHING_STATUS.DONE) {
//       navigate('/dashboard/settings', { replace: true });
//     }
//   }, [mailSettings, navigate, org, mailSettingStatus]);
// }

function EmailGlobalEffect(): null {
  useFetchEmails();
  // useRedirectToSettings();
  return null;
}

export default memo(EmailGlobalEffect);
