import { SOCKET_STATUS } from '@/constants/sockets';

export interface IMessage {
  id: string;
  role: IMessageRole;
  content: string;
  extraHTML?: string;
  avatar?: string;
  name?: string;
  sessionId: string;
  conversationId?: string;
  createdAt: string;
  status?: SOCKET_STATUS;
  user?: string;
  error?: string;
}
export enum IMessageRole {
  USER = 'customer',
  ASSISTANT = 'assistant',
  STARTER = 'starter',
}
