/**
 * @description Component-Banner
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import Frame77 from './assets/Frame77.png';
interface IPropTypes {
  className?: string;
}
const Banner: FC<IPropTypes> = function () {
  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };
  return (
    <div className={`cor-mobilebanner`}>
      <div className="cor-mobilebanner-title">
        <div>
          The <b className="highlight">Leading</b> Customer-Centric AI CX Platform
        </div>
      </div>
      <div className="cor-mobilebanner-subtitle">
        Interpret Multi-Channel Insight
        <br />
        for Accelerated Growth
      </div>
      <div className="cor-mobilebanner-btn" onClick={handleScrollToBottom}>
        See it live
      </div>
      <img className="cor-mobilebanner-img" src={Frame77} />
      <div className="cor-mobilebanner-bottom">
        The first <b>customer-centric</b> intelligent CX platform delivers personalized, real-time interactions that{' '}
        <b>boosts conversions, ROI, and customer satisfaction</b>.  
      </div>
    </div>
  );
};

export default Banner;
