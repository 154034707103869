/**
 * @description Component-ChatContent
 */
import React, { FC, useCallback, useEffect, useRef } from 'react';
import './index.less';

import { useOrg } from '@/features/settings/hooks';
import { IMessage } from '@/types/aigc';
import BotChatItem from './BotChatItem';
import { useBotStatus } from '@/features/aigc/hooks';
import { FETCHING_STATUS } from '@/constants';
import ChatLoading from './ChatLoading';
import { useDebounceFn } from 'ahooks';
interface IPropTypes {
  className?: string;
  data: IMessage[];
  customEmail: string | undefined;
}
const ChatContent: FC<IPropTypes> = function ({ data, customEmail }) {
  const org = useOrg();
  const chatbotStatus = useBotStatus(org?.id, customEmail);
  const containerRef = useRef<HTMLDivElement>(null);
  const { run: scrollToBottom } = useDebounceFn(
    () => {
      containerRef.current &&
        containerRef.current?.scrollTo({ top: containerRef.current.scrollHeight, behavior: 'smooth' });
    },
    { wait: 200 },
  );
  useEffect(() => {
    scrollToBottom();
  }, [data, scrollToBottom]);

  return (
    <div ref={containerRef} className="cor-bot-chat-content">
      {data?.map((d) => (
        <BotChatItem key={d.id} className="cor-bot-chat-contentItem" item={d} />
      ))}
      {chatbotStatus === FETCHING_STATUS.FETCHING && <ChatLoading />}
    </div>
  );
};

export default ChatContent;
