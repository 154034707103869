/**
 * @description Component-InboxGmail
 */
import React, { FC, useState, useRef } from 'react';
import './index.less';
import Chat from '../Chat';
import Chating from '../Chating';
import UserList from './UserList';
import UserSearch from './UserSearch';
import SendMailModal from '@/components/SendMailModal';
import { MAIL_BTN_TYPE } from '@/constants/inbox';
import useResizableSidebar from '@/hooks/useResizableSidebar';
import { useOrg } from '@/features/settings/hooks';
import { useCurrentUserId } from '@/features/inbox/hooks';
interface IPropTypes {
  className?: string;
  isSidebarOpen?: boolean;
}
const InboxGmail: FC<IPropTypes> = function ({ isSidebarOpen = true }) {
  const org = useOrg();
  const currentUserId = useCurrentUserId(org?.id);
  const { sidebarWidth, isResizerVisible, handleMouseDown, handleMouseEnter, handleMouseLeave } = useResizableSidebar();

  return (
    <div className="cor-inbox-gmail-chat">
      <div className={`cor-inbox-gmail-chat-left ${isSidebarOpen ? 'open' : 'closed'}`} style={{ width: sidebarWidth }}>
        <div className="cor-inbox-gmail-chat-left-header">
          <UserSearch /> <SendMailModal type={MAIL_BTN_TYPE.NEW} />
        </div>
        {/* <div className="cor-inbox-gmail-chat-left-search"></div> */}
        <UserList />
        <div
          className={`cor-inbox-gmail-chat-left-resizer ${isResizerVisible ? 'visible' : ''}`}
          onMouseDown={handleMouseDown}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </div>
      <div className="cor-inbox-gmail-chat-right">
        {currentUserId ? <Chating /> : <></>}
        <Chat />
      </div>
    </div>
  );
};

export default InboxGmail;
