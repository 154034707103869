import { memo, useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../hooks';
import { setAuthState, setCurrentToken } from './actions';
import { AUTH_ACCESS_TOKEN } from '@/constants';
import { getOrg } from '../settings/actions';
import { useAuthToken } from './hooks';
import { useOrg } from '../settings/hooks';
import { useDebounceEffect } from 'ahooks';
import { retry } from '@/utils/retry';
function useWatchLogin() {
  const authState = useAuth0();
  const { isAuthenticated, user, isLoading, getAccessTokenSilently, logout } = authState;

  const navigate = useNavigate();
  const location = useLocation();
  const getToken = useCallback(async () => {
    try {
      // check if token is normal everytime
      await retry(getAccessTokenSilently(), { n: 3, minWait: 300, maxWait: 1000 }).promise;
    } catch (e) {
      if (isAuthenticated) {
        await logout({ openUrl: false });
        navigate('/', { replace: true });
      }
    }
  }, [getAccessTokenSilently, isAuthenticated, logout, navigate]);
  useDebounceEffect(
    () => {
      if (!isAuthenticated && !isLoading && location?.pathname?.startsWith('/dashboard')) {
        console.log('🚀 ~ useEffect ~ isLoading:', isLoading, isAuthenticated);
        navigate('/', { replace: true });
      }
    },
    [location, isAuthenticated, navigate, isLoading, user],
    { wait: 800 },
  );
  useEffect(() => {
    getToken();
  }, [getToken]);
}
function useWatchUserToken() {
  const authState = useAuth0();
  const { isAuthenticated, user, isLoading, getIdTokenClaims } = authState;
  const dispatch = useAppDispatch();
  const getUserToken = useCallback(async () => {
    try {
      const token = await getIdTokenClaims();
      if (token) {
        dispatch(setCurrentToken(token.__raw));
        localStorage.setItem(AUTH_ACCESS_TOKEN, token.__raw);
      }
    } catch (e) {
      console.log('🚀 ~ file: GlobalEffect.tsx:27 ~ getUserToken ~ e:', e);
    }
  }, [dispatch, getIdTokenClaims]);
  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      getUserToken();
    }
  }, [getUserToken, isAuthenticated, isLoading, user, dispatch]);
  useEffect(() => {
    authState && dispatch(setAuthState(authState));
  }, [authState, dispatch]);
}
function useGetOrg() {
  const dispatch = useAppDispatch();
  const { isAuthenticated, isLoading } = useAuth0();
  const currentToken = useAuthToken();
  const org = useOrg();
  useEffect(() => {
    if (isAuthenticated && !isLoading && currentToken && !org) {
      dispatch(getOrg());
    }
  }, [isAuthenticated, isLoading, dispatch, currentToken, org]);
}

function AuthGlobalEffect(): null {
  useWatchLogin();
  useGetOrg();
  useWatchUserToken();
  return null;
}

export default memo(AuthGlobalEffect);
