/**
 * @description Component-PostCommentList
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import './index.less';
import {
  useMetaPostById,
  useMetaPostComments,
  usePostCommentsSelected,
  useReplyingComments,
} from '@/features/inbox/hooks';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import { useAppDispatch } from '@/features/hooks';
import { ReplyMetaPostComments, setAigcCommentReplies, setPostCommentSelected } from '@/features/inbox/actions';
import { useOrg } from '@/features/settings/hooks';
import Avatar from 'react-avatar';
import FormattedDate from '@/components/FormattedDate';
import { Checkbox, Button } from 'antd';
import classNames from 'classnames';
import { COMMENT_MODES } from '@/constants/inbox';
import CommentList from '../CommentList';
import { useSocket } from '@/features/socket/hooks';
import { SOCKET_CHANNELS } from '@/constants/sockets';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  postId: string;
}
const PostCommentList: FC<IPropTypes> = function ({ postId }) {
  const org = useOrg();
  const post = useMetaPostById(org?.id, postId);
  const comments = useMetaPostComments(org?.id, postId);
  const dispatch = useAppDispatch();
  const [fetchingReplies, setFetchingReplies] = useState(false);
  const { deviceType } = useMediaQueryDevice();
  const replyingComments = useReplyingComments();
  const postSelectedComments = usePostCommentsSelected(postId);
  const socket = useSocket(SOCKET_CHANNELS.META_IN_ONE);
  const onError = useCallback(() => {
    setFetchingReplies(false);
  }, []);
  const onResponse = useCallback(
    (data) => {
      dispatch(setAigcCommentReplies(data));
      setFetchingReplies(false);
    },
    [dispatch],
  );
  const replySelected = useCallback(() => {
    if (org && comments) {
      const selectedComments = comments.filter((c) => postSelectedComments.includes(c.commentId));
      post && dispatch(ReplyMetaPostComments({ comments: selectedComments, orgId: org?.id, postId: post.id }));
    }
  }, [comments, dispatch, org, post, postSelectedComments]);
  useEffect(() => {
    if (post?.id) {
      socket?.emit('message', {
        postId: post.id,
      });
      setFetchingReplies(true);
    }
  }, [post?.id, socket]);
  useEffect(() => {
    if (socket) {
      socket.on('response', onResponse);
      socket.on('error', () => onError);
    }
    return () => {
      socket && socket.off('response', onResponse);
      socket && socket.off('error', onError);
    };
  }, [dispatch, onError, onResponse, socket]);
  if (!post) {
    return null;
  }
  return (
    <div className={classNames('cor-post-comment-list', deviceType)}>
      <div className={`cor-post-comment-list-header`}>
        <div className="cor-post-comment-list-header-name">
          <Avatar name={post.pageName} size={'36'} src={post.pageName} round />
          <div className="cor-post-comment-list-header-name-right">
            {post.pageName}
            <div className="cor-post-comment-list-header-name-right-bottom">
              <FormattedDate date={post?.createdTime} />
              <span>{post.unrepliedComments} unRelied</span>
              <span>{post.totalComments} comments</span>
            </div>
          </div>
        </div>
        <div className={`cor-post-comment-list-header-content`}>{post.content}</div>
        <div className="cor-post-comment-list-header-btns">
          <Checkbox
            checked={postSelectedComments && comments && comments?.length === postSelectedComments?.length}
            disabled={fetchingReplies || replyingComments}
            onChange={(e) => {
              comments?.forEach((item) => {
                dispatch(
                  setPostCommentSelected({
                    postId: item.metaPagePostId,
                    commentId: item.commentId,
                    selected: e.target.checked,
                  }),
                );
              });
            }}>
            {' '}
            Select all
          </Checkbox>
          <Button
            disabled={!postSelectedComments?.length || fetchingReplies}
            onClick={replySelected}
            loading={replyingComments}
            size="small">
            {' '}
            Reply Selected
          </Button>
        </div>
      </div>
      <CommentList fetchingReplies={fetchingReplies} mode={COMMENT_MODES.EDIT} postId={post.id} />
    </div>
  );
};

export default PostCommentList;
