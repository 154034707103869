/**
 * @description Component-KnowledgeTable
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import { Button, Card, Empty } from 'antd';
import { FETCHING_STATUS } from '@/constants';
import { useAlloy, useShopConnections, useShopConnectionsStatus } from '@/features/shopconnect/hooks';
import { SHOP_TYPES } from '@/constants/shop';
import { ReactComponent as EcoIcon } from './assets/icon_connect_ecom.svg';
import { ReactComponent as ShopifyIcon } from './assets/icon_connect_shopify.svg';
import { ReactComponent as WoocomerceIcon } from './assets/icon_connect_wc.svg';
import { ReactComponent as OpenIcon } from '@/assets/svg/icon_open_new.svg';
import DeleteConnectionButton from './DeleteConnectionButton';
import { useAppDispatch } from '@/features/hooks';
import { getShopConnections } from '@/features/shopconnect/actions';
interface IPropTypes {
  type: SHOP_TYPES;
  className?: string;
}
const ShopConnectionsTable: FC<IPropTypes> = function ({ type }) {
  const status = useShopConnectionsStatus();
  const connections = useShopConnections();
  const alloy = useAlloy();
  const dispatch = useAppDispatch();
  const authCallBack = useCallback(() => {
    console.log('auth success');
    dispatch(getShopConnections());
  }, [dispatch]);
  const addIntegrate = useCallback(
    (appName: SHOP_TYPES) => {
      alloy &&
        alloy.authenticate({
          app: appName,
          callback: authCallBack,
          title: `${appName} Integration`,
        });
    },
    [alloy, authCallBack],
  );
  const shopIconMap = {
    [SHOP_TYPES.SHOPIFY]: <ShopifyIcon />,
    [SHOP_TYPES.WOO_COMMERCE]: <WoocomerceIcon />,
  };

  return (
    <Card
      loading={status !== FETCHING_STATUS.DONE}
      title={
        <>
          <EcoIcon />
          Shops
        </>
      }
      extra={
        !connections.length && (
          <Button type="primary" size="small" icon={<OpenIcon />} onClick={() => addIntegrate(type)}>
            Connect Shop
          </Button>
        )
      }
      className="cor-shop-connections-table-card">
      <div className="cor-shop-connections-table-items">
        {connections.map((connection) => (
          <div key={connection.connectionId} className="cor-shop-connections-table-items-item">
            <div className="cor-shop-connections-table-items-item-name">
              {shopIconMap[connection.app as SHOP_TYPES]} {connection.name}
            </div>
            <DeleteConnectionButton connection={connection} />
          </div>
        ))}
        {!connections.length && <Empty description="No connections" />}
      </div>
    </Card>
  );
};

export default ShopConnectionsTable;
