/**
 * @description Component-TextDisplay
 */
import React, { FC } from 'react';
import './index.less';
interface IPropTypes {
  content: string;
  className?: string;
}
const TextDisplay: FC<IPropTypes> = function ({ content }) {
  return (
    <pre
      className="cor-text-display"
      dangerouslySetInnerHTML={{
        __html: content.replace(/(<? *script)/gi, 'illegalscript') || '',
      }}></pre>
  );
};

export default TextDisplay;
