/**
 * @description Component-Stats
 */
import React, { FC } from 'react';
import './index.less';
import moment from 'moment';
import CountUp from 'react-countup';
interface IPropTypes {
  className?: string;
}
const Stats: FC<IPropTypes> = function () {
  return (
    <div className="cor-stats">
      <div className="cor-stats-title">
        From <span className="date">{moment().subtract(10, 'days').format('MM-DD')} </span> to{' '}
        <span className="date">{moment().format('MM-DD')}</span>
      </div>
      <div className="cor-stats-container">
        <div className="cor-stats-container-row">
          <div className="cor-stats-container-row-item">
            <div className="cor-stats-container-row-item-number">
              $<CountUp start={0} decimals={0} end={1000000} />
            </div>
            <div className="cor-stats-container-row-item-title">Revenue</div>
          </div>
          <div className="cor-stats-container-row-item">
            <div className="cor-stats-container-row-item-number">
              $<CountUp start={0} decimals={0} end={413000} />
            </div>
            <div className="cor-stats-container-row-item-title">Revenue from Live Chat</div>
          </div>
        </div>
        <div className="cor-stats-container-row">
          <div className="cor-stats-container-row-item">
            <div className="cor-stats-container-row-item-number">
              <CountUp start={0} end={25014} />
            </div>
            <div className="cor-stats-container-row-item-title">Live Chats</div>
          </div>
          <div className="cor-stats-container-row-item">
            <div className="cor-stats-container-row-item-number">
              <CountUp start={0} end={3336} />
            </div>
            <div className="cor-stats-container-row-item-title"> Email Replies</div>
          </div>
          <div className="cor-stats-container-row-item">
            <div className="cor-stats-container-row-item-number">
              <CountUp start={0} end={1286} />
            </div>
            <div className="cor-stats-container-row-item-title">Meta Replies</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
