/**
 * @description Component-ChatReplyBox
 */
import React, { FC, useCallback, useEffect, useRef } from 'react';
import {
  Button,
  Input,
  // Radio, RadioChangeEvent
} from 'antd';
import { ReactComponent as SendOutlined } from '@/assets/svg/icon_btnsend.svg';

import { useAppDispatch } from '@/features/hooks';
import {
  useOrg,
  // useTemplates
} from '@/features/settings/hooks';
import {
  useAigcCommentReply,
  useAigcReplyResponse,
  useCurrentMetaText,
  useReplyingComment,
} from '@/features/inbox/hooks';

import './index.less';
import { ReplyMetaPostComment, setAigcReplyResponse, setInboxMetaCurrentText } from '@/features/inbox/actions';
import { SOCKET_CHANNELS } from '@/constants/sockets';
import { useSocket } from '@/features/socket/hooks';
import { ISocketReplyResponse } from '@/types/socket';
import { FETCHING_STATUS } from '@/constants';
import { toast } from 'react-toastify';
import { IMetaComment } from '@/types/inbox';

interface IPropTypes {
  className?: string;
  comment: IMetaComment | undefined;
  formId: string | undefined;
}

const MetaReplyBox: FC<IPropTypes> = function ({ comment, formId }) {
  const dispatch = useAppDispatch();
  const org = useOrg();
  const replyCommentStatus = useReplyingComment(comment?.id);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const socket = useSocket(SOCKET_CHANNELS.META);
  const currentText = useCurrentMetaText(org?.id, comment?.commentId);
  const aigcReplyResponse = useAigcReplyResponse(comment?.commentId);
  const aigcReply = useAigcCommentReply(comment?.commentId);

  const inputMessage = (messageText: string) => {
    org &&
      comment &&
      dispatch(
        setInboxMetaCurrentText({
          commentId: comment?.commentId,
          orgId: org.id,
          text: messageText,
        }),
      );
  };

  const dispatchSendMessage = useCallback(() => {
    dispatch(
      ReplyMetaPostComment({
        orgId: org?.id,
        id: comment?.id,
        postId: comment?.metaPagePostId,
        commentId: comment?.commentId,
        message: currentText,
      }),
    );
  }, [dispatch, org, currentText, comment]);

  const scrollToBottom = useCallback(() => {
    containerRef.current &&
      containerRef.current?.scrollTo({ top: containerRef.current.scrollHeight, behavior: 'smooth' });
  }, []);
  const onResponse = useCallback(
    (data: ISocketReplyResponse<string>) => {
      if (data.error) {
        toast.error(data.error);
        org &&
          comment &&
          dispatch(
            setAigcReplyResponse({
              response: '',
              commentId: comment.commentId,
              status: FETCHING_STATUS.DONE,
            }),
          );
        return;
      }
      data &&
        dispatch(
          setAigcReplyResponse({
            commentId: data.commentId,
            response: data.data || '',
            status: data.status ? FETCHING_STATUS.DONE : FETCHING_STATUS.FETCHING,
          }),
        );
      scrollToBottom();
      textareaRef.current?.focus();
    },
    [dispatch, scrollToBottom, org, comment],
  );

  useEffect(() => {
    if (replyCommentStatus) {
      return;
    }
    org &&
      comment &&
      dispatch(
        setInboxMetaCurrentText({
          commentId: comment?.commentId,
          orgId: org.id,
          text: '',
        }),
      );
  }, [dispatch, org, comment, replyCommentStatus]);

  useEffect(() => {
    org &&
      comment &&
      dispatch(
        setInboxMetaCurrentText({
          commentId: comment.commentId,
          orgId: org.id,
          text: aigcReplyResponse,
        }),
      );
  }, [dispatch, org, aigcReplyResponse, comment]);

  useEffect(() => {
    org &&
      comment &&
      dispatch(
        setInboxMetaCurrentText({
          commentId: comment.commentId,
          orgId: org.id,
          text: aigcReply,
        }),
      );
  }, [dispatch, org, aigcReply, comment, formId]);

  useEffect(() => {
    if (socket) {
      socket.on('response', onResponse);
    }
    return () => {
      socket && socket.off('response', onResponse);
    };
  }, [dispatch, comment, onResponse, socket]);

  return (
    <div className="cor-chat-reply-meta-box">
      <div className="cor-chat-reply-meta-box-input-container">
        <div className="header">
          <div className="header-left">Replying: {comment?.from?.name}</div>
          <div className="btns">
            {/* <Button
              type="primary"
              className="normal-btn generate"
              onClick={generateResponse}
              loading={gettingAigcReplyResponse}>
              Generate Response
              {gettingAigcReplyResponse ? <></> : <RobotOutlined />}
            </Button> */}
            <Button
              type="text"
              className="normal-btn bluesend"
              disabled={!currentText}
              loading={replyCommentStatus}
              onClick={dispatchSendMessage}>
              {replyCommentStatus ? <></> : <SendOutlined />}
            </Button>
          </div>
        </div>
        <div className="textarea">
          <div className="top" ref={containerRef}>
            <Input.TextArea
              ref={textareaRef}
              value={currentText}
              onChange={(e) => inputMessage(e.target.value)}
              autoSize={{ minRows: 5 }}
              placeholder="Type here or Click Generate Response to reply"
              bordered
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetaReplyBox;
