/**
 * @description Component-NewKnowledgeTable
 */
import React, { FC, useMemo, useState } from 'react';
import './index.less';
import { Card, Table, Typography } from 'antd';
import { matchSorter } from 'match-sorter';
import ActionNewKnowledge from './ActionNewKnowledge';
import { useNewKnowledgeChunks, useOrg, useNewKnowledgeChunksStatus } from '@/features/settings/hooks';
import { INewKnowledgeBase } from '@/types/settings';
import DeleteNewBtn from './DeleteNewBtn';
import EditNewBtn from './EditNewBtn';
import { DATE_FORMAT, FETCHING_STATUS } from '@/constants';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
import { ReactComponent as EditIcon } from '@/assets/svg/icon_edit_linear.svg';
import _ from 'lodash';
import { useDebounceFn } from 'ahooks';
import MDEditor from '@uiw/react-md-editor';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const NewKnowledgeTable: FC<IPropTypes> = function () {
  const org = useOrg();
  const status = useNewKnowledgeChunksStatus(org?.id);
  const chunks = useNewKnowledgeChunks(org?.id);
  const [search, setSearch] = useState('');

  const { run: changeInput } = useDebounceFn(
    (str: string) => {
      setSearch(str);
    },
    { wait: 200 },
  );

  const matchSearchChunks = useMemo(() => {
    if (search) {
      const list = matchSorter(Object.values(chunks), search, {
        keys: ['content'],
        threshold: matchSorter.rankings.ACRONYM, // https://www.npmjs.com/package/match-sorter?activeTab=readme
      });
      return _.orderBy(_.values(_.keyBy(list, 'id')), ['updatedAt'], ['desc']);
    }
    return chunks;
  }, [chunks, search]);

  const columDefs: ColumnType<INewKnowledgeBase>[] = useMemo(
    () => [
      // {
      //   title: 'Id',
      //   key: 'id',
      //   align: 'left',
      //   ellipsis: true,
      //   width: 60,
      //   render: (_value: any, record: INewKnowledgeBase, index: number) => index + 1,
      // },
      {
        title: 'Knowledge content',
        key: 'content',
        align: 'left',
        ellipsis: true,
        render: (_value: any, record: INewKnowledgeBase) => record.content,
      },
      {
        title: 'Update Time',
        key: 'updated',
        align: 'center',
        width: 100,
        render: (_value: any, record: INewKnowledgeBase) => moment(record.updatedAt).format(DATE_FORMAT),
      },
      {
        title: 'Action',
        key: 'action',
        align: 'right',
        width: 100,
        render: (_value: any, record: INewKnowledgeBase) => (
          <div className="cor-knowledge-base-actions">
            <EditNewBtn title="Edit" type="text" icon={<EditIcon />} knowledge={record} />
            <DeleteNewBtn org={org?.id} knowledge={record} />
          </div>
        ),
      },
    ],
    [org?.id],
  );

  const pagination = useMemo(() => {
    return {
      total: matchSearchChunks.length,
      showTotal: (total: any) => `Total ${total} items`,
    };
  }, [matchSearchChunks]);
  const rowClassName = () => 'custom-table-row';
  return (
    <Card
      bordered={false}
      title={<Typography.Title level={4}>Document List</Typography.Title>}
      extra={<ActionNewKnowledge onInputChange={changeInput} />}
      className="cor-knowledge-table">
      <Table
        loading={status === FETCHING_STATUS.FETCHING}
        rowKey={(record) => record?.id + record?.content?.slice(0, 40)}
        columns={columDefs}
        dataSource={matchSearchChunks}
        pagination={pagination}
        rowClassName={rowClassName}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (record) => (
            <MDEditor.Markdown style={{ margin: 0, padding: '0 20px 0 50px' }} source={record.content} />
          ),
        }}
      />
    </Card>
  );
};

export default NewKnowledgeTable;
