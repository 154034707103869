/**
 * @description Component-CommentListSkeleton
 */
import React, { FC } from 'react';
import './index.less';
import { Skeleton } from 'antd';
interface IPropTypes {
  className?: string;
}
const CommentListSkeleton: FC<IPropTypes> = function () {
  const items = new Array(3).fill(0);
  return (
    <div className="cor-comment-list-skeleton">
      {items.map((_, index) => (
        <div key={index} className="cor-comment-list-skeleton-item">
          <Skeleton.Avatar size={32} active={true} />
          <Skeleton.Button active={true} />
        </div>
      ))}
    </div>
  );
};

export default CommentListSkeleton;
