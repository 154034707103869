/**
 * @description Component-HeaderDropDown
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import { useAuth0 } from '@auth0/auth0-react';
import { Dropdown, DropdownProps } from 'antd';

import Avatar from 'react-avatar';
interface IPropTypes extends DropdownProps {
  className?: string;
}
const HeaderDropDown: FC<IPropTypes> = function ({ placement }) {
  const { user, logout } = useAuth0();

  const render = useCallback(
    () => (
      <div className="cor-header-drop-msg-render">
        <div className="cor-header-drop-msg-render-item" onClick={async () => await logout({})}>
          logout
        </div>
      </div>
    ),
    [logout],
  );
  return (
    <Dropdown placement={placement} dropdownRender={render} trigger={['click']}>
      <div className="cor-header-drop-msg">
        <Avatar round size="32" src={user?.picture} name={user?.name} />
        <div className="cor-header-drop-msg-data">
          <div className="cor-header-drop-msg-data-text1">{user?.name}</div>
          <div className="cor-header-drop-msg-data-text2">{user?.email}</div>
        </div>
      </div>
    </Dropdown>
  );
};

export default HeaderDropDown;
