import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import mailSlice from './mail/slice';
import settingsSlice from './settings/slice';
import authSlice from './auth/slice';
import userSlice from './user/slice';
import inboxSlice from './inbox/slice';
import aigcSlice from './aigc/slice';
import brandSlice from './brandvoice/slice';
import shopSlice from './shopconnect/slice';
import socketSlice from './socket/slice';
import pluginSlice from './plugin/slice';
import customerSlice from './customers/slice';
import analysisSlice from './analysis/slice';
import demoSlice from './demo/slice';
import teamSlice from './team/slice';
import subscriptionSlice from './subscription/slice';
import commentSlice from './comment/slice';
import { JWTExpiredMiddleware } from './middlewares';
export const store = configureStore({
  reducer: {
    email: mailSlice,
    settings: settingsSlice,
    auth: authSlice,
    inbox: inboxSlice,
    aigc: aigcSlice,
    user: userSlice,
    socket: socketSlice,
    brand: brandSlice,
    shop: shopSlice,
    plugin: pluginSlice,
    customer: customerSlice,
    analysis: analysisSlice,
    demo: demoSlice,
    team: teamSlice,
    subscription: subscriptionSlice,
    comment:commentSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(JWTExpiredMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>;
