/**
 * @description Component-EditTicket
 */
import React, { FC, useCallback, useMemo, useState } from 'react';
import './index.less';
import { Button, Form, Input, Modal, Select } from 'antd';
import { useAppDispatch } from '@/features/hooks';
import { useOrg } from '@/features/settings/hooks';
import { ITicket } from '@/types/plugin';
import { ReactComponent as CloseIcon } from '@/pages/Dashboard/assets/close.svg';
import FormItem from 'antd/es/form/FormItem';
import { createTicket, updateTicket } from '@/features/plugin/actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useTicketSavingStatus } from '@/features/plugin/hooks';
import { FETCHING_STATUS } from '@/constants';
import { TICKET_PRIORITY, TICKET_STATUS } from '@/constants/Tickets';
import { useTeamMembers } from '@/features/team/hooks';
import { MEMBER_STATUS } from '@/constants/team';

interface IPropTypes {
  className?: string;
  text: string;
  title: string;
  icon: React.ReactNode;
  type?: 'primary' | 'text' | 'ghost';
  ticketToEdit?: ITicket;
  initialValues?: Partial<ITicket>;
}
const EditTicket: FC<IPropTypes> = function ({ text, title, icon, type = 'primary', ticketToEdit, initialValues }) {
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const org = useOrg();
  const savingStatus = useTicketSavingStatus(org?.id);
  const { user } = useAuth0();
  const members = useTeamMembers(org?.id);
  const membersFiltered = useMemo(
    () => members,
    // ?.filter((m) => m.status !== MEMBER_STATUS.PENDING)
    [members],
  );

  const [form] = Form.useForm();
  const onSaveTicket = useCallback(
    async (values: ITicket & { assignees: string[] }) => {
      try {
        if (org && user?.email) {
          if (ticketToEdit) {
            await dispatch(
              updateTicket({
                ...ticketToEdit,
                ...values,
                orgId: org.id,
                assignees: values?.assignees?.join(',') || '',
              }),
            ).unwrap();
          } else {
            await dispatch(
              createTicket({
                ...values,
                trackId: org?.teams[0].trackId,
                source: 'web',
                type: 'manual',
                merchantEmail: user?.email,
                orgId: org.id,
                assignees: values?.assignees?.join(',') || '',
              }),
            ).unwrap();
          }
          setModalOpen(false);
        }
      } catch (e) {
        console.log('🚀 ~ e', e);
      }
    },
    [dispatch, org, ticketToEdit, user?.email],
  );
  const onClickAdd = useCallback(() => {
    setModalOpen(true);
    form.resetFields();
    form.setFieldsValue({
      ...ticketToEdit,
      priority: ticketToEdit?.priority || TICKET_PRIORITY.MEDIUM,
      ...initialValues,
    });
  }, [form, initialValues, ticketToEdit]);

  return (
    <div className="cor-edit-ticket">
      <Button size="small" type={type} icon={icon} onClick={onClickAdd}>
        {text}
      </Button>
      <Modal
        footer={false}
        title={title}
        open={modalOpen}
        width={640}
        centered
        className="cor-edit-ticket-modal"
        closeIcon={<CloseIcon />}
        onCancel={() => setModalOpen(false)}
        onOk={() => setModalOpen(false)}>
        <Form form={form} onFinish={onSaveTicket}>
          <FormItem rules={[{ required: true, message: 'Please enter a title' }]} label="Title" name="title">
            <Input />
          </FormItem>
          <FormItem
            rules={[{ type: 'email', message: 'Please enter an valid customer email' }]}
            label="Customer Email"
            name="customerEmail">
            <Input />
          </FormItem>
          <FormItem
            rules={[{ required: true, message: 'Please enter a description' }]}
            label="Description"
            name="content">
            <Input.TextArea rows={5} />
          </FormItem>
          <FormItem label="Assign to " name="assignees">
            <Select mode="multiple" style={{ width: '100%' }}>
              {membersFiltered?.map((member) => (
                <Select.Option key={member.email} value={member.email}>
                  {member.email}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem rules={[{ required: true }]} label="Priority" name="priority">
            <Select>
              <Select.Option value={TICKET_PRIORITY.HIGH}>High</Select.Option>
              <Select.Option value={TICKET_PRIORITY.MEDIUM}>Medium</Select.Option>
              <Select.Option value={TICKET_PRIORITY.LOW}>Low</Select.Option>
            </Select>
          </FormItem>
          {ticketToEdit && (
            <FormItem rules={[{ required: true }]} label="Status" name="status">
              <Select>
                <Select.Option value={TICKET_STATUS.PENDING}>Pending</Select.Option>
                <Select.Option value={TICKET_STATUS.PROCESSING}>Processing</Select.Option>
                <Select.Option value={TICKET_STATUS.Completed}>Completed</Select.Option>
              </Select>
            </FormItem>
          )}

          <Form.Item>
            <Button
              loading={savingStatus === FETCHING_STATUS.FETCHING}
              disabled={savingStatus === FETCHING_STATUS.FETCHING}
              className="cor-save"
              type="primary"
              htmlType="submit">
              {ticketToEdit ? 'Save' : 'Add'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditTicket;
