/**
 * @description Component-ProductSubscribe
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import './index.less';
import { Button } from 'antd';
// import { Stripe, loadStripe } from '@stripe/stripe-js';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch } from '@/features/hooks';
import { subscribe } from '@/features/user/action';

// let stripePromise: Stripe | PromiseLike<Stripe | null> | null;

// const getStripe = () => {
//   if (!stripePromise) {
//     stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');
//   }

//   return stripePromise;
// };
interface IPropTypes {
  className?: string;
  priceId: string;
}
const ProductSubscribe: FC<IPropTypes> = function ({ priceId }) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [checkoutInited, setCheckoutInited] = useState(false);
  const dispatch = useAppDispatch();
  // const checkoutOptions = useMemo(
  //   () => ({
  //     lineItems: [{ price: priceId, quantity: 1 }],
  //     successUrl: `${window.location.origin}/success`,
  //     cancelUrl: `${window.location.origin}/cancel`,
  //     mode: 'subscription',
  //     customerEmail: user?.email,
  //   }),
  //   [priceId, user?.email],
  // );
  const redirectToCheckout = useCallback(async () => {
    console.log('redirectToCheckout');
    if (isAuthenticated) {
      // const stripe = await getStripe();
      setCheckoutInited(false);
      dispatch(
        subscribe({
          priceId,
          success_url: `${window.location.origin}/success`,
          cancel_url: `${window.location.origin}/cancel`,
        }),
      );
      // const res = await stripe?.redirectToCheckout(checkoutOptions as RedirectToCheckoutClientOptions);
      // if (res?.error) {
      //   console.log('🚀 ~ file: index.tsx:47 ~ redirectToCheckout ~ error:', res.error);
      // }
    } else {
      setCheckoutInited(true);
      await loginWithRedirect();
    }
  }, [dispatch, isAuthenticated, loginWithRedirect, priceId]);
  useEffect(() => {
    if (checkoutInited) {
      redirectToCheckout();
    }
  }, [checkoutInited, redirectToCheckout]);

  return (
    <div className="cor-product-subscribe">
      <Button block onClick={redirectToCheckout}>
        Subscribe
      </Button>
    </div>
  );
};

export default ProductSubscribe;
