/**
 * @description Component-MailSettingCard
 */
import React, { FC, useCallback, useMemo, useState } from 'react';
import './index.less';
import { Button, Popconfirm, Skeleton, Switch } from 'antd';
import { useAppDispatch } from '@/features/hooks';
import { useOrg, useMailSettings, useMailSettingsStatus, useAutoReplySettings } from '@/features/settings/hooks';
import { FETCHING_STATUS } from '@/constants';
import DeleteBtn from './DeleteBtn';
import { getGmailAuthorizeUrl, getOutlookAuthorizeUrl, updateAutoReplySetting } from '@/features/settings/actions';
import { ReactComponent as OpenIcon } from '@/assets/svg/icon_open_new.svg';
import Gmail from '@/assets/images/logo_gmail_24.png';
import Outlook from '@/assets/svg/icon_outlook.svg';
import { ReactComponent as MailIcon } from './assets/icon_connect_mail.svg';
import { MAIL_SETTING_NAMES } from '@/constants/settings';
import _ from 'lodash';
interface IPropTypes {
  className?: string;
}
const MailSettingCard: FC<IPropTypes> = function () {
  const org = useOrg();
  const dispatch = useAppDispatch();
  const [mailSettings] = useMailSettings(org?.id);
  const mailStatus = useMailSettingsStatus(org?.id);
  const [switching, setSwitching] = useState(false);
  const autoReplySettings = useAutoReplySettings(org?.id);
  const mailIconMap = useMemo(
    () => ({
      [MAIL_SETTING_NAMES.GMAIL]: Gmail,
      [MAIL_SETTING_NAMES.OUTLOOK]: Outlook,
    }),
    [],
  );
  const authorizeGoogle = useCallback(async () => {
    const url = await dispatch(getGmailAuthorizeUrl({ org: org?.id })).unwrap();
    window.location.href = url;
  }, [dispatch, org]);

  const authorizeOutlook = useCallback(async () => {
    const url = await dispatch(getOutlookAuthorizeUrl({ org: org?.id })).unwrap();
    window.location.href = url;
  }, [dispatch, org]);
  const settingsMap = useMemo(
    () => ({
      [MAIL_SETTING_NAMES.GMAIL]: {
        icon: Gmail,
        label: 'Gmail',
        key: MAIL_SETTING_NAMES.GMAIL,
        button: (
          // <Popconfirm
          //   onConfirm={authorizeGoogle}
          //   okText="Accept"
          //   cancelText="Cancel"
          //   title="Your anonymized data may be shared with our third-part Large Language Learning Model partners.">
          <Button onClick={authorizeGoogle} size="small">
            Connect <OpenIcon />
          </Button>
          // </Popconfirm>
        ),
      },
      [MAIL_SETTING_NAMES.OUTLOOK]: {
        icon: Outlook,
        label: 'Outlook',
        key: MAIL_SETTING_NAMES.OUTLOOK,
        button: (
          // <Popconfirm
          //   onConfirm={authorizeOutlook}
          //   okText="Accept"
          //   cancelText="Cancel"
          //   title="Your anonymized data may be shared with our third-part Large Language Learning Model partners.">
          <Button onClick={authorizeOutlook} size="small">
            Connect <OpenIcon />
          </Button>
          // </Popconfirm>
        ),
      },
    }),
    [authorizeGoogle, authorizeOutlook],
  );
  const switchAutoReply = useCallback(
    async (enable: boolean) => {
      try {
        if (org) {
          setSwitching(true);
          await dispatch(
            updateAutoReplySetting({
              orgId: org?.id,
              settings: {
                ...autoReplySettings,
                mail: {
                  enable,
                },
              },
            }),
          );
          setSwitching(false);
        }
      } catch (e) {
        setSwitching(false);
      }
    },
    [autoReplySettings, dispatch, org],
  );
  return (
    <div className="cor-connections-card">
      <div className="cor-connections-card-head">
        <div className="cor-connections-card-title">
          <MailIcon />
          Email
        </div>
      </div>
      <ul className="cor-connections-card-items">
        {mailStatus === FETCHING_STATUS.DONE ? (
          mailSettings ? (
            <li className="cor-connections-card-item">
              <div className="cor-connections-card-item-left">
                <div className="cor-connections-card-item-left-desc">
                  {' '}
                  <img width={32} height={32} src={mailIconMap[mailSettings.name]} />
                  {mailSettings.user}
                </div>
              </div>
              <div className="cor-connections-card-item-right">
                <div>
                  <Switch loading={switching} onChange={switchAutoReply} checked={autoReplySettings?.mail?.enable} />{' '}
                  Auto Reply
                </div>
                <div className="cor-connections-card-item-right-divider" />
                <DeleteBtn className="cor-connections-card-delete-btn" org={org?.id} setting={mailSettings} />
              </div>
            </li>
          ) : (
            _.values(settingsMap).map((setting) => (
              <li className="cor-connections-card-item" key={setting.key}>
                <div>
                  <img src={setting.icon} height={32} width={32} loading="lazy" /> {setting.label}
                </div>
                {setting.button}
              </li>
            ))
          )
        ) : (
          <Skeleton active title={false} />
        )}
      </ul>
    </div>
  );
};

export default MailSettingCard;
