/**
 * @description Component-ChatContentItem
 */
import React, { FC, useCallback } from 'react';
import Avatar from 'react-avatar';

import { IMail } from '@/types/email';
import { useOrg } from '@/features/settings/hooks';
import { useCurrentUserId, useEmailRenderStatus, useUserById } from '@/features/inbox/hooks';
import { useAppDispatch } from '@/features/hooks';
import { setEmailRender } from '@/features/inbox/actions';
import ContentComponent from './overflow';

import './index.less';

interface IPropTypes {
  className?: string;
  item: IMail;
}

const ChatContentItem: FC<IPropTypes> = function ({ item }) {
  const dispatch = useAppDispatch();
  const org = useOrg();
  const userId = useCurrentUserId(org?.id);
  const user = useUserById(org?.id, userId);
  const renderStatus = useEmailRenderStatus();

  const switchItemRender = useCallback(
    (item: IMail, render: string) => {
      dispatch(
        setEmailRender({
          mailId: item.id,
          render,
        }),
      );
    },
    [dispatch],
  );
  return (
    <div className={`cor-chat-content-item ${item.type || 'email'} ${item.from !== user?.from ? 'Response' : ''}`}>
      <Avatar className="ant-avatar" round size="32" src={item?.avatar} name={item?.fromName || item?.from} />
      <div className="cor-chat-content-item-content">
        <ContentComponent
          isHtml={renderStatus[item.id] === 'html'}
          isFrom={item.from === user?.from}
          data={item}
          onSwitchItemRender={switchItemRender}
        />
      </div>
    </div>
  );
};

export default ChatContentItem;
