import { KNOWLEDGEBASE_CATEGORY } from '@/constants/settings';
import {
  IKnowledgeBase,
  IMailSetting,
  IMailSettingResponse,
  IMailSignature,
  IOrganization,
  IRequiredKnowledgeBase,
  IResponse,
  ITemplate,
  ISocialSetting,
  IAutoReplySettings,
  INewKnowledgeBase,
  IupdateWebpage
} from '@/types/settings';
import { axiosGet, axiosPost } from '@/utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { resetMeta } from '../inbox/actions';

export const getOrg = createAsyncThunk('settings/getOrg', async (): Promise<IOrganization | undefined> => {
  try {
    const { data } = await axiosGet(process.env.REACT_APP_API_ROOT + `organization/`);
    return data.data;
  } catch (e) {
    console.log('🚀 ~ file: actions.ts:18 ~ getOrg ~ e:', e);

    throw e;
  }
});
export const saveOrg = createAsyncThunk(
  'settings/saveOrg',
  async ({ name }: { name: string }): Promise<string | undefined> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `organization`, { name });
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:30 ~ e:', e);
      throw e;
    }
  },
);
export const getOrgKey = createAsyncThunk(
  'settings/getOrgKey',
  async ({}: { org: string }): Promise<string | undefined> => {
    try {
      const { data } = await axiosGet(process.env.REACT_APP_API_ROOT + `organization/openai`);
      return data.key;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const saveOrgKey = createAsyncThunk(
  'settings/saveOrgKey',
  async ({ openaiApiKey }: { openaiApiKey: string }): Promise<string | undefined> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `organization/openai`, {
        openaiApiKey,
      });
      return data.key;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const getOrgMailSettings = createAsyncThunk(
  'settings/getOrgMailSettings',
  async ({}: { org: string }): Promise<Record<string, IMailSetting> | undefined> => {
    try {
      const { data } = await axiosGet(process.env.REACT_APP_API_ROOT + `mail/settings`);
      return _.keyBy(data.data, 'id');
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const saveOrgMailSettings = createAsyncThunk(
  'settings/saveOrgMailSettings',
  async ({ imap, org }: { imap: IMailSetting; org: string }, { dispatch }): Promise<IMailSettingResponse> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `mail/settings`, {
        ...imap,
        secure: true,
      });
      org && dispatch(getOrgMailSettings({ org }));
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const deleteOrgMailSettings = createAsyncThunk(
  'settings/deleteOrgMailSettings',
  async (
    { setting, org }: { setting: IMailSetting; org: string | undefined },
    { dispatch },
  ): Promise<IResponse<any>> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `mail/settings/delete`, {
        ...setting,
      });
      org && dispatch(getOrgMailSettings({ org }));
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);

export const getKnowledgeBases = createAsyncThunk(
  'settings/getKnowledgeBases',
  async ({}: { org: string }): Promise<Record<string, IKnowledgeBase[]> | undefined> => {
    try {
      const { data } = await axiosGet(process.env.REACT_APP_API_ROOT + `vectorstore/knowledgebase`);
      return _.keyBy(data.data, 'id');
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);

export const getNewKnowledgeBases = createAsyncThunk(
  'settings/getNewKnowledgeBases',
  async ({}: {
    org: string;
  }): Promise<{
    knowledgeData: INewKnowledgeBase | undefined;
    knowledgeChunksData: Record<string, INewKnowledgeBase[]> | undefined;
  }> => {
    try {
      const { data: knowledgeChunksData } = await axiosGet(
        process.env.REACT_APP_API_ROOT + `knowledge/chunks?pageSize=10000`,
      );
      const { data: knowledgeData } = await axiosGet(process.env.REACT_APP_API_ROOT + `knowledge`);
      return {
        knowledgeChunksData: _.keyBy(knowledgeChunksData.data, 'id'),
        knowledgeData: knowledgeData.data,
      };
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getNewKnowledgeBases ~ (e:', e);
      throw e;
    }
  },
);

export const getDataSource = createAsyncThunk(
  'settings/getDataSource',
  async ({ id }: { org: string; id: string }): Promise<IResponse<IKnowledgeBase>> => {
    try {
      const { data } = await axiosGet(
        process.env.REACT_APP_API_ROOT + `vectorstore/datasource_knowledgebase/get?id=${id}`,
      );
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const getRequiredKnowledgeBase = createAsyncThunk(
  'settings/getRequiredKnowledgeBase',
  async ({}: { org: string }): Promise<IResponse<IRequiredKnowledgeBase>> => {
    try {
      const { data } = await axiosGet(process.env.REACT_APP_API_ROOT + `vectorstore/knowledgebase/required`);
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getRequiredKnowledgeBase ~ (e:', e);
      throw e;
    }
  },
);

export const saveKnowledgeBase = createAsyncThunk(
  'settings/saveKnowledgeBases',
  async ({ knowledge }: { org: string; knowledge: IKnowledgeBase }): Promise<IKnowledgeBase | undefined> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `vectorstore/datasource_knowledgebase`, {
        ...knowledge,
      });
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);

export const saveNewKnowledgeChunks = createAsyncThunk(
  'settings/saveNewKnowledgeChunks',
  async ({
    knowledgeId,
    content,
  }: {
    org: string;
    knowledgeId?: string;
    content: string;
  }): Promise<INewKnowledgeBase | undefined> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `knowledge/chunks`, {
        knowledgeId,
        content,
      });
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);

export const updateKnowledgeBase = createAsyncThunk(
  'settings/updateKnowledgeBase',
  async (
    { knowledge, org }: { org: string; knowledge: IKnowledgeBase },
    { dispatch },
  ): Promise<IKnowledgeBase | undefined> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `vectorstore/datasource_knowledgebase/update`, {
        ...knowledge,
      });
      if (knowledge.category !== KNOWLEDGEBASE_CATEGORY.OTHERS) {
        dispatch(getRequiredKnowledgeBase({ org }));
      } else {
        dispatch(getKnowledgeBases({ org }));
      }
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);

export const updateNewKnowledgeChunks = createAsyncThunk(
  'settings/updateNewKnowledgeChunks',
  async (
    { knowledge, org }: { org: string; knowledge: INewKnowledgeBase },
    { dispatch },
  ): Promise<INewKnowledgeBase | undefined> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `knowledge/chunks/update`, {
        ...knowledge,
      });
      // dispatch(getNewKnowledgeBases({ org }));
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);

export const deleteKnowledgeBase = createAsyncThunk(
  'settings/deleteKnowledgeBase',
  async ({ id }: { org: string | undefined; id: string }): Promise<IResponse<boolean>> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `vectorstore/knowledgebase/delete`, {
        id,
      });
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);

export const deleteNewKnowledgeBase = createAsyncThunk(
  'settings/deleteNewKnowledgeBase',
  async ({ id }: { org: string | undefined; id: string }): Promise<IResponse<boolean>> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `knowledge/chunks/delete`, {
        id,
      });
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);

export const getUploadPresign = createAsyncThunk('settings/getUploadPresign', async (): Promise<string> => {
  try {
    const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `s3/presign`, { type: 'put' });
    return data.data.url;
  } catch (e) {
    console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
    throw e;
  }
});
export const parseUploadedFile = createAsyncThunk(
  'settings/parseUploadedFile',
  async ({ url, ext }: { url: string; ext: string }): Promise<string> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `s3/file/parse`, { url, ext });
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);

export const uploadKnowledgeFile = createAsyncThunk(
  'settings/uploadKnowledgeFile',
  async ({ name, content, org }: { name: string; content: string; org: string }, { dispatch }): Promise<string> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `knowledge`, { name, content });
      dispatch(getNewKnowledgeBases({ org }));
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);

export const getSignature = createAsyncThunk(
  'settings/getSignature',
  async ({}: { org: string | undefined }): Promise<IResponse<Record<string, IMailSignature>>> => {
    try {
      const { data } = await axiosGet(process.env.REACT_APP_API_ROOT + `mail/signature`);
      return { ...data, data: _.keyBy(data.data, 'id') };
    } catch (e) {
      console.log('🚀 ~ file: actions.ts ~ getSignature ~ (e:', e);
      throw e;
    }
  },
);
export const saveSignature = createAsyncThunk(
  'settings/saveSignature',
  async ({ signature }: { org: string | undefined; signature: IMailSignature }): Promise<IResponse<IMailSignature>> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `mail/save_signature`, {
        ...signature,
      });
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts ~ saveSignature ~ (e:', e);
      throw e;
    }
  },
);
export const deleteSignature = createAsyncThunk(
  'settings/deleteSignature',
  async ({ id }: { org: string | undefined; id: string }): Promise<IResponse<boolean>> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `mail/signature/delete`, {
        id,
      });
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions ~ deleteSignature ~ (e:', e);
      throw e;
    }
  },
);
export const getTemplate = createAsyncThunk(
  'settings/getTemplate',
  async ({}: { org: string | undefined }): Promise<IResponse<Record<string, ITemplate>>> => {
    try {
      const { data } = await axiosGet(process.env.REACT_APP_API_ROOT + `llm/prompt_template`);
      return { ...data, data: _.keyBy(data.data, 'id') };
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const saveTemplate = createAsyncThunk(
  'settings/saveTemplate',
  async ({ template }: { org: string | undefined; template: ITemplate }): Promise<IResponse<ITemplate>> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `llm/prompt_template`, {
        ...template,
      });
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const perfectPrompt = createAsyncThunk(
  'settings/perfectPrompt',
  async ({ prompt }: { prompt: string }): Promise<string> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `llm/prompt_template/perfect`, {
        prompt,
      });
      const perfectedPrompt = data.data.promptPerfect;
      return perfectedPrompt;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const updateTemplate = createAsyncThunk(
  'settings/updateTemplate',
  async ({ template }: { org: string | undefined; template: ITemplate }): Promise<IResponse<ITemplate>> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `llm/prompt_template/update`, {
        ...template,
      });
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const deleteTemplate = createAsyncThunk(
  'settings/deleteTemplate',
  async ({ id }: { org: string | undefined; id: string }): Promise<IResponse<boolean>> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `llm/prompt_template/delete`, {
        id,
      });
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const setDefaultTemplate = createAsyncThunk(
  'settings/setDefaultTemplate',
  async ({ id, org }: { org: string | undefined; id: string }, { dispatch }): Promise<IResponse<boolean>> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `llm/prompt_template/default`, {
        id,
      });
      dispatch(getTemplate({ org }));
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ set default ~ (e:', e);
      throw e;
    }
  },
);
export const getGmailAuthorizeUrl = createAsyncThunk(
  'settings/getGmailAuthorizeUrl',
  async ({}: { org: string | undefined }): Promise<string> => {
    try {
      const { data } = await axiosGet(process.env.REACT_APP_API_ROOT + `mail/gmail/authorize`);
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);

export const postGmailAuthorizeCallback = createAsyncThunk(
  'settings/postGmailAuthorizeCallback',
  async ({ code, scope }: { code: string; scope: string }): Promise<boolean> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `mail/gmail/authorize/callback`, {
        code,
        scope,
      });
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const getKnowledgeWebpages = createAsyncThunk(
  'settings/getKnowledgeWebpages',
  async ({}: { org: string }): Promise<Record<string, IKnowledgeBase[]> | undefined> => {
    try {
      const { data } = await axiosGet(process.env.REACT_APP_API_ROOT + `vectorstore/knowledgebase?category=webpage`);
      return _.keyBy(data.data, 'id');
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getKnowledgeWebpages ~ (e:', e);
      throw e;
    }
  },
);
export const saveKnowledgeWebpage = createAsyncThunk(
  'settings/saveKnowledgeWebpages',
  async ({ knowledge }: { org: string; knowledge: IKnowledgeBase }): Promise<IKnowledgeBase | undefined> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `vectorstore/datasource_knowledgebase`, {
        ...knowledge,
        category: 'webpage',
      });
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ saveKnowledgeWebpages ~ (e:', e);
      throw e;
    }
  },
);
export const updateKnowledgeWebpage = createAsyncThunk(
  'settings/updateKnowledgeBase',
  async (
    { knowledge, org }: { org: string; knowledge: Partial<IKnowledgeBase> },
    { dispatch },
  ): Promise<IKnowledgeBase | undefined> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `vectorstore/datasource_knowledgebase/update`, {
        ...knowledge,
      });
      dispatch(getKnowledgeWebpages({ org }));
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ updateKnowledgeWebpage ~ (e:', e);
      throw e;
    }
  },
);
export const updateWebpage = createAsyncThunk(
  'settings/updateWebpage',
  async (
    { knowledge, org }: { org: string; knowledge: Partial<IupdateWebpage> },
    { dispatch },
  ): Promise<IupdateWebpage | undefined> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `knowledge/webpages/update`, {
        ...knowledge,
      });
      dispatch(getKnowledgeWebpages({ org }));
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ updateWebpage ~ (e:', e);
      throw e;
    }
  },
);
export const deleteKnowledgeWebpage = createAsyncThunk(
  'settings/deleteKnowledgeWebpage',
  async ({ id }: { org: string | undefined; id: string }): Promise<IResponse<boolean>> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `vectorstore/knowledgebase/delete`, {
        id,
      });
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ deleteKnowledgeWebpage ~ (e:', e);
      throw e;
    }
  },
);

export const getOutlookAuthorizeUrl = createAsyncThunk(
  'settings/getOutlookAuthorizeUrl',
  async ({}: { org: string | undefined }): Promise<string> => {
    try {
      const { data } = await axiosGet(process.env.REACT_APP_API_ROOT + `mail/outlook/authorize`);
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getOutlookAuthorizeUrl ~ (e:', e);
      throw e;
    }
  },
);

export const postOutlookAuthorizeCallback = createAsyncThunk(
  'settings/postOutlookAuthorizeCallback',
  async ({ code, client_info }: { code: string; client_info: string }): Promise<boolean> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `mail/outlook/authorize/callback`, {
        code,
        client_info,
      });
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ postOutlookAuthorizeCallback ~ (e:', e);
      throw e;
    }
  },
);

export const getOrgSocialSettings = createAsyncThunk(
  'settings/getOrgSocialSettings',
  async (): Promise<IResponse<ISocialSetting>> => {
    try {
      const { data } = await axiosGet(process.env.REACT_APP_API_ROOT + `meta/user`);
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:469 ~ getOrgSocialSettings ~ (e:', e);
      throw e;
    }
  },
);

export const saveOrgSocialSettings = createAsyncThunk(
  'settings/saveOrgSocialSettings',
  async (
    { userID, accessToken }: { userID: string; accessToken: string; org: string | undefined },
    { dispatch },
  ): Promise<boolean> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `meta/user`, {
        userId: userID,
        userToken: accessToken,
      });
      dispatch(getOrgSocialSettings());
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:489 ~ saveOrgSocialSettings ~ (e:', e);
      throw e;
    }
  },
);

export const deleteOrgSocialSettings = createAsyncThunk(
  'settings/deleteOrgSocialSettings',
  async ({}: { orgId?: string }, { dispatch }): Promise<string> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `meta/user/delete`);
      dispatch(getOrgSocialSettings());
      dispatch(resetMeta());
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:503 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const getAutoReplySettings = createAsyncThunk(
  'settings/getAutoReplySettings',
  async ({}: { orgId: string }): Promise<IAutoReplySettings | undefined> => {
    try {
      const { data } = await axiosGet(process.env.REACT_APP_API_ROOT + `autoreply`);
      return data.data;
    } catch (e) {
      console.log('🚀 ~ e:', e);
      return undefined;
    }
  },
);
export const updateAutoReplySetting = createAsyncThunk(
  'settings/updateAutoReplySetting',
  async ({ settings }: { orgId: string; settings: IAutoReplySettings }): Promise<IAutoReplySettings | undefined> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + `autoreply`, {
        ...settings,
      });
      return data.data;
    } catch (e) {
      console.log('🚀 ~ e:', e);
      return undefined;
    }
  },
);
