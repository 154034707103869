/**
 * @description Component-CorNewUploader
 */
import React, { FC, useCallback, useState } from 'react';
import { Button, Upload, UploadFile } from 'antd';
import { getUploadPresign, parseUploadedFile, uploadKnowledgeFile } from '@/features/settings/actions';
import axios from 'axios';
import { useAppDispatch } from '@/features/hooks';
import { FETCHING_STATUS } from '@/constants';
import { useOrg } from '@/features/settings/hooks';
import _ from 'lodash';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const CorNewUploader: FC<IPropTypes> = function () {
  const [loading, setLoading] = useState(FETCHING_STATUS.INIT);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const org = useOrg();
  const dispatch = useAppDispatch();
  const getPresignedUrl = useCallback(async () => {
    const url = await dispatch(getUploadPresign()).unwrap();
    return url;
  }, [dispatch]);

  const parseFile = useCallback(
    async (url: string, fileExt: string) => {
      if (org) {
        try {
          if (fileExt) {
            const fileText = await dispatch(parseUploadedFile({ url, ext: `.${fileExt}` })).unwrap();
            await dispatch(uploadKnowledgeFile({ name: 'ccc', content: fileText, org: org.id })).unwrap();
            setLoading(FETCHING_STATUS.DONE);
          }
        } catch (e) {
          console.log('🚀 ~ file: index.tsx:97 ~ parseFile ~ e:', e);
          setLoading(FETCHING_STATUS.DONE);
        }
      }
    },
    [dispatch, org],
  );

  return (
    <div className="cor-new-uploader">
      <Upload
        multiple={false}
        beforeUpload={() => {
          setFileList([]);
        }}
        customRequest={async ({ file, onError, onSuccess, onProgress }) => {
          if (file && onError && onSuccess && onProgress) {
            const fileType = _.get(file, ['type']);
            try {
              setLoading(FETCHING_STATUS.FETCHING);
              const signedUrl = await getPresignedUrl();
              const options = {
                onUploadProgress: (event: any) => {
                  const { loaded, total } = event;
                  onProgress({
                    percent: Math.round((loaded / total) * 100),
                  });
                },
                headers: {
                  'Content-Type': fileType,
                },
              };
              const uploadResult = await axios.put(signedUrl, file, options);

              if (uploadResult.status === 200) {
                const fileExt = _.split(_.get(file, ['name']), '.').pop();
                if (fileExt) {
                  parseFile(signedUrl, fileExt);
                } else {
                  setFileList([]);
                }
                onSuccess(uploadResult, file);
              }
            } catch (error) {
              onError(error);
              setLoading(FETCHING_STATUS.DONE);
            }
          }
        }}
        fileList={fileList}
        showUploadList={false}
        onChange={({ fileList }) => setFileList(fileList)}
        accept=".md"
        name="Knowledge Base">
        <Button
          title="Batch Upload(.md only)"
          size="small"
          disabled={loading === FETCHING_STATUS.FETCHING}
          loading={loading === FETCHING_STATUS.FETCHING}>
          Batch Upload(.md only)
        </Button>
      </Upload>
    </div>
  );
};

export default CorNewUploader;
