import { IPolicies, IUserInfo } from '@/types/users';
import { useAppSelector } from '../hooks';
import { selectUserInfo, selectUserInfoStatus, selectUserPolicies } from './slice';
import { useMemo } from 'react';
import { FETCHING_STATUS } from '@/constants';
import { USER_ROLE } from '@/constants/user';

export function useUserInfo(): IUserInfo | undefined {
  const info = useAppSelector(selectUserInfo);
  return useMemo(() => info, [info]);
}
export function useUserPolicies(): IPolicies | undefined {
  return useAppSelector(selectUserPolicies);
}
export function useUserRoles(): USER_ROLE[] | undefined {
  const policies = useAppSelector(selectUserPolicies);
  return useMemo(() => policies?.roles.map((r) => r.role), [policies?.roles]);
}
export function useUserInfoStatus(): FETCHING_STATUS {
  const status = useAppSelector(selectUserInfoStatus);
  return useMemo(() => status, [status]);
}
