/**
 * @description Component-BotChatItem
 */
import React, { FC } from 'react';
import Avatar from 'react-avatar';
import './index.less';
import { IMessage, IMessageRole } from '@/types/aigc';
import classNames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import stoneIcon from '@/assets/svg/stone.svg';
import moment from 'moment';
import { DATE_FORMAT } from '@/constants';
import MDEditor from '@uiw/react-md-editor';
import { SOCKET_STATUS } from '@/constants/sockets';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  item: IMessage;
}
const BotChatItem: FC<IPropTypes> = function ({ item }) {
  const { user } = useAuth0();
  return (
    <div className={classNames('cor-bot-chat-item', item.role)}>
      <Avatar
        size="40"
        round
        src={item.role === IMessageRole.ASSISTANT ? stoneIcon : item?.avatar}
        name={item.role === IMessageRole.ASSISTANT ? 'stone' : user?.name}
      />
      <div className="cor-bot-chat-item-content">
        <div className="cor-bot-chat-item-date">{moment(item.createdAt).format(DATE_FORMAT)}</div>
        <div className="cor-bot-chat-item-date">
          <MDEditor.Markdown
            className={classNames('cor-bot-chat-item-text', { generating: item.status === SOCKET_STATUS.FETCHING })}
            source={item.content + (item.status === SOCKET_STATUS.FETCHING ? ' <div class="cor-generating" />' : '')}
          />
          {}
        </div>
      </div>
    </div>
  );
};

export default BotChatItem;
