/**
 * @description Component-PluginSessions
 */
import React, { FC, useEffect } from 'react';
import './index.less';
import Merchants from './Merchants';
import MerchantDetail from './MerchantDetail';
import DemoGlobalEffect from '@/features/demo/globalEffect';
import { useOrg } from '@/features/settings/hooks';
import { useNavigate } from 'react-router-dom';
interface IPropTypes {
  className?: string;
}
const Demos: FC<IPropTypes> = function () {
  const org = useOrg();
  const navigate = useNavigate();
  useEffect(() => {
    if (!org) {
      navigate('', { replace: true });
    }
  }, [org]);
  return (
    <div className="cor-demos">
      <div className="cor-demos-left">
        <Merchants />
      </div>
      <div className="cor-demos-right">
        <MerchantDetail />
      </div>
      <DemoGlobalEffect />
    </div>
  );
};

export default Demos;
