/**
 * @description Component-EditMember
 */
import React, { FC, useCallback, useState } from 'react';
import './index.less';
import { FETCHING_STATUS } from '@/constants';
import { useAppDispatch } from '@/features/hooks';
import { useOrg } from '@/features/settings/hooks';

import { Button, Modal, Input, Form, Select } from 'antd';
import { ReactComponent as CloseIcon } from '@/pages/Dashboard/assets/close.svg';

import FormItem from 'antd/es/form/FormItem';
import { getTeamMembers, inviteTeamMember, updateTeamMember } from '@/features/team/actions';
import { IMember } from '@/types/team';
import { useMemberSavingStatus } from '@/features/team/hooks';
import { MEMBER_ROLE } from '@/constants/team';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  text: string;
  title: string;
  icon: React.ReactNode;
  type?: 'primary' | 'text' | 'ghost';
  memberToEdit?: IMember;
}
const EditMember: FC<IPropTypes> = function ({ text, title, icon, type = 'primary', memberToEdit }) {
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const org = useOrg();
  const savingStatus = useMemberSavingStatus(org?.id);

  const [form] = Form.useForm();
  const onSaveMember = useCallback(
    async (values: IMember) => {
      if (org) {
        if (!memberToEdit) {
          await dispatch(
            inviteTeamMember({
              orgId: org.id,
              email: values.email,
              role: values.role,
            }),
          ).unwrap();
        } else {
          await dispatch(
            updateTeamMember({
              orgId: org.id,
              email: values.email,
              role: values.role,
            }),
          ).unwrap();
        }

        dispatch(getTeamMembers({ orgId: org?.id }));
        setModalOpen(false);
      }
    },
    [org, dispatch],
  );
  const onClickAdd = useCallback(() => {
    setModalOpen(true);
    form.resetFields();
    form.setFieldsValue({
      ...memberToEdit,
      role: memberToEdit?.role || MEMBER_ROLE.MEMBER,
    });
  }, [form, memberToEdit]);

  return (
    <div className="cor-edit-member">
      <Button size="small" type={type} icon={icon} onClick={onClickAdd}>
        {text}
      </Button>
      <Modal
        footer={false}
        title={title}
        open={modalOpen}
        width={480}
        centered
        className="cor-edit-member-doc-modal"
        closeIcon={<CloseIcon />}
        onCancel={() => setModalOpen(false)}
        onOk={() => setModalOpen(false)}>
        <Form form={form} onFinish={onSaveMember}>
          <FormItem
            rules={[{ required: true, type: 'email', message: 'Please enter an valid email' }]}
            label="Email"
            name="email">
            <Input />
          </FormItem>
          <Form.Item label="Role" name="role" rules={[{ required: true, message: 'Please select a role' }]}>
            <Select defaultValue="admin">
              {/* {org?.isOwner && <Select.Option value={MEMBER_ROLE.OWNER}>Owner</Select.Option>} */}
              {org?.isOwner && <Select.Option value={MEMBER_ROLE.ADMIN}>Admin</Select.Option>}
              <Select.Option value={MEMBER_ROLE.MEMBER}>Member</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <div className="cor-edit-member-doc-modal-save">
              <Button
                loading={savingStatus !== FETCHING_STATUS.DONE}
                disabled={savingStatus !== FETCHING_STATUS.DONE}
                className="cor-edit-member-doc-modal-save-btn"
                htmlType="submit">
                {memberToEdit ? 'Save' : 'Send'}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditMember;
