/**
 * @description Component-InboxMeta
 */
import React, { FC } from 'react';
import './index.less';

import PostList from './PostList';
import PostSearch from './PostSearch';
import { useCurrentPostId } from '@/features/inbox/hooks';
import { useOrg } from '@/features/settings/hooks';
import PostCommentList from './PostCommentList';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import useResizableSidebar from '@/hooks/useResizableSidebar';
interface IPropTypes {
  currentUserId?: string | undefined;
  className?: string;
  isSidebarOpen?: boolean;
}

const InboxMeta: FC<IPropTypes> = function ({ isSidebarOpen = true }) {
  const org = useOrg();
  const currentPostId = useCurrentPostId(org?.id);
  const { isMobile } = useMediaQueryDevice();
  const { sidebarWidth, isResizerVisible, handleMouseDown, handleMouseEnter, handleMouseLeave } = useResizableSidebar();

  return (
    <div className="cor-inbox-meta">
      <div className={`cor-inbox-meta-left ${isSidebarOpen ? 'open' : 'closed'}`} style={{ width: sidebarWidth }}>
        <div className="cor-inbox-meta-left-search">
          <PostSearch />
        </div>
        <PostList />
        <div
          className={`cor-inbox-meta-left-resizer ${isResizerVisible ? 'visible' : ''}`}
          onMouseDown={handleMouseDown}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </div>

      {!isMobile && (
        <div className="cor-inbox-meta-right">
          <PostCommentList postId={currentPostId} />
        </div>
      )}
    </div>
  );
};

export default InboxMeta;
