import {
  ItemStatus,
  ListStatus,
  ObjectStatus,
  getDefaultListStatus,
  getDefaultObjectStatus,
  setFulfilledItemStatus,
  setFulfilledListStatus,
  setFulfilledObjectStatus,
} from '@/types/redux';
import {
  IAutoReplySettings,
  IKnowledgeBase,
  IMailSetting,
  IMailSignature,
  INewKnowledgeBase,
  IOrganization,
  IRequiredKnowledgeBase,
  ISocialSetting,
  ITemplate,
} from '@/types/settings';
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  deleteKnowledgeBase,
  deleteNewKnowledgeBase,
  deleteOrgMailSettings,
  deleteSignature,
  deleteTemplate,
  setDefaultTemplate,
  getDataSource,
  getKnowledgeBases,
  getNewKnowledgeBases,
  getOrg,
  getOrgKey,
  getOrgMailSettings,
  getSignature,
  getTemplate,
  saveKnowledgeBase,
  saveNewKnowledgeChunks,
  saveOrgMailSettings,
  saveSignature,
  saveTemplate,
  updateKnowledgeBase,
  updateNewKnowledgeChunks,
  updateTemplate,
  getRequiredKnowledgeBase,
  getKnowledgeWebpages,
  deleteKnowledgeWebpage,
  saveKnowledgeWebpage,
  getOrgSocialSettings,
  deleteOrgSocialSettings,
  saveOrgSocialSettings,
  getAutoReplySettings,
  updateAutoReplySetting,
} from './actions';
import { AppState } from '../store';
import { ERROR_CODE, FETCHING_STATUS } from '@/constants';
import { toast } from 'react-toastify';
import { KNOWLEDGEBASE_CATEGORY } from '@/constants/settings';

export interface ISettingsState {
  orgKeys: {
    [org: string]: string;
  };
  org: IOrganization | undefined;
  orgMailSettings: {
    [org: string]: ListStatus<IMailSetting>;
  };
  orgMailSavingStatus: {
    [org: string]: FETCHING_STATUS;
  };
  orgSignature: {
    [org: string]: ListStatus<IMailSignature>;
  };
  orgSignatureStatus: {
    [org: string]: { [id: string]: FETCHING_STATUS };
  };
  orgTemplate: {
    [org: string]: ListStatus<ITemplate>;
  };
  orgTemplateStatus: {
    [org: string]: { [id: string]: FETCHING_STATUS };
  };
  orgTemplateSettingStatus: {
    [org: string]: { [id: string]: FETCHING_STATUS };
  };
  orgMailSettingDeletingStatus: {
    [org: string]: { [id: string]: FETCHING_STATUS };
  };
  orgKnowledgeBases: {
    [org: string]: ListStatus<IKnowledgeBase>;
  };
  orgNewKnowledgeBases: {
    [org: string]: INewKnowledgeBase | undefined;
  };

  orgNewKnowledgeChunks: {
    [org: string]: ListStatus<INewKnowledgeBase>;
  };

  orgKnowledgeWebpages: {
    [org: string]: ListStatus<IKnowledgeBase>;
  };
  orgKnowledgeWebpageSavingStatus: {
    [org: string]: FETCHING_STATUS;
  };
  orgKnowledgeWebpageDeletingStatus: {
    [org: string]: { [id: string]: FETCHING_STATUS };
  };
  orgDataSource: {
    [org: string]: { [id: string]: IKnowledgeBase };
  };
  orgKnowledgeSavingStatus: {
    [org: string]: FETCHING_STATUS;
  };
  orgNewKnowledgeChunksSavingStatus: {
    [org: string]: FETCHING_STATUS;
  };
  orgKnowledgeDeletingStatus: {
    [org: string]: { [id: string]: FETCHING_STATUS };
  };
  orgNewKnowledgeDeletingStatus: {
    [org: string]: { [id: string]: FETCHING_STATUS };
  };

  orgRequiredKnowledgeBase: {
    [org: string]: IRequiredKnowledgeBase;
  };
  orgSocialSettings: ObjectStatus<ISocialSetting>;
  orgSocialDeletingStatus: FETCHING_STATUS;
  orgSocialSavingStatus: FETCHING_STATUS;
  orgAutoReplySettings: { [orgId: string]: ItemStatus<IAutoReplySettings> };
}

const initialState: ISettingsState = {
  orgKeys: {},
  org: undefined,
  orgMailSettings: {},
  orgKnowledgeBases: {},
  orgNewKnowledgeBases: {},
  orgNewKnowledgeChunks: {},
  orgKnowledgeWebpages: {},
  orgKnowledgeWebpageDeletingStatus: {},
  orgKnowledgeWebpageSavingStatus: {},
  orgMailSettingDeletingStatus: {},
  orgMailSavingStatus: {},
  orgKnowledgeDeletingStatus: {},
  orgNewKnowledgeDeletingStatus: {},
  orgKnowledgeSavingStatus: {},
  orgNewKnowledgeChunksSavingStatus: {},
  orgSignature: {},
  orgSignatureStatus: {},
  orgTemplate: {},
  orgTemplateStatus: {},
  orgTemplateSettingStatus: {},
  orgDataSource: {},
  orgRequiredKnowledgeBase: {},
  orgSocialSettings: {
    status: FETCHING_STATUS.INIT,
  },
  orgSocialDeletingStatus: FETCHING_STATUS.INIT,
  orgSocialSavingStatus: FETCHING_STATUS.INIT,
  orgAutoReplySettings: {},
};

export const settingsSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrgKey.fulfilled, (state, { meta, payload }) => {
        _.set(state.orgKeys, [meta.arg.org], payload);
      })
      .addCase(getOrg.fulfilled, (state, { payload }) => {
        state.org = payload;
      })
      .addCase(getOrgMailSettings.pending, (state, { meta }) => {
        _.set(state.orgMailSettings, [meta.arg.org || ''], getDefaultListStatus(FETCHING_STATUS.FETCHING));
      })
      .addCase(getOrgMailSettings.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          _.set(state.orgMailSettings, [meta.arg.org], setFulfilledListStatus(payload));
        }
      })
      .addCase(getOrgMailSettings.rejected, (state, { meta }) => {
        _.set(state.orgMailSettings, [meta.arg.org || ''], getDefaultListStatus(FETCHING_STATUS.DONE));
      })
      .addCase(saveOrgMailSettings.pending, (state, { meta }) => {
        _.set(state.orgMailSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.FETCHING);
      })
      .addCase(saveOrgMailSettings.rejected, (state, { meta }) => {
        _.set(state.orgMailSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.DONE);
      })
      .addCase(saveOrgMailSettings.fulfilled, (state, { meta, payload }) => {
        if (payload && payload.errno !== 0) {
          toast.error('Mail settings incorrect');
        } else {
          _.set(state.orgMailSettings, [meta.arg.org || '', 'list', payload.data.id], payload.data);
        }
        _.set(state.orgMailSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.DONE);
      })
      .addCase(deleteOrgMailSettings.pending, (state, { meta }) => {
        _.set(state.orgMailSettingDeletingStatus, [meta.arg.org || '', meta.arg.setting.id], FETCHING_STATUS.FETCHING);
      })
      .addCase(deleteOrgMailSettings.rejected, (state, { meta }) => {
        _.set(state.orgMailSettingDeletingStatus, [meta.arg.org || '', meta.arg.setting.id], FETCHING_STATUS.DONE);
      })
      .addCase(deleteOrgMailSettings.fulfilled, (state, { meta, payload }) => {
        if (payload && payload.errno !== 0) {
          toast.error(payload.errmsg);
        }
        _.set(state.orgMailSettingDeletingStatus, [meta.arg.org || '', meta.arg.setting.id], FETCHING_STATUS.DONE);
      })
      .addCase(getKnowledgeBases.pending, (state, { meta }) => {
        _.set(state.orgKnowledgeBases, [meta.arg.org, 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getKnowledgeBases.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          _.set(state.orgKnowledgeBases, [meta.arg.org], setFulfilledListStatus(payload));
        }
      })
      .addCase(getKnowledgeBases.rejected, (state, { meta }) => {
        _.set(state.orgKnowledgeBases, [meta.arg.org, 'status'], FETCHING_STATUS.DONE);
      })
      .addCase(getNewKnowledgeBases.pending, (state, { meta }) => {
        _.set(state.orgNewKnowledgeChunks, [meta.arg.org, 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getNewKnowledgeBases.fulfilled, (state, { meta, payload }) => {
        const { knowledgeData, knowledgeChunksData } = payload;
        if (knowledgeChunksData) {
          _.set(state.orgNewKnowledgeChunks, [meta.arg.org], setFulfilledListStatus(knowledgeChunksData));
        }
        if (knowledgeData) {
          _.set(state.orgNewKnowledgeBases, [meta.arg.org], knowledgeData);
        }
      })
      .addCase(getNewKnowledgeBases.rejected, (state, { meta }) => {
        _.set(state.orgNewKnowledgeChunks, [meta.arg.org, 'status'], FETCHING_STATUS.DONE);
      })
      .addCase(getKnowledgeWebpages.pending, (state, { meta }) => {
        _.set(state.orgKnowledgeWebpages, [meta.arg.org, 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getKnowledgeWebpages.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          _.set(state.orgKnowledgeWebpages, [meta.arg.org], setFulfilledListStatus(payload));
        }
      })
      .addCase(getKnowledgeWebpages.rejected, (state, { meta }) => {
        _.set(state.orgKnowledgeWebpages, [meta.arg.org, 'status'], FETCHING_STATUS.DONE);
      })
      .addCase(saveKnowledgeWebpage.pending, (state, { meta }) => {
        _.set(state.orgKnowledgeWebpageSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.FETCHING);
      })
      .addCase(saveKnowledgeWebpage.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          _.set(state.orgKnowledgeWebpages, [meta.arg.org, 'list', payload.id], payload);
        }

        _.set(state.orgKnowledgeWebpageSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.DONE);
      })
      .addCase(saveKnowledgeWebpage.rejected, (state, { meta }) => {
        _.set(state.orgKnowledgeWebpageSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.DONE);
      })
      .addCase(deleteKnowledgeWebpage.pending, (state, { meta }) => {
        _.set(state.orgKnowledgeWebpageDeletingStatus, [meta.arg.org || '', meta.arg.id], FETCHING_STATUS.FETCHING);
      })
      .addCase(deleteKnowledgeWebpage.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          const list = _.get(state.orgKnowledgeWebpages, [meta.arg.org || '', 'list']);
          const newList = _.omit(list, [meta.arg.id]);
          _.set(state.orgKnowledgeWebpages, [meta.arg.org || '', 'list'], newList);
        }
        _.set(state.orgKnowledgeWebpageDeletingStatus, [meta.arg.org || '', meta.arg.id], FETCHING_STATUS.DONE);
      })
      .addCase(deleteKnowledgeWebpage.rejected, (state, { meta }) => {
        _.set(state.orgKnowledgeWebpageDeletingStatus, [meta.arg.org || '', meta.arg.id], FETCHING_STATUS.DONE);
      })
      .addCase(getDataSource.fulfilled, (state, { meta, payload }) => {
        if (payload && payload.data) {
          _.set(state.orgKnowledgeBases, [meta.arg.org, 'list', payload.data.id], payload.data);
        }
      })

      .addCase(getRequiredKnowledgeBase.pending, (state, { meta }) => {
        _.set(state.orgRequiredKnowledgeBase, [meta.arg.org || '', 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getRequiredKnowledgeBase.fulfilled, (state, { meta, payload }) => {
        if (payload && payload.data) {
          _.set(state.orgRequiredKnowledgeBase, [meta.arg.org], payload.data);
        }
      })
      .addCase(getRequiredKnowledgeBase.rejected, (state, { meta }) => {
        _.set(state.orgRequiredKnowledgeBase, [meta.arg.org || '', 'status'], FETCHING_STATUS.DONE);
      })

      .addCase(saveKnowledgeBase.pending, (state, { meta }) => {
        _.set(state.orgKnowledgeSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.FETCHING);
      })
      .addCase(saveKnowledgeBase.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          if (payload.category === KNOWLEDGEBASE_CATEGORY.OTHERS) {
            _.set(state.orgKnowledgeBases, [meta.arg.org, 'list', payload.id], payload);
          } else {
            _.set(state.orgRequiredKnowledgeBase, [meta.arg.org, payload.category], payload);
          }
        }
        _.set(state.orgKnowledgeSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.DONE);
      })
      .addCase(saveKnowledgeBase.rejected, (state, { meta }) => {
        _.set(state.orgKnowledgeSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.DONE);
      })

      .addCase(saveNewKnowledgeChunks.pending, (state, { meta }) => {
        _.set(state.orgNewKnowledgeChunksSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.FETCHING);
      })
      .addCase(saveNewKnowledgeChunks.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          _.set(state.orgNewKnowledgeChunks, [meta.arg.org, 'list', payload.id], payload);
        }
        _.set(state.orgNewKnowledgeChunksSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.DONE);
      })
      .addCase(saveNewKnowledgeChunks.rejected, (state, { meta }) => {
        _.set(state.orgNewKnowledgeChunksSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.DONE);
      })

      .addCase(updateKnowledgeBase.pending, (state, { meta }) => {
        _.set(state.orgKnowledgeSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.FETCHING);
      })
      .addCase(updateKnowledgeBase.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          if (payload.category === KNOWLEDGEBASE_CATEGORY.OTHERS) {
            _.set(
              state.orgKnowledgeBases,
              [meta.arg.org, 'list', payload.id],
              _.merge(_.get(state.orgKnowledgeBases, [meta.arg.org, 'list', payload.id]), payload),
            );
          } else {
            _.set(
              state.orgRequiredKnowledgeBase,
              [meta.arg.org, payload.category],
              _.merge(_.get(state.orgRequiredKnowledgeBase, [meta.arg.org, payload.category]), payload),
            );
          }
        }
        _.set(state.orgKnowledgeSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.DONE);
      })
      .addCase(updateKnowledgeBase.rejected, (state, { meta }) => {
        _.set(state.orgKnowledgeSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.DONE);
      })

      .addCase(updateNewKnowledgeChunks.pending, (state, { meta }) => {
        _.set(state.orgNewKnowledgeChunksSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.FETCHING);
      })
      .addCase(updateNewKnowledgeChunks.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          _.set(state.orgNewKnowledgeChunks, [meta.arg.org, 'list', payload.id], payload);
        }
        _.set(state.orgNewKnowledgeChunksSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.DONE);
      })
      .addCase(updateNewKnowledgeChunks.rejected, (state, { meta }) => {
        _.set(state.orgNewKnowledgeChunksSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.DONE);
      })

      .addCase(deleteKnowledgeBase.pending, (state, { meta }) => {
        _.set(state.orgKnowledgeDeletingStatus, [meta.arg.org || '', meta.arg.id], FETCHING_STATUS.FETCHING);
      })
      .addCase(deleteKnowledgeBase.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          const list = _.get(state.orgKnowledgeBases, [meta.arg.org || '', 'list']);
          const newList = _.omit(list, [meta.arg.id]);
          _.set(state.orgKnowledgeBases, [meta.arg.org || '', 'list'], newList);
        }
        _.set(state.orgKnowledgeDeletingStatus, [meta.arg.org || '', meta.arg.id], FETCHING_STATUS.DONE);
      })
      .addCase(deleteKnowledgeBase.rejected, (state, { meta }) => {
        _.set(state.orgKnowledgeDeletingStatus, [meta.arg.org || '', meta.arg.id], FETCHING_STATUS.DONE);
      })

      .addCase(deleteNewKnowledgeBase.pending, (state, { meta }) => {
        _.set(state.orgNewKnowledgeDeletingStatus, [meta.arg.org || '', meta.arg.id], FETCHING_STATUS.FETCHING);
      })
      .addCase(deleteNewKnowledgeBase.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          const list = _.get(state.orgNewKnowledgeChunks, [meta.arg.org || '', 'list']);
          const newList = _.omit(list, [meta.arg.id]);
          _.set(state.orgNewKnowledgeChunks, [meta.arg.org || '', 'list'], newList);
        }
        _.set(state.orgNewKnowledgeDeletingStatus, [meta.arg.org || '', meta.arg.id], FETCHING_STATUS.DONE);
      })
      .addCase(deleteNewKnowledgeBase.rejected, (state, { meta }) => {
        _.set(state.orgNewKnowledgeDeletingStatus, [meta.arg.org || '', meta.arg.id], FETCHING_STATUS.DONE);
      })

      .addCase(saveTemplate.pending, (state, { meta }) => {
        _.set(state.orgTemplateStatus, [meta.arg.org || '', 'default'], FETCHING_STATUS.FETCHING);
      })
      .addCase(saveTemplate.fulfilled, (state, { meta, payload }) => {
        if (payload.errno === ERROR_CODE.ERROR) {
          toast.error(payload.errmsg);
        } else if (payload) {
          _.set(state.orgTemplate, [meta.arg.org || '', 'list', payload.data.id || ''], payload.data);
        }
        _.set(state.orgTemplateStatus, [meta.arg.org || '', 'default'], FETCHING_STATUS.DONE);
      })
      .addCase(saveTemplate.rejected, (state, { meta }) => {
        _.set(state.orgTemplateStatus, [meta.arg.org || '', 'default'], FETCHING_STATUS.DONE);
      })
      .addCase(updateTemplate.pending, (state, { meta }) => {
        _.set(state.orgTemplateStatus, [meta.arg.org || '', meta.arg.template.id || ''], FETCHING_STATUS.FETCHING);
      })
      .addCase(updateTemplate.fulfilled, (state, { meta, payload }) => {
        if (payload.errno === ERROR_CODE.ERROR) {
          toast.error(payload.errmsg);
        } else if (payload) {
          _.set(state.orgTemplate, [meta.arg.org || '', 'list', payload.data.id || ''], payload.data);
        }
        _.set(state.orgTemplateStatus, [meta.arg.org || '', meta.arg.template.id || ''], FETCHING_STATUS.DONE);
      })
      .addCase(updateTemplate.rejected, (state, { meta }) => {
        _.set(state.orgTemplateStatus, [meta.arg.org || '', meta.arg.template.id || ''], FETCHING_STATUS.DONE);
      })
      .addCase(deleteTemplate.pending, (state, { meta }) => {
        _.set(state.orgTemplateStatus, [meta.arg.org || '', meta.arg.id], FETCHING_STATUS.FETCHING);
      })
      .addCase(deleteTemplate.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          const list = _.get(state.orgTemplate, [meta.arg.org || '', 'list']);
          const newList = _.omit(list, [meta.arg.id]);
          _.set(state.orgTemplate, [meta.arg.org || '', 'list'], newList);
        }
        _.set(state.orgTemplateStatus, [meta.arg.org || '', meta.arg.id || ''], FETCHING_STATUS.DONE);
      })
      .addCase(deleteTemplate.rejected, (state, { meta }) => {
        _.set(state.orgTemplateStatus, [meta.arg.org || '', meta.arg.id || ''], FETCHING_STATUS.DONE);
      })

      .addCase(setDefaultTemplate.pending, (state, { meta }) => {
        _.set(state.orgTemplateSettingStatus, [meta.arg.org || '', meta.arg.id], FETCHING_STATUS.FETCHING);
      })
      .addCase(setDefaultTemplate.fulfilled, (state, { meta }) => {
        _.set(state.orgTemplateSettingStatus, [meta.arg.org || '', meta.arg.id || ''], FETCHING_STATUS.DONE);
      })
      .addCase(setDefaultTemplate.rejected, (state, { meta }) => {
        _.set(state.orgTemplateSettingStatus, [meta.arg.org || '', meta.arg.id || ''], FETCHING_STATUS.DONE);
      })
      .addCase(getTemplate.pending, (state, { meta }) => {
        _.set(state.orgTemplateStatus, [meta.arg.org || ''], getDefaultListStatus(FETCHING_STATUS.FETCHING));
      })
      .addCase(getTemplate.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          _.set(state.orgTemplate, [meta.arg.org || ''], setFulfilledListStatus(payload.data));
        }
      })
      .addCase(deleteSignature.pending, (state, { meta }) => {
        _.set(state.orgSignatureStatus, [meta.arg.org || '', meta.arg.id], FETCHING_STATUS.FETCHING);
      })
      .addCase(deleteSignature.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          const list = _.get(state.orgSignature, [meta.arg.org || '', 'list']);
          const newList = _.omit(list, [meta.arg.id]);
          _.set(state.orgSignature, [meta.arg.org || '', 'list'], newList);
        }
        _.set(state.orgSignatureStatus, [meta.arg.org || '', meta.arg.id || ''], FETCHING_STATUS.DONE);
      })
      .addCase(deleteSignature.rejected, (state, { meta }) => {
        _.set(state.orgSignatureStatus, [meta.arg.org || '', meta.arg.id || ''], FETCHING_STATUS.DONE);
      })
      .addCase(saveSignature.pending, (state, { meta }) => {
        _.set(state.orgSignatureStatus, [meta.arg.org || '', 'default'], FETCHING_STATUS.FETCHING);
      })
      .addCase(saveSignature.fulfilled, (state, { meta, payload }) => {
        if (payload.errno === ERROR_CODE.ERROR) {
          toast.error(payload.errmsg);
        } else if (payload) {
          _.set(state.orgSignature, [meta.arg.org || '', 'list', payload.data.id || ''], payload.data);
        }
        _.set(state.orgSignatureStatus, [meta.arg.org || '', 'default'], FETCHING_STATUS.DONE);
      })
      .addCase(saveSignature.rejected, (state, { meta }) => {
        _.set(state.orgSignatureStatus, [meta.arg.org || '', 'default'], FETCHING_STATUS.DONE);
      })
      .addCase(getSignature.rejected, (state, { meta }) => {
        _.set(state.orgSignatureStatus, [meta.arg.org || ''], getDefaultListStatus(FETCHING_STATUS.DONE));
      })
      .addCase(getSignature.pending, (state, { meta }) => {
        _.set(state.orgSignatureStatus, [meta.arg.org || ''], getDefaultListStatus(FETCHING_STATUS.FETCHING));
      })
      .addCase(getSignature.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          _.set(state.orgSignature, [meta.arg.org || ''], setFulfilledListStatus(payload.data));
        }
      })
      .addCase(getOrgSocialSettings.pending, (state) => {
        state.orgSocialSettings = getDefaultObjectStatus(FETCHING_STATUS.FETCHING);
      })
      .addCase(getOrgSocialSettings.rejected, (state) => {
        state.orgSocialSettings = getDefaultObjectStatus(FETCHING_STATUS.DONE);
      })
      .addCase(getOrgSocialSettings.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.orgSocialSettings = setFulfilledObjectStatus(payload.data);
        } else {
          state.orgSocialSettings = getDefaultObjectStatus(FETCHING_STATUS.DONE);
        }
      })
      .addCase(deleteOrgSocialSettings.pending, (state) => {
        state.orgSocialDeletingStatus = FETCHING_STATUS.FETCHING;
      })
      .addCase(deleteOrgSocialSettings.rejected, (state) => {
        state.orgSocialDeletingStatus = FETCHING_STATUS.DONE;
      })
      .addCase(deleteOrgSocialSettings.fulfilled, (state, { payload }) => {
        if (payload) {
          state.orgSocialDeletingStatus = FETCHING_STATUS.DONE;
        }
      })
      .addCase(saveOrgSocialSettings.pending, (state) => {
        state.orgSocialSavingStatus = FETCHING_STATUS.FETCHING;
      })
      .addCase(saveOrgSocialSettings.rejected, (state) => {
        state.orgSocialSavingStatus = FETCHING_STATUS.DONE;
      })
      .addCase(saveOrgSocialSettings.fulfilled, (state, { payload }) => {
        if (payload) {
          state.orgSocialSavingStatus = FETCHING_STATUS.DONE;
        }
      });
    builder
      .addCase(getAutoReplySettings.pending, (state, { meta }) => {
        _.set(state.orgAutoReplySettings, [meta.arg.orgId, 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getAutoReplySettings.fulfilled, (state, { meta, payload }) => {
        _.set(state.orgAutoReplySettings, [meta.arg.orgId], setFulfilledItemStatus(payload));
      })
      .addCase(updateAutoReplySetting.fulfilled, (state, { meta, payload }) => {
        _.set(state.orgAutoReplySettings, [meta.arg.orgId], setFulfilledItemStatus(payload));
      });
  },
});
export const selectOrgKey =
  (org: string | undefined) =>
  (state: AppState): string | undefined =>
    _.get(state.settings.orgKeys, [org || '']);
export const selectOrg = (state: AppState): IOrganization | undefined => state.settings.org;
export const selectSavingMailStatus =
  (org: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgMailSavingStatus, [org || ''], FETCHING_STATUS.DONE);
export const selectDeletingMailStatus =
  (org: string | undefined, id: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgMailSettingDeletingStatus, [org || '', id || ''], FETCHING_STATUS.DONE);
export const selectSavingKnowledgeStatus =
  (org: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgKnowledgeSavingStatus, [org || ''], FETCHING_STATUS.DONE);

export const selectSavingNewKnowledgeStatus =
  (org: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgNewKnowledgeChunksSavingStatus, [org || ''], FETCHING_STATUS.DONE);

export const selectDeletingKnowledgeStatus =
  (org: string | undefined, id: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgKnowledgeDeletingStatus, [org || '', id || ''], FETCHING_STATUS.DONE);

export const selectDeletingNewKnowledgeStatus =
  (org: string | undefined, id: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgNewKnowledgeDeletingStatus, [org || '', id || ''], FETCHING_STATUS.DONE);

export const selectMailSettings =
  (org: string | undefined) =>
  (state: AppState): Record<string, IMailSetting> | undefined =>
    _.get(state.settings.orgMailSettings, [org || '', 'list']);
export const selectMailSettingsStatus =
  (org: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgMailSettings, [org || '', 'status']);
export const selectRequiredKnowledgeBase =
  (org: string | undefined) =>
  (state: AppState): IRequiredKnowledgeBase | undefined => {
    return _.get(state.settings.orgRequiredKnowledgeBase, [org || '']);
  };

export const selectRequiredKnowledgeBaseStatus =
  (org: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgRequiredKnowledgeBase, [org || '', 'status']);

export const selectKnowledgeBases =
  (org: string | undefined) =>
  (state: AppState): Record<string, IKnowledgeBase> | undefined =>
    _.get(state.settings.orgKnowledgeBases, [org || '', 'list']);

export const selectNewKnowledgeBases =
  (org: string | undefined) =>
  (state: AppState): INewKnowledgeBase | undefined =>
    _.get(state.settings.orgNewKnowledgeBases, [org || '']);

export const selectNewKnowledgeChunks =
  (org: string | undefined) =>
  (state: AppState): Record<string, INewKnowledgeBase> | undefined =>
    _.get(state.settings.orgNewKnowledgeChunks, [org || '', 'list']);

export const selectNewKnowledgeChunksStatus =
  (org: string | undefined) =>
  (state: AppState): FETCHING_STATUS | undefined =>
    _.get(state.settings.orgNewKnowledgeChunks, [org || '', 'status']);

export const selectDataSource =
  (org: string | undefined, id: string) =>
  (state: AppState): IKnowledgeBase | undefined =>
    _.get(state.settings.orgDataSource, [org || '', id || '']);
export const selectKnowledgeBasesStatus =
  (org: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgKnowledgeBases, [org || '', 'status']);
export const selectTemplate =
  (org: string | undefined) =>
  (state: AppState): Record<string, ITemplate> =>
    _.get(state.settings.orgTemplate, [org || '', 'list']);
export const selectTemplateStatus =
  (org: string | undefined, id: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgTemplateStatus, [org || '', id || 'default']);
export const setTemplateStatus =
  (org: string | undefined, id: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgTemplateSettingStatus, [org || '', id || 'default']);
export const selectTemplateFetchingStatus =
  (org: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgTemplate, [org || '', 'status']);
export const selectSignature =
  (org: string | undefined) =>
  (state: AppState): Record<string, IMailSignature> =>
    _.get(state.settings.orgSignature, [org || '', 'list']);
export const selectSignatureStatus =
  (org: string | undefined, id: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgSignature, [org || '', id || 'default']);
export const selectSignatureFetchingStatus =
  (org: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgSignature, [org || '', 'status']);
export const selectKnowledgeWebpages =
  (org: string | undefined) =>
  (state: AppState): Record<string, IKnowledgeBase> | undefined =>
    _.get(state.settings.orgKnowledgeWebpages, [org || '', 'list']);
export const selectKnowledgeWebpagesStatus =
  (org: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgKnowledgeWebpages, [org || '', 'status']);
export const selectDeletingKnowledgeWebpageStatus =
  (org: string | undefined, id: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgKnowledgeWebpageDeletingStatus, [org || '', id || ''], FETCHING_STATUS.DONE);
export const selectSavingKnowledgeWebpageStatus =
  (org: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgKnowledgeWebpageSavingStatus, [org || ''], FETCHING_STATUS.DONE);

export const selectSocialSettings = (state: AppState): ObjectStatus<ISocialSetting> => state.settings.orgSocialSettings;
export const selectSocialSettingsStatus =
  () =>
  (state: AppState): FETCHING_STATUS =>
    state.settings.orgSocialSettings.status;

export const selectDeletingSocialStatus =
  () =>
  (state: AppState): FETCHING_STATUS =>
    state.settings.orgSocialDeletingStatus;

export const selectSavingSocialStatus =
  () =>
  (state: AppState): FETCHING_STATUS =>
    state.settings.orgSocialSavingStatus;
export const selectOrgAutoReplySettings =
  (orgId: string | undefined) =>
  (state: AppState): IAutoReplySettings | undefined =>
    _.get(state.settings.orgAutoReplySettings, [orgId || '', 'item']);
export const selectOrgAutoReplySettingsStatus =
  (orgId: string | undefined) =>
  (state: AppState): FETCHING_STATUS | undefined =>
    _.get(state.settings.orgAutoReplySettings, [orgId || '', 'status']);

export default settingsSlice.reducer;
