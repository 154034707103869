/**
 * @description Component-DeleteConnectionButton
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import { IShopConnection } from '@/types/shop';
import { Button } from 'antd';
import { useAppDispatch } from '@/features/hooks';
import { deleteShopConnection } from '@/features/shopconnect/actions';
import { useShopConnectionRemovingStatus } from '@/features/shopconnect/hooks';
import { FETCHING_STATUS } from '@/constants';
interface IPropTypes {
  connection?: IShopConnection;
  className?: string;
}
const DeleteConnectionButton: FC<IPropTypes> = function ({ connection }) {
  const dispatch = useAppDispatch();
  const deletingStatus = useShopConnectionRemovingStatus(connection?.connectionId);
  const onDelete = useCallback(() => {
    if (connection) {
      dispatch(deleteShopConnection({ connectionId: connection.connectionId }));
    }
  }, [connection, dispatch]);
  return (
    <Button
      loading={deletingStatus === FETCHING_STATUS.FETCHING}
      type="text"
      size="small"
      onClick={onDelete}
      className="cor-delete-connection-button">
      Disconnect
    </Button>
  );
};

export default DeleteConnectionButton;
