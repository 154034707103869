/**
 * @description Component-Banner
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import st_1 from './assets/st-1.png';
import st_2 from './assets/st-2.png';
import st_3 from './assets/st-3.png';
interface IPropTypes {
  className?: string;
}
const Solution: FC<IPropTypes> = function () {
  return (
    <div className={`cor-mibileSolution`}>
      <div className="cor-mibileSolution-title">
        Intelligent mobile solutions for <b>unstoppable growth</b>
      </div>
      <div className="cor-mibileSolution-container">
        <div className="cor-mibileSolution-container-left cor-mibileSolution-container-tong wow">
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <img className="cor-mibileSolution-container-tong-img" src={st_1} />
            <div className="cor-mibileSolution-container-tong-title">Social Media Management</div>
          </div>
          <div className="cor-mibileSolution-container-tong-subtitle">
            Enhance your brand with AI-driven social media automation. Engage customers.
          </div>
          <div className="tips1"></div>
          <div className="tips2">
            <div></div>
          </div>
          <div className="tips3">
            <div></div>
          </div>
          <div className="tips4"></div>
        </div>
        <div className="cor-mibileSolution-container-center cor-mibileSolution-container-tong wow">
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <img className="cor-mibileSolution-container-tong-img" src={st_2} />
            <div className="cor-mibileSolution-container-tong-title">Website Live Chat</div>
          </div>
          <div className="cor-mibileSolution-container-tong-subtitle">
            Boost conversions with our chatbot that answers questions, offers personalized recommendations, and delivers
            timely discounts.
          </div>
          <div className="tips1"></div>
          <div className="tips2">
            <div></div>
          </div>
          <div className="tips3">
            <div></div>
          </div>
          <div className="tips4"></div>
        </div>
        <div className="cor-mibileSolution-container-right cor-mibileSolution-container-tong wow">
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <img className="cor-mibileSolution-container-tong-img" src={st_3} />
            <div className="cor-mibileSolution-container-tong-title">Automated Email Responses</div>
          </div>
          <div className="cor-mibileSolution-container-tong-subtitle">
            Increase conversions with our smart chatbot that answers questions, provides personalized recommendations
            and timely discounts to turn visitors into buyers.
          </div>
          <div className="tips1"></div>
          <div className="tips2">
            <div></div>
          </div>
          <div className="tips3">
            <div></div>
          </div>
          <div className="tips4"></div>
        </div>
      </div>
    </div>
  );
};

export default Solution;
