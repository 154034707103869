import React, { useCallback, useEffect } from 'react';
// import _ from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import './index.less';
import { Button } from 'antd';
import logo from '@/assets/images/logo_cornerstone.png';
import logoText from '@/assets/images/logo_text.png';
import { useNavigate } from 'react-router-dom';
export default function Login(): JSX.Element {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const login = useCallback(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);
  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      navigate('/');
    }
  }, [isAuthenticated, isLoading, navigate]);
  return (
    <div className="login">
      <div className="login-content">
        <div className="login-content-logo">
          <img className="login-content-logo-top" src={logo} />
          <img className="login-content-logo-bottom" src={logoText} />
        </div>
        <div className="login-content-bottom">
          <Button type="primary" className="login-content-btn cor-save" onClick={login}>
            Sign In{' '}
          </Button>
        </div>
      </div>
    </div>
  );
}
