import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { axiosGet, axiosPost, axiosPut } from '@/utils/axios';
import { IInboxUser, IMetaPost, IMetaComment } from '@/types/inbox';
import { INBOX_SOURCES_KEY, USER_PAGE_SIZE } from '@/constants/inbox';
import { IMail } from '@/types/email';
import { IResponse } from '@/types/settings';
import { API_MODE, FETCHING_STATUS } from '@/constants';
import { AppState } from '@auth0/auth0-react';
import { isNA } from '@/utils/aigc';

export const resetMeta = createAction('inbox/resetMeta');
export const setInboxSourceType = createAction<{ key: INBOX_SOURCES_KEY; orgId: string }>('inbox/setInboxSourceType');
export const setInboxSearchStr = createAction<{ searchStr: string; orgId: string }>('inbox/setInboxSearchStr');
export const setInboxCurrentId = createAction<{ id: string; orgId: string }>('inbox/setInboxCurrentId');
export const setInboxCurrentText =
  createAction<{ id: string; orgId: string; text: string }>('inbox/setInboxCurrentText');
export const setEmailRender = createAction<{ mailId: string; render: string }>('inbox/setEmailRender');
export const setInboxDrawerOpen = createAction<{ drawerOpen: boolean }>('inbox/setInboxDrawerOpen');
export const setInboxReplying = createAction<{ replying: boolean }>('inbox/setInboxReplying');
export const setAigcResponse =
  createAction<{ mailId: string; response: string; status: FETCHING_STATUS }>('inbox/setAigcResponse');

export const setInboxPostSearchStr = createAction<{ searchStr: string; orgId: string }>('inbox/setInboxPostSearchStr');
export const setInboxCurrentPostId = createAction<{ id: string; orgId: string }>('inbox/setInboxCurrentPostId');

export const setInboxCommentSearchStr = createAction<{ searchStr: string; orgId: string }>(
  'inbox/setInboxCommentSearchStr',
);
export const setInboxCurrentPostComment = createAction<{ comment?: IMetaComment; orgId: string }>(
  'inbox/setInboxCurrentPostComment',
);
export const setPostCommentSelected =
  createAction<{ commentId: string; postId: string; selected: boolean }>('inbox/setPostCommentSelected');
export const setAigcReplyResponse =
  createAction<{ commentId: string; response: string; status: FETCHING_STATUS }>('inbox/setAigcReplyResponse');
export const setAigcCommentReplies = createAction<{ [commentId: string]: string }>('inbox/setAigcCommentReplies');
export const setAigcCommentReply = createAction<{ commentId: string; reply: string }>('inbox/setAigcCommentReply');
export const setInboxMetaCurrentText = createAction<{ commentId: string; orgId: string; text: string }>(
  'inbox/setInboxMetaCurrentText',
);

export type GetUserListRequest = {
  nextId?: string | undefined;
  preId?: string | undefined;
  orgId: string;
  query?: string;
  pageSize?: number;
  mode: 'unshift' | 'append' | 'refresh' | 'splice';
};

export type GetPostListRequest = GetUserListRequest;

export const getUserList = createAsyncThunk(
  'inbox/getUserList',
  async ({
    nextId = '',
    preId = '',
    pageSize = USER_PAGE_SIZE,
    query = '',
    mode = 'refresh',
  }: GetUserListRequest): Promise<Record<string, IInboxUser>> => {
    try {
      const { data } = await axiosGet(`${process.env.REACT_APP_API_ROOT}mail/users`, {
        params: {
          pageSize,
          nextId,
          preId,
          query,
          mode,
        },
      });
      return _.keyBy(data.data, 'from');
      // return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:22 ~ e:', e);
      throw e;
    }
  },
);
export const getArchiveById = createAsyncThunk(
  'inbox/getArchiveById',
  async ({ id = '', orgId = '' }: { id: string; orgId: string }): Promise<string | undefined> => {
    try {
      const { data } = await axiosPut(`${process.env.REACT_APP_API_ROOT}mail/users/archive/${id}`, {
        params: orgId,
      });
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:22 ~ e:', e);
      throw e;
    }
  },
);

export const putReadById = createAsyncThunk(
  'inbox/putReadById',
  async ({
    id = '',
    orgId = '',
    from = '',
  }: {
    id: string;
    orgId: string;
    from: string;
  }): Promise<string | undefined> => {
    try {
      const { data } = await axiosPut(`${process.env.REACT_APP_API_ROOT}mail/user/read/${id}`, {
        params: { orgId, from },
      });
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:22 ~ e:', e);
      throw e;
    }
  },
);

export const getUserMailList = createAsyncThunk(
  'inbox/getUserMailList',
  async ({
    from,
    nextId = '',
    preId = '',
    pageSize = 10,
    mode = 'refresh',
  }: {
    nextId?: string | undefined;
    preId?: string | undefined;
    orgId: string;
    from: string;
    pageSize?: number;
    mode?: string; // oneof: unshift append refresh
  }): Promise<IMail[]> => {
    try {
      const { data } = await axiosGet(
        `${process.env.REACT_APP_API_ROOT}mail/from?pageSize=${pageSize}&nextId=${nextId}&from=${from}&preId=${preId}&mode=${mode}`,
      );
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:22 ~ e:', e);
      throw e;
    }
  },
);
export const getAigcResponse = createAsyncThunk(
  'inbox/getAigcResponse',
  async ({
    question,
    mailId,
    templateId,
  }: {
    question: string | undefined;
    mailId: string;
    templateId: string;
  }): Promise<IResponse<{ text: string } | undefined>> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT + 'llm/completions', {
        question,
        mailId,
        templateId,
      });
      return data;
    } catch (e) {
      toast.warning(_.get(e, ['message']));
      throw e;
    }
  },
);
export const sendMail = createAsyncThunk(
  'inbox/sendMail',
  async (
    {
      orgId,
      subject,
      to,
      body,
      mailId,
    }: { subject: string; to: string; body: string; mailId: string; orgId: string | undefined },
    {},
  ): Promise<IResponse<boolean | undefined>> => {
    try {
      const result = await axiosPost(process.env.REACT_APP_API_ROOT + 'mail/send_mail', {
        orgId,
        subject,
        to,
        body,
        mailId,
      });
      return result.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const sendDirectMail = createAsyncThunk(
  'inbox/sendDirectMail',
  async (
    { subject, to, body, orgId }: { subject: string; to: string; body: string; orgId: string },
    { dispatch },
  ): Promise<IResponse<boolean | undefined>> => {
    try {
      const result = await axiosPost(process.env.REACT_APP_API_ROOT + 'mail/direct_send_mail', {
        subject,
        to,
        body,
      });
      dispatch(getUserList({ orgId, mode: API_MODE.REFRESH }));
      return result.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const getMetaPosts = createAsyncThunk(
  'inbox/getMetaPosts',
  async (
    { nextId = '', preId = '', pageSize = USER_PAGE_SIZE, query = '', mode = 'refresh' }: GetPostListRequest,
    {},
  ): Promise<Record<string, IMetaPost>> => {
    try {
      const result = await axiosGet(process.env.REACT_APP_API_ROOT + 'meta/pages/posts', {
        params: {
          pageSize,
          nextId,
          preId,
          query,
          mode,
        },
      });
      return _.keyBy(result.data.data, 'id');
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:197 ~ getMetaPosts ~ (e:', e);
      throw e;
    }
  },
);
export const getMetaPostComments = createAsyncThunk(
  'inbox/getMetaPostComments',
  async (
    {
      nextId = '',
      preId = '',
      pageSize = 999,
      metaPagePostId = '',
    }: { pageSize?: number; nextId?: string; preId?: string; metaPagePostId: string; orgId: string },
    {},
  ): Promise<Record<string, IMetaComment>> => {
    try {
      const result = await axiosGet(process.env.REACT_APP_API_ROOT + 'meta/page/post/comments', {
        params: {
          pageSize,
          nextId,
          preId,
          metaPagePostId,
        },
      });
      return _.keyBy(result.data.data, 'id');
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:229 ~ getMetaPostComments ~ (e:', e);
      throw e;
    }
  },
);

export const getMetaPostCommentReplies = createAsyncThunk(
  'inbox/getMetaPostCommentReplies',
  async (
    {
      nextId = '',
      preId = '',
      pageSize = 999,
      metaPostCommentId = '',
    }: { pageSize?: number; nextId?: string; preId?: string; metaPostCommentId?: string; orgId: string },
    {},
  ): Promise<Record<string, IMetaComment>> => {
    try {
      const result = await axiosGet(process.env.REACT_APP_API_ROOT + 'meta/page/post/comment/replies', {
        params: {
          pageSize,
          nextId,
          preId,
          metaPostCommentId,
        },
      });
      return _.keyBy(result.data.data, 'id');
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:259 ~ getMetaPostCommentReplies ~ (e:', e);
      throw e;
    }
  },
);

export const ReplyMetaPostComment = createAsyncThunk(
  'inbox/ReplyMetaPostComment',
  async (
    {
      commentId = '',
      message = '',
      orgId,
      id,
      postId,
    }: { commentId?: string; message?: string; id?: string; orgId?: string; postId: string | undefined },
    { dispatch },
  ): Promise<IResponse<IMetaComment>> => {
    try {
      const result = await axiosPost(process.env.REACT_APP_API_ROOT + 'meta/page/post/comment/reply', {
        commentId,
        message,
      });
      console.log(result.data);
      if (orgId) {
        dispatch(
          getMetaPostCommentReplies({
            metaPostCommentId: id,
            orgId,
          }),
        );
        postId &&
          dispatch(
            getMetaPostComments({
              metaPagePostId: postId,
              orgId,
            }),
          );
      }
      return result.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:281 ~ ReplyMetaPostComment ~ (e:', e);
      throw e;
    }
  },
);
export const ReplyMetaPostComments = createAsyncThunk(
  'inbox/ReplyMetaPostComments',
  async (
    { comments, orgId, postId }: { comments: { commentId: string; id: string }[]; orgId?: string; postId: string },
    { dispatch, getState },
  ): Promise<void> => {
    try {
      const {
        inbox: { commentAigcReplies },
      } = getState() as AppState;
      comments.forEach((c) => {
        const reply = _.get(commentAigcReplies, [c.commentId]);
        if (!isNA(reply) && !!reply) {
          dispatch(
            ReplyMetaPostComment({
              commentId: c.commentId,
              id: c.id,
              postId,
              message: isNA(reply) ? '' : reply,
              orgId,
            }),
          );
        }
      });
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:281 ~ ReplyMetaPostComment ~ (e:', e);
    }
  },
);
