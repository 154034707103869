import { useMemo } from 'react';
import { useAppSelector } from '../hooks';
import { selectBotHistory, selectBotStatus, selectCurrentConversationId, selectLastMessage } from './slice';
import { IMessage } from '@/types/aigc';
import { FETCHING_STATUS } from '@/constants';
import { AIGC_TYPE } from '@/constants/aigc';

export function useBotHistory(org: string | undefined, userEmail: string | undefined, scene: AIGC_TYPE): IMessage[] {
  const list = useAppSelector(selectBotHistory(org, userEmail, scene));
  return useMemo(() => list, [list]);
}
export function useLastMessage(
  org: string | undefined,
  userEmail: string | undefined,
  scene: AIGC_TYPE,
): IMessage | undefined {
  const message = useAppSelector(selectLastMessage(org, userEmail, scene));
  return useMemo(() => message, [message]);
}
export function useBotStatus(org: string | undefined, userEmail: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectBotStatus(org, userEmail));
  return useMemo(() => status, [status]);
}
export function useCurrentConversationId(org: string | undefined): string {
  const id = useAppSelector(selectCurrentConversationId(org));
  return useMemo(() => id, [id]);
}
