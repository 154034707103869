import { createSlice } from '@reduxjs/toolkit';
import { setSocket } from './actions';
import { AppState } from '../store';
import { Socket } from 'socket.io-client';
import _ from 'lodash';
import { SOCKET_CHANNELS } from '@/constants/sockets';
export interface ISocketState {
  socket: { [chanel: string]: Socket | undefined };
}

const initialState: ISocketState = {
  socket: {},
};

export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSocket, (state, { payload }) => {
      _.set(state.socket, [payload.channel], payload.socket);
    });
  },
});
export const selectSocket =
  (channel: SOCKET_CHANNELS) =>
  (state: AppState): Socket | undefined =>
    _.get(state.socket.socket, [channel]);
export default socketSlice.reducer;
