/**
 * @description Component-PostListSkeleton
 */
import React, { FC } from 'react';
import './index.less';
import { Skeleton } from 'antd';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const PostListSkeleton: FC<IPropTypes> = function (props) {
  const items = new Array(4).fill(0);
  return (
    <div className="cor-post-list-skeleton">
      {items.map((_, index) => (
        <div key={index} className="cor-post-list-skeleton-item">
          <Skeleton paragraph={{ rows: 2 }} active={true} />
          <div key={index} className="cor-post-list-skeleton-item-btns">
            <Skeleton.Button active={true} />
            <Skeleton.Button active={true} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default PostListSkeleton;
