/**
 * @description Component-DeleteTemplate
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import { Button, Popconfirm } from 'antd';
import { useOrg, useTemplateStatus } from '@/features/settings/hooks';
import { ITemplate } from '@/types/settings';
import { useAppDispatch } from '@/features/hooks';
import { deleteTemplate } from '@/features/settings/actions';
import { FETCHING_STATUS } from '@/constants';
import { ReactComponent as DeleteIcon } from '@/assets/svg/icon_delete.svg';
interface IPropTypes {
  className?: string;
  template: ITemplate;
}
const DeleteTemplate: FC<IPropTypes> = function ({ template }) {
  const org = useOrg();
  const dispatch = useAppDispatch();
  const deleting = useTemplateStatus(org?.id, template.id);
  const onDeleteTemplate = useCallback(() => {
    org && template.id && dispatch(deleteTemplate({ org: org.id, id: template.id }));
  }, [dispatch, org, template]);
  return (
    <div className="cor-delete-template">
      <Popconfirm onConfirm={onDeleteTemplate} okText="Yes" cancelText="No" title="Are you sure to delete this role?">
        <Button
          size="small"
          disabled={deleting === FETCHING_STATUS.FETCHING}
          loading={deleting === FETCHING_STATUS.FETCHING}
          type="text"
          icon={<DeleteIcon />}>
          Delete
        </Button>
      </Popconfirm>
    </div>
  );
};

export default DeleteTemplate;
