import { createSlice } from '@reduxjs/toolkit';

import { ItemStatus, getDefaultItemStatus, setFulfilledItemStatus } from '@/types/redux';
import { getCustomerByEmail } from './action';
import { ICustomer } from '@/types/customers';
import _ from 'lodash';
import { FETCHING_STATUS } from '@/constants';
import { AppState } from '@auth0/auth0-react';
export interface ICustomerState {
  customers: { [orgId: string]: { [email: string]: ItemStatus<ICustomer> } };
}

const initialState: ICustomerState = {
  customers: {},
};

export const ICustomerState = createSlice({
  name: 'customer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomerByEmail.pending, (state, { meta }) => {
      _.set(state.customers, [meta.arg.orgId, meta.arg.email], getDefaultItemStatus());
    });
    builder.addCase(getCustomerByEmail.fulfilled, (state, { meta, payload }) => {
      if (payload) {
        _.set(state.customers, [meta.arg.orgId, meta.arg.email], setFulfilledItemStatus(payload));
      }
    });
    builder.addCase(getCustomerByEmail.rejected, (state, { meta }) => {
      _.set(state.customers, [meta.arg.orgId, meta.arg.email, 'status'], FETCHING_STATUS.DONE);
    });
  },
});
export const selectCustomerByEmail =
  (orgId: string | undefined, email: string | undefined) =>
  (state: AppState): ICustomer | undefined =>
    _.get(state.customer.customers, [orgId || '', email || '', 'item']);
export const selectCustomerStatusByEmail =
  (orgId: string | undefined, email: string | undefined) =>
  (state: AppState): FETCHING_STATUS | undefined =>
    _.get(state.customer.customers, [orgId || '', email || '', 'status']);

export default ICustomerState.reducer;
