/**
 * @description Component-EditTemplate
 */
import React, { FC, useCallback, useState } from 'react';
import './index.less';
import { ITemplate } from '@/types/settings';
import { Button, Col, Form, Input, Modal, Row, Tooltip } from 'antd';
import { ReactComponent as CloseIcon } from '@/pages/Dashboard/assets/close.svg';
import { ReactComponent as GenerateIcon } from '@/pages/Dashboard/assets/icon_generate.svg';
import { ReactComponent as QuestionIcon } from '@/assets/svg/icon_question.svg';
import { FETCHING_STATUS } from '@/constants';
import FormItem from 'antd/es/form/FormItem';
import { useOrg, useTemplateStatus } from '@/features/settings/hooks';
import { useAppDispatch } from '@/features/hooks';
import { perfectPrompt, saveTemplate, updateTemplate } from '@/features/settings/actions';
// import _ from 'lodash';
interface IPropTypes {
  className?: string;
  template?: ITemplate | undefined;
  text: string;
  icon: React.ReactNode;
  btnType: 'primary' | 'text' | 'ghost';
  disabled?: boolean;
}

const { TextArea } = Input;
const EditTemplate: FC<IPropTypes> = function ({ text, btnType, icon, template, disabled }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [perfecting, setPerfecting] = useState(false);
  const org = useOrg();
  const [form] = Form.useForm();
  const [templateValue, setTemplateValue] = useState(template);
  const dispatch = useAppDispatch();
  const savingStatus = useTemplateStatus(org?.id, template?.id);
  const openModal = useCallback(() => {
    setModalOpen(true);
    if (template) {
      form.setFieldsValue(template);
    } else {
      form.resetFields();
    }
  }, [form, template]);
  const onSaveTemplate = useCallback(async () => {
    let result;
    if (org) {
      if (templateValue?.id) {
        result = await dispatch(updateTemplate({ template: templateValue, org: org?.id })).unwrap();
      } else if (templateValue) {
        result = await dispatch(saveTemplate({ template: templateValue, org: org?.id })).unwrap();
      }
      if (result) {
        setModalOpen(false);
      }
    }
  }, [dispatch, org, templateValue]);

  const onPerfectPrompt = useCallback(async () => {
    const promptTemplate = form.getFieldValue('promptTemplate');
    if (promptTemplate) {
      setPerfecting(true);
      const promptPerfected = await dispatch(
        perfectPrompt({
          prompt: promptTemplate,
        }),
      ).unwrap();
      console.log('🚀 ~ file: index.tsx:71 ~ onPerfectPrompt ~ promptPerfected:', promptPerfected);
      if (promptPerfected) {
        form.setFieldValue('promptTemplate', promptPerfected);
      }
      setPerfecting(false);
    }
  }, [form, dispatch]);

  return (
    <div className="cor-edit-template">
      <Button size="small" disabled={disabled} type={btnType} icon={icon} onClick={openModal}>
        {text}
      </Button>
      <Modal
        width={640}
        footer={false}
        title={text}
        open={modalOpen}
        closeIcon={<CloseIcon />}
        className="cor-settings-modal cor-edit-template-modal"
        onCancel={() => setModalOpen(false)}
        onOk={() => setModalOpen(false)}>
        <Form
          onValuesChange={(_changedValues, _value) => {
            setTemplateValue({
              ...templateValue,
              ..._value,
            });
          }}
          form={form}
          initialValues={template}
          onFinish={onSaveTemplate}>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem rules={[{ required: true, message: 'Please input role name' }]} label="Role Name" name="name">
                <Input placeholder="Enter role name" />
              </FormItem>
              <FormItem
                rules={[{ required: true, message: 'Please input contact name' }]}
                label="Contact name"
                name={['promptVariable', 'contactName']}>
                <Input placeholder="Enter contact name" />
              </FormItem>
              <FormItem
                rules={[{ required: true, message: 'Please input company name' }]}
                label="Company name"
                name={['promptVariable', 'companyName']}>
                <Input placeholder="Enter company name" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                className="cor-edit-template-prompt"
                rules={[{ required: !template || template.isCustom, message: 'Please input prompt' }]}
                label={
                  <div className="cor-edit-template-prompt-label">
                    <span>
                      Prompt
                      {(!template || template.isCustom) && <span className="highlight">*</span>}
                      <Tooltip
                        title={
                          <p>
                            e.g: You name is {templateValue?.promptVariable.contactName}, you are a customer service
                            representative for {templateValue?.promptVariable?.companyName}, You are passionate and
                            patient.....
                          </p>
                        }>
                        <QuestionIcon width={16} height={16} />
                      </Tooltip>
                    </span>
                    <Button
                      size="small"
                      loading={perfecting}
                      disabled={perfecting}
                      onClick={onPerfectPrompt}
                      icon={<GenerateIcon />}
                      type="link">
                      Perfect it with AI
                    </Button>
                  </div>
                }
                name="promptTemplate">
                <TextArea placeholder="Enter prompt" rows={9} />
              </FormItem>
            </Col>
          </Row>

          <Form.Item>
            <Button
              disabled={savingStatus === FETCHING_STATUS.FETCHING}
              loading={savingStatus === FETCHING_STATUS.FETCHING}
              className="cor-save"
              type="primary"
              htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditTemplate;
