/**
 * @description Component-Analysis
 */
import React, { FC, useCallback, useMemo, useState } from 'react';
import './index.less';
import { ANALYSIS_TABS } from '@/constants/analysis';
import WeeklyReport from './WeeklyReport';
import { Tabs } from 'antd';
import Stats from './Stats';

interface IPropTypes {
  className?: string;
}
const Analysis: FC<IPropTypes> = function ({}) {
  const [tab, setTab] = useState(ANALYSIS_TABS.STATS);

  const items = useMemo(
    () => [
      {
        label: 'Stats',
        key: ANALYSIS_TABS.STATS.toString(),
      },
      {
        label: 'Reports',
        key: ANALYSIS_TABS.REPORTS.toString(),
      },
    ],
    [],
  );
  const tabChange = useCallback((key: ANALYSIS_TABS) => {
    setTab(key);
  }, []);

  return (
    <div className="cor-analysis">
      <div className="cor-analysis-tabs">
        <Tabs items={items} activeKey={tab} onChange={(key) => tabChange(key as ANALYSIS_TABS)} />
      </div>
      <div className="cor-analysis-container">
        {tab === ANALYSIS_TABS.STATS && <Stats />}
        {tab === ANALYSIS_TABS.REPORTS && <WeeklyReport />}
      </div>
    </div>
  );
};

export default Analysis;
