/**
 * @description Component-Merchants
 */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import './index.less';
import { useCurrentMerchant, useMerchantReplying, useMerchants, useMerchantsStatus } from '@/features/demo/hooks';
import { useAppDispatch } from '@/features/hooks';
import { FETCHING_STATUS } from '@/constants';
import { getMerchants, setSelectedMerchant } from '@/features/demo/actions';
import _ from 'lodash';
import { Empty } from 'antd';
import ListSkeleton from '@/pages/components/ListSkeleton';
import logo from '@/assets/images/logo1.png';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const Merchants: FC<IPropTypes> = function () {
  const merchants = useMerchants();
  const merchantsStatus = useMerchantsStatus();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedMerchant = useCurrentMerchant();
  const replying = useMerchantReplying(selectedMerchant?.id);

  const onLoad = useCallback(() => {
    if (!merchantsStatus || merchantsStatus === FETCHING_STATUS.DONE) {
      dispatch(getMerchants({}));
    }
  }, [dispatch, merchantsStatus]);

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    //  set first session to selected
    if (!!merchants?.length && !merchants?.some((s) => s.id === selectedMerchant?.id)) {
      const firstMerchant = _.first(merchants);
      firstMerchant && dispatch(setSelectedMerchant(firstMerchant));
    }
  }, [merchants, selectedMerchant, dispatch]);
  return (
    <div className="cor-merchants-wrap">
      <a className="cor-merchants-wrap-log" onClick={() => navigate('/')}>
        <img height="48" src={logo} />
      </a>
      <div className="cor-merchants">
        {merchantsStatus === FETCHING_STATUS.FETCHING && !merchants?.length && <ListSkeleton />}
        {(merchantsStatus === FETCHING_STATUS.DONE || merchantsStatus === FETCHING_STATUS.COMPLETED) &&
          !merchants?.length && <Empty description={<div>No Merchants</div>} />}
        {merchants?.map((m) => (
          <div
            onClick={() => {
              if (replying) {
                toast.warning('Please wait until the chat completes.');
                return;
              }
              dispatch(setSelectedMerchant(m));
            }}
            className={classNames('cor-merchants-item', {
              selected: m.id === selectedMerchant?.id,
              disabled: replying,
            })}
            key={m.id}>
            {m.logo && <img src={m.logo} />}
            <div className="cor-merchants-item-name">
              {m.name} {m.language}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Merchants;
