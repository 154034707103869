/**
 * @description Component-Plugin
 */
import React, { FC, useMemo, useState } from 'react';
import './index.less';
import CorAceEditor from '@/components/CorAceEditor';
import { Button, Tabs } from 'antd';
import useCopyClipboard from '@/hooks/copied';
import PluginGlobalEffect from '@/features/plugin/globalEffect';
import { useOrg } from '@/features/settings/hooks';
import { PLUGIN_TABS } from '@/constants/inbox';
import { useTranslation } from 'react-i18next';
// import PluginSessions from './PluginSessions';
interface IPropTypes {
  className?: string;
}
const Plugin: FC<IPropTypes> = function () {
  const [copied, setCopied] = useCopyClipboard(2000);
  const org = useOrg();
  const [tab, setTab] = useState(PLUGIN_TABS.SET_UP);
  const { t } = useTranslation();

  const code = `
<!-- CornerStone plugin Code  -->
<script>
    (function(c,o,r,n,s){
      c.CornerStoneId = '${org?.teams[0].trackId}';
      n=o.getElementsByTagName('head')[0];
      s=o.createElement('script');
      s.src = r;
      s.async=1;
      n.appendChild(s);
    })(window, document,'https://d2lio0t6t1cem3.cloudfront.net/${
      process.env.BUILD_ENV === 'staging' ? 'staging/' : ''
    }livechat/livechat.js');
</script>
<!-- CornerStone plugin Code  -->
`;
  return (
    <div className="cor-plugin">
      <div className="cor-plugin-step">
        <div className="cor-plugin-step-header">
          <div className="cor-plugin-step-header-left">
            <div className="cor-plugin-step-header-number">1</div>
            Copy this code
          </div>
          <Button type="ghost" size="small" onClick={() => setCopied(code)}>
            {copied ? 'Code copied!' : 'Copy code'}
          </Button>
        </div>
        <div className="cor-plugin-step-content">
          <CorAceEditor mode="html" minLines={11} value={code} />
        </div>
      </div>
      <div className="cor-plugin-step">
        <div className="cor-plugin-step-header">
          <div className="cor-plugin-step-header-left">
            <div className="cor-plugin-step-header-number">2</div>
            Please paste the code into the <span className="head">{`<head>`}</span>of your html page.
          </div>
        </div>
      </div>
      <PluginGlobalEffect />
    </div>
  );
};

export default Plugin;
