import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { IAnalysis } from '@/types/analysis';
import { ItemStatus, setFulfilledItemStatus } from '@/types/redux';
import { getAnalysis } from './actions';
import { AppState } from '@auth0/auth0-react';
import { FETCHING_STATUS } from '@/constants';
export interface IAnalysisState {
  orgAnalysis: { [orgId: string]: ItemStatus<IAnalysis[]> };
}

const initialState: IAnalysisState = {
  orgAnalysis: {},
};

export const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAnalysis.fulfilled, (state, { meta, payload }) => {
      if (payload) {
        _.set(state.orgAnalysis, [meta.arg.orgId], setFulfilledItemStatus(payload.data));
      }
    });
  },
});
export const selectAnalysis =
  (orgId: string | undefined) =>
  (state: AppState): IAnalysis | undefined =>
    _.get(state.analysis.orgAnalysis, [orgId || '', 'item', 0]);
export const selectAnalysisStatus =
  (orgId: string | undefined) =>
  (state: AppState): FETCHING_STATUS | undefined =>
    _.get(state.analysis.orgAnalysis, [orgId || '', 'status']);
export default analysisSlice.reducer;
