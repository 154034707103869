export enum SOCKET_CHANNELS {
  MAIL = 'mail',
  CHATBOT = 'chatbot',
  META = 'meta',
  META_IN_ONE = 'meta/reply-in-one',
  ERROR_GLOBAL = 'errorGlobal',
  LIVE_CHAT_MANUAL = 'livechat/manual',
  LIVE_CHAT_ASSISTANT = 'livechat/assistant',
  LIVE_DEMO = 'livedemo',
  GENERATE_EMAIL = 'livechat/generate_mail',
}
export enum SOCKET_COMMANDS {
  CONVERSATION = 'conversation',
  SEND_MAIL = 'send_mail',
  SEARCH = 'search',
}
export enum SOCKET_STATUS {
  FINISHED = 'finished',
  FETCHING = 'fetching',
}
