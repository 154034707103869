/**
 * @description Component-EditSignature
 */
import React, { FC, useCallback, useState } from 'react';
import './index.less';
import { IMailSignature } from '@/types/settings';
import { Button, Form, Input } from 'antd';
import { FETCHING_STATUS } from '@/constants';
import FormItem from 'antd/es/form/FormItem';
import { useOrg, useSignatureStatus } from '@/features/settings/hooks';
import { useAppDispatch } from '@/features/hooks';
import { saveSignature } from '@/features/settings/actions';
import _ from 'lodash';
interface IPropTypes {
  className?: string;
  signature?: IMailSignature | undefined;
  onCancel: () => void;
}

const { TextArea } = Input;

const EditSignature: FC<IPropTypes> = function ({ signature, onCancel }) {
  const org = useOrg();
  const [signatureVal, setSignatureVal] = useState(signature);
  const dispatch = useAppDispatch();
  const savingStatus = useSignatureStatus(org?.id, signature?.id);

  const onSaveSignature = useCallback(async () => {
    let result;
    if (org && signatureVal) {
      result = await dispatch(
        saveSignature({ signature: { ...signatureVal, name: 'Signature' }, org: org?.id }),
      ).unwrap();
      if (result) {
        onCancel();
      }
    }
  }, [dispatch, org, signatureVal, onCancel]);

  return (
    <div className="cor-edit-signature">
      <Form
        onValuesChange={(_changedValues, _value) => {
          setSignatureVal({
            ...signatureVal,
            ..._value,
          });
        }}
        initialValues={signature}
        onFinish={onSaveSignature}>
        <FormItem rules={[{ required: true, message: 'Please input signature' }]} name="signature">
          <TextArea rows={6} />
        </FormItem>

        <Form.Item>
          <Button
            disabled={savingStatus === FETCHING_STATUS.FETCHING}
            loading={savingStatus === FETCHING_STATUS.FETCHING}
            className="cor-save"
            type="primary"
            htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditSignature;
