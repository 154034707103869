/**
 * @description Component-About
 */
import React, { FC } from 'react';
import './index.less';
import Footer from '../Landing/Footer';
import Header from '../Landing/Header';
import HeaderMobile from '../LandingMobile/Header';
import FooterMobile from '../LandingMobile/Footer';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import classNames from 'classnames';
import MDEditor from '@uiw/react-md-editor';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const Career: FC<IPropTypes> = function (props) {
  const { deviceType, isMobile } = useMediaQueryDevice();
  return (
    <div className={classNames('cor-career', deviceType)}>
      {isMobile ? <HeaderMobile /> : <Header />}
      <div className="cor-career-content">
        <MDEditor.Markdown
          source={`
**Job Title:** Senior Full Stack Software Engineer

**Company:** Cornerstone AI

**Location:** Seattle/Remote

**About Us:**

At Cornerstone AI, we are pioneers in the field of AI-driven SaaS solutions, specializing in dynamic personalization, robust data consolidation, and intelligent customer engagement for e-commerce. Our software empowers businesses with high-ticket, high-tech products to enhance their customer service, sales conversions, and overall customer satisfaction. We integrate cutting-edge technologies to provide secure, scalable, and high-performance solutions. As we continue to innovate, we're looking for a Senior Full Stack Software Engineer to join our team and play a key role in driving our platform to new heights.

**Responsibilities:**

**Full Stack Development:**

-   Design, develop, and maintain scalable web applications using modern frontend technologies such as React, TypeScript, and Next.js.
-   Build and optimize backend services using Python and Django, ensuring robust data processing and seamless API integrations.
-   Implement and manage cloud infrastructure, with a focus on AWS, to support our SaaS platform's growth and reliability.

**Collaboration & Leadership:**

-   Work closely with product managers, UX/UI designers, and other engineers to deliver high-quality features that meet customer needs.
-   Lead and mentor junior developers, advocating for best practices in code quality, testing, and deployment.
-   Foster a culture of continuous learning and improvement within the engineering team.

**Continuous Learning:**

-   Keep up with the latest advancements in software development, particularly in AI and cloud technologies.
-   Experiment with and implement new tools and frameworks to enhance platform performance and scalability.

**Requirements:**

-   Bachelor's or Master's degree in Computer Science, Software Engineering, or a related field.
-   5+ years of experience in full-stack software development, with a strong emphasis on frontend technologies like React, TypeScript, and Next.js.
-   Proven expertise in backend development with Python and Django.
-   Experience with AWS services, including EC2, S3, Lambda, and RDS.
-   Proficiency in deploying and managing applications in containerized environments using Docker and Kubernetes.
-   Familiarity with data processing frameworks and tools like Kafka, Spark, and Airflow.
-   Solid understanding of infrastructure-as-code practices, particularly with Terraform.
-   Excellent problem-solving skills and attention to detail.
-   Strong communication skills and ability to work effectively in a collaborative, remote-first environment.

**Nice to Have:**

-   Previous experience in the e-commerce or AI-driven SaaS industries.
-   Contributions to open-source projects or active participation in developer communities.
-   Familiarity with security practices in software development, particularly in the context of AWS.

Join us at Cornerstone AI, where you'll have the opportunity to shape the future of intelligent customer engagement and drive real impact in the e-commerce industry. If you're passionate about building scalable, AI-powered solutions and thrive in a dynamic environment, we'd love to hear from you.

Apply at: careers@cornerstone.chat
            `}
        />
        <MDEditor.Markdown
          source={`

**Job Title: Senior Data Scientist**

**Company:** Cornerstone AI

**Location:** Seattle/Remote

**About Us:**

At Cornerstone AI, we are at the forefront of AI-driven SaaS solutions, dedicated to transforming the e-commerce landscape, particularly for high-ticket, high-tech products. Our platform excels in dynamic personalization, robust data consolidation, and intelligent customer engagement. We empower businesses to convert interactions into actionable insights that drive growth and optimize revenue. As we continue to innovate, we're looking for a Senior Data Scientist to join our team and help shape the future of our data-driven products.

**Responsibilities:**

**Data Analysis and Insight Generation:**

-   Analyze complex datasets using statistical and machine learning techniques to extract insights that drive business decisions and product development.

**Model Development and Optimization:**

-   Design, develop, and optimize machine learning models and predictive analytics tools to solve key business challenges.
-   Continuously refine models to improve accuracy, efficiency, and scalability.

**Cross-functional Collaboration:**

-   Partner with engineering, product, and business teams to align data science initiatives with company objectives.
-   Communicate findings effectively to both technical and non-technical stakeholders.

**Mentorship and Leadership:**

-   Provide mentorship and guidance to junior data scientists, fostering a culture of learning and excellence.
-   Lead data-driven projects from conception through to deployment.

**Data Strategy and Innovation:**

-   Identify new data sources and opportunities for leveraging data to solve complex business problems.
-   Stay informed on the latest trends in data science and AI to ensure our solutions remain cutting-edge.

**Deployment and Integration:**

-   Work closely with the engineering team to deploy models into production, ensuring they are integrated seamlessly into our products and services.

**Continuous Learning and Development:**

-   Stay updated on the latest advancements in AI, machine learning, and data science.
-   Apply new techniques and methodologies to improve our data science capabilities.

**Requirements:**

-   Master’s or Ph.D. in Computer Science, Statistics, Mathematics, or a related field.
-   5+ years of experience in data science, with a strong track record of driving business impact through data.
-   Proficiency in Python, R, or similar programming languages.
-   Expertise in machine learning, statistical modeling, and data mining.
-   Familiarity with big data tools and cloud computing platforms (e.g., AWS).
-   Strong problem-solving skills with the ability to translate business challenges into data science tasks.
-   Excellent communication skills, with the ability to present complex ideas to non-technical audiences.

**Nice to Have:**

-   Experience in the e-commerce domain or AI-driven SaaS industries.
-   Contributions to open-source projects or active participation in data science communities.
-   Experience with deploying models in production environments, particularly within cloud-based infrastructures.

Join us at Cornerstone AI, where you'll have the opportunity to work on groundbreaking projects that redefine e-commerce success. If you're passionate about data science and eager to make a tangible impact, we'd love to hear from you.

Apply at: careers@cornerstone.chat
`}
        />
      </div>
      {isMobile ? <FooterMobile /> : <Footer />}
    </div>
  );
};

export default Career;
