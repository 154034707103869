/**
 * @description Component-Loading
 */
import React, { FC } from 'react';
import './index.less';

import { Spin } from 'antd';
interface IPropTypes {
  className?: string;
}
const Loading: FC<IPropTypes> = function () {
  return (
    <div className="cor-loading">
      <Spin tip="We are on our way..." size="large"></Spin>
    </div>
  );
};

export default Loading;
