export enum MEMBER_ROLE {
  MEMBER = 'member',
  OWNER = 'owner',
  ADMIN = 'admin',
}
export enum MEMBER_STATUS {
  PENDING = 'pending',
  ACTIVE = 'active',
}
export const EDITABLE_ROLES = [MEMBER_ROLE.ADMIN, MEMBER_ROLE.OWNER];
