/**
 * @description Component-CommentItem
 */
import React, { FC, useEffect, useState } from 'react';
import './index.less';
import FormattedDate from '@/components/FormattedDate';
import { Button, Skeleton, Tag } from 'antd';
import ChatContent from '../../Chat/ChatContent';
import MetaReplyBox from '../../Chat/ChatReplyBox';
import { IMetaComment } from '@/types/inbox';
import Avatar from 'react-avatar';
import Modal from 'antd/lib/modal/Modal';
import { COMMENT_MODES } from '@/constants/inbox';
import AICommentReply from '../../Chat/ChatContent/AICommentReply';
import { useAppDispatch } from '@/features/hooks';
import { getMetaPostCommentReplies } from '@/features/inbox/actions';
import { useOrg } from '@/features/settings/hooks';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import classNames from 'classnames';
import { useMetaPostCommentReplies } from '@/features/inbox/hooks';

interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  comment: IMetaComment;
  mode?: COMMENT_MODES;
  fetchingReplies?: boolean;
  currentComment: IMetaComment;
}
const CommentItem: FC<IPropTypes> = function ({
  comment,
  currentComment,
  mode = COMMENT_MODES.DISPLAY,
  fetchingReplies,
}) {
  const [selectedComment, setSelectedComment] = useState(comment);
  const [modalOpen, setModalOpen] = useState(false);
  const [openContent, setOpenContent] = useState(false);
  const dispatch = useAppDispatch();
  const org = useOrg();
  const replies = useMetaPostCommentReplies(org?.id, comment?.id);
  const { deviceType } = useMediaQueryDevice();
  useEffect(() => {
    org &&
      comment?.metaPagePostId &&
      // comment?.from?.id &&
      dispatch(getMetaPostCommentReplies({ metaPostCommentId: comment.id, orgId: org?.id }));
  }, [dispatch, org, comment]);

  return (
    <div key={comment.id} className={classNames('cor-comment-item', deviceType)}>
      <div className="cor-comment-item-left">
        <Avatar name={comment.from?.name} size={'28'} src={comment.from?.name} round />
      </div>
      <div className="cor-comment-item-right">
        <div className="cor-comment-item-right-top">
          <div className="cor-comment-item-name">
            <div className={`cor-comment-item-name-left ${comment.id === currentComment?.id ? 'active' : ''}`}>
              {comment.from?.name}
            </div>
            <div className="cor-comment-item-name-right">
              <FormattedDate date={comment?.createdTime} />
            </div>
          </div>
          <div className="cor-user-list-item-content">{comment.message}</div>
        </div>
        {mode === COMMENT_MODES.DISPLAY && (
          <div className="cor-comment-item-tags">
            <Button type="link"> {comment.totalReplies} replies</Button>
            {!comment.totalReplies && (
              <Tag onClick={() => setSelectedComment(comment)} color="#f66" style={{ fontWeight: 700, height: 20 }}>
                Unreplied
              </Tag>
            )}
            <Button type="link" onClick={() => setModalOpen(true)}>
              AI Reply Ready
            </Button>
          </div>
        )}
        {mode === COMMENT_MODES.EDIT && (
          <>
            {fetchingReplies ? (
              <Skeleton title={false} paragraph={{ rows: 2 }} active={true} />
            ) : (
              <>
                <div className="cor-comment-item-tags">
                  <Button size="small" type="link" onClick={() => setOpenContent((prev) => !prev)}>
                    {' '}
                    {comment.totalReplies} replies
                  </Button>
                </div>
                {replies && replies?.length > 0 && openContent && (
                  <ChatContent selectComment={setSelectedComment} comment={comment} />
                )}
                <AICommentReply item={comment} />
              </>
            )}
          </>
        )}
      </div>
      <Modal
        open={modalOpen}
        width={800}
        destroyOnClose={true}
        footer={<MetaReplyBox formId={comment.from?.id} comment={selectedComment} />}
        centered
        title={
          <div className={`cor-comment-item-title`}>
            <div className={`cor-comment-item-title-top`}>
              <div className="cor-comment-item-title-left">
                <Avatar name={comment.from?.name} size="36" src={comment.from?.name} round />
              </div>
              <div className="cor-comment-item-title-right">
                <div className="cor-comment-item-title-name">
                  {comment.from?.name}
                  <div>
                    <FormattedDate date={comment?.createdTime} />
                  </div>
                </div>
              </div>
            </div>
            <div className="cor-user-list-item-content">{comment.message}</div>
            <div className="cor-comment-item-tags">
              {comment.totalReplies} replies
              {!comment.totalReplies && (
                <Tag onClick={() => setSelectedComment(comment)} color="#f66" style={{ fontWeight: 700 }}>
                  Unreplied
                </Tag>
              )}
            </div>
          </div>
        }
        onCancel={() => setModalOpen(false)}
        className="cor-comment-item-modal">
        <ChatContent selectComment={setSelectedComment} comment={comment} />{' '}
      </Modal>
    </div>
  );
};

export default CommentItem;
