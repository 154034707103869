import React, { FC, useEffect, useRef } from 'react';
import './index.less';
import {
  useCurrentPostComment,
  useInboxCommentSearchStr,
  useMetaPostComments,
  useMetaPostsCommentsStatus,
} from '@/features/inbox/hooks';
import { useOrg } from '@/features/settings/hooks';
import { useAppDispatch } from '@/features/hooks';
import { getMetaPostComments } from '@/features/inbox/actions';
import InfiniteScroller from 'react-infinite-scroller';
import { FETCHING_STATUS } from '@/constants';
import { Empty } from 'antd';
import { IMetaComment } from '@/types/inbox';
import _ from 'lodash';
import CommentItem from './CommentItem';
import { COMMENT_MODES } from '@/constants/inbox';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import classNames from 'classnames';
import CommentListSkeleton from './CommentListSkeleton';

interface IPropTypes {
  className?: string;
  postId: string;
  mode?: COMMENT_MODES;
  fetchingReplies?: boolean;
  selectComment?: (comment: IMetaComment | undefined) => void;
}
const CommentList: FC<IPropTypes> = function ({ postId, mode, fetchingReplies }) {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const org = useOrg();
  const { deviceType } = useMediaQueryDevice();
  const currentComment = useCurrentPostComment(org?.id);
  const comments = useMetaPostComments(org?.id, postId);
  const commentsStatus = useMetaPostsCommentsStatus(org?.id, postId);
  const query = useInboxCommentSearchStr(org?.id);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    org && dispatch(getMetaPostComments({ orgId: org?.id, metaPagePostId: postId }));
  }, [dispatch, org, postId]);

  useEffect(() => {
    const id = currentComment?.id;
    if (id === _.head(comments)?.from?.id) {
      listRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (id === _.last(comments)?.from?.id) {
      listRef.current?.scrollTo({ top: listRef.current.scrollHeight, behavior: 'smooth' });
    }
  }, [currentComment, comments]);

  return (
    <div className="cor-comment-list-wrap" ref={scrollerRef}>
      <InfiniteScroller
        className={classNames('cor-comment-list', deviceType)}
        initialLoad={true}
        useWindow={false}
        hasMore={false}
        loadMore={() => {
          console.log('todo');
        }}
        getScrollParent={() => scrollerRef.current}>
        {(commentsStatus === FETCHING_STATUS.FETCHING || commentsStatus === undefined) && !comments?.length && (
          <CommentListSkeleton />
        )}
        {commentsStatus !== FETCHING_STATUS.FETCHING && !comments?.length && (
          <Empty
            description={
              <>
                <div>No Comments</div>
              </>
            }
          />
        )}
        {comments &&
          comments
            ?.filter((i) => {
              return query ? i.message.includes(query) : true;
            })
            ?.map((comment) => (
              <CommentItem
                fetchingReplies={fetchingReplies}
                mode={mode}
                key={comment.id}
                comment={comment}
                currentComment={currentComment}
              />
            ))}
      </InfiniteScroller>
    </div>
  );
};

export default CommentList;
