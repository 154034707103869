import { createSlice } from '@reduxjs/toolkit';

import { AppState } from '@/features/store';
import { getUserInfo, getUserPolicies } from './action';
import { IPolicies, IUserInfo } from '@/types/users';
import { FETCHING_STATUS } from '@/constants';
export interface IUserState {
  userInfo: IUserInfo | undefined;
  userInfoStatus: FETCHING_STATUS;
  userPolicies: IPolicies | undefined;
}

const initialState: IUserState = {
  userInfo: undefined,
  userInfoStatus: FETCHING_STATUS.INIT,
  userPolicies: undefined,
};

export const IUserState = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.fulfilled, (state, { payload }) => {
      if (payload) {
        state.userInfo = payload.data;
      }
      state.userInfoStatus = FETCHING_STATUS.DONE;
    });
    builder.addCase(getUserPolicies.fulfilled, (state, { payload }) => {
      if (payload) {
        state.userPolicies = payload.data;
      }
      state.userInfoStatus = FETCHING_STATUS.DONE;
    });
  },
});
export const selectUserInfo = (state: AppState): IUserInfo | undefined => state.user.userInfo;
export const selectUserPolicies = (state: AppState): IPolicies | undefined => state.user.userPolicies;
export const selectUserInfoStatus = (state: AppState): FETCHING_STATUS => state.user.userInfoStatus;

export default IUserState.reducer;
