/**
 * @description Component-CustomerDrawer
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import './index.less';
import { Drawer, Empty, Tag, Tooltip } from 'antd';
import { useAppDispatch } from '@/features/hooks';
import { useOrg } from '@/features/settings/hooks';
import { getCustomerByEmail } from '@/features/customers/action';
import { useCustomer, useCustomerStatus } from '@/features/customers/hooks';
import moment from 'moment';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import classNames from 'classnames';
import { FETCHING_STATUS } from '@/constants';
interface IPropTypes {
  className?: string;
  email: string;
}
const CustomerDrawer: FC<IPropTypes> = function ({ email }) {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const org = useOrg();
  const { deviceType } = useMediaQueryDevice();
  const customer = useCustomer(org?.id, email);
  const customerStatus = useCustomerStatus(org?.id, email);
  const getCustomer = useCallback(() => {
    org && dispatch(getCustomerByEmail({ email, orgId: org?.id }));
  }, [dispatch, email, org]);
  useEffect(() => {
    getCustomer();
  }, [getCustomer]);
  if (!customer?.orders.length) {
    return null;
  }
  return (
    <>
      <Tooltip
        title={
          customer?.orders.length ? (
            <div className="cor-customer-drawer-title-tooltip">
              {customer?.orders.map((o) => (
                <Tag key={o.orderNumber} color="#66f">
                  #{o.orderNumber}
                </Tag>
              ))}
            </div>
          ) : (
            ''
          )
        }>
        <Tag
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
          color="#66f"
          className="cor-customer-drawer-title">
          {customer?.orders.length} Orders
        </Tag>
      </Tooltip>
      {open && (
        <Drawer
          title={email}
          onClose={(e) => {
            e.stopPropagation();
            setOpen(false);
          }}
          open={open}
          className={classNames('cor-customer-drawer', deviceType)}>
          <div className="cor-customer-drawer-container">
            <div className="cor-customer-drawer-container-title">Orders </div>
            <div className="cor-customer-drawer-container-orders">
              {customer?.orders?.map((order) => (
                <div className="cor-customer-drawer-container-orders-item" key={order.orderNumber}>
                  <div className="cor-customer-drawer-container-orders-item-desc">
                    <dl>
                      <dt>Created At</dt>
                      <dd>{moment(order.createdAt).format('MM-DD HH:mm')}</dd>
                    </dl>
                    <dl>
                      <dt>Total Price</dt>
                      <dd>
                        {order.totalPrice}
                        {order.currency}
                      </dd>
                    </dl>
                    <dl>
                      <dt>Number</dt>
                      <dd>
                        {' '}
                        <Tag color="#66f">#{order.orderNumber}</Tag>
                      </dd>
                    </dl>
                  </div>
                  <div className="cor-customer-drawer-container-orders-item-lines">
                    <div className="cor-customer-drawer-container-orders-item-lines-item">
                      <span>Title</span>
                      <span>Quantity</span>
                      <span>Subtotal</span>
                    </div>

                    {order.lineItems?.map((item) => (
                      <div key={item.variantId} className="cor-customer-drawer-container-orders-item-lines-item">
                        <span>{item.title}</span>
                        <span>{item.quantity}</span>
                        <span>
                          {item.price * item.quantity} {item.currency}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}{' '}
              {customerStatus === FETCHING_STATUS.DONE && !customer?.orders.length && (
                <Empty description="No orders"></Empty>
              )}
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default CustomerDrawer;
