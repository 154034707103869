/**
 * @description Component-PluginSessions
 */
import React, { FC } from 'react';
import './index.less';
import SessionList from './SessionList';
import SessionDetail from './SessionDetail';
import useResizableSidebar from '@/hooks/useResizableSidebar';
interface IPropTypes {
  className?: string;
  isSidebarOpen?: boolean;
}
const PluginSessions: FC<IPropTypes> = function ({ isSidebarOpen = true }) {
  const { sidebarWidth, isResizerVisible, handleMouseDown, handleMouseEnter, handleMouseLeave } = useResizableSidebar();
  return (
    <div className="cor-plugin-sessions">
      <div className={`cor-plugin-sessions-left ${isSidebarOpen ? 'open' : 'closed'}`} style={{ width: sidebarWidth }}>
        <SessionList />
        <div
          className={`cor-plugin-sessions-left-resizer ${isResizerVisible ? 'visible' : ''}`}
          onMouseDown={handleMouseDown}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </div>
      <div className="cor-plugin-sessions-right">
        <SessionDetail />
      </div>
    </div>
  );
};

export default PluginSessions;
