/**
 * @description Component-Redirect
 */
import React, { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounceEffect } from 'ahooks';
import { useAuth0 } from '@auth0/auth0-react';
import { retry } from '@/utils/retry';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const LoginSuccess: FC<IPropTypes> = function () {
  const navigate = useNavigate();
  const { getAccessTokenSilently, error, isAuthenticated } = useAuth0();
  const getRedirectUrl = useCallback(async () => {
    try {
      if (isAuthenticated) {
        await retry(getAccessTokenSilently({ cacheMode: 'off' }), { n: 3, minWait: 300, maxWait: 1000 }).promise;
        // window.location.href = window.location.origin + '/dashboard';
        navigate('/', { replace: true });
      }
      if (error) {
        navigate('/', { replace: true });
      }
    } catch (e) {
      console.log('🚀 ~ getRedirectUrl ~ e:', e);
      navigate('/', { replace: true });
    }
  }, [error, getAccessTokenSilently, isAuthenticated, navigate]);
  // must use debounce if redirect to dashboard immediately
  useEffect(() => {
    getRedirectUrl();
  }, [getRedirectUrl]);
  return null;
};

export default LoginSuccess;
