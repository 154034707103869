/**
 * @description Component-SessionItem
 */
import React, { FC } from 'react';
import './index.less';
import { IDemoSession } from '@/types/demo';
import { useAppDispatch } from '@/features/hooks';
import { setMerchantSession } from '@/features/demo/actions';
import classNames from 'classnames';
import { useMerchantSessionId } from '@/features/demo/hooks';
import { toast } from 'react-toastify';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  session: IDemoSession;
  merchantId: string | undefined;
  disabled: boolean;
}
const SessionItem: FC<IPropTypes> = function ({ session, merchantId, disabled }) {
  const dispatch = useAppDispatch();
  const merchantSessionId = useMerchantSessionId(merchantId);
  return (
    <div
      onClick={() => {
        if (disabled) {
          toast.warning('Please wait until the chat completes.');
          return;
        }
        merchantId &&
          dispatch(
            setMerchantSession({
              merchantId,
              sessionId: session.sessionId,
            }),
          );
      }}
      className={classNames('cor-demo-session-item', { selected: merchantSessionId === session.sessionId, disabled })}>
      {session.content}
    </div>
  );
};

export default SessionItem;
