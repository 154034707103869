/**
 * @description Component-DeleteBtn
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import { IMailSetting } from '@/types/settings';
import { useMailDeletingStatus } from '@/features/settings/hooks';
import { Button } from 'antd';
import { FETCHING_STATUS } from '@/constants';
import { useAppDispatch } from '@/features/hooks';
import { deleteOrgMailSettings } from '@/features/settings/actions';
interface IPropTypes {
  setting?: IMailSetting;
  org: string | undefined;
  className?: string;
}
const DeleteBtn: FC<IPropTypes> = function ({ setting, org, className }) {
  const deleting = useMailDeletingStatus(org, setting?.id);
  const dispatch = useAppDispatch();
  const deleteSettings = useCallback(() => {
    setting && dispatch(deleteOrgMailSettings({ setting, org }));
  }, [dispatch, org, setting]);
  return (
    // <Popconfirm
    //   onConfirm={deleteSettings}
    //   okText="Yes"
    //   cancelText="No"
    //   title="Are you sure to delete this email account?">
    <Button
      size="small"
      onClick={deleteSettings}
      className={className}
      disabled={deleting === FETCHING_STATUS.FETCHING}
      loading={deleting === FETCHING_STATUS.FETCHING}
      type="text">
      Disconnect
    </Button>
    // </Popconfirm>
  );
};

export default DeleteBtn;
