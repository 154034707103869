/**
 * @description Component-OutlokCallBack
 */
import { useAppDispatch } from '@/features/hooks';
import { postOutlookAuthorizeCallback } from '@/features/settings/actions';
import { FC, useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
interface IPropTypes {
  className?: string;
}
const OutlookCallBack: FC<IPropTypes> = function ({}) {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const postCallBack = useCallback(async () => {
    const code = searchParams.get('code');
    const client_info = searchParams.get('client_info');
    const errmsg = searchParams.get('errmsg');
    const state = searchParams.get('state');
    const error = searchParams.get('error');
    if (errmsg || (!errmsg && error)) {
      toast.error(errmsg || error);
      navigate('/dashboard/settings', { replace: true });
    } else if (state === 'success') {
      toast.success('Successfully connected to Outlook');
      navigate('/dashboard/settings', { replace: true });
    } else if (code && client_info) {
      try {
        const result: {success: boolean; redirectURL: string} = await dispatch(
          postOutlookAuthorizeCallback({
            code,
            client_info
          }),
        ).unwrap();
        if (result && result?.redirectURL !== null) {
          window.location.replace(result.redirectURL);
        }
      } catch (e) {
        console.log('🚀 ~ file: index.tsx:32 ~ postCallBack ~ e:', e);
      }
    }
  }, [dispatch, searchParams, navigate]);

  useEffect(() => {
    postCallBack();
  }, [postCallBack]);

  return null;
};

export default OutlookCallBack;
