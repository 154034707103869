import { memo, useEffect } from 'react';
import { useOrg } from '../settings/hooks';
import { AUTH_ACCESS_TOKEN } from '@/constants';
import { io } from 'socket.io-client';
import { useAppDispatch } from '../hooks';
import { setSocket } from './actions';
import { SOCKET_CHANNELS } from '@/constants/sockets';
const URL = process.env.REACT_APP_WSS || '';
function useInitSocket() {
  const org = useOrg();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (org?.id) {
      const authToken = localStorage.getItem(AUTH_ACCESS_TOKEN);
      if (authToken) {
        const socket = io(`${URL}/${SOCKET_CHANNELS.MAIL}?token=${localStorage.getItem(AUTH_ACCESS_TOKEN)}`, {
          transports: ['websocket'],
          upgrade: true,
        });
        socket.connect();
        dispatch(setSocket({ socket, channel: SOCKET_CHANNELS.MAIL }));

        const chatSocket = io(`${URL}/${SOCKET_CHANNELS.CHATBOT}?token=${localStorage.getItem(AUTH_ACCESS_TOKEN)}`, {
          transports: ['websocket'],
          upgrade: true,
        });
        chatSocket.connect();
        dispatch(setSocket({ socket: chatSocket, channel: SOCKET_CHANNELS.CHATBOT }));

        const metaSocket = io(`${URL}/${SOCKET_CHANNELS.META}?token=${localStorage.getItem(AUTH_ACCESS_TOKEN)}`, {
          transports: ['websocket'],
          upgrade: true,
        });
        metaSocket.connect();
        dispatch(setSocket({ socket: metaSocket, channel: SOCKET_CHANNELS.META }));

        const metaReplyInOneSocket = io(
          `${URL}/${SOCKET_CHANNELS.META_IN_ONE}?token=${localStorage.getItem(AUTH_ACCESS_TOKEN)}`,
          {
            transports: ['websocket'],
            upgrade: true,
          },
        );
        metaReplyInOneSocket.connect();
        dispatch(setSocket({ socket: metaReplyInOneSocket, channel: SOCKET_CHANNELS.META_IN_ONE }));
        const liveChatSocket = io(
          `${URL}/${SOCKET_CHANNELS.LIVE_CHAT_MANUAL}?token=${localStorage.getItem(AUTH_ACCESS_TOKEN)}`,
          {
            transports: ['websocket'],
            upgrade: true,
          },
        );
        liveChatSocket.connect();
        dispatch(setSocket({ socket: liveChatSocket, channel: SOCKET_CHANNELS.LIVE_CHAT_MANUAL }));
        const liveChatAssistantSocket = io(
          `${URL}/${SOCKET_CHANNELS.LIVE_CHAT_ASSISTANT}?token=${localStorage.getItem(AUTH_ACCESS_TOKEN)}`,
          {
            transports: ['websocket'],
            upgrade: true,
          },
        );
        liveChatAssistantSocket.connect();
        dispatch(setSocket({ socket: liveChatAssistantSocket, channel: SOCKET_CHANNELS.LIVE_CHAT_ASSISTANT }));
        const liveChaGenerateEmailSocket = io(
          `${URL}/${SOCKET_CHANNELS.GENERATE_EMAIL}?token=${localStorage.getItem(AUTH_ACCESS_TOKEN)}`,
          {
            transports: ['websocket'],
            upgrade: true,
          },
        );
        liveChaGenerateEmailSocket.connect();
        dispatch(setSocket({ socket: liveChaGenerateEmailSocket, channel: SOCKET_CHANNELS.GENERATE_EMAIL }));
      }
    }
  }, [dispatch, org]);
}

function SocketGlobalEffect(): null {
  useInitSocket();
  return null;
}

export default memo(SocketGlobalEffect);
