/**
 * @description Component-Logo
 */
import React, { FC } from 'react';
import './index.less';
import { Link } from 'react-router-dom';
import logo from '@/assets/images/logo1.png';
import logoMobile from '@/assets/images/logo_cornerstone.png';
// import logoMobile from '@/assets/images/logo_cornerstone.png';
import { useMediaQueryDevice } from '@/components/MediaQuery';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const Logo: FC<IPropTypes> = function () {
  const { deviceType, isMobile } = useMediaQueryDevice();
  return (
    <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/" className={`cor-logo ${deviceType}`}>
      <img height={isMobile ? 24 : 66} src={isMobile ? logoMobile : logo} />
    </Link>
  );
};

export default Logo;
