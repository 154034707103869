import { Socket } from 'socket.io-client';
import { useAppSelector } from '../hooks';
import {
  selectMerchants,
  selectMerchantsStatus,
  selectLanguages,
  selectSelectedMerchant,
  selectMerchantSocket,
  selectSessionMessages,
  selectSessionReplying,
  selectMerchantSessionId,
  selectMerchantSessions,
  selectMerchantSessionsStatus,
  selectSessionMessagesStatus,
  selectReplying,
} from './slice';
import { FETCHING_STATUS } from '@/constants';
import { IDemoMessage, IDemoSession, ILanguage, IMerchant } from '@/types/demo';
import { useMemo } from 'react';
import { IMessageRole } from '@/types/aigc';

export function useMerchants(): IMerchant[] | undefined {
  return useAppSelector(selectMerchants);
}

export function useMerchantSocket(merchantId: string | undefined): Socket | undefined {
  return useAppSelector(selectMerchantSocket(merchantId));
}
export function useSessionMessages(sessionId: string | undefined): IDemoMessage[] | undefined {
  const messages = useAppSelector(selectSessionMessages(sessionId));
  return useMemo(() => messages?.filter((m) => m.role !== IMessageRole.STARTER), [messages]);
}
export function useSessionMessagesStatus(sessionId: string | undefined): FETCHING_STATUS | undefined {
  return useAppSelector(selectSessionMessagesStatus(sessionId));
}
export function useMerchantsStatus(): FETCHING_STATUS | undefined {
  return useAppSelector(selectMerchantsStatus);
}
export function useCurrentMerchant(): IMerchant | undefined {
  return useAppSelector(selectSelectedMerchant);
}
export function useSessionReplying(sessionId: string | undefined): boolean {
  return useAppSelector(selectSessionReplying(sessionId));
}
export function useMerchantReplying(merchantId: string | undefined): boolean {
  const merchantSessionId = useMerchantSessionId(merchantId);
  return useSessionReplying(merchantSessionId);
}
export function useReplying(): boolean {
  const replyings = useAppSelector(selectReplying);
  return useMemo(() => replyings.reduce((acc, current) => acc || current, false), [replyings]);
}
export function useLanguages(): ILanguage[] | undefined {
  return useAppSelector(selectLanguages);
}
export function useMerchantSessionId(merchantId: string | undefined): string | undefined {
  return useAppSelector(selectMerchantSessionId(merchantId));
}
export function useMerchantSessions(merchantId: string | undefined): IDemoSession[] | undefined {
  return useAppSelector(selectMerchantSessions(merchantId));
}
export function useMerchantSessionsStatus(merchantId: string | undefined): FETCHING_STATUS | undefined {
  return useAppSelector(selectMerchantSessionsStatus(merchantId));
}
