/**
 * @description Component-Team
 */
import React, { FC, useMemo } from 'react';
import TeamGlobalEffect from '@/features/team/globalEffect';
import { IMember } from '@/types/team';
import moment from 'moment';
import { useOrg } from '@/features/settings/hooks';
import { DATE_FORMAT, FETCHING_STATUS } from '@/constants';
import { Card } from 'antd';
import { ReactComponent as AddIcon } from '@/assets/svg/icon_add_16.svg';
import { ReactComponent as EditIcon } from '@/assets/svg/icon_edit_linear.svg';
import { useTeamMembers, useTeamMembersStatus } from '@/features/team/hooks';
import EditMember from './EditMember';
import './index.less';
import { Table } from 'antd';
import DeleteTeamButton from './DeleteTeamButton';
import { ColumnType } from 'antd/lib/table';
import { EDITABLE_ROLES, MEMBER_ROLE } from '@/constants/team';
import { useAuth0 } from '@auth0/auth0-react';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const Team: FC<IPropTypes> = function () {
  const org = useOrg();
  const { user } = useAuth0();
  const status = useTeamMembersStatus(org?.id);
  const members = useTeamMembers(org?.id);
  const memberFiltered = useMemo(
    () => members?.filter((m) => m.email.toLowerCase() !== user?.email?.toLocaleLowerCase()),
    [members, user?.email],
  );
  const columDefs: ColumnType<IMember>[] = useMemo(() => {
    const cols: ColumnType<IMember>[] = [
      {
        title: 'Email',
        key: 'email',
        align: 'left',
        ellipsis: true,
        render: (_value: any, record: IMember) => record.email,
      },
      {
        title: 'Status',
        key: 'status',
        ellipsis: true,
        align: 'right',
        render: (_value: any, record: IMember) => <span style={{ textTransform: 'capitalize' }}>{record.status}</span>,
      },
      {
        title: 'Created Time',
        key: 'createdAt',
        ellipsis: true,
        align: 'right',
        render: (_value: any, record: IMember) => moment(record.updatedAt).format(DATE_FORMAT),
      },
      {
        title: 'Role',
        key: 'role',
        ellipsis: true,
        align: 'right',
        render: (_value: any, record: IMember) => record.role,
      },
    ];
    if (EDITABLE_ROLES.includes(org?.role as MEMBER_ROLE)) {
      cols.push({
        title: 'Action',
        key: 'action',
        align: 'right',
        render: (_value: any, record: IMember) => (
          <div className="cor-team-actions">
            <EditMember memberToEdit={record} text="Edit" title={'Edit Member'} icon={<EditIcon />} />
            <DeleteTeamButton member={record} />
          </div>
        ),
      });
    }
    return cols;
  }, [org?.role]);
  const pagination = useMemo(() => {
    return {
      total: members?.length,
      showTotal: (total: any) => `Total ${total} items`,
    };
  }, [members]);
  return (
    <div className="cor-team">
      <div className="cor-team-title">Members</div>
      <Card
        bordered={false}
        extra={
          EDITABLE_ROLES.includes(org?.role as MEMBER_ROLE) ? (
            <EditMember text="Invite member" title="Invite Member" type="primary" icon={<AddIcon />} />
          ) : (
            <></>
          )
        }
        className="cor-team-card">
        <Table
          loading={status === FETCHING_STATUS.FETCHING}
          rowKey={(record) => record?.id}
          columns={columDefs}
          dataSource={memberFiltered}
          pagination={pagination}
        />
      </Card>
      <TeamGlobalEffect />
    </div>
  );
};

export default Team;
