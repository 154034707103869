import React, { FC, useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';

import { IMail } from '@/types/email';
import { DATE_FORMAT } from '@/constants';

import './index.less';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  isFrom?: boolean;
  isHtml?: boolean;
  data: IMail;
  onSwitchItemRender: (item: IMail, type: string) => void;
}
const ContentComponent: FC<IPropTypes> = function ({ data, onSwitchItemRender, isFrom }) {
  const [isOverflow, setIsOverflow] = useState<boolean>(false);
  const [isShowOverflowBtn, setIsShowOverflowBtn] = useState<boolean>(false);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const checkHeight = () => {
      const chat = chatContainerRef.current;
      if (chat) {
        const height = chat.getBoundingClientRect().height;
        if (height > 300) {
          setIsShowOverflowBtn(true);
        }
      }
    };

    checkHeight();

    window.addEventListener('resize', checkHeight);

    return () => {
      window.removeEventListener('resize', checkHeight);
    };
  }, [chatContainerRef]);

  return (
    <>
      <div className="cor-chat-content-item-timestamp">
        <div className="date-and-option">
          {isFrom ? (
            <div className="date-and-option-left">
              <span className="name">{data.fromName}</span>
              <span className="date">{dayjs(data.date).format(DATE_FORMAT)}</span>
              {data.aiCategory && <Tag>{data.aiCategory}</Tag>}
            </div>
          ) : (
            <div className="date-and-option-left">
              <span className="date">{dayjs(data.date).format(DATE_FORMAT)}</span>
              <span className="name">{data.fromName}</span>
            </div>
          )}

          {isShowOverflowBtn ? (
            <span className="btn-wrap">
              <Button
                className={!isOverflow ? 'collapse' : 'expand'}
                type="link"
                onClick={() => {
                  if (!isOverflow) {
                    setIsOverflow(true);
                    onSwitchItemRender(data, 'text');
                  } else {
                    setIsOverflow(false);
                    onSwitchItemRender(data, 'html');
                  }
                }}>
                {!isOverflow ? (
                  <>
                    Collapse <CaretUpOutlined />
                  </>
                ) : (
                  <>
                    {' '}
                    Expand <CaretDownOutlined />
                  </>
                )}
              </Button>
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="cor-chat-content-item-text" ref={chatContainerRef}>
        {data.subject ? (
          <div className="cor-chat-content-item-text-subject">
            <span className="subject-1">Subject</span>
            <span className="subject-2">{data.subject}</span>
          </div>
        ) : (
          <></>
        )}
        {data?.html ? (
          <div
            className={`cor-chat-content-item-html ${isOverflow ? 'overflow' : ''}`}
            dangerouslySetInnerHTML={{ __html: data?.html || '' }}
          />
        ) : (
          <pre className={`cor-chat-content-item-html ${isOverflow ? 'overflow' : ''}`}>{data.text}</pre>
        )}
      </div>
    </>
  );
};

export default ContentComponent;
