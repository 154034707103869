import { memo, useEffect } from 'react';
import { useAppDispatch } from '../hooks';
import { getInvoices } from './actions';
function useInitSubscription(): void {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getInvoices());
  }, [dispatch]);
}
function SubscriptionGlobalEffect(): null {
  useInitSubscription();
  return null;
}

export default memo(SubscriptionGlobalEffect);
