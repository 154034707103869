import { createSlice } from '@reduxjs/toolkit';
import { getInvoices } from './actions';
import _ from 'lodash';
import { AppState } from '@auth0/auth0-react';
import { ListArrayStatus, getDefaultListArrayStatus, setFulfilledEndListArrayStatus } from '@/types/redux';
import { FETCHING_STATUS } from '@/constants';
import { IInvoice } from '@/types/subscription';

export interface ISubscriptionState {
  invoices: ListArrayStatus<IInvoice | undefined>;
}

const initialState: ISubscriptionState = {
  invoices: getDefaultListArrayStatus(),
};

export const subscriptionSlice = createSlice({
  name: 'demo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInvoices.pending, (state) => {
        _.set(state.invoices, ['status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getInvoices.fulfilled, (state, { payload }) => {
        state.invoices = setFulfilledEndListArrayStatus(payload);
      });
  },
});

export const selectInvoices = (state: AppState): ListArrayStatus<IInvoice> | undefined => state.subscription.invoices;

export default subscriptionSlice.reducer;
