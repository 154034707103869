/**
 * @description Component-Template
 */
import React, { FC, useMemo } from 'react';
import './index.less';
import { DATE_FORMAT, FETCHING_STATUS } from '@/constants';
import { Card, Spin, Table, Tag, Tooltip, Typography } from 'antd';
import { useOrg, useTemplateFetchingStatus, useTemplates } from '@/features/settings/hooks';
import { ITemplate } from '@/types/settings';
import DeleteTemplate from './DeleteTemplate';
import EditTemplate from './EditTemplate';
import { ReactComponent as AddIcon } from '@/assets/svg/icon_add_16.svg';
import { ReactComponent as EditIcon } from '@/assets/svg/icon_edit_linear.svg';
import SettingDefault from './SettingDefault';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
interface IPropTypes {
  className?: string;
}
const Template: FC<IPropTypes> = function () {
  const org = useOrg();
  const status = useTemplateFetchingStatus(org?.id);
  const templates = useTemplates(org?.id);
  const columDefs: ColumnType<ITemplate>[] = useMemo(
    () => [
      {
        title: 'Name',
        key: 'Name',
        render: (_value: any, record: ITemplate) =>
          record.isDefault ? <span>{record.name}</span> : <span>{record.name}</span>,
      },
      {
        title: 'Updated Time',
        key: 'updated',
        align: 'center',
        render: (_value: any, record: ITemplate) => moment(record.updatedAt).format(DATE_FORMAT),
      },

      {
        title: 'Action',
        key: 'action',
        render: (_value: any, record: ITemplate) => (
          <div className="cor-template-actions">
            {!record.isDefault ? <SettingDefault template={record} /> : <Tag color="#0080ff">default</Tag>}
            <EditTemplate btnType="text" template={record} text="Edit" icon={<EditIcon width={16} height={16} />} />
            <DeleteTemplate template={record} />
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Card
      bordered={false}
      title={<Typography.Title level={4}>Tailor your unique roles, entirely in your control.</Typography.Title>}
      extra={<EditTemplate btnType="primary" text="Add" icon={<AddIcon />} />}
      className="cor-template">
      <Spin spinning={status === FETCHING_STATUS.FETCHING}>
        <Table rowKey={(record, index) => record.id || index || ''} columns={columDefs} dataSource={templates} />
      </Spin>
    </Card>
  );
};

export default Template;
