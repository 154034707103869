/**
 * @description Component-ChatContentItem
 */
import React, { FC } from 'react';
import Avatar from 'react-avatar';
import dayjs from 'dayjs';

import './index.less';
import { IMetaComment } from '@/types/inbox';
import { DATE_FORMAT } from '@/constants';
import classNames from 'classnames';

interface IPropTypes {
  className?: string;
  item: IMetaComment;
  fromId: string | undefined;
  selectComment?: (comment: IMetaComment) => void;
}

const ChatContentItem: FC<IPropTypes> = function ({ item, fromId: fromId }) {
  return (
    <div className={classNames(`cor-chat-meta-content-item`, { commenter: fromId === item.from?.id })}>
      <Avatar className="ant-avatar" round size="24" src={item?.from?.name} name={item?.from?.name} />
      <div className="cor-chat-meta-content-item-content">
        <div className="cor-chat-meta-content-item-content-top">
          <div className="cor-chat-meta-content-item-timestamp">
            <div className="cor-chat-meta-content-item-timestamp-name">{item?.from?.name}</div>
            <div className="date-and-option">
              <span className="date">{dayjs(item?.createdTime).format(DATE_FORMAT)}</span>
            </div>
          </div>
          <div className="cor-chat-meta-content-item-text">{item?.message}</div>
        </div>
      </div>
    </div>
  );
};

export default ChatContentItem;
