export const APP_URL = '';
export enum FETCHING_STATUS {
  INIT = 0,
  FETCHING = 1, // >0 display loading
  DONE = -1,
  COMPLETED = -2, // loading completed no next page
  NO_NEW_DATA = -4,
  BOTTOM = -3, // need scroll bottom
  FAILED = 4,
}

export enum DASHBOARD_MENU_ITEMS {
  PROFILE = 'profile',
  SETTINGS = 'settings',
  MAIL = 'mail',
  USERS = 'users',
  LOG_OUT = 'logout',
  INBOX = 'inbox',
  HOME = 'home',
  META = 'meta',
  TEAM = 'team',
  WEB_CHAT = 'web-chat',
  BRAND_VOICE = 'brand-voice',
  PLUGIN = 'plugin',
  TICKETS = 'tickets',
  SHOP_CONNECT = 'shop-connect',
  ANALYSIS = 'analysis',
  SUBSCRIPTION = 'subscription',
}
export enum SETTINGS_TABS {
  KEYS = 'keys',
  CONNECTIONS = 'connections',
  PROFILE = 'profile',
  TEMPLATE = 'template',
  KNOWLEDGE = 'knowledge',
  PLUGIN = 'plugin',
  SIGNATURE = 'signature',
  BRAND_VOICE = 'brand_voice',
  TEAM = 'team',
}

export const AUTH_ACCESS_TOKEN = 'auth_token';
export enum ERROR_CODE {
  NORMAL = 0,
  ERROR = 1,
  UNAUTHED = 401,
}
export const DATE_FORMAT = 'DD MMM HH:mm';
export const DATE_FORMAT_DETAIL = 'DD MMM HH:MM:ss';

export enum BRAND_STATUS_CODES {
  SUCCESS = 'succeeded',
  FAILED = 'failed',
  PROCESSING = 'processing',
}

export const LANGUAGE_KEY = 'en';

export enum API_MODE {
  UNSHIFT = 'unshift',
  REFRESH = 'refresh',
  APPEND = 'append',
}
