import { MEMBER_ROLE } from '@/constants/team';
import { IPluginSession } from '@/types/plugin';
import { IResponse } from '@/types/settings';
import { IMember } from '@/types/team';
import { axiosGet, axiosPost } from '@/utils/axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
export const setSelectedSession = createAction<{ orgId: string; session: IPluginSession }>('plugin/setSelectedSession');

export const setTeamMembers = createAction<{ orgId?: string; members: IMember[] }>('plugin/setTeamMembers');

export const getTeamMembers = createAsyncThunk(
  'team/getTeamMembers',
  async ({}: { orgId: string }): Promise<IResponse<IMember[]>> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT}team/members`);
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);

export const inviteTeamMember = createAsyncThunk(
  'team/inviteTeamMember',
  async ({ email, role }: { orgId: string; email: string; role: MEMBER_ROLE }): Promise<IResponse<boolean>> => {
    try {
      const result = await axiosPost(`${process.env.REACT_APP_API_ROOT}team/invite`, {
        email,
        role,
      });
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const updateTeamMember = createAsyncThunk(
  'team/updateTeamMember',
  async ({ email, role }: { orgId: string; email: string; role: MEMBER_ROLE }): Promise<IResponse<boolean>> => {
    try {
      const result = await axiosPost(`${process.env.REACT_APP_API_ROOT}team/members/update`, {
        email,
        role,
      });
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const deleteTeamMember = createAsyncThunk(
  'team/deleteTeamMember',
  async ({ email, orgId }: { orgId: string; email: string }, { dispatch }): Promise<boolean> => {
    try {
      const result = await axiosPost(`${process.env.REACT_APP_API_ROOT}team/members/remove`, {
        email,
      });
      dispatch(getTeamMembers({ orgId }));
      return result.data;
    } catch (e) {
      return false;
    }
  },
);
