/**
 * @description Component-FormatedDate
 */
import React, { FC, useMemo } from 'react';
import moment from 'moment';
import './index.less';
interface IPropTypes {
  date: string | undefined;
  className?: string;
}
const FormattedDate: FC<IPropTypes> = function ({ date }) {
  const dateDisplay = useMemo(() => {
    const now = moment();
    const dateMoment = moment(date);
    if (date) {
      if (now.year() !== dateMoment.year()) {
        return dateMoment.format('DD/MM/YY');
      }
      if (now.month() !== dateMoment.month() || now.date() !== dateMoment.date()) {
        return dateMoment.format('DD MMM');
      }
      return dateMoment.format('HH:mm');
    }
  }, [date]);
  if (!date) {
    return <></>;
  }
  return <div className="cor-formated-date">{dateDisplay}</div>;
};

export default FormattedDate;
