import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch } from '../hooks';
import { memo, useEffect } from 'react';
import { getUserInfo, getUserPolicies } from './action';
import { useAuthToken } from '../auth/hooks';

function useGetUserInfo() {
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  const authToken = useAuthToken();
  useEffect(() => {
    if (user?.email && authToken) {
      dispatch(getUserInfo({ email: user.email }));
      dispatch(getUserPolicies());
    }
  }, [dispatch, user, authToken]);
}

function UserGlobalEffect(): null {
  useGetUserInfo();
  return null;
}

export default memo(UserGlobalEffect);
