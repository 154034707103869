/**
 * @description Component-KnowledgeTable
 */
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import './index.less';
import { Card, Table, Tooltip, Typography } from 'antd';
import EditKnowledge from './EditKnowledgeWebpage';
import { ReactComponent as AddIcon } from '@/assets/svg/icon_add_16.svg';
import { useKnowledgeWebpages, useKnowledgeWebpagesStatus, useOrg } from '@/features/settings/hooks';
import { IKnowledgeBase } from '@/types/settings';
import DeleteBtn from './DeleteWebpageBtn';
import { DATE_FORMAT, FETCHING_STATUS } from '@/constants';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
import { useAppDispatch } from '@/features/hooks';
import { getKnowledgeWebpages, updateKnowledgeWebpage } from '@/features/settings/actions';
import ViewWebpageKnowledge from './ViewKnowledgeWebpage';
import { pollingFunc } from '@/utils/axios';
import { CRAWLING_STATUS } from '@/constants/settings';
import classNames from 'classnames';
import LoadingIco from '@/components/LoadingIco';
import { useNavigate } from 'react-router-dom';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
let polling: NodeJS.Timeout;
const KnowledgeTable: FC<IPropTypes> = function () {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const org = useOrg();
  const status = useKnowledgeWebpagesStatus(org?.id);
  const bases = useKnowledgeWebpages(org?.id);
  const updateWebpage = useCallback(
    (record: IKnowledgeBase) => {
      org &&
        dispatch(
          updateKnowledgeWebpage({
            org: org.id,
            knowledge: { name: record.name, id: record.id },
          }),
        );
    },
    [dispatch, org],
  );
  const stopPropagation = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
  };
  const columDefs: (ColumnType<IKnowledgeBase> & { editable?: boolean })[] = useMemo(
    () => [
      {
        title: 'Name',
        key: 'Name',
        align: 'left',
        editable: true,
        width: 110,
        ellipsis: true,
        onCell: (record: IKnowledgeBase) => ({
          record,
          editable: true,
          dataIndex: 'name',
          title: 'Name',
          handleSave: updateWebpage,
        }),
        render: (_value: any, record: IKnowledgeBase) => record.name,
      },
      {
        title: 'Url',
        key: 'content',
        align: 'center',
        ellipsis: true,
        render: (_value: any, record: IKnowledgeBase) => <Tooltip title={record.knowledge}>{record.knowledge}</Tooltip>,
      },
      {
        title: 'Status',
        key: 'status',
        align: 'center',
        render: (_value: any, record: IKnowledgeBase) => (
          <div className={classNames('cor-knowledge-base-status', record.status)}>
            {(record.status === CRAWLING_STATUS.CRAWLING ||
              record.status === CRAWLING_STATUS.SUBMITTED ||
              record.status === CRAWLING_STATUS.PROCESSING) && <LoadingIco />}
            {record.status}
            {/* {record.status === CRAWLING_STATUS.SUCCEED && (
              <span className="label">{record.result?.urls?.length || 0}</span>
            )} */}
          </div>
        ),
      },
      {
        title: 'Updated Time',
        key: 'updated',
        width: 110,
        render: (_value: any, record: IKnowledgeBase) => moment(record.updatedAt).format(DATE_FORMAT),
      },

      {
        title: 'Action',
        key: 'action',
        align: 'right',
        width: 80,
        render: (_value: any, record: IKnowledgeBase) => (
          <div className="cor-knowledge-base-actions">
            <ViewWebpageKnowledge status={record.status || ''} name={record.name} knowledge={record.result?.urls} />
            <DeleteBtn org={org?.id} knowledge={record} />
          </div>
        ),
        onCell: () => ({
          onClick: stopPropagation, // 阻止事件冒泡
        }),
      },
    ],
    [org?.id, updateWebpage],
  );
  const crawling = useMemo(
    () =>
      bases.reduce(
        (crawling, curr) =>
          crawling || (curr.status !== CRAWLING_STATUS.FAILED && curr.status !== CRAWLING_STATUS.SUCCEED),
        false,
      ),
    [bases],
  );
  console.log('🚀 ~ file: KnowledgeWebpageTable.tsx:111 ~ crawling:', crawling);

  const pagination = useMemo(() => {
    return {
      total: bases.length,
      showTotal: (total: any) => `Total ${total} items`,
    };
  }, [bases]);

  useEffect(() => {
    polling && clearInterval(polling);
    if (crawling && org) {
      polling = pollingFunc(async () => {
        dispatch(getKnowledgeWebpages({ org: org.id })).unwrap();
      }, 15000);
    }
    return () => polling && clearInterval(polling);
  }, [dispatch, org, crawling]);
  const rowClassName = () => 'custom-table-row';
  return (
    <Card
      bordered={false}
      title={<Typography.Title level={4}>Webpage List</Typography.Title>}
      extra={<EditKnowledge text="Add" type="primary" title="Add Webpage" icon={<AddIcon />} />}
      className="cor-knowledge-table">
      <Table
        loading={status === FETCHING_STATUS.FETCHING}
        rowKey={(record) => record?.id + record?.name + record.status}
        columns={columDefs}
        dataSource={bases}
        pagination={pagination}
        rowClassName={rowClassName}
        onRow={(record) => ({
          onClick: () => {
            if(record.status!==CRAWLING_STATUS.SUCCEED){
              return
            }
            navigate(`/dashboard/settings/webpage/${record.id}`); // 根据记录的 ID 跳转到 /dashboard/:id
          },
        })}
      />
    </Card>
  );
};

export default KnowledgeTable;
